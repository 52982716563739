import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject, Subscription} from "rxjs";
import {TableColumnEnum} from "@enum/table-column/table-column.enum";
import {TableFilterEnum} from "@enum/table-filter/table-filter.enum";
import {TableStateInterface} from "@interface/common/table-state.interface";
import {EventService} from "@service/common/event.service";
import {ToastService} from "@service/toast.service";
import {UtilsService} from "@service/utils/utils.service";
import {SortDirection} from "@type/common/sort-direction.type";
import {catchError, debounceTime, map, switchMap, tap} from "rxjs/operators";
import {StreakApiService} from "@service/streak/streak-api.service";
import {EventEnum} from "@enum/event/event.enum";

@Injectable({
  providedIn: 'root'
})
export class StreakService {
  public _delete$ = new Subject<any>();
  public _create$ = new Subject<string>();
  public _search$ = new Subject<void>();
  public visibleColumns: { visible: boolean, key: TableColumnEnum, label: any }[] = this.utils.streaksTableColumn;
  public visibleFilters: { visible: boolean, key: TableFilterEnum, label: any }[] = this.utils.streaksTableFilters;
  private _currentTableState: TableStateInterface = this.utils.tableDefaultState;
  private searchStreakSubscription: Subscription;
  private createStreakSubscription: Subscription;
  private deleteStreakSubscription: Subscription;

  constructor(private streakApiService: StreakApiService, private eventService: EventService,
              private toastService: ToastService, private utils: UtilsService) {
  }

  private _creating$ = new BehaviorSubject<boolean>(false);

  get creating$() {
    return this._creating$.asObservable();
  }

  private _deleting$ = new BehaviorSubject<boolean>(false);

  get deleting$() {
    return this._deleting$.asObservable();
  }


  private _loading$ = new BehaviorSubject<boolean>(true);

  public get loading$() {
    return this._loading$.asObservable();
  }

  private _streakList$ = new BehaviorSubject<any[]>([]);

  public get streakList$() {
    return this._streakList$.asObservable();
  }

  private _totalRecords$ = new BehaviorSubject<number>(0);

  public get totalRecords$() {
    return this._totalRecords$.asObservable();
  }

  get columns() {
    return this.visibleColumns;
  }

  get filters() {
    return this.visibleFilters;
  }

  public get searchTerm() {
    return this._currentTableState.searchTerm;
  }

  public set searchTermLocal(searchTerm: string) {
    this._currentTableState.searchTerm = searchTerm;
  }

  public set searchTerm(searchTerm: string) {
    this._setValue({searchTerm});
  }

  public get pageSize() {
    return this._currentTableState.pageSize;
  }

  public set pageSize(pageSize: number) {
    const page = 1;
    this._setValue({page})
    this._setValue({pageSize});
  }

  public get page() {
    return this._currentTableState.page;
  }

  public set page(page: number) {
    this._setValue({page});
  }

  public get sortColumn() {
    return this._currentTableState.sortColumn;
  }

  public set sortColumn(sortColumn: string) {
    this._setValue({sortColumn});
  }

  public get sortDirection() {
    return this._currentTableState.sortDirection;
  }

  public set sortDirection(sortDirection: SortDirection) {
    this._setValue({sortDirection});
  }

  public delete(data: any): void {
    this._delete$.next(data);
  }


  public initDeleteListener(): void {
    this.deleteStreakSubscription = this._delete$.pipe(
      tap(() => this._deleting$.next(true)),
      tap(() => this._loading$.next(true)),
      switchMap((streak) => this.streakApiService.deleteStreak(streak).pipe(
        map((result) => {
          this._deleting$.next(false);
          return this.modalSuccess(result, EventEnum.CLOSE_DELETE_MODAL, 'Streak deleted successfully');
        }),
        catchError((err, caught) => {
          this._deleting$.next(false);
          return this.modalError(err, EventEnum.CLOSE_DELETE_MODAL);
        })
      )),
      tap(() => this._deleting$.next(false)),
    ).subscribe((result) => {
    });
  }

  public initSearchListener(): void {
    this.searchStreakSubscription = this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(50),
      switchMap(() => this.streakApiService.getStreakList(this._extractSearchParams())),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      const streaks = result?.data?.map((streak: any) => {
        streak.alreadyRunning = new Date(streak?.sinceDate) < new Date();
        return streak;
      });
      this._streakList$.next(streaks);
      this._totalRecords$.next(result?.size);
    });
  }

  public removeSearchStreakSubscribe(): void {
    this.searchStreakSubscription?.unsubscribe();
    this._loading$.next(false);
  }

  public clearFilters(): void {
    this.searchTerm = undefined;
    this.action = undefined;
    this.action = undefined;
    this.validForCompetitions = undefined;
    this.credits = undefined;
    this.validityDate = undefined;
  }

  public isFilterApplied(): boolean {
    const params: any = this._extractSearchParams();
    if (Object.keys(params?.filters)?.length > 0) {
      const obj = this.utils.clearObject(params?.filters);
      return Object.keys(obj)?.length > 0;
    } else {
      return false;
    }
  }

  get action() {
    return this._currentTableState.action;
  }

  set action(action: string) {
    this._setValue({action});
  }

  get validForCompetitions() {
    return this._currentTableState.validForCompetitions;
  }

  set validForCompetitions(validForCompetitions: boolean | undefined | null) {
    this._setValue({validForCompetitions});
  }

  get credits() {
    return this._currentTableState.credits;
  }

  set credits(credits: string[]) {
    this._setValue({credits});
  }


  get validityDate() {
    return this._currentTableState.validityDate;
  }

  set validityDate(validityDate: string[]) {
    this._setValue({validityDate});
  }


  private _extractSearchParams(): any {
    return {
      filters: {
        query: this.searchTerm ? [this.searchTerm] : undefined,
        action: (this.action !== undefined && this.action !== null) ? [this.action] : undefined,
        validForCompetitions: (this.validForCompetitions !== undefined && this.validForCompetitions !== null) ? [this.validForCompetitions] : undefined,
        credits: this.credits && this.credits?.length > 0 ? this.credits : undefined,
        validityDate: this.validityDate && this.validityDate?.length > 0 ? this.validityDate : undefined,
      },
      sort: this.extractSorting(),
      page: this.page,
      size: this.pageSize
    }
  }

  public getFitnessScoring(): any {
    return this.streakApiService.getTemplateFitnessScoringFunctions(null);
  }

  public createStreak(data: any): void {
    this._create$.next(data);
  }

  public initCreateListener(): void {
    this.createStreakSubscription = this._create$.pipe(
      tap(() => this._creating$.next(true)),
      tap(() => this._loading$.next(true)),
      switchMap((data: any) => this.streakApiService.createStreak(data).pipe(
        map((result) => {
          if (result?.length > 0) {
            result.map((error) => {
              this.toastService.show(error?.cause, {classname: 'bg-danger text-light'});
              return error;
            });
            this.eventService.broadcast(EventEnum.CLOSE_CREATE_STREAK, null)
            this._search$.next();
            return result;
          } else {
            const message = data?.id ? 'Streak edited successfully' : 'Streak created successfully';
            return this.modalSuccess(result, EventEnum.CLOSE_CREATE_STREAK, message);
          }
        }),
        catchError((err, caught) => {
          this._creating$.next(false);
          return this.modalError(err, EventEnum.CLOSE_CREATE_STREAK);
        })
      )),
      tap(() => this._creating$.next(false))
    ).subscribe((result) => {
    });
  }

  private modalSuccess(result, modalEvent: EventEnum, message: string) {
    this.toastService.show(message, {classname: 'bg-success text-light'});
    this.eventService.broadcast(modalEvent, null)
    this._search$.next();
    return result;
  }

  private modalError(err, modalEvent: EventEnum) {
    this.eventService.broadcast(modalEvent, null)
    this.toastService.show(err, {classname: 'bg-danger text-light'});
    this._search$.next();
    return err;
  }

  public removeStreakCreateSubscribe(): void {
    this.createStreakSubscription?.unsubscribe();
    this._creating$.next(false);
  }

  public removeDeleteSubscribe(): void {
    this.deleteStreakSubscription?.unsubscribe();
    this._loading$.next(false);
  }

  private extractSorting(): string {
    return this.utils.extractSorting(this.sortColumn, this.sortDirection);
  }

  private _setValue(patch: Partial<TableStateInterface>) {
    Object.assign(this._currentTableState, patch);
    this._search$.next();
  }
}

import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {EventService} from "@service/common/event.service";
import {FaqsService} from "@service/faqs/faqs.service";
import {EventEnum} from "@enum/event/event.enum";
import {UtilsService} from "@service/utils/utils.service";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {DeleteModalTypeEnum} from "@enum/delete-modal-type/delete-modal-type.enum";

@Component({
  selector: 'app-add-faq-modal',
  templateUrl: './add-faq-modal.component.html',
  styleUrls: ['./add-faq-modal.component.scss']
})
export class AddFaqModalComponent implements OnInit, OnDestroy {
  @Input() faq: any;
  @ViewChild('modal') private modalContent: TemplateRef<any>
  public Editor = ClassicEditor
  public creatingFaq$: Observable<boolean>;
  public formSubmitted: boolean;
  public faqForm: FormGroup;
  private modalRef: NgbModalRef;
  private closeModalSubscription: Subscription;

  constructor(private modalService: NgbModal, private eventService: EventService,
              private utils: UtilsService,
              public formBuilder: FormBuilder, private faqsService: FaqsService) { }

  get form() {
    return this.faqForm.controls;
  }

  ngOnInit(): void {
    this._closeModal();
    this.creatingFaq$ = this.faqsService?.creating$;
    this.initForm();
  }

  private initForm() {
    this.faqForm = this.formBuilder.group({
      active: [false],
      title: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(100)]],
      htmlText: [null, [Validators.required, Validators.minLength(5)]],
      priority: [null, [Validators.required]],
      linkText: [null],
      linkUrl: [null, [Validators.pattern(this.utils.validateUrlRegex)]],
    });
  }

  public open(data?: any): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, {size: 'lg', centered: true, backdrop: false})
      this.modalRef.result.then(resolve, resolve);
      if (data?.id) {
        this.setFaq(data);
      }
    })
  }

  public validSubmit() {
    if (this.faqForm.valid) {
      let formData = this.faqForm.getRawValue();
      this.faqsService?.initCreateListener();
      if (this.faq?.id) {
        formData.id = this.faq?.id;
      }
      this.faqsService.createEditFaq(formData);
    }
  }

  public createFaq() {
    this.formSubmitted = true;
  }

  public setFaq(faq: any): void {
    this.faq = faq;
    this.patchFormWithEditingData();
  }

  private patchFormWithEditingData() {
    this.faqForm.patchValue({
      active: this.faq?.active,
      title: this.faq?.title,
      htmlText: this.faq?.htmlText,
      priority: this.faq?.priority,
      linkText: this.faq?.linkText,
      linkUrl: this.faq?.linkUrl
    });
    if (this.faq.duplicate) {
      this.faq = undefined;
    }
  }

  ngOnDestroy() {
    if (this.closeModalSubscription) {
      this.closeModalSubscription.unsubscribe();
    }
  }

  private _closeModal(): void {
    this.closeModalSubscription = this.eventService.subscribe(EventEnum.CLOSE_CREATE_FAQ, (reason: string | undefined) => {
      this.faqsService?.removeFaqCreateSubscribe();
      this.initForm();
      this.formSubmitted = false;
      this.faq = undefined;
      this.modalRef?.dismiss(reason);
    });
  }
}

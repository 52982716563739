import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject, Subscription} from "rxjs";
import {TableStateInterface} from "@interface/common/table-state.interface";
import {UserElementInterface} from "@interface/user/user-element.interface";
import {UtilsService} from "@service/utils/utils.service";
import {UsersApiService} from "@service/users/users-api.service";
import {catchError, debounceTime, map, switchMap, tap} from "rxjs/operators";
import {SortDirection} from "@type/common/sort-direction.type";
import {TableColumnEnum} from "@enum/table-column/table-column.enum";
import {TableFilterEnum} from "@enum/table-filter/table-filter.enum";
import {TranslateService} from "@ngx-translate/core";
import {EventEnum} from "@enum/event/event.enum";
import {EventService} from "@service/common/event.service";
import {ToastService} from "@service/toast.service";

@Injectable({
  providedIn: 'root'
})
export class UserBannedListService {
  public _search$ = new Subject<void>();
  public _delete$ = new Subject<void>();
  public _removeBan$ = new Subject<UserElementInterface[]>();
  public visibleColumns: { visible: boolean, key: TableColumnEnum, label: any }[] = this.utils.banTableColumns;
  public visibleFilters: { visible: boolean, key: TableFilterEnum, label: any }[] = this.utils.banTableFilters;
  private _currentTableState: TableStateInterface = this.utils.tableDefaultState;
  private searchBanSubscription: Subscription;
  private createEditBanSubscription: Subscription;
  private removeBanSubscription: Subscription;

  constructor(private utils: UtilsService, private usersApiService: UsersApiService,
              private translate: TranslateService, private eventService: EventService, private toastService: ToastService) {
    this.initDeleteListener();
  }

  private _loading$ = new BehaviorSubject<boolean>(true);

  get loading$() {
    return this._loading$.asObservable();
  }

  private _deleting$ = new BehaviorSubject<boolean>(false);

  get deleting$() {
    return this._deleting$.asObservable();
  }

  private _removingBan$ = new BehaviorSubject<boolean>(false);

  get removingBan$() {
    return this._removingBan$.asObservable();
  }

  private _totalRecords$ = new BehaviorSubject<number>(0);

  get totalRecords$() {
    return this._totalRecords$.asObservable();
  }


  get columns() {
    return this.visibleColumns;
  }

  get filters() {
    return this.visibleFilters;
  }

  private _userList$ = new BehaviorSubject<UserElementInterface[]>([]);

  get userList$() {
    return this._userList$.asObservable();
  }

  get page() {
    return this._currentTableState.page;
  }

  set page(page: number) {
    this._setValue({page});
  }

  get sortColumn() {
    return this._currentTableState.sortColumn;
  }

  set sortColumn(sortColumn: string) {
    this._setValue({sortColumn});
  }

  get sortDirection() {
    return this._currentTableState.sortDirection;
  }

  set sortDirection(sortDirection: SortDirection) {
    this._setValue({sortDirection});
  }

  get pageSize() {
    return this._currentTableState.pageSize;
  }

  set pageSize(pageSize: number) {
    const page = 1;
    this._setValue({page})
    this._setValue({pageSize});
  }

  get userId() {
    return this._currentTableState.userId;
  }

  set userId(userId: number) {
    this._setValue({userId});
  }

  get searchTerm() {
    return this._currentTableState.searchTerm;
  }

  set searchTerm(searchTerm: string) {
    this._setValue({searchTerm});
  }

  get banStartDate() {
    return this._currentTableState.banStartDate;
  }

  set banStartDate(banStartDate: string[]) {
    this._setValue({banStartDate});
  }

  get banEndDate() {
    return this._currentTableState.banEndDate;
  }

  set banEndDate(banEndDate: string[]) {
    this._setValue({banEndDate});
  }


  get company() {
    return this._currentTableState.company;
  }

  set company(company: string) {
    this._setValue({company});
  }

  get banned() {
    return this._currentTableState.banned;
  }

  set banned(banned: boolean | undefined | null) {
    this._setValue({banned});
  }


  get bannedBySystem() {
    return this._currentTableState.systemBan;
  }

  set bannedBySystem(systemBan: boolean | undefined | null) {
    this._setValue({systemBan});
  }

  get fitnessKit() {
    return this._currentTableState.fitnessKit;
  }

  set fitnessKit(fitnessKit: string | undefined) {
    this._setValue({fitnessKit});
  }

  get startIndex() {
    return this._currentTableState.startIndex;
  }

  set startIndex(startIndex: number) {
    this._setValue({startIndex});
  }

  get endIndex() {
    return this._currentTableState.endIndex;
  }

  set endIndex(endIndex: number) {
    this._setValue({endIndex});
  }

  get totalRecords() {
    return this._currentTableState.totalRecords;
  }

  set totalRecords(totalRecords: number) {
    this._setValue({totalRecords});
  }

  public removeUsersBan(users: UserElementInterface[]): void {
    this._removeBan$.next(users);
  }

  public removeBanUnsubscribe(): void {
    this.removeBanSubscription?.unsubscribe();
    this._removingBan$.next(false);
  }

  public initRemoveBanListener(): void {
    this.removeBanSubscription = this._removeBan$.pipe(
      tap(() => this._removingBan$.next(true)),
      tap(() => this._loading$.next(true)),
      switchMap((banData) => this.usersApiService.removeBan(banData).pipe(
        map((result) => {
          if (result?.length > 0) {
            result.map((error) => {
              this.toastService.show(error?.cause, {classname: 'bg-danger text-light'});
              return error;
            });
            this.eventService.broadcast(EventEnum.CLOSE_REMOVE_BAN_MODAL, null)
            this._search$.next();
            return result;
          } else {
            return this.modalSuccess(result, EventEnum.CLOSE_REMOVE_BAN_MODAL, 'Ban removed successfully');
          }
        }),
        catchError((err, caught) => {
          return this.modalError(err, EventEnum.CLOSE_REMOVE_BAN_MODAL);
        })
      )),
      tap(() => this._removingBan$.next(false))
    ).subscribe(() => {
    });
  }

  public delete(data: any): void {
    this._delete$.next(data);
  }

  public initDeleteListener(): void {
    this._delete$.pipe(
      tap(() => this._deleting$.next(true)),
      tap(() => this._loading$.next(true)),
      switchMap((banData: any) => this.usersApiService.deleteUsers(banData).pipe(
        map((result) => {
          return this.modalSuccess(result, EventEnum.CLOSE_DELETE_MODAL, 'User deleted successfully');
        }),
        catchError((err, caught) => {
          return this.modalError(err, EventEnum.CLOSE_DELETE_MODAL);
        })
      )),
      tap(() => this._deleting$.next(false))
    ).subscribe(() => {
    });
  }

  public removeSearchSubscribe(): void {
    this.searchBanSubscription?.unsubscribe();
    this._loading$.next(false);
  }

  public initSearchListener(): void {
    this.searchBanSubscription = this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(50),
      switchMap(() => this.usersApiService.getUserBans(this._extractSearchParams())),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      this._userList$.next(result.data);
      this._totalRecords$.next(result.size);
    });
  }

  public isFilterApplied(): boolean {
    const params: any = this._extractSearchParams();
    if (Object.keys(params?.filters)?.length > 0) {
      const obj = this.utils.clearObject(params?.filters);
      return Object.keys(obj)?.length > 0;
    } else {
      return false;
    }
  }

  public clearFilters(): void {
    this.banStartDate = undefined;
    this.company = undefined;
    this.banned = undefined;
    this.banEndDate = undefined;
    this.userId = undefined;
    this.searchTerm = undefined;
  }

  private modalError(err, modalEvent: EventEnum) {
    this.eventService.broadcast(modalEvent, null)
    this.toastService.show(err, {classname: 'bg-danger text-light'});
    this._search$.next();
    return err;
  }

  private modalSuccess(result, modalEvent: EventEnum, message: string) {
    this.toastService.show(message, {classname: 'bg-success text-light'});
    this.eventService.broadcast(modalEvent, null)
    this._search$.next();
    return result;
  }

  private _setValue(patch: Partial<TableStateInterface>) {
    Object.assign(this._currentTableState, patch);
    this._search$.next();
  }

  private _extractSearchParams(): any {
    return {
      filters: {
        query: this.searchTerm ? [this.searchTerm] : undefined,
        userId: this.userId ? ["EQ", Number(this.userId)] : undefined,
      },
      bannedAtFrom: this.banStartDate && this.banStartDate?.length > 0 ? this.updateDate(this.banStartDate)[0] : undefined,
      bannedAtTo: this.banStartDate && this.banStartDate?.length > 0 ? this.updateDate(this.banStartDate)[1] : undefined,
      unBannedAtFrom: this.banEndDate && this.banEndDate?.length > 0 ? this.updateDate(this.banEndDate)[0] : undefined,
      unBannedAtTo: this.banEndDate && this.banEndDate?.length > 0 ? this.updateDate(this.banEndDate)[1] : undefined,
      userIsCurrentlyBanned: (this.banned !== undefined && this.banned !== null) ? this.banned : undefined,
      systemBan: (this.bannedBySystem !== undefined && this.bannedBySystem !== null) ? this.bannedBySystem : undefined,
      sort: this.extractSorting(),
      page: this.page,
      size: this.pageSize
    }
  }

  private updateDate(date: any[]): any {
    return date.filter(element => element !== "BETWEEN");
  }

  private extractSorting(): string {
    return this.utils.extractSorting(this.sortColumn, this.sortDirection);
  }
}

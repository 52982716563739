import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {UserElementInterface} from "@interface/user/user-element.interface";
import {DeleteModalTypeEnum} from "@enum/delete-modal-type/delete-modal-type.enum";
import {EventEnum} from "@enum/event/event.enum";
import {EventService} from "@service/common/event.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-users-recap',
  templateUrl: './users-recap.component.html',
  styleUrls: ['./users-recap.component.scss']
})
export class UsersRecapComponent implements OnInit, OnChanges, OnDestroy {
  @Input() users: UserElementInterface[];
  @Input() description: string;
  @Input() massiveAction?: boolean;
  @Output() sendMassive = new EventEmitter();
  private removeUserSubscription: Subscription;
  public type = DeleteModalTypeEnum.SCHEDULED_NOTIFICATION;
  constructor(private eventService: EventService) {
  }

  ngOnInit(): void {
    this.removeUserSubscription = this.eventService.subscribe(EventEnum.REMOVE_USER_FROM_NOTIFICATION_LIST, (selectedUser: any) => {
      if (this.users?.length > 0) {
        this.users = this.users.filter((user) => user !== selectedUser);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  ngOnDestroy() {
    this.removeUserSubscription?.unsubscribe();
  }

  public sendMassiveNotification(): void {
    this.sendMassive.emit();
  }

}

import {Injectable} from "@angular/core";
import {TableStateInterface} from "@interface/common/table-state.interface";
import {UtilsService} from "@service/utils/utils.service";
import {BehaviorSubject, Subject, Subscription} from "rxjs";
import {catchError, debounceTime, map, switchMap, tap} from "rxjs/operators";
import {UtilityApiService} from "@service/utility/utility-api.service";
import {SortDirection} from "@type/common/sort-direction.type";
import {TableColumnEnum} from "@enum/table-column/table-column.enum";
import {TableFilterEnum} from "@enum/table-filter/table-filter.enum";
import {EventEnum} from "@enum/event/event.enum";
import {ToastService} from "@service/toast.service";
import {EventService} from "@service/common/event.service";

@Injectable({
  providedIn: 'root'
})
export class QrCodeService {
  public _search$ = new Subject<void>();
  public _create$ = new Subject<string>();
  public _delete$ = new Subject<any>();
  public visibleColumns: { visible: boolean, key: TableColumnEnum, label: any }[] = this.utils.qrTableColumns;
  public visibleFilters: { visible: boolean, key: TableFilterEnum, label: any }[] = this.utils.qrTableFilters;
  private _currentTableState: TableStateInterface = this.utils.tableDefaultState;
  private createQrSubscription: Subscription;

  constructor(private utils: UtilsService, private utilityApiService: UtilityApiService,
              private toastService: ToastService, private eventService: EventService) {
    this.initSearchListener();
  }

  private _loading$ = new BehaviorSubject<boolean>(true);

  get loading$() {
    return this._loading$.asObservable();
  }

  private _totalRecords$ = new BehaviorSubject<number>(0);

  get totalRecords$() {
    return this._totalRecords$.asObservable();
  }

  private _qrList$ = new BehaviorSubject<any[]>([]);

  get qrList$() {
    return this._qrList$.asObservable();
  }

  private _creating$ = new BehaviorSubject<boolean>(false);

  get creating$() {
    return this._creating$.asObservable();
  }

  get columns() {
    return this.visibleColumns;
  }

  get filters() {
    return this.visibleFilters;
  }

  get searchTerm() {
    return this._currentTableState.searchTerm;
  }

  set searchTerm(searchTerm: string) {
    this._setValue({searchTerm});
  }

  get sortColumn() {
    return this._currentTableState.sortColumn;
  }

  set sortColumn(sortColumn: string) {
    this._setValue({sortColumn});
  }

  get sortDirection() {
    return this._currentTableState.sortDirection;
  }

  set sortDirection(sortDirection: SortDirection) {
    this._setValue({sortDirection});
  }

  get page() {
    return this._currentTableState.page;
  }

  set page(page: number) {
    this._setValue({page});
  }

  get pageSize() {
    return this._currentTableState.pageSize;
  }

  set pageSize(pageSize: number) {
    const page = 1;
    this._setValue({page})
    this._setValue({pageSize});
  }

  get assignedCredits() {
    return this._currentTableState.assignedCredits;
  }

  set assignedCredits(assignedCredits: string[]) {
    this._setValue({assignedCredits});
  }

  get numberOfUses() {
    return this._currentTableState.numberOfUses;
  }

  set numberOfUses(numberOfUses: string[]) {
    this._setValue({numberOfUses});
  }

  get active() {
    return this._currentTableState.active;
  }

  set active(active: boolean | undefined | null) {
    this._setValue({active});
  }

  get singleUseForUser() {
    return this._currentTableState.singleUseForUser;
  }

  set singleUseForUser(singleUseForUser: boolean | undefined | null) {
    this._setValue({singleUseForUser});
  }

  public createQrCode(data: any): void {
    this._create$.next(data);
  }

  public removeQrCreateSubscribe(): void {
    this.createQrSubscription?.unsubscribe();
    this._creating$.next(false);
  }

  public initCreateListener(): void {
    this.createQrSubscription = this._create$.pipe(
      tap(() => this._creating$.next(true)),
      tap(() => this._loading$.next(true)),
      switchMap((data: any) => this.utilityApiService.createQrCode(data).pipe(
        map((result) => {
          if (result?.length > 0) {
            result.map((error) => {
              this.toastService.show(error?.cause, {classname: 'bg-danger text-light'});
              return error;
            });
            this.eventService.broadcast(EventEnum.CLOSE_QR_CREATE_MODAL, null)
            this._search$.next();
            return result;
          } else {
            const message = data?.id ? 'QR edited successfully' : 'QR created successfully';
            return this.modalSuccess(result, EventEnum.CLOSE_QR_CREATE_MODAL, message);
          }
        }),
        catchError((err, caught) => {
          this._creating$.next(false);
          return this.modalError(err, EventEnum.CLOSE_QR_CREATE_MODAL);
        })
      )),
      tap(() => this._creating$.next(false))
    ).subscribe((result) => {
    });
  }

  public initSearchListener(): void {
    this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(50),
      switchMap(() => this.utilityApiService.getQrList(this._extractSearchParams())),
      tap(() => this._loading$.next(false))
    ).subscribe((result) => {
      let codes = result?.data?.map((qrCode) => {
        qrCode.languages = this.utils.getAvailableLang(qrCode?.localizableKey);
        return qrCode;
      });
      this._qrList$.next(codes);
      this._totalRecords$.next(result.size);
    });
  }

  public isFilterApplied(): boolean {
    const params: any = this._extractSearchParams();
    if (Object.keys(params?.filters)?.length > 0) {
      const obj = this.utils.clearObject(params?.filters);
      return Object.keys(obj)?.length > 0;
    } else {
      return false;
    }
  }

  private _setValue(patch: Partial<TableStateInterface>) {
    Object.assign(this._currentTableState, patch);
    this._search$.next();
  }

  private modalSuccess(result, modalEvent: EventEnum, message: string) {
    this.toastService.show(message, {classname: 'bg-success text-light'});
    this.eventService.broadcast(modalEvent, null)
    this._search$.next();
    return result;
  }

  private modalError(err, modalEvent: EventEnum) {
    this.eventService.broadcast(modalEvent, null)
    this.toastService.show(err, {classname: 'bg-danger text-light'});
    this._search$.next();
    return err;
  }

  private extractSorting(): string {
    return this.utils.extractSorting(this.sortColumn, this.sortDirection);
  }

  private _extractSearchParams(): any {
    return {
      filters: {
        query: this.searchTerm ? [this.searchTerm] : undefined,
        active: (this.active !== undefined && this.active !== null) ? [this.active] : undefined,
        singleUseForUser: (this.singleUseForUser !== undefined && this.singleUseForUser !== null) ? [this.singleUseForUser] : undefined,
        amount: this.assignedCredits && this.assignedCredits?.length > 0 ? this.assignedCredits : undefined,
        numberOfUsers: this.numberOfUses && this.numberOfUses?.length > 0 ? this.numberOfUses : undefined,
      },
      sort: this.extractSorting(),
      page: this.page,
      size: this.pageSize
    }
  }

}

import {Injectable} from '@angular/core';
import {TableStateInterface} from "@interface/common/table-state.interface";
import {TableColumnEnum} from "@enum/table-column/table-column.enum";
import {TranslateService} from "@ngx-translate/core";
import {TableFilterEnum} from "@enum/table-filter/table-filter.enum";
import {NumberPickerEnum} from "@enum/number-picker/number-picker.enum";
import {UserElementInterface} from "@interface/user/user-element.interface";
import {NgbDate} from "@ng-bootstrap/ng-bootstrap";
import {NotificationTableColumnEnum} from "@enum/notifications/notification-table-column/notification-table-column.enum";
import {BanStatusEnum} from "@enum/ban-status/ban-status.enum";
import {FitnessKitEnum} from "@enum/fitness-kit/fitness-kit.enum";
import {FitnessMetricEnum} from "@enum/fitness-metric/fitness-metric.enum";

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  private availableLangs = [
    {text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it', selected: true},
    {text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en'},
    {text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es'},
    {text: 'French', flag: 'assets/images/flags/french.jpg', lang: 'fr'},
  ];

  constructor(private translate: TranslateService) {}

  private _tableDefaultState: TableStateInterface = {
    page: 1,
    start: undefined,
    pageSize: 25,
    searchTerm: undefined,
    sortColumn: undefined,
    sortDirection: undefined,
    company: undefined,
    banned: undefined,
    systemBan: undefined,
    whiteList: undefined,
    fitnessKit: undefined,
    status: undefined,
    numberOfUsers: undefined,
    platform: undefined,
    profileComplete: undefined,
    contextDetailsId: undefined,
    lastSync: undefined,
    lastAppAccessTs: undefined,
    availableCredits: undefined,
    userId: undefined,
    startIndex: undefined,
    type: undefined,
    active: undefined,
    isPublic: undefined,
    metricLongDescription: undefined,
    challengeStatus: undefined,
    ante: undefined,
    currentPool: undefined,
    participantsCount: undefined,
    maxParticipants: undefined,
    priority: undefined,
    validForAllCompanies: undefined,
    createdAt: undefined,
    startsAt: undefined,
    endsAt: undefined,
    acceptsResultsUntil: undefined,
    updatedAt: undefined,
    singleUseForUser: undefined,
    assignedCredits: undefined,
    numberOfUses: undefined,
    endIndex: undefined,
    registrationDate: undefined,
    totalRecords: undefined,
    banStartDate: undefined,
    alreadySent: undefined,
    id: undefined,
    banEndDate: undefined,
    price: undefined,
    totalUnits: undefined,
    redeemedUnits: undefined,
    availableUnits: undefined,
    expiresAt: undefined,
    enabled: undefined,
    discoverable: undefined,
    showUsersCount: undefined,
    showCompanyLeaderboards: undefined,
    mustInsertCode: undefined,
    byPowerLevel: undefined,
    competitionStatus: undefined,
    prizeType: undefined,
    contextType: undefined,
    competitionType: undefined,
    fitnessScoringFunction: undefined,
    validityDate: undefined,
    credits: undefined,
    validForCompetitions: undefined,
    action: undefined
  };

  public get languages() {
    return this.availableLangs;
  }

  public getSpecificLanguage(selectedLang: string) {
    if (selectedLang) {
      return this.availableLangs.find((lang) => lang.lang === selectedLang).flag;
    }
  }

  public getSpecificLanguageName(selectedLang: string) {
    if (selectedLang) {
      return this.availableLangs.find((lang) => lang.lang === selectedLang)?.text;
    }
  }

  public get tableDefaultState(): TableStateInterface {
    return JSON.parse(JSON.stringify(this._tableDefaultState));
  }

  public get validateUrlRegex() {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
  }

  public get banTableColumns(): { visible: boolean, key: TableColumnEnum, label: any }[] {
    return [
      {visible: true, key: TableColumnEnum.USER_ID, label: this.translate.instant(TableColumnEnum.USER_ID)},
      {visible: true, key: TableColumnEnum.EMAIL, label: this.translate.instant(TableColumnEnum.EMAIL)},
      {visible: true, key: TableColumnEnum.COMPLETE_NAME, label: this.translate.instant(TableColumnEnum.COMPLETE_NAME)},
      {visible: false, key: TableColumnEnum.BAN_ID, label: this.translate.instant(TableColumnEnum.BAN_ID)},
      {visible: true, key: TableColumnEnum.BAN_START_DATE, label: this.translate.instant(TableColumnEnum.BAN_START_DATE)},
      {visible: false, key: TableColumnEnum.BAN_START_HOUR, label: this.translate.instant(TableColumnEnum.BAN_START_HOUR)},
      {visible: true, key: TableColumnEnum.BAN_END_DATE, label: this.translate.instant(TableColumnEnum.BAN_END_DATE)},
      {visible: false, key: TableColumnEnum.BAN_END_HOUR, label: this.translate.instant(TableColumnEnum.BAN_END_HOUR)},
      {visible: true, key: TableColumnEnum.BAN_MOTIVATION, label: this.translate.instant(TableColumnEnum.BAN_MOTIVATION)},
      // {visible: false, key: TableColumnEnum.BAN_SEND_EMAIL, label: this.translate.instant(TableColumnEnum.BAN_SEND_EMAIL)},
      // {visible: false, key: TableColumnEnum.BAN_SEND_PUSH, label: this.translate.instant(TableColumnEnum.BAN_SEND_PUSH)},
      {visible: true, key: TableColumnEnum.TOTAL_BAN_RECEIVED, label: this.translate.instant(TableColumnEnum.TOTAL_BAN_RECEIVED)},
      {visible: true, key: TableColumnEnum.BAN_STATUS, label: this.translate.instant(TableColumnEnum.BAN_STATUS)},
      {visible: true, key: TableColumnEnum.BY_SYSTEM, label: this.translate.instant(TableColumnEnum.BY_SYSTEM)},
      {visible: true, key: TableColumnEnum.BAN_NOTES, label: this.translate.instant(TableColumnEnum.BAN_NOTES)},
    ];
  }

  public get banTableFilters(): { visible: boolean, key: TableFilterEnum, label: any }[] {
    return [
      {visible: true, key: TableFilterEnum.BAN_STATUS, label: this.translate.instant(TableFilterEnum.BAN_STATUS)},
      {visible: true, key: TableFilterEnum.BAN_START_DATE, label: this.translate.instant(TableFilterEnum.BAN_START_DATE)},
      {visible: true, key: TableFilterEnum.BAN_END_DATE, label: this.translate.instant(TableFilterEnum.BAN_END_DATE)},
      {visible: true, key: TableFilterEnum.BY_SYSTEM, label: this.translate.instant(TableFilterEnum.BY_SYSTEM)},
      // {visible: true, key: TableFilterEnum.SEND_PUSH, label: this.translate.instant(TableFilterEnum.SEND_PUSH)},
      // {visible: true, key: TableFilterEnum.SEND_EMAIL, label: this.translate.instant(TableFilterEnum.SEND_EMAIL)},
    ];
  }

  public get qrTableColumns(): { visible: boolean, key: TableColumnEnum, label: any }[] {
    return [
      {visible: true, key: TableColumnEnum.QR_ID, label: this.translate.instant(TableColumnEnum.QR_ID)},
      {visible: true, key: TableColumnEnum.CREATION_DATE, label: this.translate.instant(TableColumnEnum.CREATION_DATE)},
      {visible: true, key: TableColumnEnum.CODE, label: this.translate.instant(TableColumnEnum.CODE)},
      {visible: true, key: TableColumnEnum.DEEPLINK, label: this.translate.instant(TableColumnEnum.DEEPLINK)},
      {visible: true, key: TableColumnEnum.ASSIGNED_CREDITS, label: this.translate.instant(TableColumnEnum.ASSIGNED_CREDITS)},
      {visible: true, key: TableColumnEnum.MESSAGE, label: this.translate.instant(TableColumnEnum.MESSAGE)},
      {visible: true, key: TableColumnEnum.SINGLE_USE_FOR_USER, label: this.translate.instant(TableColumnEnum.SINGLE_USE_FOR_USER)},
      {visible: true, key: TableColumnEnum.CREDITS_VALID_FOR_COMPETITION, label: this.translate.instant(TableColumnEnum.CREDITS_VALID_FOR_COMPETITION)},
      {visible: true, key: TableColumnEnum.ACTIVE, label: this.translate.instant(TableColumnEnum.ACTIVE)},
      {visible: true, key: TableColumnEnum.NUMBER_OF_USES, label: this.translate.instant(TableColumnEnum.NUMBER_OF_USES)},
      {visible: true, key: TableColumnEnum.LANGUAGES, label: this.translate.instant(TableColumnEnum.LANGUAGES)},
      {visible: true, key: TableColumnEnum.NOTES, label: this.translate.instant(TableColumnEnum.NOTES)},
    ];
  }

  public get qrTableFilters(): { visible: boolean, key: TableFilterEnum, label: any }[] {
    return [
      {visible: false, key: TableFilterEnum.ACTIVE, label: this.translate.instant(TableFilterEnum.ACTIVE)},
      {visible: false, key: TableFilterEnum.SINGLE_USE_FOR_USER, label: this.translate.instant(TableFilterEnum.SINGLE_USE_FOR_USER)},
      {visible: false, key: TableFilterEnum.ASSIGNED_CREDITS, label: this.translate.instant(TableFilterEnum.ASSIGNED_CREDITS)},
      {visible: false, key: TableFilterEnum.NUMBER_OF_USES, label: this.translate.instant(TableFilterEnum.NUMBER_OF_USES)}
    ];
  }

  public get banMotivationFilters(): { visible: boolean, key: TableFilterEnum, label: any }[] {
    return [
      {visible: false, key: TableFilterEnum.NUMBER_OF_USERS, label: this.translate.instant(TableFilterEnum.NUMBER_OF_USERS)},
    ];
  }

  public get automaticBanRulesColumns(): { visible: boolean, key: TableColumnEnum, label: any }[] {
    return [
      {visible: true, key: TableColumnEnum.RULE_ID, label: this.translate.instant(TableColumnEnum.RULE_ID)},
      {visible: true, key: TableColumnEnum.ACTIVE, label: this.translate.instant(TableColumnEnum.ACTIVE)},
      {visible: true, key: TableColumnEnum.BAN_THRESHOLD, label: this.translate.instant(TableColumnEnum.BAN_THRESHOLD)},
      {visible: true, key: TableColumnEnum.BAN_THRESHOLD_FOR_WHITELIST, label: this.translate.instant(TableColumnEnum.BAN_THRESHOLD_FOR_WHITELIST)},
      {visible: true, key: TableColumnEnum.FITNESS_METRIC, label: this.translate.instant(TableColumnEnum.FITNESS_METRIC)}
    ];
  }

  public get automaticBanRulesFilters(): { visible: boolean, key: TableFilterEnum, label: any }[] {
    return [
      {visible: false, key: TableFilterEnum.ACTIVE, label: this.translate.instant(TableFilterEnum.ACTIVE)},
      {visible: false, key: TableFilterEnum.BAN_THRESHOLD, label: this.translate.instant(TableFilterEnum.BAN_THRESHOLD)},
      {visible: false, key: TableFilterEnum.BAN_THRESHOLD_FOR_WHITELIST, label: this.translate.instant(TableFilterEnum.BAN_THRESHOLD_FOR_WHITELIST)}
    ];
  }

  public get blacklistSourceColumns(): { visible: boolean, key: TableColumnEnum, label: any }[] {
    return [
      {visible: true, key: TableColumnEnum.SOURCE_ID, label: this.translate.instant(TableColumnEnum.SOURCE_ID)},
      {visible: true, key: TableColumnEnum.FITNESS_KIT, label: this.translate.instant(TableColumnEnum.FITNESS_KIT)},
      {visible: true, key: TableColumnEnum.SOURCE_NAME, label: this.translate.instant(TableColumnEnum.SOURCE_NAME)},
      {visible: true, key: TableColumnEnum.SOURCE_DESCRIPTION, label: this.translate.instant(TableColumnEnum.SOURCE_DESCRIPTION)},
      {visible: true, key: TableColumnEnum.SOURCE_PATH, label: this.translate.instant(TableColumnEnum.SOURCE_PATH)}
    ];
  }

  public get blacklistSourceFilters(): { visible: boolean, key: TableFilterEnum, label: any }[] {
    return [];
  }

  public get newsListColumns(): { visible: boolean, key: TableColumnEnum, label: any }[] {
    return [
      {visible: true, key: TableColumnEnum.NEWS_ID, label: this.translate.instant(TableColumnEnum.NEWS_ID)},
      {visible: true, key: TableColumnEnum.COMPANY_NAME, label: this.translate.instant(TableColumnEnum.COMPANY_NAME)},
      {visible: true, key: TableColumnEnum.CREATION_DATE, label: this.translate.instant(TableColumnEnum.CREATION_DATE)},
      {visible: true, key: TableColumnEnum.ACTIVE, label: this.translate.instant(TableColumnEnum.ACTIVE)},
      {visible: true, key: TableColumnEnum.PRIORITY, label: this.translate.instant(TableColumnEnum.PRIORITY)},
      {visible: true, key: TableColumnEnum.TITLE, label: this.translate.instant(TableColumnEnum.TITLE)},
      {visible: true, key: TableColumnEnum.LANGUAGES, label: this.translate.instant(TableColumnEnum.LANGUAGES)},
      {visible: true, key: TableColumnEnum.LINK, label: this.translate.instant(TableColumnEnum.LINK)},
      {visible: true, key: TableColumnEnum.VALID_FOR_ALL_COMPANIES, label: this.translate.instant(TableColumnEnum.VALID_FOR_ALL_COMPANIES)},
      {visible: true, key: TableColumnEnum.UPDATED_AT, label: this.translate.instant(TableColumnEnum.UPDATED_AT)},
    ];
  }

  public get newsListFilters(): { visible: boolean, key: TableFilterEnum, label: any }[] {
    return [
      {visible: false, key: TableFilterEnum.ACTIVE, label: this.translate.instant(TableFilterEnum.ACTIVE)},
      {visible: false, key: TableFilterEnum.VALID_FOR_ALL_COMPANIES, label: this.translate.instant(TableFilterEnum.VALID_FOR_ALL_COMPANIES)},
      {visible: false, key: TableFilterEnum.PRIORITY, label: this.translate.instant(TableFilterEnum.PRIORITY)},
      {visible: false, key: TableFilterEnum.CREATION_DATE, label: this.translate.instant(TableFilterEnum.CREATION_DATE)},
      {visible: false, key: TableFilterEnum.UPDATED_AT, label: this.translate.instant(TableFilterEnum.UPDATED_AT)},
    ];
  }

  public get notificationTableColumns(): { visible: boolean, key: NotificationTableColumnEnum, label: any }[] {
    return [
      {visible: true, key: NotificationTableColumnEnum.NOTIFICATION_ID, label: this.translate.instant(NotificationTableColumnEnum.NOTIFICATION_ID)},
      {visible: true, key: NotificationTableColumnEnum.SCHEDULED_AT, label: this.translate.instant(NotificationTableColumnEnum.SCHEDULED_AT)},
      {visible: true, key: NotificationTableColumnEnum.SCHEDULED_AT_HOUR, label: this.translate.instant(NotificationTableColumnEnum.SCHEDULED_AT_HOUR)},
      {visible: true, key: NotificationTableColumnEnum.AUDIENCE, label: this.translate.instant(NotificationTableColumnEnum.AUDIENCE)},
      {visible: true, key: NotificationTableColumnEnum.AUDIENCE_TARGETS, label: this.translate.instant(NotificationTableColumnEnum.AUDIENCE_TARGETS)},
      {visible: true, key: NotificationTableColumnEnum.TITLE, label: this.translate.instant(NotificationTableColumnEnum.TITLE)},
      {visible: true, key: NotificationTableColumnEnum.MESSAGE, label: this.translate.instant(NotificationTableColumnEnum.MESSAGE)},
      {visible: true, key: NotificationTableColumnEnum.SELECTED_ACTION, label: this.translate.instant(NotificationTableColumnEnum.SELECTED_ACTION)},
      {visible: true, key: NotificationTableColumnEnum.ACTION_PAYLOAD, label: this.translate.instant(NotificationTableColumnEnum.ACTION_PAYLOAD)},
      {visible: false, key: NotificationTableColumnEnum.IMAGE, label: this.translate.instant(NotificationTableColumnEnum.IMAGE)},
      // {visible: true, key: NotificationTableColumnEnum.LINK, label: this.translate.instant(NotificationTableColumnEnum.LINK)},
      {visible: true, key: NotificationTableColumnEnum.LOCALE, label: this.translate.instant(NotificationTableColumnEnum.LOCALE)},
      {visible: false, key: NotificationTableColumnEnum.NOTES, label: this.translate.instant(NotificationTableColumnEnum.NOTES)},
    ];
  }

  public get invitesListColumns(): { visible: boolean, key: TableColumnEnum, label: any }[] {
    return [
      {visible: true, key: TableColumnEnum.INVITE_ID, label: this.translate.instant(TableColumnEnum.INVITE_ID)},
      {visible: true, key: TableColumnEnum.INVITE_CODE_TYPE, label: this.translate.instant(TableColumnEnum.INVITE_CODE_TYPE)},
      {visible: true, key: TableColumnEnum.INVITE_CODE, label: this.translate.instant(TableColumnEnum.INVITE_CODE)},
      {visible: true, key: TableColumnEnum.INVITER_EMAIL, label: this.translate.instant(TableColumnEnum.INVITER_EMAIL)},
      {visible: true, key: TableColumnEnum.INVITER_FULLNAME, label: this.translate.instant(TableColumnEnum.INVITER_FULLNAME)},
      {visible: true, key: TableColumnEnum.INVITE_ACTIVE, label: this.translate.instant(TableColumnEnum.INVITE_ACTIVE)},
      {visible: true, key: TableColumnEnum.INVITE_DEEPLINK, label: this.translate.instant(TableColumnEnum.INVITE_DEEPLINK)},
      {visible: true, key: TableColumnEnum.CREATED_AT, label: this.translate.instant(TableColumnEnum.CREATED_AT)},
      {visible: true, key: TableColumnEnum.USES_COUNT, label: this.translate.instant(TableColumnEnum.USES_COUNT)},
    ];
  }


  public get invitesUsesColumns(): { visible: boolean, key: TableColumnEnum, label: any }[] {
    return [
      {visible: true, key: TableColumnEnum.INVITE_USE_ID, label: this.translate.instant(TableColumnEnum.INVITE_USE_ID)},
      {visible: true, key: TableColumnEnum.INVITE_USE_CODE, label: this.translate.instant(TableColumnEnum.INVITE_USE_CODE)},
      {visible: true, key: TableColumnEnum.INVITE_USE_CODE_TYPE, label: this.translate.instant(TableColumnEnum.INVITE_USE_CODE_TYPE)},
      {visible: true, key: TableColumnEnum.INVITE_USE_CODE_ID, label: this.translate.instant(TableColumnEnum.INVITE_USE_CODE_ID)},
      {visible: true, key: TableColumnEnum.USER_EMAIL, label: this.translate.instant(TableColumnEnum.USER_EMAIL)},
      {visible: true, key: TableColumnEnum.USER_FULLNAME, label: this.translate.instant(TableColumnEnum.USER_FULLNAME)},
      {visible: true, key: TableColumnEnum.USED_AT, label: this.translate.instant(TableColumnEnum.USED_AT)},
    ];
  }


  public get invitesListFilters(): { visible: boolean, key: TableFilterEnum, label: any }[] {
    return [
      {visible: false, key: TableFilterEnum.INVITE_ID, label: this.translate.instant(TableFilterEnum.INVITE_ID)},
      {visible: false, key: TableFilterEnum.INVITE_USER_ID, label: this.translate.instant(TableFilterEnum.INVITE_USER_ID)},
      {visible: false, key: TableFilterEnum.INVITE_ACTIVE, label: this.translate.instant(TableFilterEnum.INVITE_ACTIVE)},
      {visible: false, key: TableFilterEnum.INVITE_CREATED_AT, label: this.translate.instant(TableFilterEnum.INVITE_CREATED_AT)},
      {visible: false, key: TableFilterEnum.INVITE_USES_COUNT, label: this.translate.instant(TableFilterEnum.INVITE_USES_COUNT)},
    ];
  }

  public get invitesUsesFilters(): { visible: boolean, key: TableFilterEnum, label: any }[] {
    return [
      {visible: false, key: TableFilterEnum.INVITE_USE_ID, label: this.translate.instant(TableFilterEnum.INVITE_USE_ID)},
      {visible: false, key: TableFilterEnum.INVITE_USE_USER_ID, label: this.translate.instant(TableFilterEnum.INVITE_USE_USER_ID)},
      {visible: false, key: TableFilterEnum.INVITE_USE_CODE_ID, label: this.translate.instant(TableFilterEnum.INVITE_USE_CODE_ID)},
      {visible: false, key: TableFilterEnum.INVITE_USE_USED_AT, label: this.translate.instant(TableFilterEnum.INVITE_USE_USED_AT)},
    ];
  }

  public get sentNotificationTableColumns(): { visible: boolean, key: NotificationTableColumnEnum, label: any }[] {
    return [
      {visible: true, key: NotificationTableColumnEnum.NOTIFICATION_ID, label: this.translate.instant(NotificationTableColumnEnum.NOTIFICATION_ID)},
      {visible: true, key: NotificationTableColumnEnum.SENT_AT, label: this.translate.instant(NotificationTableColumnEnum.SENT_AT)},
      {visible: true, key: NotificationTableColumnEnum.AUDIENCE, label: this.translate.instant(NotificationTableColumnEnum.AUDIENCE)},
      {visible: true, key: NotificationTableColumnEnum.AUDIENCE_TARGETS, label: this.translate.instant(NotificationTableColumnEnum.AUDIENCE_TARGETS)},
      {visible: true, key: NotificationTableColumnEnum.TITLE, label: this.translate.instant(NotificationTableColumnEnum.TITLE)},
      {visible: true, key: NotificationTableColumnEnum.MESSAGE, label: this.translate.instant(NotificationTableColumnEnum.MESSAGE)},
      {visible: true, key: NotificationTableColumnEnum.SELECTED_ACTION, label: this.translate.instant(NotificationTableColumnEnum.SELECTED_ACTION)},
      {visible: true, key: NotificationTableColumnEnum.ACTION_PAYLOAD, label: this.translate.instant(NotificationTableColumnEnum.ACTION_PAYLOAD)},
      {visible: false, key: NotificationTableColumnEnum.IMAGE, label: this.translate.instant(NotificationTableColumnEnum.IMAGE)},
      // {visible: true, key: NotificationTableColumnEnum.LINK, label: this.translate.instant(NotificationTableColumnEnum.LINK)},
      {visible: true, key: NotificationTableColumnEnum.LOCALE, label: this.translate.instant(NotificationTableColumnEnum.LOCALE)},
      {visible: false, key: NotificationTableColumnEnum.NOTES, label: this.translate.instant(NotificationTableColumnEnum.NOTES)},
    ];
  }

  public get notificationTableFilters(): { visible: boolean, key: TableFilterEnum, label: any }[] {
    return [];
    // return [
    //   {visible: true, key: TableFilterEnum.NOTIFICATION_START_DATE, label: this.translate.instant(TableFilterEnum.NOTIFICATION_START_DATE)},
    // ];
  }

  public get prizesTableColumn(): { visible: boolean, key: TableColumnEnum, label: any }[] {
    return [
      {visible: true, key: TableColumnEnum.PRIZE_ID, label: this.translate.instant(TableColumnEnum.PRIZE_ID)},
      {visible: true, key: TableColumnEnum.PRIZE_IMAGE, label: this.translate.instant(TableColumnEnum.PRIZE_IMAGE)},
      {visible: true, key: TableColumnEnum.PRIZE_ACTIVE, label: this.translate.instant(TableColumnEnum.PRIZE_ACTIVE)},
      {visible: true, key: TableColumnEnum.PRIZE_CONTEXT_TYPE, label: this.translate.instant(TableColumnEnum.PRIZE_CONTEXT_TYPE)},
      {visible: true, key: TableColumnEnum.PRIZE_CONTEXT_DESCRIPTION, label: this.translate.instant(TableColumnEnum.PRIZE_CONTEXT_DESCRIPTION)},
      {visible: false, key: TableColumnEnum.PRIZE_CREATION_DATE, label: this.translate.instant(TableColumnEnum.PRIZE_CREATION_DATE)},
      {visible: true, key: TableColumnEnum.PRIZE_TITLE, label: this.translate.instant(TableColumnEnum.PRIZE_TITLE)},
      {visible: false, key: TableColumnEnum.PRIZE_DESCRIPTION, label: this.translate.instant(TableColumnEnum.PRIZE_DESCRIPTION)},
      {visible: true, key: TableColumnEnum.PRIZE_EXPIRATION_DATE, label: this.translate.instant(TableColumnEnum.PRIZE_EXPIRATION_DATE)},
      {visible: true, key: TableColumnEnum.PRIZE_TRANSLATIONS, label: this.translate.instant(TableColumnEnum.PRIZE_TRANSLATIONS)},
      {visible: true, key: TableColumnEnum.PRIZE_PRICE, label: this.translate.instant('CREDITS')},
      {visible: false, key: TableColumnEnum.PRIZE_PRIORITY, label: this.translate.instant(TableColumnEnum.PRIZE_PRIORITY)},
      {visible: true, key: TableColumnEnum.PRIZE_TYPE, label: this.translate.instant(TableColumnEnum.PRIZE_TYPE)},
      {visible: true, key: TableColumnEnum.PRIZE_REDEEMED_UNITS, label: this.translate.instant(TableColumnEnum.PRIZE_REDEEMED_UNITS)},
      {visible: true, key: TableColumnEnum.PRIZE_TOTAL_UNITS, label: this.translate.instant(TableColumnEnum.PRIZE_TOTAL_UNITS)},
      {visible: true, key: TableColumnEnum.PRIZE_AVAILABLE_UNITS, label: this.translate.instant(TableColumnEnum.PRIZE_AVAILABLE_UNITS)},
      {visible: false, key: TableColumnEnum.PRIZE_REDEMPTION_URL, label: this.translate.instant(TableColumnEnum.PRIZE_REDEMPTION_URL)},
      {visible: false, key: TableColumnEnum.PRIZE_WEBSITE, label: this.translate.instant(TableColumnEnum.PRIZE_WEBSITE)}
    ];
  }

  public get prizesTableFilters(): { visible: boolean, key: TableFilterEnum, label: any }[] {
    return [
      {visible: false, key: TableFilterEnum.PRIZE_PRICE, label: this.translate.instant('CREDITS')},
      {visible: false, key: TableFilterEnum.PRIZE_TOTAL_UNITS, label: this.translate.instant(TableFilterEnum.PRIZE_TOTAL_UNITS)},
      {visible: false, key: TableFilterEnum.PRIZE_REDEEMED_UNITS, label: this.translate.instant(TableFilterEnum.PRIZE_REDEEMED_UNITS)},
      {visible: false, key: TableFilterEnum.PRIZE_TYPE, label: this.translate.instant(TableFilterEnum.PRIZE_TYPE)},
      {visible: false, key: TableFilterEnum.PRIZE_CONTEXT_TYPE, label: this.translate.instant(TableFilterEnum.PRIZE_CONTEXT_TYPE)},
      {visible: false, key: TableFilterEnum.PRIZE_AVAILABLE_UNITS, label: this.translate.instant(TableFilterEnum.PRIZE_AVAILABLE_UNITS)},
      {visible: false, key: TableFilterEnum.PRIZE_ACTIVE, label: this.translate.instant(TableFilterEnum.PRIZE_ACTIVE)},
      {visible: false, key: TableFilterEnum.PRIZE_CREATION_DATE, label: this.translate.instant(TableFilterEnum.PRIZE_CREATION_DATE)},
      {visible: false, key: TableFilterEnum.PRIZE_EXPIRATION_DATE, label: this.translate.instant(TableFilterEnum.PRIZE_EXPIRATION_DATE)}
    ];
  }

  public get challengesTableColumn(): { visible: boolean, key: TableColumnEnum, label: any }[] {
    return [
      {visible: true, key: TableColumnEnum.CHALLENGE_ID, label: this.translate.instant(TableColumnEnum.CHALLENGE_ID)},
      {visible: true, key: TableColumnEnum.CHALLENGE_TYPE, label: this.translate.instant(TableColumnEnum.CHALLENGE_TYPE)},
      {visible: true, key: TableColumnEnum.CHALLENGE_INVITER, label: this.translate.instant(TableColumnEnum.CHALLENGE_INVITER)},
      {visible: false, key: TableColumnEnum.CHALLENGE_NAME, label: this.translate.instant(TableColumnEnum.CHALLENGE_NAME)},
      {visible: false, key: TableColumnEnum.CHALLENGE_DESCRIPTION, label: this.translate.instant(TableColumnEnum.CHALLENGE_DESCRIPTION)},
      {visible: true, key: TableColumnEnum.CHALLENGE_PUBLIC, label: this.translate.instant(TableColumnEnum.CHALLENGE_PUBLIC)},
      {visible: true, key: TableColumnEnum.CHALLENGE_METRIC, label: this.translate.instant(TableColumnEnum.CHALLENGE_METRIC)},
      {visible: true, key: TableColumnEnum.CHALLENGE_ANTE, label: this.translate.instant(TableColumnEnum.CHALLENGE_ANTE)},
      {visible: true, key: TableColumnEnum.CHALLENGE_POOL, label: this.translate.instant(TableColumnEnum.CHALLENGE_POOL)},
      {visible: true, key: TableColumnEnum.CHALLENGE_PARTICIPANTS, label: this.translate.instant(TableColumnEnum.CHALLENGE_PARTICIPANTS)},
      {visible: true, key: TableColumnEnum.CHALLENGE_MAX_PARTICIPANTS, label: this.translate.instant(TableColumnEnum.CHALLENGE_MAX_PARTICIPANTS)},
      {visible: true, key: TableColumnEnum.CHALLENGE_STATUS, label: this.translate.instant(TableColumnEnum.CHALLENGE_STATUS)},
      {visible: true, key: TableColumnEnum.CHALLENGE_CREATED_AT, label: this.translate.instant(TableColumnEnum.CHALLENGE_CREATED_AT)},
      {visible: true, key: TableColumnEnum.CHALLENGE_STARTS_AT, label: this.translate.instant(TableColumnEnum.CHALLENGE_STARTS_AT)},
      {visible: true, key: TableColumnEnum.CHALLENGE_ENDS_AT, label: this.translate.instant(TableColumnEnum.CHALLENGE_ENDS_AT)},
      {visible: true, key: TableColumnEnum.CHALLENGE_ACCEPT_RESULTS_UNTIL, label: this.translate.instant(TableColumnEnum.CHALLENGE_ACCEPT_RESULTS_UNTIL)}
    ];
  }

  public get challengesTableFilters(): { visible: boolean, key: TableFilterEnum, label: any }[] {
    return [
      {visible: false, key: TableFilterEnum.CHALLENGE_TYPE, label: this.translate.instant(TableFilterEnum.CHALLENGE_TYPE)},
      {visible: false, key: TableFilterEnum.CHALLENGE_PUBLIC, label: this.translate.instant(TableFilterEnum.CHALLENGE_PUBLIC)},
      {visible: false, key: TableFilterEnum.CHALLENGE_ANTE, label: this.translate.instant(TableFilterEnum.CHALLENGE_ANTE)},
      {visible: false, key: TableFilterEnum.CHALLENGE_POOL, label: this.translate.instant(TableFilterEnum.CHALLENGE_POOL)},
      {visible: false, key: TableFilterEnum.CHALLENGE_PARTICIPANTS, label: this.translate.instant(TableFilterEnum.CHALLENGE_PARTICIPANTS)},
      {visible: false, key: TableFilterEnum.CHALLENGE_MAX_PARTICIPANTS, label: this.translate.instant(TableFilterEnum.CHALLENGE_MAX_PARTICIPANTS)},
      {visible: false, key: TableFilterEnum.CHALLENGE_METRIC, label: this.translate.instant(TableFilterEnum.CHALLENGE_METRIC)},
      {visible: false, key: TableFilterEnum.CHALLENGE_STATUS, label: this.translate.instant(TableFilterEnum.CHALLENGE_STATUS)},
      {visible: false, key: TableFilterEnum.CHALLENGE_CREATED_AT, label: this.translate.instant(TableFilterEnum.CHALLENGE_CREATED_AT)},
      {visible: false, key: TableFilterEnum.CHALLENGE_STARTS_AT, label: this.translate.instant(TableFilterEnum.CHALLENGE_STARTS_AT)},
      {visible: false, key: TableFilterEnum.CHALLENGE_ENDS_AT, label: this.translate.instant(TableFilterEnum.CHALLENGE_ENDS_AT)},
      {visible: false, key: TableFilterEnum.CHALLENGE_ACCEPT_RESULTS_UNTIL, label: this.translate.instant(TableFilterEnum.CHALLENGE_ACCEPT_RESULTS_UNTIL)}
    ];
  }

  public get companiesTableColumn(): { visible: boolean, key: TableColumnEnum, label: any }[] {
    return [
      {visible: true, key: TableColumnEnum.COMPANY_ID, label: this.translate.instant(TableColumnEnum.COMPANY_ID)},
      {visible: true, key: TableColumnEnum.COMPANY_PRIORITY, label: this.translate.instant(TableColumnEnum.COMPANY_PRIORITY)},
      {visible: true, key: TableColumnEnum.COMPANY_NAME, label: this.translate.instant(TableColumnEnum.COMPANY_NAME)},
      {visible: false, key: TableColumnEnum.COMPANY_DESCRIPTION, label: this.translate.instant(TableColumnEnum.COMPANY_DESCRIPTION)},
      {visible: false, key: TableColumnEnum.COMPANY_LOGO, label: this.translate.instant(TableColumnEnum.COMPANY_LOGO)},
      {visible: true, key: TableColumnEnum.COMPANY_IMAGE, label: this.translate.instant(TableColumnEnum.COMPANY_IMAGE)},
      {visible: true, key: TableColumnEnum.COMPANY_ENABLED, label: this.translate.instant(TableColumnEnum.COMPANY_ENABLED)},
      {visible: true, key: TableColumnEnum.COMPANY_DISCOVERABLE, label: this.translate.instant(TableColumnEnum.COMPANY_DISCOVERABLE)},
      {visible: true, key: TableColumnEnum.COMPANY_ACTIVE, label: this.translate.instant(TableColumnEnum.COMPANY_ACTIVE)},
      {visible: true, key: TableColumnEnum.COMPANY_SHOW_USERS_COUNT, label: this.translate.instant(TableColumnEnum.COMPANY_SHOW_USERS_COUNT)},
      {visible: true, key: TableColumnEnum.COMPANY_SHOW_LEADERBOARDS, label: this.translate.instant(TableColumnEnum.COMPANY_SHOW_LEADERBOARDS)},
      {visible: true, key: TableColumnEnum.COMPANY_MUST_INSERT_CODE, label: this.translate.instant(TableColumnEnum.COMPANY_MUST_INSERT_CODE)},
      {visible: true, key: TableColumnEnum.COMPANY_CREATED_AT, label: this.translate.instant(TableColumnEnum.COMPANY_CREATED_AT)},
      {visible: true, key: TableColumnEnum.COMPANY_LAST_UPDATE, label: this.translate.instant(TableColumnEnum.COMPANY_LAST_UPDATE)}
    ];
  }

  public get companiesTableFilters(): { visible: boolean, key: TableFilterEnum, label: any }[] {
    return [
      {visible: false, key: TableFilterEnum.COMPANY_PRIORITY, label: this.translate.instant(TableFilterEnum.COMPANY_PRIORITY)},
      {visible: false, key: TableFilterEnum.COMPANY_ENABLED, label: this.translate.instant(TableFilterEnum.COMPANY_ENABLED)},
      {visible: false, key: TableFilterEnum.COMPANY_DISCOVERABLE, label: this.translate.instant(TableFilterEnum.COMPANY_DISCOVERABLE)},
      {visible: false, key: TableFilterEnum.COMPANY_ACTIVE, label: this.translate.instant(TableFilterEnum.COMPANY_ACTIVE)},
      {visible: false, key: TableFilterEnum.COMPANY_SHOW_USERS_COUNT, label: this.translate.instant(TableFilterEnum.COMPANY_SHOW_USERS_COUNT)},
      {visible: false, key: TableFilterEnum.COMPANY_SHOW_LEADERBOARDS, label: this.translate.instant(TableFilterEnum.COMPANY_SHOW_LEADERBOARDS)},
      {visible: false, key: TableFilterEnum.COMPANY_MUST_INSERT_CODE, label: this.translate.instant(TableFilterEnum.COMPANY_MUST_INSERT_CODE)},
      {visible: false, key: TableFilterEnum.COMPANY_CREATED_AT, label: this.translate.instant(TableFilterEnum.COMPANY_CREATED_AT)},
      {visible: false, key: TableFilterEnum.COMPANY_LAST_UPDATE, label: this.translate.instant(TableFilterEnum.COMPANY_LAST_UPDATE)},
    ];
  }

  public get competitionsTableColumn(): { visible: boolean, key: TableColumnEnum, label: any }[] {
    return [
      {visible: true, key: TableColumnEnum.COMPETITION_ID, label: this.translate.instant(TableColumnEnum.COMPETITION_ID)},
      {visible: true, key: TableColumnEnum.COMPETITION_NAME, label: this.translate.instant(TableColumnEnum.COMPETITION_NAME)},
      {visible: false, key: TableColumnEnum.COMPETITION_DESCRIPTION, label: this.translate.instant(TableColumnEnum.COMPETITION_DESCRIPTION)},
      {visible: true, key: TableColumnEnum.COMPETITION_TYPE, label: this.translate.instant(TableColumnEnum.COMPETITION_TYPE)},
      {visible: true, key: TableColumnEnum.COMPETITION_METRIC, label: this.translate.instant(TableColumnEnum.COMPETITION_METRIC)},
      {visible: true, key: TableColumnEnum.COMPETITION_STATUS, label: this.translate.instant(TableColumnEnum.COMPETITION_STATUS)},
      {visible: true, key: TableColumnEnum.COMPETITION_IMAGE, label: this.translate.instant(TableColumnEnum.COMPETITION_IMAGE)},
      {visible: true, key: TableColumnEnum.COMPETITION_ACTIVE, label: this.translate.instant(TableColumnEnum.COMPETITION_ACTIVE)},
      {visible: true, key: TableColumnEnum.COMPETITION_BY_POWER_LEVEL, label: this.translate.instant(TableColumnEnum.COMPETITION_BY_POWER_LEVEL)},
      {visible: true, key: TableColumnEnum.COMPETITION_CREATED_AT, label: this.translate.instant(TableColumnEnum.COMPETITION_CREATED_AT)}
    ];
  }

  public get competitionsTableFilters(): { visible: boolean, key: TableFilterEnum, label: any }[] {
    return [
      {visible: false, key: TableFilterEnum.COMPETITION_ACTIVE, label: this.translate.instant(TableFilterEnum.COMPETITION_ACTIVE)},
      {visible: false, key: TableFilterEnum.COMPETITION_BY_POWER_LEVEL, label: this.translate.instant(TableFilterEnum.COMPETITION_BY_POWER_LEVEL)},
      {visible: false, key: TableFilterEnum.COMPETITION_TYPE, label: this.translate.instant(TableFilterEnum.COMPETITION_TYPE)},
      {visible: false, key: TableFilterEnum.COMPETITION_STATUS, label: this.translate.instant(TableFilterEnum.COMPETITION_STATUS)},
      {visible: false, key: TableFilterEnum.COMPETITION_CREATED_AT, label: this.translate.instant(TableFilterEnum.COMPETITION_CREATED_AT)}
    ];
  }

  public get faqsTableColumn(): { visible: boolean, key: TableColumnEnum, label: any }[] {
    return [
      {visible: true, key: TableColumnEnum.FAQ_ID, label: this.translate.instant(TableColumnEnum.FAQ_ID)},
      {visible: true, key: TableColumnEnum.FAQ_PRIORITY, label: this.translate.instant(TableColumnEnum.FAQ_PRIORITY)},
      {visible: true, key: TableColumnEnum.FAQ_TITLE, label: this.translate.instant(TableColumnEnum.FAQ_TITLE)},
      {visible: true, key: TableColumnEnum.FAQ_DESCRIPTION, label: this.translate.instant(TableColumnEnum.FAQ_DESCRIPTION)},
      {visible: true, key: TableColumnEnum.FAQ_ACTIVE, label: this.translate.instant(TableColumnEnum.FAQ_ACTIVE)},
      {visible: true, key: TableColumnEnum.FAQ_LINK_URL, label: this.translate.instant(TableColumnEnum.FAQ_LINK_URL)},
      {visible: true, key: TableColumnEnum.FAQ_LINK_TEXT, label: this.translate.instant(TableColumnEnum.FAQ_LINK_TEXT)},
      {visible: true, key: TableColumnEnum.FAQ_CREATED_AT, label: this.translate.instant(TableColumnEnum.FAQ_CREATED_AT)},
      {visible: true, key: TableColumnEnum.FAQ_UPDATED_AT, label: this.translate.instant(TableColumnEnum.FAQ_UPDATED_AT)},
      {visible: true, key: TableColumnEnum.FAQ_LANGUAGES, label: this.translate.instant(TableColumnEnum.FAQ_LANGUAGES)}
    ];
  }

  public get faqsTableFilters(): { visible: boolean, key: TableFilterEnum, label: any }[] {
    return [
      {visible: false, key: TableFilterEnum.FAQ_PRIORITY, label: this.translate.instant(TableFilterEnum.FAQ_PRIORITY)},
      {visible: false, key: TableFilterEnum.FAQ_ACTIVE, label: this.translate.instant(TableFilterEnum.FAQ_ACTIVE)},
      {visible: false, key: TableFilterEnum.FAQ_CREATED_AT, label: this.translate.instant(TableFilterEnum.FAQ_CREATED_AT)},
      {visible: false, key: TableFilterEnum.FAQ_UPDATED_AT, label: this.translate.instant(TableFilterEnum.FAQ_UPDATED_AT)}
    ];
  }

  public get streaksTemplateTableColumn(): { visible: boolean, key: TableColumnEnum, label: any }[] {
    return [
      {visible: true, key: TableColumnEnum.STREAK_TEMPLATE_ID, label: this.translate.instant(TableColumnEnum.STREAK_TEMPLATE_ID)},
      {visible: true, key: TableColumnEnum.STREAK_TEMPLATE_FITNESS_SCORING, label: this.translate.instant(TableColumnEnum.STREAK_TEMPLATE_FITNESS_SCORING)},
      {visible: true, key: TableColumnEnum.STREAK_TEMPLATE_LOCKED_ICON, label: this.translate.instant(TableColumnEnum.STREAK_TEMPLATE_LOCKED_ICON)},
      {visible: true, key: TableColumnEnum.STREAK_TEMPLATE_UNLOCKED_ICON, label: this.translate.instant(TableColumnEnum.STREAK_TEMPLATE_UNLOCKED_ICON)},
      {visible: true, key: TableColumnEnum.STREAK_TEMPLATE_DESCRIPTION, label: this.translate.instant(TableColumnEnum.STREAK_TEMPLATE_DESCRIPTION)},
      {visible: true, key: TableColumnEnum.STREAK_TEMPLATE_NAME, label: this.translate.instant(TableColumnEnum.STREAK_TEMPLATE_NAME)},
      {visible: true, key: TableColumnEnum.STREAK_TEMPLATE_LANGUAGES, label: this.translate.instant(TableColumnEnum.STREAK_TEMPLATE_LANGUAGES)},
    ];
  }

  public get streaksTemplateTableFilters(): { visible: boolean, key: TableFilterEnum, label: any }[] {
    return [
      {visible: false, key: TableFilterEnum.STREAK_TEMPLATE_FITNESS_SCORING, label: this.translate.instant(TableFilterEnum.STREAK_TEMPLATE_FITNESS_SCORING)},
    ];
  }

  public get streaksTableColumn(): { visible: boolean, key: TableColumnEnum, label: any }[] {
    return [
      {visible: true, key: TableColumnEnum.STREAK_ID, label: this.translate.instant(TableColumnEnum.STREAK_ID)},
      {visible: true, key: TableColumnEnum.STREAK_TEMPLATE, label: this.translate.instant(TableColumnEnum.STREAK_TEMPLATE)},
      {visible: true, key: TableColumnEnum.STREAK_SINCE_DATE, label: this.translate.instant(TableColumnEnum.STREAK_SINCE_DATE)},
      {visible: true, key: TableColumnEnum.STREAK_UP_TO_DATE, label: this.translate.instant(TableColumnEnum.STREAK_UP_TO_DATE,)},
      {visible: true, key: TableColumnEnum.STREAK_LOCKED_IMAGE, label: this.translate.instant(TableColumnEnum.STREAK_LOCKED_IMAGE,)},
      {visible: true, key: TableColumnEnum.STREAK_UNLOCKED_IMAGE, label: this.translate.instant(TableColumnEnum.STREAK_UNLOCKED_IMAGE,)},
      {visible: true, key: TableColumnEnum.STREAK_TEMPLATE_FITNESS_SCORING, label: this.translate.instant(TableColumnEnum.STREAK_TEMPLATE_FITNESS_SCORING,)},
      {visible: true, key: TableColumnEnum.STREAK_THRESHOLD, label: this.translate.instant(TableColumnEnum.STREAK_THRESHOLD)},
      {visible: true, key: TableColumnEnum.STREAK_CREDITS, label: this.translate.instant(TableColumnEnum.STREAK_CREDITS)},
      {visible: true, key: TableColumnEnum.STREAK_VALID_FOR_COMPETITIONS, label: this.translate.instant(TableColumnEnum.STREAK_VALID_FOR_COMPETITIONS)},
      {visible: true, key: TableColumnEnum.STREAK_AUTOREDEEM, label: this.translate.instant(TableColumnEnum.STREAK_AUTOREDEEM)},
      {visible: true, key: TableColumnEnum.STREAK_ACTION, label: this.translate.instant(TableColumnEnum.STREAK_ACTION)},
      {visible: true, key: TableColumnEnum.STREAK_DETAIL, label: this.translate.instant(TableColumnEnum.STREAK_DETAIL)},
    ];
  }

  public get streaksTableFilters(): { visible: boolean, key: TableFilterEnum, label: any }[] {
    return [
      {visible: false, key: TableFilterEnum.STREAK_VALIDITY_DATE, label: this.translate.instant(TableFilterEnum.STREAK_VALIDITY_DATE)},
      {visible: false, key: TableFilterEnum.STREAK_CREDITS, label: this.translate.instant(TableFilterEnum.STREAK_CREDITS)},
      {visible: false, key: TableFilterEnum.STREAK_VALID_FOR_COMPETITIONS, label: this.translate.instant(TableFilterEnum.STREAK_VALID_FOR_COMPETITIONS)},
      {visible: false, key: TableFilterEnum.STREAK_ACTION, label: this.translate.instant(TableFilterEnum.STREAK_ACTION)},
    ];
  }

  public get rewardsTableColumn(): { visible: boolean, key: TableColumnEnum, label: any }[] {
    return [
      {visible: true, key: TableColumnEnum.REWARD_ID, label: this.translate.instant(TableColumnEnum.REWARD_ID)},
      {visible: true, key: TableColumnEnum.REWARD_NAME, label: this.translate.instant(TableColumnEnum.REWARD_NAME)},
      {visible: true, key: TableColumnEnum.REWARD_BASED_ON, label: this.translate.instant(TableColumnEnum.REWARD_BASED_ON)},
      {visible: true, key: TableColumnEnum.REWARD_ATTRIUTED_WHEN, label: this.translate.instant(TableColumnEnum.REWARD_ATTRIUTED_WHEN,)},
      {visible: true, key: TableColumnEnum.REWARD_CREDITS, label: this.translate.instant(TableColumnEnum.REWARD_CREDITS,)},
      {visible: true, key: TableColumnEnum.REWARD_LIMITS, label: this.translate.instant(TableColumnEnum.REWARD_LIMITS,)},
    ];
  }

  public get rewardsTableFilters(): { visible: boolean, key: TableFilterEnum, label: any }[] {
    return [];
  }

  public get ftoTableColumn(): { visible: boolean, key: TableColumnEnum, label: any }[] {
    return [
      // {visible: true, key: TableColumnEnum.FTO_ID, label: this.translate.instant(TableColumnEnum.FTO_ID)},
      // {visible: false, key: TableColumnEnum.FTO_VALID_SINCE, label: this.translate.instant(TableColumnEnum.FTO_VALID_SINCE)},
      // {visible: false, key: TableColumnEnum.FTO_VALID_UNTIL, label: this.translate.instant(TableColumnEnum.FTO_VALID_UNTIL)},
      {visible: true, key: TableColumnEnum.FTO_PRIORITY, label: this.translate.instant(TableColumnEnum.FTO_PRIORITY,)},
      {visible: true, key: TableColumnEnum.FTO_FITNESS_METRIC, label: this.translate.instant(TableColumnEnum.FTO_FITNESS_METRIC,)},
      // {visible: false, key: TableColumnEnum.FTO_RECOMMENDED_UNITS, label: this.translate.instant(TableColumnEnum.FTO_RECOMMENDED_UNITS,)},
      // {visible: false, key: TableColumnEnum.FTO_MAX_UNITS, label: this.translate.instant(TableColumnEnum.FTO_MAX_UNITS,)},
      // {visible: false, key: TableColumnEnum.FTO_CREDITABLE_QUANTUM, label: this.translate.instant(TableColumnEnum.FTO_CREDITABLE_QUANTUM,)},
      // {visible: false, key: TableColumnEnum.FTO_CREDITS_QUANTUM, label: this.translate.instant(TableColumnEnum.FTO_CREDITS_QUANTUM,)},
      {visible: true, key: TableColumnEnum.FTO_IMAGE, label: this.translate.instant(TableColumnEnum.FTO_IMAGE,)},
    ];
  }

  public get ftoTableFilters(): { visible: boolean, key: TableFilterEnum, label: any }[] {
    return [];
  }

  public get tagsTableColumn(): { visible: boolean, key: TableColumnEnum, label: any }[] {
    return [
      {visible: true, key: TableColumnEnum.TAG_ID, label: this.translate.instant(TableColumnEnum.TAG_ID)},
      {visible: true, key: TableColumnEnum.TAG_ALIAS, label: this.translate.instant(TableColumnEnum.TAG_ALIAS)},
      {visible: true, key: TableColumnEnum.TAG_TRANSLATIONS, label: this.translate.instant(TableColumnEnum.TAG_TRANSLATIONS)},
    ];
  }

  public get tagsTableFilters(): { visible: boolean, key: TableFilterEnum, label: any }[] {
    return [];
  }

  public get challengeDurationTableColumn(): { visible: boolean, key: TableColumnEnum, label: any }[] {
    return [
      {visible: true, key: TableColumnEnum.CHALLENGE_DURATION_ID, label: this.translate.instant(TableColumnEnum.CHALLENGE_DURATION_ID)},
      {visible: true, key: TableColumnEnum.CHALLENGE_DURATION_TYPE, label: this.translate.instant(TableColumnEnum.CHALLENGE_DURATION_TYPE)},
      {visible: true, key: TableColumnEnum.CHALLENGE_DURATION_HOUR, label: this.translate.instant(TableColumnEnum.CHALLENGE_DURATION_HOUR)},
      {visible: true, key: TableColumnEnum.CHALLENGE_DURATION_PRIORITY, label: this.translate.instant(TableColumnEnum.CHALLENGE_DURATION_PRIORITY)},
      {visible: true, key: TableColumnEnum.CHALLENGE_DURATION_ANTE, label: this.translate.instant(TableColumnEnum.CHALLENGE_DURATION_ANTE)},
      {visible: true, key: TableColumnEnum.CHALLENGE_DURATION_DESCRIPTION, label: this.translate.instant(TableColumnEnum.CHALLENGE_DURATION_DESCRIPTION)},
      {visible: true, key: TableColumnEnum.CHALLENGE_DURATION_TRANSLATIONS, label: this.translate.instant(TableColumnEnum.CHALLENGE_DURATION_TRANSLATIONS)},
    ];
  }

  public get challengeDurationTableFilters(): { visible: boolean, key: TableFilterEnum, label: any }[] {
    return [];
  }

  public get contestMetricsTableColumn(): { visible: boolean, key: TableColumnEnum, label: any }[] {
    return [
      {visible: true, key: TableColumnEnum.CONTEST_ACTUAL_METRIC, label: this.translate.instant(TableColumnEnum.CONTEST_ACTUAL_METRIC)},
      {visible: true, key: TableColumnEnum.CONTEST_PRIORITY, label: this.translate.instant(TableColumnEnum.CONTEST_PRIORITY)},
      {visible: true, key: TableColumnEnum.CONTEST_DESCRIPTION, label: this.translate.instant(TableColumnEnum.CONTEST_DESCRIPTION)},
      {visible: true, key: TableColumnEnum.CONTEST_HANDLE, label: this.translate.instant(TableColumnEnum.CONTEST_HANDLE)},
      {visible: true, key: TableColumnEnum.CONTEST_CAN_BE_USED_FOR_CHALLENGES, label: this.translate.instant(TableColumnEnum.CONTEST_CAN_BE_USED_FOR_CHALLENGES)},
      {visible: true, key: TableColumnEnum.CONTEST_TRANSLATIONS, label: this.translate.instant(TableColumnEnum.CONTEST_TRANSLATIONS)},
      {visible: true, key: TableColumnEnum.CONTEST_MESSAGE, label: this.translate.instant(TableColumnEnum.CONTEST_MESSAGE)},
      {visible: true, key: TableColumnEnum.CONTEST_IMAGE, label: this.translate.instant(TableColumnEnum.CONTEST_IMAGE)},
    ];
  }

  public get contestMetricsTableFilters(): { visible: boolean, key: TableFilterEnum, label: any }[] {
    return [];
  }

  public sanitizeDate(date: any): any {
    if (date && (date instanceof String || typeof date === 'string') && date?.includes('/')) {
      return this.fromStringDateToIso(date);
    } else if (date && (date instanceof String || typeof date === 'string') && date?.includes('T')) {
      return  date?.split('T')[0];
    } else if (date && (typeof date === 'number' || date instanceof Number)) {
      // @ts-ignore
      const newDate: any = new Date(date);
      return newDate?.toISOString()?.split('T')[0];
    }
  }

  public fromStringDateToIso(date: any): any {
    const dateArray: any = date.split("/");
    let dateObject = new Date(+dateArray[2], dateArray[1] - 1, +dateArray[0]);
    const offset = dateObject.getTimezoneOffset()
    dateObject = new Date(dateObject.getTime() - (offset * 60 * 1000))
    return dateObject.toISOString().split('T')[0]
  }

  public formatDateFromNgb(ngbDate: NgbDate): any {
    let date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day)
    const offset = date.getTimezoneOffset()
    date = new Date(date.getTime() - (offset * 60 * 1000))
    return date.toISOString().split('T')[0]
  }

  public getAvailableLang(localizableKey: string): void {
    const langs = JSON.parse(JSON.stringify(this.availableLangs))?.filter((lang) => {
      return lang?.lang !== 'en';
    });
    return langs?.map((language) => {
      var re = /en-it/gi;
      language.link = localizableKey?.replace(re, ['en', language.lang].join('-'));
      return language;
    });
  }

  public extractSorting(sortColumn: string, sortDirection: string): string {
    let sortValue: any;
    const column = sortColumn ? sortColumn : null
    const direction = sortDirection ? sortDirection : null
    sortValue = [column ? column : '', direction ? direction : ''].join(',');
    return !sortValue.startsWith(',') ? sortValue : null;
  }

  public clearObject(data: Object): any {
    const omitObj = obj => Object.keys(obj).reduce((acc, key) => {
      if (obj[key] === undefined) {
        return acc;
      }
      acc[key] = obj[key];
      return acc;
    }, {});
    return omitObj(data);
  }

  public checkNumber(value: number, numberToCheck: number, check: NumberPickerEnum): boolean {
    switch (check) {
      case NumberPickerEnum.EQ:
        return value === numberToCheck;
        break;
      case NumberPickerEnum.GT:
        return value > numberToCheck;
        break;
      case NumberPickerEnum.GTE:
        return value >= numberToCheck;
        break;
      case NumberPickerEnum.LT:
        return value < numberToCheck;
        break;
      case NumberPickerEnum.LTE:
        return value <= numberToCheck;
        break;
    }
  }

  public authorizedFullAccess(user: UserElementInterface): boolean {
    switch (user?.email) {
      case 'mfancello@healthyvirtuoso.com':
      case 'spighi@healthyvirtuoso.com':
      case 'mvargiu@healthyvirtuoso.com':
        return true;
      default:
        return false;
    }
  }

  public goCrowdin(lang: any): void {
    window.open(lang.link, '_blank');
  }

  public checkFileIsImage(file: any, imageKit?: boolean): boolean {
    return !imageKit ? file.type.includes('image') : file?.fileType === 'image';
  }

  public downloadQrCode(qrCode) {
    const element: any = document.getElementById('qrCode' + qrCode.id);
    const parentElement = element.querySelector('img').src;
    let blobData = this.convertBase64ToBlob(parentElement);
    if (window.navigator && (window.navigator as any)?.msSaveOrOpenBlob) {
      (window.navigator as any).msSaveOrOpenBlob(blobData, 'Qrcode');
    } else {
      const blob = new Blob([blobData], {type: 'image/png'});
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = [qrCode?.id, qrCode?.code].join('_');
      link.click();
    }
  }

  public convertBase64ToBlob(Base64Image: any) {
    // SPLIT INTO TWO PARTS
    const parts = Base64Image.split(';base64,');
    // HOLD THE CONTENT TYPE
    const imageType = parts[0].split(':')[1];
    // DECODE BASE64 STRING
    const decodedData = window.atob(parts[1]);
    // CREATE UNIT8ARRAY OF SIZE SAME AS ROW DATA LENGTH
    const uInt8Array = new Uint8Array(decodedData.length);
    // INSERT ALL CHARACTER CODE INTO UINT8ARRAY
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    // RETURN BLOB IMAGE AFTER CONVERSION
    return new Blob([uInt8Array], {type: imageType});
  }

  public flat<T>(arr: T[][]): T[] {
    return arr.reduce((acc, val) => acc.concat(val), []);
  }

  public isString(val) {
    return (typeof val === "string");
  }

  public isArrayOfStrings(value: any): boolean {
    return Array.isArray(value) && value.every(item => typeof item === "string");
  }

  public isNumber(val) {
    return (typeof val === "number");
  }

  public isArrayOfNumbers(value: any): boolean {
    return Array.isArray(value) && value.every(item => typeof item === "number");
  }

  public getDiagnosticColors(index: number): string {
    const colors = ["#fbaf00","#ffd639","#ffa3af","#007cbe","#00af54","#36453b","#e3170a","#a9def9","#04e762","#12eaea"];
    return colors[index]
  }

  public getTranslatedValue(value: string): string {
    return this.translate.instant(value);
  }

  public getFitnessMetrics(): any {
    return [
      {value: FitnessMetricEnum.STEPS, label: this.translate.instant(FitnessMetricEnum.STEPS)},
      {value: FitnessMetricEnum.SLEEP, label: this.translate.instant(FitnessMetricEnum.SLEEP)},
      {value: FitnessMetricEnum.MEDITATION, label: this.translate.instant(FitnessMetricEnum.MEDITATION)},
      {value: FitnessMetricEnum.SPORT, label: this.translate.instant(FitnessMetricEnum.SPORT)},
      {value: FitnessMetricEnum.CYCLING_MINUTES, label: this.translate.instant(FitnessMetricEnum.CYCLING_MINUTES)},
      {value: FitnessMetricEnum.CYCLING_METERS, label: this.translate.instant(FitnessMetricEnum.CYCLING_METERS)},
      {value: FitnessMetricEnum.RUNNING_MINUTES, label: this.translate.instant(FitnessMetricEnum.RUNNING_MINUTES)},
      {value: FitnessMetricEnum.RUNNING_METERS, label: this.translate.instant(FitnessMetricEnum.RUNNING_METERS)},
      {value: FitnessMetricEnum.YOGA_MINUTES, label: this.translate.instant(FitnessMetricEnum.YOGA_MINUTES)},
    ]
  }

  public extractSelectedItems(list: any, selectedItems: any): any {
    if (list && list?.length > 0 && selectedItems && selectedItems?.length > 0) {
      const selectedIds = selectedItems.filter(item => !isNaN(item));
      if (selectedIds?.length > 0) {
        selectedIds.map((id) => {
          selectedItems.push(list.find(x => x.id == id));
          selectedItems = selectedItems.filter(item => item !== id);
          return id;
        });
        return selectedItems;
      }
    }
  }


}

import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {EventEnum} from "@enum/event/event.enum";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {EventService} from "@service/common/event.service";

@Component({
  selector: 'app-deactivate-modal',
  templateUrl: './deactivate-modal.component.html',
  styleUrls: ['./deactivate-modal.component.scss']
})
export class DeactivateModalComponent implements OnInit, OnDestroy {
  @ViewChild('modal') private modalContent: TemplateRef<any>
  @Input() service: any;
  public data: any | undefined = undefined;
  public title: string | undefined = undefined;
  public titleParams: string | undefined = undefined;
  public description: string | undefined = undefined;
  public working$: Observable<boolean>;
  public showAreYouSure: boolean = false;
  private closeModalSubscription: Subscription;
  private modalRef: NgbModalRef;
  constructor(private modalService: NgbModal, private eventService: EventService) { }

  ngOnInit(): void {
  }

  public open(data?: any, title?: string, description?: string, titleParams?: string): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, {size: 'md', centered: true, backdrop: false})
      this.modalRef.result.then(resolve, resolve)
      this.setModalData(data, title, description, titleParams);
      this._closeModal();
    })
  }

  private setModalData(data: any, title: string, description: string, titleParams: string) {
    this.data = data;
    this.title = title;
    this.description = description;
    this.titleParams = titleParams;
    this.working$ = this.service.working$;
  }

  public deactivate(): void {
    this.service.deactivate(this.data, EventEnum.CLOSE_DEACTIVATE_MODAL);
  }

  private _closeModal(): void {
    this.closeModalSubscription = this.eventService.subscribe(EventEnum.CLOSE_DEACTIVATE_MODAL, (reason: string | undefined) => {
      this.resetModalData();
      this.closeModalSubscription?.unsubscribe();
      this.modalRef?.dismiss(reason);
    });
  }

  private resetModalData(): void {
    this.title = undefined;
    this.description = undefined;
    this.titleParams = undefined;
    this.showAreYouSure = false;
  }

  ngOnDestroy() {
    this.closeModalSubscription?.unsubscribe();
  }


}

import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Subject, Subscription} from "rxjs";
import {TableStateInterface} from "@interface/common/table-state.interface";
import {EventService} from "@service/common/event.service";
import {ToastService} from "@service/toast.service";
import {UtilsService} from "@service/utils/utils.service";
import {SortDirection} from "@type/common/sort-direction.type";
import {catchError, debounceTime, map, switchMap, tap} from "rxjs/operators";
import {PrizesApiService} from "@service/prizes/prizes-api.service";
import {TableFilterEnum} from "@enum/table-filter/table-filter.enum";
import {TableColumnEnum} from "@enum/table-column/table-column.enum";
import {Router} from "@angular/router";
import {EventEnum} from "@enum/event/event.enum";

@Injectable({
  providedIn: 'root'
})
export class PrizesService {
  public _create$ = new Subject<string>();
  public _search$ = new Subject<void>();
  public visibleColumns: { visible: boolean, key: TableColumnEnum, label: any }[] = this.utils.prizesTableColumn;
  public visibleFilters: { visible: boolean, key: TableFilterEnum, label: any }[] = this.utils.prizesTableFilters;
  private _currentTableState: TableStateInterface = this.utils.tableDefaultState;
  private searchPrizesSubscription: Subscription;
  private createPrizeSubscription: Subscription;
  private filter: any = {
    userId: null,
    companyId: null,
    competitionId: null
  }

  constructor(private prizesApiService: PrizesApiService, private eventService: EventService,
              private router: Router,
              private toastService: ToastService, private utils: UtilsService) {
  }

  private _working$ = new BehaviorSubject<boolean>(false);

  get working$() {
    return this._working$.asObservable();
  }

  private _loading$ = new BehaviorSubject<boolean>(true);

  public get loading$() {
    return this._loading$.asObservable();
  }

  private _creating$ = new BehaviorSubject<boolean>(false);

  get creating$() {
    return this._creating$.asObservable();
  }

  private _prizeList$ = new BehaviorSubject<any[]>([]);

  public get prizeList$() {
    return this._prizeList$.asObservable();
  }

  private _totalRecords$ = new BehaviorSubject<number>(0);

  public get totalRecords$() {
    return this._totalRecords$.asObservable();
  }

  public get searchTerm() {
    return this._currentTableState.searchTerm;
  }

  public set searchTerm(searchTerm: string) {
    this._setValue({searchTerm});
  }

  public set searchTermLocal(searchTerm: string) {
    this._currentTableState.searchTerm = searchTerm;
  }

  public get pageSize() {
    return this._currentTableState.pageSize;
  }

  public set pageSize(pageSize: number) {
    const page = 1;
    this._setValue({page})
    this._setValue({pageSize});
  }

  public get page() {
    return this._currentTableState.page;
  }

  public set page(page: number) {
    this._setValue({page});
  }

  public get sortColumn() {
    return this._currentTableState.sortColumn;
  }

  public set sortColumn(sortColumn: string) {
    this._setValue({sortColumn});
  }

  public get sortDirection() {
    return this._currentTableState.sortDirection;
  }

  public set sortDirection(sortDirection: SortDirection) {
    this._setValue({sortDirection});
  }

  get columns() {
    return this.visibleColumns;
  }

  get filters() {
    return this.visibleFilters;
  }

  get price() {
    return this._currentTableState.price;
  }

  set price(price: string[]) {
    this._setValue({price});
  }

  get totalUnits() {
    return this._currentTableState.totalUnits;
  }

  set totalUnits(totalUnits: string[]) {
    this._setValue({totalUnits});
  }

  get redeemedUnits() {
    return this._currentTableState.redeemedUnits;
  }

  set redeemedUnits(redeemedUnits: string[]) {
    this._setValue({redeemedUnits});
  }

  get availableUnits() {
    return this._currentTableState.availableUnits;
  }

  set availableUnits(availableUnits: any[]) {
    this._setValue({availableUnits});
  }

  get active() {
    return this._currentTableState.active;
  }

  set active(active: boolean | undefined | null) {
    this._setValue({active});
  }

  get createdAt() {
    return this._currentTableState.createdAt;
  }

  set createdAt(createdAt: string[]) {
    this._setValue({createdAt});
  }


  get prizeType() {
    return this._currentTableState.prizeType;
  }

  set prizeType(prizeType: string | undefined) {
    this._setValue({prizeType});
  }

  get contextType() {
    return this._currentTableState.contextType;
  }

  set contextType(contextType: string | undefined) {
    this._setValue({contextType});
  }

  get contextDetailsId() {
    return this._currentTableState.contextDetailsId;
  }

  set contextDetailsId(contextDetailsId: string | undefined) {
    this._setValue({contextDetailsId});
  }

  get expiresAt() {
    return this._currentTableState.expiresAt;
  }

  set expiresAt(expiresAt: string[]) {
    this._setValue({expiresAt});
  }

  public removeSearchPrizesSubscribe(): void {
    this.searchPrizesSubscription?.unsubscribe();
    this._loading$.next(false);
  }

  public removeCreatePrizesSubscribe(): void {
    this.createPrizeSubscription?.unsubscribe();
    this._loading$.next(false);
  }

  public createPrize(prize: any): void {
    this._create$.next(prize);
  }

  public setCompetitionFilter(competitionId: any): void {
    this.filter.competitionId = competitionId;
    this._search$.next();
  }

  public setCompanyFilter(companyId: any): void {
    this.filter.companyId = companyId;
    this._search$.next();
  }

  public setUserFilter(userId: any): void {
    this.filter.userId = userId;
    this._search$.next();
  }

  public initSearchPrizesListener(): void {
    this.searchPrizesSubscription = this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(50),
      switchMap(() => this.prizesApiService.getPrizeList(this._extractSearchParams(), this.filter)),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      let prizes = result?.data?.map((prize) => {
        prize.languages = this.utils?.getAvailableLang(prize?.localizableKey);
        return prize;
      });
      this._prizeList$.next(prizes);
      this._totalRecords$.next(result?.size);
    });
  }

  public initForUserSearchPrizesListener(): void {
    this.searchPrizesSubscription = this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(50),
      switchMap(() => this.prizesApiService.getUserRedeemedPrizes(this.filter.userId, this._extractSearchParams())),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      this._prizeList$.next(result?.data);
      this._totalRecords$.next(result?.size);
    });
  }


  public initCreateListener(): void {
    this.createPrizeSubscription = this._create$.pipe(
      tap(() => this._creating$.next(true)),
      tap(() => this._loading$.next(true)),
      switchMap((data: any) => this.prizesApiService.createEditPrize(data).pipe(
        map((result) => {
          if (result?.length > 0) {
            this._creating$.next(false);
            result.map((error) => {
              this.toastService.show(error?.cause, {classname: 'bg-danger text-light'});
              return error;
            });
            this.eventService.broadcast(EventEnum.CLOSE_CREATE_PRIZE, null)
            this._search$.next();
            return result;
          } else {
            if (!data?.id && data?.prizeType === 'code_redemption' && data?.prizeCodes?.length > 0) {
              this.addPrizeCodes(data?.prizeCodes, result?.id).subscribe((result: any) => {
                return this.closeCreatingModal(data, result);
              });
            } else {
              return this.closeCreatingModal(data, result);
            }
          }
        }),
        catchError((err, caught) => {
          this._creating$.next(false);
          return this.modalError(err, EventEnum.CLOSE_CREATE_PRIZE);
        })
      )),
      tap(() => this._creating$.next(false))
    ).subscribe((result) => {
    });
  }

  private closeCreatingModal(data: any, result) {
    this._creating$.next(false);
    const message = data?.id ? 'Prize edited successfully' : 'Prize created successfully';
    return this.modalSuccess(result, EventEnum.CLOSE_CREATE_PRIZE, message);
  }

  public activate(prizeData: any, modal: any): any {
    prizeData.active = true;
    this.activateDeactivatePrize(prizeData, modal);
  }

  public deactivate(prizeData: any, modal: any): any {
    prizeData.active = false;
    this.activateDeactivatePrize(prizeData, modal);
  }

  private activateDeactivatePrize(prize: any, modal: any) {
    this._working$.next(true);
    this.prizesApiService.createEditPrize(prize).subscribe((result) => {
      if (result?.length > 0) {
        result.map((error) => {
          this.toastService.show(error?.cause, {classname: 'bg-danger text-light'});
          return error;
        });
      } else {
        const message = prize?.active ? 'Prize activated successfully' : 'Prize deactivated successfully';
        this.toastService.show(message, {classname: 'bg-success text-light'});
      }
      this._working$.next(false);
      this._search$.next();
      this.eventService.broadcast(modal, null)
    }, (error => {
      this.toastService.show(error, {classname: 'bg-danger text-light'});
      this._working$.next(false);
      this._search$.next();
      this.eventService.broadcast(modal, null)
    }));
  }

  private modalSuccess(result, modalEvent: EventEnum, message: string) {
    this.toastService.show(message, {classname: 'bg-success text-light'});
    this.eventService.broadcast(modalEvent, null)
    this._creating$.next(false);
    this._search$.next();
    return result;
  }

  private modalError(err, modalEvent: EventEnum) {
    this.eventService.broadcast(modalEvent, null)
    this.toastService.show(err, {classname: 'bg-danger text-light'});
    this._creating$.next(false);
    this._search$.next();
    return err;
  }

  public addPrizeCodes(codes: any[], prizeId: any): any {
    let codeApi: any[] = [];
    codes?.map((code) => {
      code.id = null;
      code.prizeId = prizeId;
      codeApi.push(this.prizesApiService.addPrizeCode(code));
      return code;
    });
    return combineLatest(codeApi);
  }

  public deletePrizeCodes(codes: any): any {
    let codeApi: any[] = [];
    codes?.map((code) => {
      codeApi.push(this.prizesApiService.deletePrizeCode(code));
      return code;
    });
    return combineLatest(codeApi);
  }

  public editPrizeCode(code: any, prizeId: any): any {
    code.prizeId = prizeId;
    this.prizesApiService.addPrizeCode(code).subscribe((result: any) => {
      this.toastService.show('Code edited successfully', {classname: 'bg-success text-light'});
    }, (error => {
      this.toastService.show(error, {classname: 'bg-danger text-light'});
    }));
  }

  public getPrizeData(id: any): any {
    return this.prizesApiService.getPrizeData({id: id})
  }

  public getPrizeForCompetition(id: any, competitionId: any): any {
    return this.prizesApiService.getPrizeForCompetition({id: id, competitionId: competitionId})
  }

  public getPrizeForCompany(id: any, companyId: any): any {
    return this.prizesApiService.getPrizeForCompany({id: id, companyId: companyId})
  }

  public getPrizeCodes(id: any): any {
    return this.prizesApiService.getPrizeCodes({forPrizeId: id, start: 0, limit: 1000})
  }

  public getPrizePreconditions(): any {
    return this.prizesApiService.getPrizePreconditions({page: 1, start: 0, limit: 100});
  }

  public clearFilters(): void {
    this.searchTerm = undefined;
    this.price = undefined;
    this.totalUnits = undefined;
    this.redeemedUnits = undefined;
    this.availableUnits = undefined;
    this.active = undefined;
    if (!this.router.url.includes('/active-list')) {
      this.active = undefined;
    }
    if (!this.router.url.includes('/expired-list')) {
      this.expiresAt = undefined;
    }
    if (!this.router.url.includes('/sold-out-list')) {
      this.availableUnits = undefined;
    }
    this.createdAt = undefined;
    this.expiresAt = undefined;
    this.contextDetailsId = undefined;
    this.contextType = undefined;
    this.prizeType = undefined;
  }

  public isFilterApplied(): boolean {
    const params: any = this._extractSearchParams();
    if (Object.keys(params?.filters)?.length > 0) {
      const obj = this.utils.clearObject(params?.filters);
      if (Object.keys(obj)?.length === 1 && Object.keys(obj)[0] === 'expiresAt' && this.router.url.includes('/expired-list')) {
        return false;
      } else if (Object.keys(obj)?.length === 1 && Object.keys(obj)[0] === 'active' && this.router.url.includes('/active-list')) {
        return false;
      } else if (Object.keys(obj)?.length === 1 && Object.keys(obj)[0] === 'availableUnits' && this.router.url.includes('/sold-out-list')) {
        return false;
      } else {
        return Object.keys(obj)?.length > 0;
      }
    } else {
      return false;
    }
  }

  private _extractSearchParams(): any {
    return {
      filters: {
        query: this.searchTerm ? [this.searchTerm] : undefined,
        price: this.price && this.price?.length > 0 ? this.price : undefined,
        totalUnits: this.totalUnits && this.totalUnits?.length > 0 ? this.totalUnits : undefined,
        redeemedUnits: this.redeemedUnits && this.redeemedUnits?.length > 0 ? this.redeemedUnits : undefined,
        availableUnits: this.availableUnits && this.availableUnits?.length > 0 ? this.availableUnits : undefined,
        active: (this.active !== undefined && this.active !== null) ? [this.active] : undefined,
        createdAt: this.createdAt && this.createdAt?.length > 0 ? this.createdAt : undefined,
        expiresAt: this.expiresAt && this.expiresAt?.length > 0 ? this.expiresAt : undefined,
        contextType: this.contextType && (this.contextType !== 'undefined') ? ["CONTAINS","IGNORE_CASE", this.contextType] : undefined,
        contextDetailsId: this.contextType && (this.contextType !== 'undefined') && this.contextDetailsId  && Number(this.contextDetailsId)? ["EQ", Number(this.contextDetailsId)] : undefined,
        prizeType: this.prizeType && (this.prizeType !== 'undefined') ? ["CONTAINS","IGNORE_CASE", this.prizeType] : undefined,
      },
      sort: this.extractSorting(),
      page: this.page,
      size: this.pageSize
    }
  }

  private _setValue(patch: Partial<TableStateInterface>) {
    Object.assign(this._currentTableState, patch);
    this._search$.next();
  }

  private extractSorting(): string {
    return this.utils.extractSorting(this.sortColumn, this.sortDirection);
  }
}

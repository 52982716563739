import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject, Subscription} from "rxjs";
import {TopbarTypeEnum} from "@enum/topbar/topbar-type.enum";
import {UserDiagnosticService} from "@service/users/user-diagnostic.service";
import {UtilsService} from "@service/utils/utils.service";
import {FitnessMetricEnum} from "@enum/fitness-metric/fitness-metric.enum";

@Component({
  selector: 'app-diagnostic-topbar',
  templateUrl: './diagnostic-topbar.component.html',
  styleUrls: ['./diagnostic-topbar.component.scss']
})
export class DiagnosticTopbarComponent implements OnInit {
  @Input() userId: number;
  @Input() type: TopbarTypeEnum;
  @Output() dateRangeSelected: EventEmitter<string[]> = new EventEmitter();
  @Output() metricSelected: EventEmitter<string[]> = new EventEmitter();
  public clearFilters: Subject<boolean> = new Subject<boolean>();
  public topbarTypeEnum = TopbarTypeEnum;
  private eventsSubscription: Subscription;
  public fitnessMetricEnum = FitnessMetricEnum;
  public dates: Date[] = [];
  public list: any = this.utils.getFitnessMetrics();
  constructor(private userDiagnosticService: UserDiagnosticService, private utils: UtilsService) { }

  ngOnInit(): void {
  }

  public setDate(date: any): void {
    if (date && !!date[1] && !! date[2]) {
      this.dates = [date[1], date[2]];
      if (this.type === TopbarTypeEnum.DIAGNOSTIC_RECORDED_ACTIVITIES) {
        this.searchFitnessDataForSelectedRanges();
      }
    } else {
      this.dates = [];
    }
  }

  private searchFitnessDataForSelectedRanges(): void {
    this.list?.map((el: any) => {
      this.userDiagnosticService.getUserFitnessCharts(this.dates[0], this.dates[1], String(this.userId), el.value).subscribe((result: any) => {
        if (result?.length > 0) {
          el.total = this.userDiagnosticService.aggregateKpisForTopbar(result);
        }
        return el;
      }, (error => {
      }));
    });
  }

  public returnOnlyFilteredFitnessMetrics(): any[] {
    return this.list.filter((el: any) => {
      return el.value === FitnessMetricEnum.STEPS || el.value === FitnessMetricEnum.SPORT || el.value === FitnessMetricEnum.SLEEP || el.value === FitnessMetricEnum.MEDITATION || el.value === FitnessMetricEnum.YOGA_MINUTES;
    });
  }

  ngOnDestroy() {
    if (this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
  }

}

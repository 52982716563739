<ng-template #modal>
  <div class="modal-header border-bottom-0">
    <h5 class="modal-title" id="modal-title">
      <span>Edit individual codes for prize: {{prize?.title}}</span>
    </h5>
    <button class="btn btn-outline-light close-modal-button"
            closeModal="CLOSE_PRIZE_CODES_MODAL">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-6" [hidden]="!showAddCodes">
        <div class="mb-3">
          <label class="font-weight-bold" for="codeType">Code type</label>
          <select class="form-select" id="codeType" (change)="checkPrizeCodes()" [(ngModel)]="selectedPrizeCodeType" [ngModelOptions]="{standalone: true}">
            <option *ngFor="let codeType of prizeCodeType" [value]="codeType">{{codeType | translate}}</option>
          </select>
        </div>
      </div>
      <div class="col-12 text-right button-code" [hidden]="showAddCodes">
        <div class="mb-3">
          <button *ngIf="selectedItems?.length > 0" (click)="removeSelectedCodes()" class="btn btn-danger ms-2">Remove selected</button>
        </div>
      </div>
      <div class="col-12" [hidden]="showAddCodes || prizeCodes?.length === 0">
        <div class="codes-table mt-3 mb-3">
          <table class="table table-bordered align-middle table-hover table-striped table-nowrap mt-0 mb-0">
            <thead>
            <tr>
              <th>
                <div (click)="selectAllResults()" class="form-check form-check-custom font-size-16 align-middle p-0">
                  <input [checked]="allCodesSelected" class="form-check-input m-0" id="userSelection01" type="checkbox">
                </div>
              </th>
              <th>
                <span class="me-3">{{'CODE' | translate}}</span>
              </th>
              <th>
                <span class="me-3">{{'PRIZE_AVAILABLE_UNITS' | translate}}</span>
              </th>
              <th>
                <span class="me-3">{{'CURRENT_USES' | translate}}</span>
              </th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let code of prizeCodes; let id=index;">
              <td align="center" class="fit-width">
                <div class="form-check form-check-custom font-size-16 p-0 m-0">
                  <input (click)="code.selected = !code.selected; extractSelectedItems()"
                         [checked]="code.selected"
                         [disabled]="code?.currentUses > 0"
                         [value]="code.selected"
                         class="form-check-input p-0 m-0" id="userSelection{{id}}"
                         type="checkbox">
                </div>
              </td>
              <td contenteditable="true"  (keyup)="changeValue(id, 'code', $event)" (blur)="updateList(id, 'code', $event)" align="center">
                <span>{{code?.code}}</span>
              </td>
              <td contenteditable="true" inputmode="decimal"
                  (keypress)="checkNumberMaxUses($event)"
                  *ngIf="selectedPrizeCodeType === 'shared'" (keyup)="changeValue(id, 'totalUnits', $event)" (blur)="updateList(id, 'totalUnits', $event)" align="center" class="fit-width">
                <span>{{code?.totalUnits}}</span>
              </td>
              <td *ngIf="selectedPrizeCodeType === 'individual'" align="center" class="fit-width">
                <span>{{code?.totalUnits}}</span>
              </td>
              <td align="center" class="fit-width">
                <span>{{code?.currentUses}}</span>
              </td>
              <td  class="pointer fit-width">
                            <span class="pointer danger font-size-16" [ngClass]="{'disabled-opacity': selectedItems?.length > 0}"  (click)="removeCode(code)" ngbTooltip="Delete" placement="left"><i
                              class="bx bx bx-trash"></i></span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-12" [hidden]="!showAddCodes">
        <div class="mt-3 mb-3" *ngIf="selectedPrizeCodeType === 'individual'">
                      <textarea [(ngModel)]="newPrizeCodes" [ngModelOptions]="{standalone: true}"
                                class="form-control codes-textarea"
                                placeholder="Insert all the codes separated with a space"
                                id="description"
                                rows="4"></textarea>
        </div>
        <div class="mt-3 mb-3" *ngIf="selectedPrizeCodeType === 'shared'">
          <input class="form-control" placeholder="Insert one single code" [(ngModel)]="newPrizeCodes" [ngModelOptions]="{standalone: true}" id="description" type="text">
        </div>
      </div>
      <div class="col-12" *ngIf="!loading && !showAddCodes && prizeCodes?.length === 0">
        <div class="text-center mt-5 mb-5">
          <h4 class="text-uppercase">{{'SORRY_NO_RESULTS' | translate}}</h4>
        </div>
      </div>
      <div *ngIf="loading" class="col-12 p-0">
        <div class="wrapper">
          <app-loader [table]="false"></app-loader>
        </div>
      </div>
    </div>
    <ul class="list-inline wizard mb-0">
      <li class="previous list-inline-item" *ngIf="showAddCodes">
        <button [disabled]="loading" class="btn btn-outline-light" (click)="showAddCodes = false; newPrizeCodes = undefined;">{{'BACK' | translate}}</button>
      </li>
      <li class="next list-inline-item float-end">
        <button [disabled]="loading" *ngIf="showAddCodes" (click)="addCodes()" class="btn btn-primary" type="submit">Save new codes</button>
        <button [disabled]="loading" *ngIf="!showAddCodes" (click)="showAddCodes = true;" class="btn btn-primary" type="submit">Add codes</button>
      </li>
    </ul>
  </div>
</ng-template>

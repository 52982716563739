<div class="row mt-3 modal-content-max">
  <div class="col-12">
    <h4 class="card-title mb-4">{{description | translate}} <span class="font-size-11">({{users?.length}})</span></h4>
    <app-users-table  [type]="type" *ngIf="users?.length > 0" [canBeRemoved]="users?.length > 1" [users]="users"></app-users-table>
  </div>
</div>
<ul class="list-inline wizard mb-0">
  <li *ngIf="massiveAction" class="previous list-inline-item">
    <button (click)="sendMassiveNotification()"
            class="btn btn-outline-light"
            ngbTooltip="Clicking here will open a new tab with the massive notification form"
            placement="bottom">Do you want to send a massive notification?
    </button>
  </li>
  <li class="next list-inline-item float-end">
    <button awNextStep class="btn btn-primary">{{'CONTINUE' | translate}}</button>
  </li>
</ul>

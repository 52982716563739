<ng-template #modal>
  <div class="modal-header border-bottom-0">
    <h5 class="modal-title" id="modal-title">
      <span *ngIf="!editingMotivation">Add ban motivation</span>
      <span *ngIf="editingMotivation">Edit motivation</span>
    </h5>
    <button [disabled]="(creating$ | async)" class="btn close-modal-button"
            closeModal="CLOSE_CREATE_BAN_MOTIVATION_MODAL">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
  <div class="modal-body">
    <p class="modal-description">{{'MEDIUM_LOREM' | translate}}</p>
    <form (ngSubmit)="submitForm()" [formGroup]="motivationForm">
      <div class="mb-3">
        <label class="form-label font-weight-semibold" for="description">Motivation description</label>
        <div>
          <textarea [ngClass]="{'is-invalid': formSubmitted && controls.reason.errors}"
                    class="form-control description-textarea"
                    formControlName="reason"
                    id="description"
                    rows="4"></textarea>
          <div *ngIf="controls.reason.errors" class="invalid-feedback">
            <span *ngIf="formSubmitted && controls.reason.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
            <span *ngIf="controls.reason.errors.maxlength">This value is too long. It should have 255 characters or fewer.</span>
            <span *ngIf="controls.reason.errors.minlength">This value is too short. It should have 50 characters or more.</span>
          </div>
        </div>
      </div>
      <div class="d-flex flex-wrap gap-2 flex-row-reverse">
        <button [disabled]="(creating$ | async)" class="btn btn-primary waves-effect waves-light" type="submit">
          <i *ngIf="creating$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          <span *ngIf="creating$ | async">
            <span *ngIf="!editingMotivation">Creating</span>
            <span *ngIf="editingMotivation">Saving</span>
          </span>
          <span [hidden]="(creating$ | async)">
            <span *ngIf="!editingMotivation">Create</span>
            <span *ngIf="editingMotivation">Save</span>
          </span>
        </button>
      </div>
    </form>
  </div>
</ng-template>

import {Injectable} from '@angular/core';
import {catchError, map} from "rxjs/operators";
import {Observable, throwError} from "rxjs";
import {HttpClient, HttpParams} from "@angular/common/http";
import {EndpointPlaceholderInterface} from "@interface/endpoint-placeholder.interface";
import {environment} from "@environment/environment";
import {ToastService} from "@service/toast.service";

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private httpClient: HttpClient, private toastService: ToastService) {
  }

  public composeUrl(endpointList: Array<string>, separator?: string | null, url?: string): string {
    if (!url) {
      endpointList.unshift(environment.apiPath);
    } else {
      endpointList.unshift(url);
    }
    if (!separator) {
      return endpointList.join('/');
    } else {
      return endpointList.join(separator);
    }
  }

  public findAndReplacePlaceholders(endpoint: string, placeholderAndValues: EndpointPlaceholderInterface[]): string {
    if (placeholderAndValues?.length === 0 || !placeholderAndValues) {
      return endpoint;
    } else {
      for (const object of placeholderAndValues) {
        endpoint = endpoint.replace(object.placeholder, object.value);
      }
      return endpoint;
    }
  }

  public generateQueryParams(params?: object | undefined): HttpParams {
    let queryParams = new HttpParams();
    if (params) {
      Object.entries(params).forEach(
        ([key, value]) => {
          if (key && value && !(typeof value === 'object')) {
            queryParams = queryParams.set(key, String(value));
          } else if (key && value && (typeof value === 'object')) {
            queryParams = queryParams.set(key, JSON.stringify(value));
          } else if (key && (typeof value === 'boolean')) {
            queryParams = queryParams.set(key, value);
          }
        }
      );
    }
    return queryParams;
  }

  public post(endpoint: string | string[], params?: object | undefined): Observable<any> {
    const url = this.composeUrl(Array.isArray(endpoint) ? endpoint : [endpoint]);
    return this.httpClient.post<any>(url, params).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  public get<T>(endpoint: string | string[], params?: HttpParams): Observable<any> {
    const url = this.composeUrl(Array.isArray(endpoint) ? endpoint : [endpoint]);
    return this.httpClient.get<T>(url, {params}).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(error => {
        this.toastService.show(error, {classname: 'bg-danger text-light'});
        return throwError(error);
      })
    );
  }

  public put(endpoint: string | string[], params?: object | undefined): Observable<any> {
    const url = this.composeUrl(Array.isArray(endpoint) ? endpoint : [endpoint]);
    return this.httpClient.put<any>(url, params).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  public delete(endpoint: string | string[], params?: object | undefined): Observable<any> {
    const url = this.composeUrl(Array.isArray(endpoint) ? endpoint : [endpoint]);
    return this.httpClient.delete<any>(url, params).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

}

<ng-template #modal>
  <div class="modal-header border-bottom-0">
    <h5 class="modal-title" id="modal-title">
      <span *ngIf="!banned">{{ 'USERS_USING_THIS_SOURCE' | translate}}</span>
      <span *ngIf="banned">{{ 'USERS_BANNED_BY_RULE' | translate}}</span>
    </h5>
    <button class="btn close-modal-button" closeModal="CLOSE_DETAIL_BLACKLIST_SOURCE_MODAL">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
  <div class="modal-body">
    <p class="modal-description">{{ 'REMOVE_BAN_DESCRIPTION' | translate}}</p>
    <div class="row mt-3 modal-content-max">
      <div class="col-12 text-center">
        <div *ngIf="detail?.loading">
          <div class="spinner-border text-secondary m-1" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <app-users-table *ngIf="detail?.users?.length > 0" [canBeRemoved]="false" [light]="true" [hideBannedColumn]="banned" [type]="deleteModalTypeEnum.BLACKLIST_SOURCE" [users]="detail?.users"></app-users-table>
        <div *ngIf="detail?.noResults && !banned">
          <ngb-alert [dismissible]="false" type="success">
            No one is using this source
          </ngb-alert>
        </div>
        <div *ngIf="detail?.noResults && banned">
          <ngb-alert [dismissible]="false" type="success">
            No one is banned because of this rule
          </ngb-alert>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<table *ngIf="users?.length > 0" class="table table-bordered align-middle table-hover table-striped table-nowrap mb-0">
  <thead>
  <tr>
    <th *ngIf="canBeRemoved"></th>
    <th>
      <span class="me-3">{{'USER_ID' | translate}}</span>
    </th>
    <th>
      <span class="me-3">{{'EMAIL' | translate}}</span>
    </th>
    <th>
      <span class="me-3">{{'NICKNAME' | translate}}</span>
    </th>
    <th *ngIf="type === deleteModalTypeEnum.BLACKLIST_SOURCE && !hideBannedColumn">
      <span class="me-3">{{'BANNED' | translate}}</span>
    </th>
    <th *ngIf="!light">
      <span class="me-3">{{'COMPLETE_NAME' | translate}}</span>
    </th>
    <th *ngIf="!light">
      <span class="me-3">{{'JOINED_COMPANIES' | translate}}</span>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr (click)="userDetail(user)" *ngFor="let user of users; let i=index;" class="pointer">
    <td (click)="removeUser(user)" *ngIf="canBeRemoved" class="pointer">
      <i class="dripicons-cross"></i>
    </td>
    <td align="center">
      <span *ngIf="type !== deleteModalTypeEnum.BLACKLIST_SOURCE"
            class="badge badge-pill badge-soft-primary">{{user?.id}}</span>
      <span *ngIf="type === deleteModalTypeEnum.BLACKLIST_SOURCE"
            class="badge badge-pill badge-soft-primary">{{user?.userId}}</span>
    </td>
    <td>
      <span>{{user?.email}}</span>
    </td>
    <td>
      <span>{{user?.nickname}}</span>
    </td>
    <td *ngIf="type === deleteModalTypeEnum.BLACKLIST_SOURCE && !hideBannedColumn">
      <span *ngIf="user?.banned">{{'YES' | translate}}</span>
      <span *ngIf="!user?.banned">{{'NO' | translate}}</span>
    </td>
    <td *ngIf="!light">
      <span>{{user?.fullName}}</span>
    </td>
    <td *ngIf="!light">
      <span>{{user?.companies | companies}}</span>
    </td>
  </tr>
  </tbody>
</table>

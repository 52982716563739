<ng-template #modal>
  <div class="modal-header border-bottom-0">
    <h5 *ngIf="!edit" class="modal-title" id="modal-title">{{'CREATE_NEWS' | translate}}</h5>
    <h5 *ngIf="edit" class="modal-title" id="modal-title">{{'EDIT_NEWS' | translate}}</h5>
    <button [disabled]="(addingNews$ | async)" class="btn close-modal-button" closeModal="CLOSE_ADD_NEWS_MODAL">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
  <div class="modal-body">
    <p class="modal-description">{{'MEDIUM_LOREM' | translate}}</p>
    <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="newsForm" autocomplete="off"
          class="needs-validation">
      <div class="row mt-3">
        <div class="col-12">
          <div class="row">
            <div class="col-md-2">
              <div class="mb-3">
                <label class="font-weight-bold">{{'NEWS_STATUS' | translate}}</label>
                <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                  <input [checked]="form.active.value" autocomplete="off" class="form-check-input m-0 pointer" formControlName="active"
                         id="active" type="checkbox">
                  <label class="form-check-label font-size-11 ms-3" for="active">
                    <span *ngIf="form?.active?.value"
                          class="badge badge-pill badge-soft-success font-size-11">Active</span>
                    <span *ngIf="!form?.active?.value"
                          class="badge badge-pill badge-soft-danger font-size-11">Inactive</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="mb-3">
                <label class="font-weight-bold" for="title">{{'TITLE' | translate}}</label>
                <input [ngClass]="{'is-invalid': formSubmitted && form.title.errors}" class="form-control"
                       formControlName="title" id="title" type="text">
                <div *ngIf="formSubmitted && form.title.errors" class="invalid-feedback">
                  <span *ngIf="formSubmitted && form.title.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="mb-3">
                <label class="font-weight-bold" for="linkUrl">{{'LINK' | translate}}</label>
                <input [ngClass]="{'is-invalid': formSubmitted && form.linkUrl.errors}" class="form-control"
                       formControlName="linkUrl" id="linkUrl" type="text">
                <div *ngIf="formSubmitted && form.linkUrl.errors" class="invalid-feedback">
                  <span *ngIf="formSubmitted && form.linkUrl.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
                  <span *ngIf="formSubmitted && form.linkUrl.errors.pattern">Please insert a valid url.</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="mb-3">
                <app-image-uploader (imageUploaded)="setImage($event)"
                                    [imageErrors]="checkImageError()"
                                    [limit]="limit" [previousSelectedImage]="checkSelectedImage()"></app-image-uploader>
              </div>
            </div>
          </div>
          <div class="row mt-2 companies-section">
            <div [ngClass]="{'col-md-6': selectedAudience !== newsAudienceEnum.ALL_USERS_IN_SPECIFIC_COMPANIES}"
                 class="col-md-4">
              <div class="mb-3 single-ng-select">
                <label class="font-weight-bold">News audience</label>
                <ng-select (change)="selectAudience($event)" [(ngModel)]="selectedAudience"
                           [clearable]="false"
                           [items]="audienceList"
                           formControlName="audience">
                  <ng-template let-item="item" ng-label-tmp>
                    <span>{{ item | translate }}</span>
                  </ng-template>
                  <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                    <span class="font-weight-semibold ms-2">{{item | translate}}</span>
                  </ng-template>
                </ng-select>
                <!--                <select [(ngModel)]="selectedAudience" class="form-select" formControlName="audience" id="audience"  [ngClass]="{'is-invalid': formSubmitted && form.audience.errors}">-->
                <!--                  <option *ngFor="let audience of audienceList" [value]="audience">-->
                <!--                    {{audience | translate}}-->
                <!--                  </option>-->
                <!--                </select>-->
                <div class="invalid-feedback">
                  <span
                    *ngIf="formSubmitted && form?.audience?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
            <div *ngIf="selectedAudience === newsAudienceEnum.ALL_USERS_IN_SPECIFIC_COMPANIES" class="col-md-4">
              <app-select-company (selectedCompany)="updateValidFlag()" [basicSelect]="true" [disabled]="disableCompany"
                                  [formSubmitted]="formSubmitted"
                                  [newsOptions]="true" [parentForm]="newsForm"
                                  [selectedItems]="checkSelectedCompanies()"></app-select-company>
            </div>
            <div [ngClass]="{'col-md-6': selectedAudience !== newsAudienceEnum.ALL_USERS_IN_SPECIFIC_COMPANIES}"
                 class="col-md-4">
              <div class="mb-3">
                <label class="font-weight-bold" for="priority">Priority</label>
                <input [ngClass]="{'is-invalid': form?.priority?.errors}" class="form-control"
                       formControlName="priority" id="priority"
                       min="0"
                       placeholder="News priority" type="number">
                <div class="invalid-feedback">
                  <span
                    *ngIf="formSubmitted && form?.priority?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul class="list-inline wizard mb-0">
        <li class="next list-inline-item float-end">
          <button (click)="valid ? showAreYouSure = true : formSubmitted = true;" *ngIf="!showAreYouSure && !edit"
                  class="btn btn-warning">Add news
          </button>
          <button (click)="valid ? showAreYouSure = true : formSubmitted = true;" *ngIf="!showAreYouSure && edit"
                  class="btn btn-warning">Edit news
          </button>
          <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
          <button *ngIf="showAreYouSure" [disabled]="(addingNews$ | async)" class="btn btn-warning me-2" type="submit">
            <i *ngIf="addingNews$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            <span *ngIf="(addingNews$ | async) && !edit">{{'CREATING' | translate}}</span>
            <span *ngIf="(addingNews$ | async) && edit">{{'EDITING' | translate}}</span>
            <span [hidden]="(addingNews$ | async)">
            {{'YES' | translate}}
          </span>
          </button>
          <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(addingNews$ | async)"
                  class="btn btn-outline-light ">{{'NO' | translate}}
          </button>
        </li>
      </ul>
    </form>
  </div>
</ng-template>

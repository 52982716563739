<ng-template #modal>
  <div class="modal-header border-bottom-0">
    <h5 *ngIf="!streakTemplate" class="modal-title" id="modal-title-1">{{'CREATE_STREAK_TEMPLATE' | translate}}</h5>
    <h5 *ngIf="streakTemplate" class="modal-title" id="modal-title-2">{{'EDIT_STREAK_TEMPLATE' | translate}}</h5>
    <button [disabled]="(creatingTemplate$ | async)" class="btn close-modal-button" closeModal="CLOSE_CREATE_STREAK_TEMPLATE">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
  <div class="modal-body">
    <!--    <p class="modal-description">{{'MEDIUM_LOREM' | translate}}</p>-->
    <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="streakTemplateForm" autocomplete="off"
          class="needs-validation">
      <div class="row mt-3">
        <div class="col-12">
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <label class="font-weight-bold mb-1" for="title">{{'NAME' | translate}}</label>
                <p class="card-title-desc mb-2">{{'STREAK_TEMPLATE_NAME_DESC' | translate}}</p>
                <input [ngClass]="{'is-invalid': formSubmitted && form.title.errors}" class="form-control"
                       formControlName="title" id="title" type="text">
                <div *ngIf="formSubmitted && form.title.errors" class="invalid-feedback">
                  <span *ngIf="formSubmitted && form.title.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
                  <span *ngIf="form.title.errors.maxlength">This value is too long. It should have 100 characters or fewer.</span>
                  <span *ngIf="form.title.errors.minlength">This value is too short. It should have 5 characters or more.</span>

                </div>
              </div>
            </div>
            <div class="col-md-6">
<!--              <div class="mb-3">-->
<!--                <label class="font-weight-bold mb-1" for="name">{{'STREAK_TEMPLATE_FITNESS_SCORING' | translate}}</label>-->
<!--                <p class="card-title-desc mb-2">{{'STREAK_TEMPLATE_FITNESS_SCORING_DESC' | translate}}</p>-->
<!--                <input [ngClass]="{'is-invalid': formSubmitted && form.name.errors}" class="form-control"-->
<!--                       formControlName="name" id="name" type="text">-->
<!--                <div *ngIf="formSubmitted && form.name.errors" class="invalid-feedback">-->
<!--                  <span *ngIf="formSubmitted && form.name.errors.required">{{'VALUE_REQUIRED' | translate}}</span>-->
<!--                  <span *ngIf="form.name.errors.maxlength">This value is too long. It should have 100 characters or fewer.</span>-->
<!--                  <span *ngIf="form.name.errors.minlength">This value is too short. It should have 5 characters or more.</span>-->

<!--                </div>-->
<!--              </div>-->
              <div class="mb-3 single-ng-select">
                <label class="font-weight-bold mb-1">{{'STREAK_TEMPLATE_FITNESS_SCORING' | translate}}</label>
                <p class="card-title-desc mb-2">{{'STREAK_TEMPLATE_FITNESS_SCORING_DESC' | translate}}</p>
                <ng-select [ngClass]="{'is-invalid-select': formSubmitted && form?.scoringFunction?.errors}" [(ngModel)]="fitnessScoringFunction"
                           [clearable]="false"
                           [items]="fitnessScoringFunctions"
                           formControlName="scoringFunction">
                  <ng-template let-item="item" ng-label-tmp>
                    <span>{{ item | translate }}</span>
                  </ng-template>
                  <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                    <span class="font-weight-semibold ms-2">{{item | translate}}</span>
                  </ng-template>
                </ng-select>
                <div class="invalid-feedback d-block"  *ngIf="formSubmitted && form?.scoringFunction?.errors">
                  <span *ngIf="formSubmitted && form?.scoringFunction?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="mb-3">
                <label class="font-weight-bold mb-1" for="descriptionTemplate">{{'DESCRIPTION' | translate}}</label>
                <p class="card-title-desc mb-2">{{'STREAK_TEMPLATE_DESCRIPTION_DESC' | translate}}</p>
                <input [ngClass]="{'is-invalid': formSubmitted && form.descriptionTemplate.errors}" class="form-control"
                       formControlName="descriptionTemplate" id="descriptionTemplate" type="text">
                <div *ngIf="formSubmitted && form.descriptionTemplate.errors" class="invalid-feedback">
                  <span class="d-block" *ngIf="form.descriptionTemplate.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
                  <span class="d-block" *ngIf="form.descriptionTemplate.errors.placeholderFirst">{{'FIRST_PLC_MISSING' | translate}}</span>
                  <span class="d-block" *ngIf="form.descriptionTemplate.errors.placeholderSecond">{{'SECOND_PLC_MISSING' | translate}}</span>
                  <span class="d-block" *ngIf="form.descriptionTemplate.errors.maxlength">This value is too long. It should have 100 characters or fewer.</span>
                  <span class="d-block" *ngIf="form.descriptionTemplate.errors.minlength">This value is too short. It should have 5 characters or more.</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <app-image-uploader [name]="'STREAK_LOCKED_IMAGE'"
                                    (imageUploaded)="setImage($event)"
                                    [showImagePreview]="true"
                                    [imageErrors]="checkLockedImageError()"
                                    [limit]="limit" [previousSelectedImage]="checkSelectedLockedImage()"></app-image-uploader>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <app-image-uploader [name]="'STREAK_UNLOCKED_IMAGE'"
                                    (imageUploaded)="setUnlockedImage($event)"
                                    [showImagePreview]="true"
                                    [imageErrors]="checkUnlockedImageError()"
                                    [limit]="limit" [previousSelectedImage]="checkSelectedUnlockedImage()"></app-image-uploader>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul class="list-inline wizard mb-0">
        <li class="next list-inline-item float-end">
          <button (click)="createStreakTemplate()" *ngIf="!streakTemplate" [disabled]="(creatingTemplate$ | async)"
                  class="btn btn-warning">
            <i *ngIf="creatingTemplate$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            <span *ngIf="(creatingTemplate$ | async) && !streakTemplate">
              {{'CREATING' | translate}}
            </span>
            <span *ngIf="!(creatingTemplate$ | async) && !streakTemplate">{{'CREATE' | translate}}</span>
          </button>
          <button (click)="createStreakTemplate()" *ngIf="streakTemplate"
                  class="btn btn-warning">
            <i *ngIf="creatingTemplate$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            <span *ngIf="(creatingTemplate$ | async) && streakTemplate">{{'EDITING' | translate}}</span>
            <span *ngIf="!(creatingTemplate$ | async) && streakTemplate">{{'EDIT' | translate}}</span>
          </button>
        </li>
      </ul>
    </form>
  </div>
</ng-template>

import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-banned-list-filters',
  templateUrl: './banned-list-filters.component.html',
  styleUrls: ['./banned-list-filters.component.scss']
})
export class BannedListFiltersComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}

<ng-template #modal>
  <div class="modal-header border-bottom-0">
    <h5 class="modal-title" id="modal-title-1" *ngIf="!edit">{{'ADD_AUTOMATIC_BAN_RULE' | translate}}</h5>
    <h5 class="modal-title" id="modal-title-1" *ngIf="edit">{{'EDIT_AUTOMATIC_BAN_RULE' | translate}}</h5>
    <button [disabled]="(creatingRule$ | async) || (working$ | async)" class="btn close-modal-button" closeModal="CLOSE_AUTOMATIC_BAN_RULE_MODAL">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
  <div class="modal-body">
    <p class="card-title-desc mb-2">If ban threshold is empty or 0 the rule will be deactivated</p>
    <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="automaticBanRuleForm" autocomplete="off"
          class="needs-validation">
      <div class="row mt-3">
        <div class="col-12">
          <div class="row">
            <div class="col-md-12">
              <div class="mb-3">
                <label class="font-weight-bold mb-1" for="rule">{{'RULE_METRIC' | translate}}</label>
                <p class="card-title-desc mb-2">{{'RULE_METRIC_DESC' | translate}}</p>
                <input [disabled]="true"  [placeholder]="this.rule?.fitnessMetric | translate" class="form-control" id="rule" type="text">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="mb-3">
                <label class="font-weight-bold" for="banThreshold">{{'BAN_THRESHOLD' | translate}}</label>
                <div class="input-group">
                  <input [ngClass]="{'is-invalid': formSubmitted && form.banThreshold.errors}" class="form-control"
                         formControlName="banThreshold" id="banThreshold" type="number" [min]="0">
                  <div class="input-group-append">
                    <label class="input-group-text input-append-btn">{{extractRuleMetric() | translate}}</label>
                  </div>
                  <div *ngIf="formSubmitted && form.banThreshold.errors" class="invalid-feedback">
                    <span *ngIf="formSubmitted && form.banThreshold.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="mb-3">
                <label class="font-weight-bold" for="banThresholdForWhiteList">{{'BAN_THRESHOLD_FOR_WHITELIST' | translate}}</label>
                <div class="input-group">
                  <input [ngClass]="{'is-invalid': formSubmitted && form.banThresholdForWhiteList.errors}" class="form-control"
                         formControlName="banThresholdForWhiteList" id="banThresholdForWhiteList" type="number" [min]="0">
                  <div class="input-group-append">
                    <label class="input-group-text input-append-btn">{{extractRuleMetric() | translate}}</label>
                  </div>
                  <div *ngIf="formSubmitted && form.banThresholdForWhiteList.errors" class="invalid-feedback">
                    <span *ngIf="formSubmitted && form.banThresholdForWhiteList.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul class="list-inline wizard mb-0">
        <li class="next list-inline-item float-start" *ngIf="!showAreYouSure && edit">
          <button (click)="deactivateRule()" [disabled]="(working$ | async)" class="btn btn-outline-danger">
            <i *ngIf="working$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            <span *ngIf="working$ | async">
            Deactivating
            </span>
            <span *ngIf="!(working$ | async)">
              Deactivate rule
            </span>
          </button>
        </li>
        <li class="next list-inline-item float-end">
          <button (click)="showAreYouSure = true" *ngIf="!showAreYouSure && !edit" class="btn btn-warning">Add rule</button>
          <button (click)="showAreYouSure = true" *ngIf="!showAreYouSure && edit" class="btn btn-warning">Edit rule</button>
          <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
          <button *ngIf="showAreYouSure" [disabled]="(creatingRule$ | async)" class="btn btn-warning me-2" type="submit">
            <i *ngIf="creatingRule$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            <span *ngIf="(creatingRule$ | async) && !edit">{{'CREATING' | translate}}</span>
            <span *ngIf="(creatingRule$ | async) && edit">{{'EDITING' | translate}}</span>
            <span [hidden]="(creatingRule$ | async)">
            {{'YES' | translate}}
          </span>
          </button>
          <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(creatingRule$ | async)"
                  class="btn btn-outline-light ">{{'NO' | translate}}
          </button>
        </li>
      </ul>
    </form>
  </div>
</ng-template>

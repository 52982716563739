import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbDate} from "@ng-bootstrap/ng-bootstrap";
import {Observable, Subscription} from "rxjs";

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {
  @Input() title: string = 'UTILS.COMMON.DATE.RANGE';
  @Input() fromDate: Date;
  @Input() toDate: Date;
  @Input() init: boolean;
  @Input() noLabel: boolean;
  @Input() clearAll: Observable<boolean>;
  @Output() dateRangeSelected: EventEmitter<string[]> = new EventEmitter();
  public hoveredDate: NgbDate;
  public fromNGDate: NgbDate;
  public toNGDate: NgbDate;
  public hideRangeDatePicker: boolean = true;
  public selectedDateRange: any;
  private eventsSubscription: Subscription;

  constructor() {
  }

  ngOnInit() {
    if (this.clearAll) {
      this.eventsSubscription = this.clearAll.subscribe((clear) => {
        if (clear) {
          this.clearComponent();
        }
      });
    }
    if (this.init) {
      const currentDate = new Date();
      const date = new Date();
      date.setDate(date.getDate() + 1);
      this.fromDate = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() - 60));
      this.toDate = date;
      this.selectedDateRange = this.fromDate.toLocaleDateString('it-IT') + '-' + this.toDate.toLocaleDateString('it-IT');
      this.emitDateRangeSelection();
      this.clearDates();
    }
  }

  ngOnDestroy() {
    if (this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
  }

  isHovered(date: NgbDate) {
    return this.fromNGDate && !this.toNGDate && this.hoveredDate && date.after(this.fromNGDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromNGDate) && date.before(this.toNGDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromNGDate) || date.equals(this.toNGDate) || this.isInside(date) || this.isHovered(date);
  }

  onDateSelection(date: NgbDate) {
    if (!date) {
      this.clearComponent();
    }
    if (!this.fromDate && !this.toDate) {
      this.fromNGDate = date;
      this.fromDate = new Date(date.year, date.month - 1, date.day);
      this.selectedDateRange = '';
    } else if (this.fromDate && !this.toDate && date.after(this.fromNGDate)) {
      this.toNGDate = date;
      this.toDate = new Date(date.year, date.month - 1, date.day);
      this.hideRangeDatePicker = true;
      this.selectedDateRange = this.fromDate.toLocaleDateString('it-IT') + '-' + this.toDate.toLocaleDateString('it-IT');
      this.emitDateRangeSelection();
      this.clearDates();
    } else {
      this.fromNGDate = date;
      this.fromDate = new Date(date.year, date.month - 1, date.day);
      this.selectedDateRange = '';
      this.dateRangeSelected.emit(null);
    }
  }

  private clearDates() {
    this.fromDate = null;
    this.toDate = null;
    this.fromNGDate = null;
    this.toNGDate = null;
  }

  closeFix(event, datePicker) {
    if (event.target.offsetParent == null)
      datePicker.close();
    else if (event.target.offsetParent.nodeName != "NGB-DATEPICKER")
      datePicker.close();
  }

  private clearComponent() {
    this.fromDate = null;
    this.toDate = null;
    this.fromNGDate = null;
    this.toNGDate = null;
    this.selectedDateRange = null;
    this.dateRangeSelected.emit(null);
  }

  private emitDateRangeSelection() {
    const from = new Date(this.fromDate.getTime() - (this.fromDate.getTimezoneOffset() * 60000)).toISOString();
    const to = new Date(this.toDate.getTime() - (this.toDate.getTimezoneOffset() * 60000)).toISOString();
    this.dateRangeSelected.emit(["BETWEEN", from, to]);
  }

}

import {AfterContentChecked, Component, Input, OnInit} from '@angular/core';
import {UsersApiService} from "@service/users/users-api.service";
import {Observable} from "rxjs";
import {UserElementInterface} from "@interface/user/user-element.interface";
import {CompaniesApiService} from "@service/companies/companies-api.service";

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, AfterContentChecked {
  @Input() userId: any;
  public user$: Observable<UserElementInterface>;
  public companies$: Observable<any>;
  public companies: [] = [];

  constructor(private usersApiService: UsersApiService, private companiesApiService: CompaniesApiService) {
  }

  ngOnInit(): void {
    if (this.userId) {
      this.user$ = this.usersApiService.getUserDetail(this.userId);
      this.loadUserCompanies();
    }
  }

  ngAfterContentChecked() {
    const column = document?.getElementById('column')?.offsetHeight;
    const scrollbar = document?.getElementById('scrollbar');
    if (scrollbar && column) {
      scrollbar.style.maxHeight = '' + (column - 140) + 'px';
      scrollbar.style.height = '' + (column - 140) + 'px';
    }
  }

  public loadUserCompanies(): void {
    const params = {
      userId: this.userId,
      page: 1,
      sieze: 9999
    }
    this.companiesApiService.getUserCompanies(params).subscribe((result: any) => {
      this.companies = result?.data ? result?.data : [];
    });
  }

  public companyDetail(company: any): void {
  }

  public extractCompanyDescription(description): string {
    return description.replace(/<[^>]*>?/gm, '');
  }

}

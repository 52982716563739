import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject, Subscription} from "rxjs";
import {TableColumnEnum} from "@enum/table-column/table-column.enum";
import {TableFilterEnum} from "@enum/table-filter/table-filter.enum";
import {TableStateInterface} from "@interface/common/table-state.interface";
import {EventService} from "@service/common/event.service";
import {ToastService} from "@service/toast.service";
import {UtilsService} from "@service/utils/utils.service";
import {SortDirection} from "@type/common/sort-direction.type";
import {catchError, debounceTime, map, switchMap, tap} from "rxjs/operators";
import {StreakTemplateApiService} from "@service/streak/streak-template-api.service";
import {EventEnum} from "@enum/event/event.enum";

@Injectable({
  providedIn: 'root'
})
export class StreakTemplateService {
  public _create$ = new Subject<string>();
  public _search$ = new Subject<void>();
  public visibleColumns: { visible: boolean, key: TableColumnEnum, label: any }[] = this.utils.streaksTemplateTableColumn;
  public visibleFilters: { visible: boolean, key: TableFilterEnum, label: any }[] = this.utils.streaksTemplateTableFilters;
  private _currentTableState: TableStateInterface = this.utils.tableDefaultState;
  private searchStreakTemplateSubscription: Subscription;
  private createTemplateSubscription: Subscription;

  constructor(private streakTemplateApiService: StreakTemplateApiService, private eventService: EventService,
              private toastService: ToastService, private utils: UtilsService) {
  }

  private _creating$ = new BehaviorSubject<boolean>(false);

  get creating$() {
    return this._creating$.asObservable();
  }

  private _deleting$ = new BehaviorSubject<boolean>(false);

  get deleting$() {
    return this._deleting$.asObservable();
  }


  private _loading$ = new BehaviorSubject<boolean>(true);

  public get loading$() {
    return this._loading$.asObservable();
  }

  private _streakList$ = new BehaviorSubject<any[]>([]);

  public get streakList$() {
    return this._streakList$.asObservable();
  }

  private _totalRecords$ = new BehaviorSubject<number>(0);

  public get totalRecords$() {
    return this._totalRecords$.asObservable();
  }

  get columns() {
    return this.visibleColumns;
  }

  get filters() {
    return this.visibleFilters;
  }

  public get searchTerm() {
    return this._currentTableState.searchTerm;
  }

  public set searchTerm(searchTerm: string) {
    this._setValue({searchTerm});
  }

  public get pageSize() {
    return this._currentTableState.pageSize;
  }

  public set pageSize(pageSize: number) {
    const page = 1;
    this._setValue({page})
    this._setValue({pageSize});
  }

  public get page() {
    return this._currentTableState.page;
  }

  public set page(page: number) {
    this._setValue({page});
  }

  public get sortColumn() {
    return this._currentTableState.sortColumn;
  }

  public set searchTermLocal(searchTerm: string) {
    this._currentTableState.searchTerm = searchTerm;
  }

  public set sortColumn(sortColumn: string) {
    this._setValue({sortColumn});
  }

  public get sortDirection() {
    return this._currentTableState.sortDirection;
  }

  public set sortDirection(sortDirection: SortDirection) {
    this._setValue({sortDirection});
  }

  public initSearchTemplateListener(): void {
    this.searchStreakTemplateSubscription = this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(50),
      switchMap(() => this.streakTemplateApiService.getTemplateList(this._extractSearchParams())),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      let templates = result?.data?.map((template) => {
        template.languages = this.utils?.getAvailableLang(template?.localizableKey);
        return template;
      });
      this._streakList$.next(templates);
      this._totalRecords$.next(result?.size);
    });
  }

  public removeSearchTemplatesSubscribe(): void {
    this.searchStreakTemplateSubscription?.unsubscribe();
    this._loading$.next(false);
  }

  public clearFilters(): void {
    this.searchTerm = undefined;
    this.fitnessScoringFunction = undefined;
  }

  public isFilterApplied(): boolean {
    const params: any = this._extractSearchParams();
    if (Object.keys(params?.filters)?.length > 0) {
      const obj = this.utils.clearObject(params?.filters);
      return Object.keys(obj)?.length > 0;
    } else {
      return false;
    }
  }

  get fitnessScoringFunction() {
    return this._currentTableState.fitnessScoringFunction;
  }

  set fitnessScoringFunction(fitnessScoringFunction: string) {
    this._setValue({fitnessScoringFunction});
  }

  public getTemplates(): any {
    return this.streakTemplateApiService.getTemplateList({page: 1, size: 1000})
  }

  private _extractSearchParams(): any {
    return {
      filters: {
        query: this.searchTerm ? [this.searchTerm] : undefined,
        fitnessScoringFunction: (this.fitnessScoringFunction !== undefined && this.fitnessScoringFunction !== null) ? [this.fitnessScoringFunction] : undefined,
      },
      sort: this.extractSorting(),
      page: this.page,
      size: this.pageSize
    }
  }

  public getFitnessScoring(): any {
    return this.streakTemplateApiService.getTemplateFitnessScoringFunctions(null);
  }

  private extractSorting(): string {
    return this.utils.extractSorting(this.sortColumn, this.sortDirection);
  }

  public createStreakTemplate(data: any): void {
    this._create$.next(data);
  }

  public initCreateListener(): void {
    this.createTemplateSubscription = this._create$.pipe(
      tap(() => this._creating$.next(true)),
      tap(() => this._loading$.next(true)),
      switchMap((data: any) => this.streakTemplateApiService.createEditTemplate(data).pipe(
        map((result) => {
          if (result?.length > 0) {
            result.map((error) => {
              this.toastService.show(error?.cause, {classname: 'bg-danger text-light'});
              return error;
            });
            this.eventService.broadcast(EventEnum.CLOSE_CREATE_STREAK_TEMPLATE, null)
            this._search$.next();
            return result;
          } else {
            const message = data?.id ? 'Streak template edited successfully' : 'Streak template created successfully';
            return this.modalSuccess(result, EventEnum.CLOSE_CREATE_STREAK_TEMPLATE, message);
          }
        }),
        catchError((err, caught) => {
          this._creating$.next(false);
          return this.modalError(err, EventEnum.CLOSE_CREATE_STREAK_TEMPLATE);
        })
      )),
      tap(() => this._creating$.next(false))
    ).subscribe((result) => {
    });
  }

  private modalSuccess(result, modalEvent: EventEnum, message: string) {
    this.toastService.show(message, {classname: 'bg-success text-light'});
    this.eventService.broadcast(modalEvent, null)
    this._search$.next();
    return result;
  }

  private modalError(err, modalEvent: EventEnum) {
    this.eventService.broadcast(modalEvent, null)
    this.toastService.show(err, {classname: 'bg-danger text-light'});
    this._search$.next();
    return err;
  }


  public removeTemplateCreateSubscribe(): void {
    this.createTemplateSubscription?.unsubscribe();
    this._creating$.next(false);
  }

  private _setValue(patch: Partial<TableStateInterface>) {
    Object.assign(this._currentTableState, patch);
    this._search$.next();
  }


}

import {Component, Input, OnInit} from '@angular/core';
import {UserElementInterface} from "@interface/user/user-element.interface";
import {EventService} from "@service/common/event.service";
import {EventEnum} from "@enum/event/event.enum";
import {DeleteModalTypeEnum} from "@enum/delete-modal-type/delete-modal-type.enum";
import {Router} from "@angular/router";

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss']
})
export class UsersTableComponent implements OnInit {
  @Input() type?: DeleteModalTypeEnum;
  @Input() hideBannedColumn: boolean;
  @Input() canBeRemoved: boolean;
  @Input() light: boolean;
  @Input() users: UserElementInterface[];
  public deleteModalTypeEnum = DeleteModalTypeEnum;

  constructor(private eventService: EventService, private router: Router) {
  }

  ngOnInit(): void {
  }

  public removeUser(selectedUser: UserElementInterface): void {
    if (this.users?.length > 1) {
      if (this.type && this.type === DeleteModalTypeEnum.USER) {
        this.eventService.broadcast(EventEnum.REMOVE_USER_FROM_DELETE_LIST, selectedUser)
      } else if (this.type && this.type === DeleteModalTypeEnum.SCHEDULED_NOTIFICATION) {
        this.eventService.broadcast(EventEnum.REMOVE_USER_FROM_NOTIFICATION_LIST, selectedUser)
      } else {
        this.eventService.broadcast(EventEnum.REMOVE_USER_FROM_BAN_LIST, selectedUser)
      }
    }
  }


  public userDetail(user: any) {
    this.router.navigate(['users/detail', String(user?.userId)]);
  }

}

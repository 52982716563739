<header class="table-topbar" id="page-topbar-filters">
  <div class="navbar-header">
    <div class="d-flex">
      <div class="row ms-2">
        <div class="col-sm-12 col-lg-12">
          <form class="app-search d-none d-xl-block">
            <div class="position-relative">
              <input class="form-control form-control-dark" [(ngModel)]="searchTerm" (ngModelChange)="fieldsChange($event)"
                     name="searchTerm" placeholder="{{'SEARCH' | translate}}" type="text">
              <span class="bx bx-search-alt"></span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</header>

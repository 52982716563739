import {Injectable} from '@angular/core';
import {HttpService} from "@service/http/http.service";
import {Observable} from "rxjs";
import {endpoints} from "@endpoint/endpoint-list";
import {EndpointParamEnum} from "@enum/endpoint-param.enum";

@Injectable({
  providedIn: 'root'
})
export class NotificationsApiService {

  constructor(private http: HttpService) {
  }

  public sendPushNotification(data: any): Observable<any> {
    return this.http.post(endpoints.notification.sendPush, data);
  }

  public getNotificationList(filters: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.notification.list, params);
  }

  public deleteNotifications(notification: any): any {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: notification[0]?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.notification.delete, placeholders);
    return this.http.delete(url, {});
  }

  public getNotificationActionsList(): Observable<any> {
    return this.http.get<any>(endpoints.notification.actions);
  }

  public getNotificationAudienceList(): Observable<any> {
    return this.http.get<any>(endpoints.notification.audience);
  }

}

import {EndpointParamEnum} from "@enum/endpoint-param.enum";

const separator = '/';
export const endpoints = {
  user: {
    list: ['users', 'list'].join(separator),
    detail: ['user', EndpointParamEnum.ID].join(separator),
    assignCredits: ['user', EndpointParamEnum.ID, 'assignCredits'].join(separator),
    memberships: ['companies', 'memberships'].join(separator),
    bans: ['user', 'bans', 'list'].join(separator),
    specificBans: ['user', 'bans'].join(separator),
    banMotivations: ['bans', 'motivation'].join(separator),
    createBanMotivation: ['bans', 'motivation'].join(separator),
    editBanMotivation: ['bans', 'motivation', EndpointParamEnum.ID].join(separator),
    deleteBanMotivation: ['bans', 'motivation', EndpointParamEnum.ID].join(separator),
    deleteUsers: ['users'].join(separator),
    diary: ['user', 'diary'].join(separator),
    fitness: ['fitnessSlots'].join(separator),
    fitnessSummary: ['user', EndpointParamEnum.ID, 'summary'].join(separator),
    challenges: ['challenges', 'forUser'].join(separator),
    notification: ['user', EndpointParamEnum.ID, 'pushMessage'].join(separator),
    credits: ['user', EndpointParamEnum.ID, 'assignCredits'].join(separator),
    ban: ['bans', 'ban'].join(separator),
    unban: ['bans', 'unban'].join(separator),
    currentUser: ['users', 'me'].join(separator),
    addRemoveWhiteList: ['fraudPrevention', 'whiteList'].join(separator),
    fitnessActivities: ['fitnessActivities'].join(separator),
    fitnessCharts: ['user', EndpointParamEnum.ID, 'dailyKpis'].join(separator),
  },
  notification: {
    sendPush: ['notifications', 'upsert'].join(separator),
    list: ['notifications', 'scheduled'].join(separator),
    delete: ['notifications', 'scheduled', EndpointParamEnum.ID].join(separator),
    actions: ['notifications', 'actions'].join(separator),
    audience: ['notifications', 'audience'].join(separator)
  },
  rewards: {
    fitnessToCredits: {
      list: ['fitnessToCreditsConversions'].join(separator),
      detail: ['fitnessToCreditsConversions', 'for', EndpointParamEnum.ID].join(separator),
      delete: ['fitnessToCreditsConversions', EndpointParamEnum.ID].join(separator),
      new: ['fitnessToCreditsConversions', 'new'].join(separator),
      update: ['fitnessToCreditsConversions', EndpointParamEnum.ID, 'details'].join(separator),
    },
    list: ['rewards'].join(separator),
    detail: ['rewards', EndpointParamEnum.ID].join(separator),
    edit: ['rewards', EndpointParamEnum.ID,].join(separator)
  },
  utility: {
    automaticBanRules: {
      edit: ['fraudPrevention', 'automaticBan', 'rule'].join(separator),
      detail: ['fraudPrevention', 'automaticBan', 'rule', EndpointParamEnum.ID, 'uses'].join(separator),
      list: ['fraudPrevention', 'automaticBan', 'rules'].join(separator)
    },
    blackListSources: {
      delete: ['fraudPrevention', 'sourceBlacklistItem', EndpointParamEnum.ID].join(separator),
      detail: ['fraudPrevention', 'sourceBlacklistItem', EndpointParamEnum.ID, 'uses'].join(separator),
      add: ['fraudPrevention', 'sourceBlacklistItem'].join(separator),
      list: ['fraudPrevention', 'sourcesBlacklist'].join(separator)
    },
    qr: {
      list: ['credited', 'codes'].join(separator),
      create: ['credited', 'code'].join(separator),
    },
    contestMetrics: {
      list: ['availableContestMetrics'].join(separator)
    },
    tags: {
      list: ['tags'].join(separator)
    }
  },
  companies: {
    list: ['companies'].join(separator),
    memberships: ['companies', 'memberships'].join(separator),
    codes: ['companies', 'inviteCodes'].join(separator),
    create:  ['companies'].join(separator),
    addCodes: ['companies', 'inviteCodes?companyId=ID'].join(separator),
    detail: ['companies', EndpointParamEnum.ID].join(separator),
    edit: ['companies', EndpointParamEnum.ID].join(separator),
    delete: ['companies', EndpointParamEnum.ID].join(separator),
  },
  competitions: {
    list: ['competitions'].join(separator)
  },
  challenges: {
    list: ['challenges'].join(separator),
    forUser: ['challenges', 'forUser'].join(separator),
    duration: ['availableChallengeDurations'].join(separator),
    detail: ['contest', 'leaderboard', 'individual'].join(separator)
  },
  prizes: {
    competition: ['competitions', 'prizes'].join(separator),
    company: ['companies', 'prizes'].join(separator),
    list: ['prizes', 'list'].join(separator),
    forUser: ['prizes', 'forUser', EndpointParamEnum.ID].join(separator),
    create: ['prizes'].join(separator),
    createForCompany: ['companies', 'prizes?companyId=ID'].join(separator),
    listForCompany: ['companies', 'prizes?companyId=ID'].join(separator),
    createForCompetition: ['competitions', 'prizes?competitionId=ID'].join(separator),
    listForCompetition: ['competitions', 'prizes?competitionId=ID'].join(separator),
    detailForCompany: ['companies', 'prizes', EndpointParamEnum.ID].join(separator),
    detailForCompetition: ['competitions', 'prizes', EndpointParamEnum.ID].join(separator),
    detail: ['prizes', EndpointParamEnum.ID].join(separator),
    edit: ['prizes', EndpointParamEnum.ID].join(separator),
    delete: ['prizes', EndpointParamEnum.ID].join(separator),
    deletePrizeCode: ['prizeCode', EndpointParamEnum.ID].join(separator),
    codes: ['prizeCodes'].join(separator),
    preconditions: ['prizePreconditions'].join(separator),
    codesEdit: ['prizeCodes', EndpointParamEnum.ID].join(separator)
  },
  news: {
    edit: ['news', EndpointParamEnum.ID].join(separator),
    delete: ['news'].join(separator),
    list: ['paged', 'news'].join(separator),
    create: ['news'].join(separator)
  },
  invites: {
    list: ['codes'].join(separator),
    uses: ['codes', 'uses'].join(separator)
  },
  faqs: {
    list: ['faqs'].join(separator),
    create: ['faqs'].join(separator),
    delete: ['faqs', EndpointParamEnum.ID].join(separator),
    edit: ['faqs', EndpointParamEnum.ID].join(separator)
  },
  streak: {
    template: {
      list: ['dailyGoalTemplates'].join(separator),
      fitnessScoring: ['fitnessScoringFunctions'].join(separator),
      add: ['dailyGoalTemplates'].join(separator),
      edit: ['dailyGoalTemplates', EndpointParamEnum.ID].join(separator),
    },
    goal: {
      delete: ['dailyGoals', EndpointParamEnum.ID].join(separator),
      list: ['dailyGoals'].join(separator),
      add: ['dailyGoals'].join(separator),
      edit: ['dailyGoals', EndpointParamEnum.ID].join(separator),
    }
  }
};

import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {NotificationsService} from "@service/notifications/notifications.service";
import {ActionsEnum} from "@enum/notifications/actions/actions.enum";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-select-actions',
  templateUrl: './select-actions.component.html',
  styleUrls: ['./select-actions.component.scss']
})
export class SelectActionsComponent implements OnInit {
  @Input() parentForm: FormGroup
  @Input() users: any
  @Input() editData: any
  @Input() limitToOpenLink: any
  @Input() formSubmitted: boolean;
  @Output() selectedAction = new EventEmitter();
  public actionsEnum = ActionsEnum;
  public list$: any;
  public selected: any;
  public selectedDetail: any;

  constructor(private notificationsService: NotificationsService) {
  }

  ngOnInit(): void {
    this.getList();
    if (!!this.parentForm?.controls?.action?.value) {
      this.selected = this.parentForm?.controls?.action?.value;
      this.selectedAction.emit();
      (this.parentForm.controls['actionDetail']).reset();
    } else if (this.editData?.action) {
      this.selected = this.editData?.action;
      this.selectedAction.emit();
    }
    if (this.editData?.actionDetail) {
      this.selectedDetail = this.editData?.actionDetail;
    } else if (this.parentForm?.controls?.actionDetail?.value) {
      this.selectedDetail = this.parentForm?.controls?.actionDetail?.value;
    }
    if (this.selected === ActionsEnum.OPEN_PROFILE) {
      this.parentForm.patchValue({['actionDetail']: 'me'});
    }
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  public updateSelectedAction(event: any): void {
    this.selectedAction.emit();
    (this.parentForm.controls['actionDetail']).reset();
    if (this.selected === ActionsEnum.OPEN_PROFILE) {
      this.parentForm.patchValue({['actionDetail']: 'me'});
    }
  }

  private getList(): void {
    this.list$ = this.notificationsService.getNotificationActions();
  }

}

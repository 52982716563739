<div *ngIf="user$ | async as user" class="row p-3 detail-cards" id="column">
  <div class="col-md-4">
    <div class="card height-100 mini-stats-wid card-body mb-0">
      <div class="d-flex flex-wrap">
        <div class="mr-3"><h5 class="mb-0 font-weight-bold">Personal information</h5></div>
        <div class="avatar-sm ms-auto">
          <div class="avatar-title bg-light rounded-circle text-primary font-size-20"><i
            class="bx bx-info-circle"></i></div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-nowrap mb-0">
          <tbody>
          <tr>
            <th scope="row">First name :</th>
            <td *ngIf="user?.profile?.firstName">{{user?.profile?.firstName}}</td>
            <td *ngIf="!user?.profile?.firstName">
              <app-missing-label></app-missing-label>
            </td>
          </tr>
          <tr>
            <th scope="row">Last name :</th>
            <td *ngIf="user?.profile?.lastName">{{user?.profile?.lastName}}</td>
            <td *ngIf="!user?.profile?.lastName">
              <app-missing-label></app-missing-label>
            </td>
          </tr>
          <tr>
            <th scope="row">Nickname :</th>
            <td *ngIf="user?.profile?.nickname">{{user?.profile?.nickname}}</td>
            <td *ngIf="!user?.profile?.nickname">
              <app-missing-label></app-missing-label>
            </td>
          </tr>
          <tr>
            <th scope="row">Birthday :</th>
            <td *ngIf="user?.profile?.birthday">{{user?.profile?.birthday}}</td>
            <td *ngIf="!user?.profile?.birthday">
              <app-missing-label></app-missing-label>
            </td>
          </tr>
          <tr>
            <th scope="row">Phone number :</th>
            <td *ngIf="user?.profile?.phoneNumber">
              <span *ngIf="user?.profile?.prefix">({{user?.profile?.prefix}})</span>
              {{user?.profile?.phoneNumber}}</td>
            <td *ngIf="!user?.profile?.phoneNumber">
              <app-missing-label></app-missing-label>
            </td>
          </tr>
          <tr>
            <th scope="row">E-mail :</th>
            <td *ngIf="user?.email">{{user?.email}}</td>
            <td *ngIf="!user?.email">
              <app-missing-label></app-missing-label>
            </td>
          </tr>
          <tr>
            <th scope="row">City :</th>
            <td *ngIf="user?.profile?.city">{{user?.profile?.city}}</td>
            <td *ngIf="!user?.profile?.city">
              <app-missing-label></app-missing-label>
            </td>
          </tr>
          <tr>
            <th scope="row">Weight :</th>
            <td *ngIf="user?.profile?.weightKg">{{user?.profile?.weightKg}}</td>
            <td *ngIf="!user?.profile?.weightKg">
              <app-missing-label></app-missing-label>
            </td>
          </tr>
          <tr>
            <th scope="row">Height :</th>
            <td *ngIf="user?.profile?.heightCentimeters">{{user?.profile?.heightCentimeters}}</td>
            <td *ngIf="!user?.profile?.heightCentimeters">
              <app-missing-label></app-missing-label>
            </td>
          </tr>
          <tr>
            <th scope="row">Gender :</th>
            <td *ngIf="user?.profile?.gender">{{user?.profile?.gender}}</td>
            <td *ngIf="!user?.profile?.gender">
              <app-missing-label></app-missing-label>
            </td>
          </tr>
          <tr>
            <th scope="row">Biography :</th>
            <td *ngIf="user?.profile?.bio">{{user?.profile?.bio}}</td>
            <td *ngIf="!user?.profile?.bio">
              <app-missing-label></app-missing-label>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="card height-100 mini-stats-wid card-body mb-0">
      <div class="d-flex flex-wrap">
        <div class="mr-3"><h5 class="mb-0 font-weight-bold">App information</h5></div>
        <div class="avatar-sm ms-auto">
          <div class="avatar-title bg-light rounded-circle text-primary font-size-20"><i
            class="bx bx-mobile"></i></div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-nowrap mb-0">
          <tbody>
          <tr>
            <th scope="row">User id :</th>
            <td *ngIf="user?.id">{{user?.id}}</td>
            <td *ngIf="!user?.id">
              <app-missing-label></app-missing-label>
            </td>
          </tr>
          <tr>
            <th scope="row">User idmId :</th>
            <td *ngIf="user?.idmid">{{user?.idmid}}</td>
            <td *ngIf="!user?.idmid">
              <app-missing-label></app-missing-label>
            </td>
          </tr>
          <tr>
            <th scope="row">Platform :</th>
            <td *ngIf="user?.lastSlot?.platform">{{user?.lastSlot?.platform}}</td>
            <td *ngIf="!user?.lastSlot?.platform">
              <app-missing-label></app-missing-label>
            </td>
          </tr>
          <tr>
            <th scope="row">Fitness Kit :</th>
            <td *ngIf="user?.lastSlot?.kit">{{user?.lastSlot?.kit}}</td>
            <td *ngIf="!user?.lastSlot?.kit">
              <app-missing-label></app-missing-label>
            </td>
          </tr>
          <tr>
            <th scope="row">Locale :</th>
            <td *ngIf="user?.locale" class="text-uppercase">{{user?.locale}}</td>
            <td *ngIf="!user?.locale">
              <app-missing-label></app-missing-label>
            </td>
          </tr>
          <tr>
            <th scope="row">Available credits :</th>
            <td *ngIf="user?.availableCredits" class="text-uppercase">{{user?.availableCredits}}</td>
            <td *ngIf="!user?.availableCredits">
              <app-missing-label></app-missing-label>
            </td>
          </tr>
          <tr>
            <th scope="row">Imported from V1 :</th>
            <td>
              <span *ngIf="user?.importedFromV1"
                    class="badge badge-pill badge-soft-success">{{'YES' | translate}}</span>
              <span *ngIf="!user?.importedFromV1" class="badge badge-pill badge-soft-danger">{{'NO' | translate}}</span>
            </td>
          </tr>
          <tr>
            <th scope="row">Consent to share :</th>
            <td>
              <span *ngIf="user?.consentToShareGiven"
                    class="badge badge-pill badge-soft-success">{{'YES' | translate}}</span>
              <span *ngIf="!user?.consentToShareGiven"
                    class="badge badge-pill badge-soft-danger">{{'NO' | translate}}</span>
            </td>
          </tr>
          <tr>
            <th scope="row">Banned :</th>
            <td>
              <span *ngIf="user?.banned" class="badge badge-pill badge-soft-success">{{'YES' | translate}}</span>
              <span *ngIf="!user?.banned" class="badge badge-pill badge-soft-danger">{{'NO' | translate}}</span>
            </td>
          </tr>
          <tr>
            <th scope="row">Registration date :</th>
            <td *ngIf="user?.registeredAt">{{user?.registeredAt | date:'dd/MM/yyyy HH:mm'}}</td>
            <td *ngIf="!user?.registeredAt">
              <app-missing-label></app-missing-label>
            </td>
          </tr>
          <tr>
            <th scope="row">Last access :</th>
            <td *ngIf="user?.lastAccess?.atTs">{{user?.lastAccess?.atTs | date:'dd/MM/yyyy HH:mm'}}</td>
            <td *ngIf="!user?.lastAccess?.atTs">
              <app-missing-label></app-missing-label>
            </td>
          </tr>
          <tr>
            <th scope="row">Last slot recived :</th>
            <td *ngIf="user?.lastSlot?.ts">{{user?.lastSlot?.ts | date:'dd/MM/yyyy HH:mm'}}</td>
            <td *ngIf="!user?.lastSlot?.ts">
              <app-missing-label></app-missing-label>
            </td>
          </tr>
          <tr>
            <th scope="row">Last update :</th>
            <td *ngIf="user?.lastUpdateTs">{{user?.lastUpdateTs | date:'dd/MM/yyyy HH:mm'}}</td>
            <td *ngIf="!user?.lastUpdateTs">
              <app-missing-label></app-missing-label>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="card height-100 mini-stats-wid card-body mb-0">
      <div class="d-flex flex-wrap mb-3">
        <div class="mr-3"><h5 class="mb-0 font-weight-bold">Companies</h5></div>
        <div class="avatar-sm ms-auto">
          <div class="avatar-title bg-light rounded-circle text-primary font-size-20"><i
            class="bx bx-buildings"></i></div>
        </div>
      </div>
      <div *ngIf="companies?.length > 0" class="table-responsive">
        <ul class="list-group">
          <ngx-simplebar id="scrollbar">
            <li *ngFor="let company of companies; let i=index;" class="list-group-item">
              <div class="media">
                <div class="avatar-xs me-3">
                                        <span class="avatar-title rounded-circle bg-light">
                                            <img [src]="company?.company?.logo" alt="" height="18">
                                        </span>
                </div>
                <div class="media-body">
                  <h5 class="font-size-14">{{company?.company?.name}}</h5>
                  <p class="text-muted">{{extractCompanyDescription(company?.company?.description)}}</p>
                  <div class="float-end">
                    <button (click)="companyDetail(company?.company)" class="btn btn-dark">Detail</button>
                  </div>
                  <p class="company-data text-muted mb-0">Company status:
                    <span *ngIf="company?.company?.active"
                          class="ms-1 badge badge-pill badge-soft-success">{{'ACTIVE' | translate}}</span>
                    <span *ngIf="!company?.company?.active"
                          class="ms-1 badge badge-pill badge-soft-danger">{{'INACTIVE' | translate}}</span>
                  </p>
                  <p class="company-data text-muted mb-0">User banned:
                    <span *ngIf="company?.banned"
                          class="ms-1 badge badge-pill badge-soft-success">{{'YES' | translate}}</span>
                    <span *ngIf="!company?.banned"
                          class="ms-1 badge badge-pill badge-soft-danger">{{'NO' | translate}}</span>
                  </p>
                </div>
              </div>
            </li>
          </ngx-simplebar>
        </ul>
      </div>
      <div *ngIf="!companies || companies?.length === 0">
        <div class="text-center mt-5">
          <h4 class="text-uppercase">{{'SORRY_NO_RESULTS' | translate}}</h4>
        </div>
      </div>
    </div>
  </div>
</div>

import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {endpoints} from "@endpoint/endpoint-list";
import {HttpService} from "@service/http/http.service";

@Injectable({
  providedIn: 'root'
})
export class InvitesApiService {

  constructor(private http: HttpService) {
  }

  public getInvitesList(filters: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.invites.list, params);
  }

  public getInviteUses(filters: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.invites.uses, params);
  }
}

import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {EventService} from "@service/common/event.service";
import {EventEnum} from "@enum/event/event.enum";
import {BlacklistSourcesService} from "@service/utility/blacklist-sources.service";
import {FitnessKitEnum} from "@enum/fitness-kit/fitness-kit.enum";

@Component({
  selector: 'app-add-blacklist-source-modal',
  templateUrl: './add-blacklist-source-modal.component.html',
  styleUrls: ['./add-blacklist-source-modal.component.scss']
})
export class AddBlacklistSourceModalComponent implements OnInit {
  @ViewChild('modal') private modalContent: TemplateRef<any>
  @Input() source: any;
  public creatingSource$: Observable<boolean>;
  private modalRef: NgbModalRef;
  private closeModalSubscription: Subscription;
  public blacklistSourceForm: FormGroup;
  public formSubmitted: boolean;
  public showAreYouSure: boolean = false;
  public edit: boolean = false;
  public fitnessKits: FitnessKitEnum[] = [FitnessKitEnum.GOOGLE, FitnessKitEnum.APPLE];

  constructor(private modalService: NgbModal, private eventService: EventService, private blacklistSourceService: BlacklistSourcesService,
              public formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this._closeModal();
    this.creatingSource$ = this.blacklistSourceService?.creating$;
  }

  public open(source: any): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, {size: 'lg', centered: true, backdrop: false})
      this.modalRef.result.then(resolve, resolve)
      this.source = source;
      this.edit = !!source;
      this.initForm();
    })
  }

  private initForm() {
    this.blacklistSourceForm = this.formBuilder.group({
      id: [this.source?.id],
      fitnessKit: [this.source?.fitnessKit ? this.source?.fitnessKit : FitnessKitEnum.GOOGLE, [Validators.required]],
      name: [this?.edit && this.source?.name ? this.source?.name : null, [Validators.required]],
      description: [this?.edit && this.source?.description ? this.source?.description : null],
      sourcePath: [this?.edit && this.source?.sourcePath ? this.source?.sourcePath : null, [Validators.required]],
    });
  }

  private patchForm() {

  }

  public validSubmit() {
    if (this.blacklistSourceForm.valid) {
      let formData = this.blacklistSourceForm.getRawValue();
      this.blacklistSourceService?.initCreateListener();
      this.blacklistSourceService.createBlacklistSource(formData);
    } else {
      this.formSubmitted = true;
    }
  }

  get valid() {
    return this.blacklistSourceForm.valid;
  }

  get form() {
    return this.blacklistSourceForm.controls;
  }

  private _closeModal(): void {
    this.closeModalSubscription = this.eventService.subscribe(EventEnum.CLOSE_ADD_BLACKLIST_SOURCE_MODAL, (reason: string | undefined) => {
      this.formSubmitted = false;
      this.showAreYouSure = false;
      this.edit = false;
      this.source = undefined;
      this.blacklistSourceService.removeCreateSubscribe();
      this.modalRef?.dismiss(reason);
    });
  }
}

export enum EventEnum {
  CLOSE_BAN_MODAL = 'CLOSE_BAN_MODAL',
  CLOSE_REMOVE_BAN_MODAL = 'CLOSE_REMOVE_BAN_MODAL',
  CLOSE_CREATE_BAN_MOTIVATION_MODAL = 'CLOSE_CREATE_BAN_MOTIVATION_MODAL',
  REMOVE_USER_FROM_BAN_LIST = 'REMOVE_USER_FROM_BAN_LIST',
  REMOVE_USER_FROM_DELETE_LIST = 'REMOVE_USER_FROM_DELETE_LIST',
  CLOSE_DELETE_MODAL = 'CLOSE_DELETE_MODAL',
  CLOSE_NOTIFICATION_MODAL = 'CLOSE_NOTIFICATION_MODAL',
  CLOSE_QR_DETAIL_MODAL = 'CLOSE_QR_DETAIL_MODAL',
  CLOSE_QR_CREATE_MODAL = 'CLOSE_QR_CREATE_MODAL',
  REMOVE_USER_FROM_NOTIFICATION_LIST = 'REMOVE_COMPANY_FROM_NOTIFICATION_LIST',
  REMOVE_COMPANY_FROM_NOTIFICATION_LIST = 'REMOVE_COMPANY_FROM_NOTIFICATION_LIST',
  REMOVE_CHALLENGE_FROM_NOTIFICATION_LIST = 'REMOVE_CHALLENGE_FROM_NOTIFICATION_LIST',
  REMOVE_COMPETITION_FROM_NOTIFICATION_LIST = 'REMOVE_COMPETITION_FROM_NOTIFICATION_LIST',
  CLOSE_AUTOMATIC_BAN_RULE_MODAL = 'CLOSE_AUTOMATIC_BAN_RULE_MODAL',
  CLOSE_ADD_REMOVE_WHITELIST_MODAL = 'CLOSE_ADD_REMOVE_WHITELIST_MODAL',
  CLOSE_ADD_BLACKLIST_SOURCE_MODAL = 'CLOSE_ADD_BLACKLIST_SOURCE_MODAL',
  CLOSE_DETAIL_BLACKLIST_SOURCE_MODAL = 'CLOSE_DETAIL_BLACKLIST_SOURCE_MODAL',
  CLOSE_ADD_NEWS_MODAL = 'CLOSE_ADD_NEWS_MODAL',
  CLOSE_DEACTIVATE_MODAL = 'CLOSE_DEACTIVATE_MODAL',
  CLOSE_ACTIVATE_MODAL = 'CLOSE_ACTIVATE_MODAL',
  CLOSE_ADD_REMOVE_CREDITS = 'CLOSE_ADD_REMOVE_CREDITS',
  CLOSE_CREATE_STREAK_TEMPLATE = 'CLOSE_CREATE_STREAK_TEMPLATE',
  CLOSE_CREATE_STREAK = 'CLOSE_CREATE_STREAK',
  CLOSE_EDIT_STREAK_TEMPLATE = 'CLOSE_EDIT_STREAK_TEMPLATE',
  CLOSE_EDIT_STREAK = 'CLOSE_EDIT_STREAK',
  CLOSE_CREATE_PRIZE = 'CLOSE_CREATE_PRIZE',
  CLOSE_CREATE_FAQ = 'CLOSE_CREATE_FAQ',
  CLOSE_CREATE_COMPANY = 'CLOSE_CREATE_COMPANY',
  CLOSE_CREATE_COMPETITION = 'CLOSE_CREATE_COMPETITION',
  CLOSE_PRIZE_CODES_MODAL = 'CLOSE_PRIZE_CODES_MODAL',
  CLOSE_COMPANY_CODES_MODAL = 'CLOSE_COMPANY_CODES_MODAL',
  CLOSE_CREATE_FTO = 'CLOSE_CREATE_FTO'
}

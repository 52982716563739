import {ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DeleteModalTypeEnum} from "@enum/delete-modal-type/delete-modal-type.enum";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {EventService} from "@service/common/event.service";
import {EventEnum} from "@enum/event/event.enum";
import {Subscription} from "rxjs";
import {PrizesService} from "@service/prizes/prizes.service";
import {ToastService} from "@service/toast.service";

@Component({
  selector: 'app-prize-codes-modal',
  templateUrl: './prize-codes-modal.component.html',
  styleUrls: ['./prize-codes-modal.component.scss']
})
export class PrizeCodesModalComponent implements OnInit, OnDestroy {
  @ViewChild('modal') private modalContent: TemplateRef<any>
  private modalRef: NgbModalRef;
  public prize: any;
  private closeModalSubscription: Subscription;
  public prizeCodeType = ['shared', 'individual'];
  public selectedPrizeCodeType: string | undefined = 'individual';
  public showAddCodes = false;
  public newPrizeCodes: string | undefined;
  public prizeCodes: any[] = [];
  public editField: string | number;
  public codesPresent = false;
  public allCodesSelected = false;
  public loading = false;
  public selectedItems: any[] = [];
  private prizeCodesSubscription: Subscription;

  constructor(private modalService: NgbModal, private eventService: EventService,
              private prizeService: PrizesService, private toastService: ToastService,
              private ngZone: NgZone, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this._closeModal();
  }


  public open(data: any): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, {size: 'lg', centered: true, backdrop: false})
      this.modalRef.result.then(resolve, resolve)
      this.prize = data;
      this.extractPrizeCodes();
    })
  }

  private extractPrizeCodes(): void {
    this.loading = true;
    this.prizeCodesSubscription = this.prizeService.getPrizeCodes(this.prize?.id).subscribe((list: any) => {
      this.prizeCodes = list;
      if (list?.length > 0) {
        this.codesPresent = false;
        this.showAddCodes = false;
      }
      if (list?.length > 1) {
        this.selectedPrizeCodeType = 'individual';
      } else {
        this.selectedPrizeCodeType = 'shared';
      }
      this.prizeCodesSubscription?.unsubscribe();
      this.loading = false;
    });
  }


  updateList(id: number, property: string, event: any) {
    this.ngZone.run(() => {
      const originalValue = JSON.parse(JSON.stringify(this.prizeCodes[id][property]))
      if (property === 'totalUnits' && !isNaN(Number(event?.target?.textContent))) {
        this.prizeCodes[id][property] = !isNaN(Number(event?.target?.textContent)) ? Number(event?.target?.textContent) : this.prizeCodes[id][property];
      } else if (property !== 'totalUnits') {
        this.prizeCodes[id][property] = event?.target?.textContent;
      }
      if (this.prize?.id && (originalValue !== event?.target?.textContent)) {
        this.prizeService.editPrizeCode(this.prizeCodes[id], this.prize?.id);
      }
      this.cdr.detectChanges();
    });
  }

  public checkNumberMaxUses(e: any) {
    if (isNaN(Number(String.fromCharCode(e.which)))) e.preventDefault();
  }

  changeValue(id: number, property: string, event: any) {
    this.ngZone.run(() => {
      if (property === 'totalUnits' && !isNaN(Number(event?.target?.textContent))) {
        this.editField = !isNaN(Number(event?.target?.textContent)) ? Number(event?.target?.textContent) : 1;
      } else if (property !== 'totalUnits') {
        this.editField = event?.target?.textContent;
      }
      this.cdr.detectChanges();
    });
  }

  public selectAllResults(): void {
    this.allCodesSelected = !this.allCodesSelected;
    this.prizeCodes?.map((code: any) => {
      if (code?.currentUses === 0) {
        code.selected = this.allCodesSelected;
      } else {
        code.selected = false;
      }
      return code;
    })
    this.extractSelectedItems();
  }

  public extractSelectedItems(): any {
    this.selectedItems = this.prizeCodes?.filter((code) => {
      return code.selected;
    });
  }

  public addCodes(): void {
    if (this.selectedPrizeCodeType === 'individual') {
      const newCodes = this.newPrizeCodes.split('\n');
      const finalArray = newCodes?.map((code: string) => {
        return {id: Math.random().toString(36).substr(2, 9), code: code, totalUnits: 1, currentUses: 0};
      })?.filter((code) => !!code?.code);
      if (this.prize?.id) {
        this.prizeService.addPrizeCodes(finalArray, this.prize?.id).subscribe((result: any) => {
          if (result?.length > 0) {
            this.toastService.show('Codes added successfully', {classname: 'bg-success text-light'});
            this.addCodesToList(result);
          } else {
            this.toastService.show('There was an error adding the codes', {classname: 'bg-danger text-light'});
          }
        });
      } else {
        this.addCodesToList(finalArray);
      }
    } else if (this.selectedPrizeCodeType === 'shared') {
      this.prizeCodes = [{
        id: Math.random().toString(36).substr(2, 9),
        code: this.newPrizeCodes,
        totalUnits: 1,
        currentUses: 0
      }];
      if (this.prize?.id) {
        this.prizeService.addPrizeCodes(this.prizeCodes, this.prize?.id).subscribe((result: any) => {
          if (result?.id) {
            this.toastService.show('Code added successfully', {classname: 'bg-success text-light'});
            this.prizeCodes = [result];
          }
        });
      }
      this.newPrizeCodes = undefined;
      this.showAddCodes = !this.showAddCodes;
    }
  }

  private addCodesToList(finalArray: { code: string; currentUses: number; id: string; totalUnits: number }[]) {
    this.prizeCodes = [...finalArray, ...this.prizeCodes];
    this.newPrizeCodes = undefined;
    this.showAddCodes = !this.showAddCodes;
  }

  public removeSelectedCodes(): void {
    if (this.prize?.id) {
      this.prizeService.deletePrizeCodes(this.selectedItems).subscribe((result: any) => {
        this.toastService.show('Codes removed successfully', {classname: 'bg-success text-light'});
        this.deleteLocalCodes();
      });
    } else {
      this.deleteLocalCodes();
    }
  }

  private deleteLocalCodes() {
    this.selectedItems?.map((selectedCode: any) => {
      this.prizeCodes = this.prizeCodes?.filter((code: any) => {
        return code?.id !== selectedCode?.id;
      });
      return selectedCode;
    });
    if (this.prizeCodes?.length === 0) {
      this.showAddCodes = true;
    }
    this.allCodesSelected = false;
  }

  public checkPrizeCodes(): void {
    this.prizeCodes = [];
    this.newPrizeCodes = undefined;
    this.showAddCodes = true;
  }


  public removeCode(selectedCode: any): void {
    if (selectedCode?.currentUses > 0) {
      return;
    } else {
      if (this.prize?.id) {
        this.prizeService.deletePrizeCodes([selectedCode]).subscribe((result: any) => {
          this.toastService.show('Code removed successfully', {classname: 'bg-success text-light'});
          this.deleteSingleLocalCode(selectedCode);
        });
      } else {
        this.deleteSingleLocalCode(selectedCode);
      }
    }
  }

  private deleteSingleLocalCode(selectedCode: any) {
    this.prizeCodes = this.prizeCodes?.filter((code: any) => {
      return code?.id !== selectedCode?.id;
    });
    if (this.prizeCodes?.length === 0) {
      this.allCodesSelected = false;
      this.showAddCodes = true;
    }
    this.cdr.detectChanges();
    this.extractSelectedItems();
  }

  private _closeModal(): void {
    this.closeModalSubscription = this.eventService.subscribe(EventEnum.CLOSE_PRIZE_CODES_MODAL, (reason: string | undefined) => {
      this.modalRef?.dismiss(reason);
    });
  }

  ngOnDestroy() {
    if (this.closeModalSubscription) {
      this.closeModalSubscription.unsubscribe();
    }
  }
}

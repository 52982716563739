<div class="table-topbar diagnostic-search" id="diagnostic-topbar-filters">
  <div class="navbar-header">
    <div class="col-lg-7 align-self-center credits" *ngIf="type === topbarTypeEnum.DIAGNOSTIC_RECORDED_ACTIVITIES">
      <div class="clearfix ms-2 mt-4 mt-lg-0 text-lg-right">
        <button class="btn btn-dark btn-rounded me-2" type="button" *ngFor="let element of returnOnlyFilteredFitnessMetrics()" [hidden]="element?.total?.consolidate < 1">
          {{element?.label}}
          {{element?.total?.consolidate}}
          <i class="mdi mdi-bed align-middle ms-2 credits-icon" *ngIf="element?.value === fitnessMetricEnum.SLEEP"></i>
          <i class="mdi mdi-run-fast align-middle ms-2 credits-icon" *ngIf="element?.value === fitnessMetricEnum.SPORT"></i>
          <i class="mdi mdi-yoga align-middle ms-2 credits-icon" *ngIf="element?.value === fitnessMetricEnum.YOGA_MINUTES"></i>
          <i class="mdi mdi-meditation align-middle ms-2 credits-icon" *ngIf="element?.value === fitnessMetricEnum.MEDITATION"></i>
          <i class="mdi mdi-shoe-sneaker align-middle ms-2 credits-icon" *ngIf="element?.value === fitnessMetricEnum.STEPS"></i>
        </button>
      </div>
    </div>
    <div class="d-flex w-100 justify-content-end">
      <div class="row ms-2">
        <div class="col-sm-12 d-flex">
          <app-fitness-metric-picker (selectedFitnessMetric)="metricSelected.emit($event)" class="me-2" *ngIf="type === topbarTypeEnum.DIAGNOSTIC_FITNESS_CHARTS"></app-fitness-metric-picker>
          <div class="d-none d-xl-block diagnostic">
            <app-date-range-picker (dateRangeSelected)="dateRangeSelected.emit($event); setDate($event);"
                                   [noLabel]="true"
                                   [clearAll]="clearFilters.asObservable()"
                                   [init]="true"
                                   [title]="'ACTIVITY_DATE_RANGE'"></app-date-range-picker>

          </div>
          <button (click)="clearFilters.next(true)" class="btn btn-secondary ms-2"
                  type="button">
            <i class="mdi mdi-progress-close"></i>
            Clear filters
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {SimplebarAngularModule} from 'simplebar-angular';
import {NgbDropdownModule, NgbPaginationModule, NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {ClickOutsideModule} from 'ng-click-outside';

import {ComponentsModule} from '@component/components.module';
import {LayoutComponent} from './container/layout.component';
import {SidebarComponent} from './main-components/sidebar/sidebar.component';
import {TopbarComponent} from './main-components/topbar/topbar.component';
import {FooterComponent} from './main-components/footer/footer.component';
import {LanguageService} from '@service/common/language.service';
import {TranslateModule} from '@ngx-translate/core';
import {BannedListFiltersComponent} from './main-components/page-topbar/banned-list-filters/banned-list-filters.component';
import {UserListFiltersComponent} from './main-components/page-topbar/user-list-filters/user-list-filters.component';
import {PageTopBarComponent} from "./main-components/page-topbar/page-topbar.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DirectiveModule} from "@app/directives/directive.module";

@NgModule({
  declarations: [
    LayoutComponent,
    SidebarComponent,
    TopbarComponent,
    FooterComponent,
    BannedListFiltersComponent,
    UserListFiltersComponent,
    PageTopBarComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    NgbDropdownModule,
    ClickOutsideModule,
    ComponentsModule,
    SimplebarAngularModule,
    NgbPaginationModule,
    NgbPopoverModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    DirectiveModule
  ],
  exports: [
    TopbarComponent,
    FooterComponent,
    UserListFiltersComponent,
    BannedListFiltersComponent,
    PageTopBarComponent
  ],
  providers: [LanguageService]
})
export class LayoutModule {
}

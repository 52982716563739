import {ChangeDetectorRef, Component, Input, NgZone, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {EventService} from "@service/common/event.service";
import {DatePipe} from "@angular/common";
import {UtilsService} from "@service/utils/utils.service";
import {ToastService} from "@service/toast.service";
import {CompaniesService} from "@service/companies/companies.service";
import {EventEnum} from "@enum/event/event.enum";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-add-competition-modal',
  templateUrl: './add-competition-modal.component.html',
  styleUrls: ['./add-competition-modal.component.scss']
})
export class AddCompetitionModalComponent implements OnInit {
  @Input() company: any;
  public Editor = ClassicEditor
  public showAreYouSure: boolean = false;
  public creatingCompany$: Observable<boolean>;
  public formSubmitted: boolean;
  public companyForm: FormGroup;
  public logoImageLimit: any = {
    fixedSize: true,
    ratio: '21:9',
    width: 1440,
    height: 616
  };
  public detailImageLimit: any = {
    fixedSize: true,
    ratio: '21:9',
    width: 1440,
    height: 616
  };
  public languages = this.utils.languages;
  public prizePreconditions = [];
  public teamScoreStrategies = ['AVG', 'SUM'];
  public countChallengeWhen = ['CHALLENGE_START', 'CHALLENGE_END'];
  public countChallengeStrategy = ['ALL_MEMBERS_IN_COMPANY', 'AT_LEAST_ONE_MEMBER_IN_COMPANY'];
  public prizeCodeType = ['shared', 'individual'];
  public prizeRestriction = ['none', 'company'];
  public competitionType = ['INDIVIDUAL','INDIVIDUAL_AND_TEAMS','TEAMS'];
  public fitnessMetric = [
  'ALL',
  'CREDITS',
  'VIRTUOSITY',
  'STEPS',
  'SPORT_MINUTES',
  'CYCLING_MINUTES',
  'CYCLING_METERS',
  'RUNNING_MINUTES',
  'RUNNING_METERS'
  ];
  public selectedPrizeCodeType: string | undefined = 'individual';
  public selectedPrizeRestriction = 'none';
  public selectedCompetitionType = undefined;
  public fitnessScoringFunction = undefined;
  public companyTypes = undefined;
  public companyRestriction = undefined;
  public companyPreconditions = undefined;
  public brackets = undefined;
  public companyCodes = undefined;
  public byLeaderboardPosition: boolean = false;
  public byMetricValue: boolean = false;
  public selectedPrecondition: any;
  public newPrizeCodes: string | undefined;
  public prizeCodes: any[] = [];
  public editField: string | number;
  public showAddCodes = false;
  public codesPresent = false;
  public allCodesSelected = false;
  public selectedItems: any[] = [];
  @ViewChild('modal') private modalContent: TemplateRef<any>
  private modalRef: NgbModalRef;
  private closeModalSubscription: Subscription;
  private companyCodesSubscription: Subscription;

  constructor(private modalService: NgbModal, private eventService: EventService, private ngZone: NgZone,
              public datepipe: DatePipe, public utils: UtilsService, private cdr: ChangeDetectorRef,
              private toastService: ToastService,
              public formBuilder: FormBuilder, private companyService: CompaniesService) {
  }

  get form() {
    return this.companyForm.controls;
  }

  public get cardImage(): string | undefined {
    if (this.company?.logoImageUrl) {
      return this.company?.logoImageUrl;
    } else if (this.form?.logoImageUrl?.value) {
      return this.form?.logoImageUrl?.value;
    } else {
      return undefined;
    }
  }

  public checkSelectedCompanies(): any {
    if (this.form?.company?.value) {
      return [this.form?.company?.value]
    } else {
      return undefined;
    }
  }

  public selectCompany(event: any): void {
    if (event) {
      this.companyForm.patchValue({
        company: event?.length > 0 ? event[0] : null,
      });
    } else {
      this.companyForm.patchValue({
        company: null,
      });
    }
  }

  public get detailImage(): string | undefined {
    if (this.company?.detailsImageUrl) {
      return this.company?.detailsImageUrl;
    } else if (this.form?.detailsImageUrl?.value) {
      return this.form?.detailsImageUrl?.value;
    } else {
      return undefined;
    }
  }

  get valid() {
    return this.companyForm.valid;
  }

  ngOnInit(): void {
    this._closeModal();
    this.creatingCompany$ = this.companyService?.creating$;
    this.initForm();
  }

  public setCompany(company: any): void {
    this.company = company;
    this.extractCompanyCodes();
    this.patchFormWithEditingData();
  }

  public validSubmit() {
    if (this.companyForm.valid) {
      let formData = this.companyForm.getRawValue();
      this.companyService?.initCreateListener();
      if (this.company?.id) {
        formData.id = this.company?.id;
      }
      formData.companyStatsConfiguration = {
        countChallengeStrategy: formData?.countChallengeStrategy,
        countChallengeWhen: formData?.countChallengeWhen,
        co2Improvement: formData?.co2Improvement,
        co2ReductionKg: 80000,
      };
      formData.prizeContext = {
        title: formData?.prizeContextTitle,
        description: formData?.prizeContextDescription,
      }
      formData.title = formData?.name;
      formData.image = formData?.detailsImageUrl;
      formData.logo = formData?.logoImageUrl;
      this.companyService.createEditCompany(JSON.parse(JSON.stringify(formData)));
    }
  }

  public createPrize() {
    this.formSubmitted = true;
  }

  public open(data?: any): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, {size: 'xl', centered: true, backdrop: false})
      this.modalRef.result.then(resolve, resolve);
      if (data?.id) {
        this.setCompany(data);
      }
    })
  }

  public selectAllResults(): void {
    this.allCodesSelected = !this.allCodesSelected;
    this.companyCodes?.map((code: any) => {
      if (code?.currentUses === 0) {
        code.selected = this.allCodesSelected;
      } else {
        code.selected = false;
      }
      return code;
    })
    this.extractSelectedItems();
  }

  public extractSelectedItems(): any {
    this.selectedItems = this.companyCodes?.filter((code) => {
      return code.selected;
    });
  }

  public addCodes(): void {
    if (this.selectedPrizeCodeType === 'individual') {
      const newCodes = this.newPrizeCodes.split('\n');
      const finalArray = newCodes?.map((code: string) => {
        return {id: Math.random().toString(36).substr(2, 9), code: code, totalUnits: 1, currentUses: 0};
      })?.filter((code) => !!code?.code);
      if (this.company?.id) {
        // this.companyService.addPrizeCodes(finalArray, this.company?.id).subscribe((result: any) => {
        //   if (result?.length > 0) {
        //     this.toastService.show('Codes added successfully', {classname: 'bg-success text-light'});
        //     this.addCodesToList(result);
        //   } else {
        //     this.toastService.show('There was an error adding the codes', {classname: 'bg-danger text-light'});
        //   }
        // });
      } else {
        this.addCodesToList(finalArray);
      }
    } else if (this.selectedPrizeCodeType === 'shared') {
      this.companyCodes = [{
        id: Math.random().toString(36).substr(2, 9),
        code: this.newPrizeCodes,
        totalUnits: 1,
        currentUses: 0
      }];
      if (this.company?.id) {
        // this.companyService.addPrizeCodes(this.companyCodes, this.company?.id).subscribe((result: any) => {
        //   if (result?.id) {
        //     this.toastService.show('Code added successfully', {classname: 'bg-success text-light'});
        //     this.companyCodes = [result];
        //   }
        // });
      }
      this.newPrizeCodes = undefined;
      this.showAddCodes = !this.showAddCodes;
    }
  }

  private addCodesToList(finalArray: { code: string; currentUses: number; id: string; totalUnits: number }[]) {
    this.companyCodes = [...finalArray, ...this.companyCodes];
    this.newPrizeCodes = undefined;
    this.showAddCodes = !this.showAddCodes;
  }

  private deleteLocalCodes() {
    this.selectedItems?.map((selectedCode: any) => {
      this.companyCodes = this.companyCodes?.filter((code: any) => {
        return code?.id !== selectedCode?.id;
      });
      return selectedCode;
    });
    if (this.companyCodes?.length === 0) {
      this.showAddCodes = true;
    }
    this.allCodesSelected = false;
    this.cdr.detectChanges();
    this.extractSelectedItems();
  }

  public setCardImage(image: any): void {
    this.companyForm.patchValue({
      logoImageUrl: image ? image?.originalUrl : undefined,
      logoImageName: image ? image?.name : undefined,
    });
  }

  public setDetailImage(image: any): void {
    this.companyForm.patchValue({
      detailsImageUrl: image ? image?.originalUrl : undefined,
      detailsImageName: image ? image?.name : undefined,
    });
  }

  public checkCardImageError(): string | undefined {
    if (this.formSubmitted && this.form.logoImageUrl.errors?.required) {
      return 'This value is required';
    } else {
      return undefined;
    }
  }

  public checkDetailImageError(): string | undefined {
    if (this.formSubmitted && this.form.detailsImageUrl.errors?.required) {
      return 'This value is required';
    } else {
      return undefined;
    }
  }

  public checkSelectedCardImage(): object | undefined {
    if (this.company?.logoImageUrl && this.form?.logoImageUrl?.value) {
      return {
        originalUrl: this.company?.logoImageUrl,
        name: [this.company?.name?.replace(/\s/g, "_"), 'card_image'].join('_')
      }
    } else if (this.form?.logoImageUrl?.value) {
      return {
        originalUrl: this.form?.logoImageUrl?.value,
        name: [this.company?.name?.replace(/\s/g, "_"), 'card_image'].join('_')
      }
    } else {
      return undefined;
    }
  }

  public checkSelectedDetailImage(): object | undefined {
    if (this.company?.detailsImageUrl && this.form.detailsImageUrl?.value) {
      return {
        originalUrl: this.company?.detailsImageUrl,
        name: [this.company?.name?.replace(/\s/g, "_"), 'detail_image'].join('_')
      }
    } else if (this.form.detailsImageUrl?.value) {
      return {
        originalUrl: this.form.detailsImageUrl?.value,
        name: [this.company?.name?.replace(/\s/g, "_"), 'card_image'].join('_')
      }
    } else {
      return undefined;
    }
  }

  public extractCodesTooltip(): string {
    if (this.form?.prizeType?.value !== 'code_redemption') {
      return 'To insert codes the prize type must be CODE REDEMPTION'
    } else {
      return '';
    }
  }

  public checkAlert(type: string): boolean {
    if (!this.formSubmitted) {
      return false;
    }
    switch (type) {
      case 'BASE':
        return !!(this.form?.name?.errors || this.form?.description?.errors || this.form?.teamScoreStrategy?.errors || this.form?.priority?.errors  || this.checkCardImageError() || this.checkDetailImageError());
        break;
      case 'CONFIGURATION':
        return !!(this.form?.countChallengeWhen?.errors || this.form?.countChallengeStrategy?.errors || this.form?.co2Improvement?.errors);
        break;
      case 'PRECONDITIONS':
        return !!(this.form?.prizeContextTitle?.errors || this.form?.prizeContextDescription?.errors);
        break;
      case 'CODES':
        break;
      default:
        return false;
    }
  }

  private initForm() {
    this.companyForm = this.formBuilder.group({
      name: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(100)]],
      description: [null, [Validators.required]],
      rules: [null, [Validators.required]],
      active: [false],
      discoverable: [false],
      enabled: [false],
      mustInsertCode: [false],
      showCompanyLeaderboards: [false],
      showMembersCount: [false],
      teamScoreStrategy: [null, [Validators.required]],
      priority: [null, [Validators.required]],
      logoImageUrl: [null, [Validators.required]],
      logoImageName: [null, [Validators.required]],
      detailsImageUrl: [null, [Validators.required]],
      detailsImageName: [null, [Validators.required]],
      co2Improvement: [null, [Validators.required]],
      countChallengeStrategy: [null, [Validators.required]],
      countChallengeWhen: [null, [Validators.required]],
      prizeContextTitle: [null, [Validators.required]],
      prizeContextDescription: [null, [Validators.required]],
    });
  }

  private extractCompanyCodes(): void {
    // this.companyCodesSubscription = this.companyService.getPrizeCodes(this.company?.id).subscribe((list: any) => {
    //   this.companyCodes = list;
    //   if (list?.length > 0) {
    //     this.codesPresent = false;
    //     this.showAddCodes = false;
    //   }
    //   if (list?.length > 1) {
    //     this.selectedPrizeCodeType = 'individual';
    //   } else {
    //     this.selectedPrizeCodeType = 'shared';
    //   }
    //   this.companyCodesSubscription?.unsubscribe();
    // });
  }

  private patchFormWithEditingData() {
    this.companyForm.patchValue({
      name: this.company?.name,
      description: this.company?.description,
      rules: this.company?.rules,
      active: this.company?.active,
      discoverable: this.company?.discoverable,
      enabled: this.company?.enabled,
      mustInsertCode: this.company?.mustInsertCode,
      showCompanyLeaderboards: this.company?.showCompanyLeaderboards,
      showMembersCount: this.company?.showMembersCount,
      teamScoreStrategy: this.company?.teamScoreStrategy,
      priority: this.company?.priority,
      logoImageUrl: this.company?.logo,
      logoImageName: this.company?.logoImageName ? this.company?.logoImageName : [this.company?.name?.replace(/\s/g, "_"), 'logo_image'].join('_'),
      detailsImageUrl: this.company?.image,
      detailsImageName: this.company?.detailsImageName ? this.company?.detailsImageName : [this.company?.name?.replace(/\s/g, "_"), 'detail_image'].join('_'),
      co2Improvement: this.company?.companyStatsConfiguration?.co2Improvement,
      countChallengeStrategy: this.company?.companyStatsConfiguration?.countChallengeStrategy,
      countChallengeWhen: this.company?.companyStatsConfiguration?.countChallengeWhen,
      prizeContextTitle: this.company?.prizeContext?.title,
      prizeContextDescription: this.company?.prizeContext?.description
    });
    if (this.company.duplicate) {
      this.company = undefined;
    }
  }

  private _closeModal(): void {
    this.closeModalSubscription = this.eventService.subscribe(EventEnum.CLOSE_CREATE_COMPANY, (reason: string | undefined) => {
      this.companyService?.removeCreateCompanySubscribe();
      this.initForm();
      this.resetAllVariables();
      this.modalRef?.dismiss(reason);
    });
  }

  private resetAllVariables() {
    this.fitnessScoringFunction = undefined;
    this.formSubmitted = false;
    this.showAreYouSure = false;
    this.byLeaderboardPosition = false;
    this.byMetricValue = false;
    this.company = undefined;
    this.selectedItems = undefined;
    this.selectedPrizeCodeType = 'individual';
    this.selectedPrizeRestriction = 'none';
    this.showAddCodes = false;
    this.companyPreconditions = [];
    this.companyCodes = [];
    this.allCodesSelected = false;
    this.codesPresent = false;
  }

  ngOnDestroy() {
    if (this.closeModalSubscription) {
      this.closeModalSubscription.unsubscribe();
    }
  }

}

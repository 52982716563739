import {Injectable} from '@angular/core';
import {HttpService} from "@service/http/http.service";
import {Observable} from "rxjs";
import {endpoints} from "@endpoint/endpoint-list";
import {EndpointParamEnum} from "@enum/endpoint-param.enum";

@Injectable({
  providedIn: 'root'
})
export class PrizesApiService {
  constructor(private http: HttpService) {
  }

  public getPrizeList(filters: any, companyCompetitionFilter?: any): Observable<any> {
    let url: any;
    if (companyCompetitionFilter?.companyId) {
      url = endpoints.prizes.company;
      filters = {...filters, companyId: companyCompetitionFilter?.companyId};
      filters.size = 1000;
    } else if (companyCompetitionFilter?.competitionId) {
      url = endpoints.prizes.competition;
      filters = {...filters, competitionId: companyCompetitionFilter?.competitionId};
      filters.size = 1000;
    } else {
      url = endpoints.prizes.list
    }
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(url, params);
  }

  public createEditPrize(params: any): Observable<any> {
    if (params?.precondition?.length > 0) {
      params.preconditions = params?.precondition?.map((precondition: any) => {
        return precondition?.id;
      });
    }
    if (params?.expiresOn && !(params?.expiresOn instanceof Date)) {
      params.expiresAt = params?.expiresOn.split("/").reverse().join("-");
    } else if (params?.expiresOn && (params?.expiresOn instanceof Date)) {
      params.expiresAt = params?.expiresOn?.toISOString()?.split('T')[0];
    }
    if (params?.expiresAt && (params?.expiresAt instanceof String || typeof params?.expiresAt === 'string') && params?.expiresAt?.includes('T')) {
      params.expiresAt = params?.expiresAt?.split('T')[0];
    } else if (params?.expiresAt && (typeof params?.expiresAt === 'number' || params?.expiresAt instanceof Number)) {
      const date = new Date(params.expiresAt);
      params.expiresAt = date?.toISOString()?.split('T')[0];
    }
    params.createdAt = null;
    if (params?.company?.id) {
      const placeholders = [{placeholder: EndpointParamEnum.ID, value: params?.company?.id}];
      const url = this.http.findAndReplacePlaceholders(endpoints.prizes.createForCompany, placeholders);
      return this.http.post(url, params);
    } else if (params?.competition?.id) {
      const placeholders = [{placeholder: EndpointParamEnum.ID, value: params?.competition?.id}];
      const url = this.http.findAndReplacePlaceholders(endpoints.prizes.createForCompetition, placeholders);
      return this.http.post(url, params);
    } else if (params?.id) {
      const placeholders = [{placeholder: EndpointParamEnum.ID, value: params?.id}];
      const url = this.http.findAndReplacePlaceholders(endpoints.prizes.edit, placeholders);
      return this.http.put(url, params);
    } else {
      return this.http.post(endpoints.prizes.create, params);
    }
  }

  public addPrizeCode(params: any): Observable<any> {
    if (params?.id) {
      const placeholders = [{placeholder: EndpointParamEnum.ID, value: params?.id}];
      const url = this.http.findAndReplacePlaceholders(endpoints.prizes.codesEdit, placeholders);
      return this.http.put(url, params);
    } else {
      return this.http.post(endpoints.prizes.codes, params);
    }
  }

  public deletePrizeCode(params: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: params?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.prizes.deletePrizeCode, placeholders);
    return this.http.delete(url, params);
  }

  public getPrizeData(filters: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: filters?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.prizes.detail, placeholders);
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(url, params);
  }

  public getPrizeForCompetition(filters: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: filters?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.prizes.detailForCompetition, placeholders);
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(url, params);
  }

  public getPrizeForCompany(filters: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: filters?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.prizes.detailForCompany, placeholders);
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(url, params);
  }

  public deletePrize(prize: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: prize?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.prizes.delete, placeholders);
    return this.http.delete(url, {});
  }

  public getPrizeCodes(filters: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.prizes.codes, params ? params : null);
  }

  public getPrizePreconditions(filters: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.prizes.preconditions, params ? params : null);
  }

  public getUserRedeemedPrizes(userId: string, filters?: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: String(userId)}];
    const url = this.http.findAndReplacePlaceholders(endpoints.prizes.forUser, placeholders);
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(url, params ? params : null);
  }
}

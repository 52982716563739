<ng-template #modal>
  <div class="modal-header border-bottom-0">
    <h5 class="modal-title" id="modal-title">
      <span *ngIf="!!titleParams">{{ title | translate: {value1: titleParams} }}</span>
      <span *ngIf="!titleParams">{{ title | translate}}</span>
    </h5>
    <button [disabled]="(working$ | async)" class="btn btn-outline-light close-modal-button"
            closeModal="CLOSE_ACTIVATE_MODAL">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
  <div class="modal-body">
    <p *ngIf="!titleParams" class="modal-description">{{ description | translate}}</p>
    <p *ngIf="!!titleParams" class="modal-description">{{ description | translate: {value1: titleParams} }}</p>
    <ul class="list-inline wizard mb-0">
      <li class="next list-inline-item float-end">
        <button (click)="showAreYouSure = true" *ngIf="!showAreYouSure" class="btn btn-success">Activate</button>
        <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
        <button (click)="activate()" *ngIf="showAreYouSure" [disabled]="(working$ | async)" class="btn btn-success me-2"
                type="submit">
          <i *ngIf="working$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          <span *ngIf="working$ | async">
            Activating
          </span>
          <span [hidden]="(working$ | async)">
            {{'YES' | translate}}
          </span>
        </button>
        <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(working$ | async)"
                class="btn btn-outline-light ">{{'NO' | translate}}
        </button>
      </li>
    </ul>
  </div>
</ng-template>

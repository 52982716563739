import {Injectable} from '@angular/core';
import {TableColumnEnum} from "@enum/table-column/table-column.enum";
import {TableFilterEnum} from "@enum/table-filter/table-filter.enum";
import {UtilsService} from "@service/utils/utils.service";
import {BehaviorSubject, Subject, Subscription} from "rxjs";
import {TableStateInterface} from "@interface/common/table-state.interface";
import {SortDirection} from "@type/common/sort-direction.type";
import {debounceTime, switchMap, tap} from "rxjs/operators";
import {EventEnum} from "@enum/event/event.enum";
import {EventService} from "@service/common/event.service";
import {ToastService} from "@service/toast.service";
import {InvitesApiService} from "@service/utility/invites-api.service";

@Injectable({
  providedIn: 'root'
})
export class InvitesService {
  public _search$ = new Subject<void>();
  public _searchUses$ = new Subject<void>();
  public listVisibleColumns: { visible: boolean, key: TableColumnEnum, label: any }[] = this.utils.invitesListColumns;
  public usesVisibleColumns: { visible: boolean, key: TableColumnEnum, label: any }[] = this.utils.invitesUsesColumns;
  public listVisibleFilters: { visible: boolean, key: TableFilterEnum, label: any }[] = this.utils.invitesListFilters;
  public usesVisibleFilters: { visible: boolean, key: TableFilterEnum, label: any }[] = this.utils.invitesUsesFilters;
  private searchInvitesSubscription: Subscription;
  private searchInvitesUsesSubscription: Subscription;
  private _currentTableState: TableStateInterface = this.utils.tableDefaultState;

  constructor(private utils: UtilsService, private invitesApiService: InvitesApiService, private eventService: EventService,
              private toastService: ToastService) {
  }

  private _loading$ = new BehaviorSubject<boolean>(true);

  public get loading$() {
    return this._loading$.asObservable();
  }

  private _inviteList$ = new BehaviorSubject<any[]>([]);

  public get inviteList$() {
    return this._inviteList$.asObservable();
  }

  private _inviteUsesList$ = new BehaviorSubject<any[]>([]);

  public get inviteUsesList$() {
    return this._inviteUsesList$.asObservable();
  }

  private _totalRecords$ = new BehaviorSubject<number>(0);

  public get totalRecords$() {
    return this._totalRecords$.asObservable();
  }

  public get searchTerm() {
    return this._currentTableState.searchTerm;
  }

  public set searchTerm(searchTerm: string) {
    this._setValue({searchTerm});
  }

  public get sortColumn() {
    return this._currentTableState.sortColumn;
  }

  public set sortColumn(sortColumn: string) {
    this._setValue({sortColumn});
  }

  public get sortDirection() {
    return this._currentTableState.sortDirection;
  }

  public set sortDirection(sortDirection: SortDirection) {
    this._setValue({sortDirection});
  }

  get id() {
    return this._currentTableState.id;
  }

  set id(id: number) {
    this._setValue({id});
  }

  get userId() {
    return this._currentTableState.userId;
  }

  set userId(userId: number) {
    this._setValue({userId});
  }

  public get pageSize() {
    return this._currentTableState.pageSize;
  }

  public set pageSize(pageSize: number) {
    const page = 1;
    this._setValue({page})
    this._setValue({pageSize});
  }

  public get page() {
    return this._currentTableState.page;
  }

  public set page(page: number) {
    this._setValue({page});
  }

  get listColumns() {
    return this.listVisibleColumns;
  }

  get listFilters() {
    return this.listVisibleFilters;
  }

  get usesColumns() {
    return this.usesVisibleColumns;
  }

  get usesFilters() {
    return this.usesVisibleFilters;
  }

  public removeSearchListSubscribe(): void {
    this.searchInvitesSubscription?.unsubscribe();
    this._loading$.next(false);
  }

  public removeSearchUsesSubscribe(): void {
    this.searchInvitesUsesSubscription?.unsubscribe();
    this._loading$.next(false);
  }

  public initSearchListListener(): void {
    this.searchInvitesSubscription = this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(50),
      switchMap(() => this.invitesApiService.getInvitesList(this._extractSearchParams())),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      this._inviteList$.next(result.data);
      this._totalRecords$.next(result.size);
    });
  }

  public initSearchUsesListener(): void {
    this.searchInvitesUsesSubscription = this._searchUses$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(50),
      switchMap(() => this.invitesApiService.getInviteUses(this._extractSearchParams())),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      this._inviteUsesList$.next(result.data);
      this._totalRecords$.next(result.size);
    });
  }

  public getInviteUses(invite: any): any {
    const params = {
      filters: {
        codeId: ["EQ", Number(invite?.id)]
      },
      page: 1,
      size: 1000
    }
    return this.invitesApiService.getInviteUses(params);
  }

  public isFilterApplied(): boolean {
    const params: any = this._extractSearchParams();
    if (Object.keys(params?.filters)?.length > 0) {
      const obj = this.utils.clearObject(params?.filters);
      if (Object.keys(obj)?.length === 1 && (Object.keys(obj)[0] === 'whiteList' || Object.keys(obj)[0] === 'codeId' || Object.keys(obj)[0] === 'userId')) {
        return false;
      } else {
        return Object.keys(obj)?.length > 0;
      }
    } else {
      return false;
    }
  }

  public clearFilters(): void {
    this.id = undefined;
    this.userId = undefined;
    this.searchTerm = undefined;
    this.sortColumn = undefined;
    this.sortDirection = undefined;
  }

  private modalSuccess(result, modalEvent: EventEnum, message: string) {
    this.toastService.show(message, {classname: 'bg-success text-light'});
    this.eventService.broadcast(modalEvent, null)
    // this._search$.next();
    return result;
  }

  private modalError(err, modalEvent: EventEnum) {
    this.eventService.broadcast(modalEvent, null)
    this.toastService.show(err, {classname: 'bg-danger text-light'});
    // this._search$.next();
    return err;
  }

  private _extractSearchParams(): any {
    return {
      filters: {
        query: this.searchTerm ? [this.searchTerm] : undefined,
        codeId: this.id ? ["EQ", Number(this.id)] : undefined,
        userId: this.userId ? ["EQ", Number(this.userId)] : undefined,
      },
      sort: this.extractSorting(),
      page: this.page,
      size: this.pageSize
    }
  }

  private _setValue(patch: Partial<TableStateInterface>) {
    Object.assign(this._currentTableState, patch);
    this._search$.next();
    this._searchUses$.next();
  }

  private extractSorting(): string {
    return this.utils.extractSorting(this.sortColumn, this.sortDirection);
  }
}

import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {Observable, Subscription} from "rxjs";
import {EventService} from "@service/common/event.service";
import {EventEnum} from "@enum/event/event.enum";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {QrCodeService} from "@service/utility/qr-code.service";

@Component({
  selector: 'app-qr-create-modal',
  templateUrl: './qr-create-modal.component.html',
  styleUrls: ['./qr-create-modal.component.scss']
})
export class QrCreateModalComponent implements OnInit {
  @Input() qrCode: any;
  public creatingQr$: Observable<boolean>;
  public formSubmitted: boolean;
  public qrForm: FormGroup;
  @ViewChild('modal') private modalContent: TemplateRef<any>
  private modalRef: NgbModalRef;
  private closeModalSubscription: Subscription;

  constructor(private modalService: NgbModal, private eventService: EventService,
              public formBuilder: FormBuilder, private qrCodeService: QrCodeService) {
  }

  get form() {
    return this.qrForm.controls;
  }

  ngOnInit(): void {
    this._closeModal();
    this.creatingQr$ = this.qrCodeService?.creating$;
    this.initForm();
  }

  public setQrCode(qrCode: any): void {
    this.qrCode = qrCode;
    this.patchFormWithEditingData();
  }

  public validSubmit() {
    if (this.qrForm.valid) {
      let formData = this.qrForm.getRawValue();
      this.qrCodeService?.initCreateListener();
      if (this.qrCode) {
        formData.id = this.qrCode?.id;
      }
      this.qrCodeService.createQrCode(formData);
    }
  }

  public createQrValidation() {
    this.formSubmitted = true;
  }

  public open(data?: any): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, {size: 'lg', centered: true, backdrop: false})
      this.modalRef.result.then(resolve, resolve)
    })
  }

  private initForm() {
    this.qrForm = this.formBuilder.group({
      code: [null, [Validators.required]],
      message: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(100)]],
      amount: [null, [Validators.required]],
      singleUseForUser: [false],
      validForCreditsBasedContests: [false],
      active: [true],
      privateNote: [null, [Validators.maxLength(255)]],
    });
    this.qrForm.controls['code'].enable();
  }

  private patchFormWithEditingData() {
    this.qrForm.patchValue({
      code: this.qrCode?.code,
      message: this.qrCode?.message,
      amount: this.qrCode?.amount,
      singleUseForUser: this.qrCode?.singleUseForUser,
      validForCreditsBasedContests: this.qrCode?.validForCreditsBasedContests,
      active: this.qrCode?.active,
      privateNote: this.qrCode?.privateNote
    });
    this.qrForm.controls['code'].disable();
  }

  private _closeModal(): void {
    this.closeModalSubscription = this.eventService.subscribe(EventEnum.CLOSE_QR_CREATE_MODAL, (reason: string | undefined) => {
      this.qrCodeService?.removeQrCreateSubscribe();
      this.initForm();
      this.formSubmitted = false;
      this.qrCode = undefined;
      this.modalRef?.dismiss(reason);
    });
  }
}

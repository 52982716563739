<div *ngIf="list$ | async as actions" [formGroup]="parentForm" class="row">
  <div class="col-md-6">
    <div class="mb-3">
      <label class="font-weight-bold" for="actions">{{'SELECT_ACTIONS' | translate}}</label>
      <p class="card-title-desc mb-1">
        Select the action that the notification will trigger
      </p>
      <select (change)="updateSelectedAction($event)" [(ngModel)]="selected" class="form-select" formControlName="action" *ngIf="!limitToOpenLink"
              id="actions">
        <option *ngFor="let actionElement of actions" [selected]="actionElement?.selected"
                [value]="actionElement?.action">{{actionElement?.label | translate}}</option>
        <option [value]="actionsEnum.OPEN_LINK">{{'OPEN_LINK' | translate}}</option>
      </select>
      <select (change)="updateSelectedAction($event)" [(ngModel)]="selected" class="form-select" formControlName="action" *ngIf="limitToOpenLink"
              id="actions">
        <option [value]="actionsEnum.OPEN_LINK">{{'OPEN_LINK' | translate}}</option>
      </select>
    </div>
  </div>
  <div class="col-md-6">
    <app-select-company *ngIf="selected === actionsEnum.OPEN_COMPANY" [formSubmitted]="formSubmitted"
                        [editDetail]="selectedDetail"
                        [users]="users"
                        [parentForm]="parentForm" [single]="true"></app-select-company>
    <app-select-competition *ngIf="selected === actionsEnum.OPEN_COMPETITION" [formSubmitted]="formSubmitted"
                            [editDetail]="selectedDetail"
                            [users]="users"
                            [parentForm]="parentForm" [single]="true"></app-select-competition>
    <app-select-challenge *ngIf="selected === actionsEnum.OPEN_CHALLENGE" [formSubmitted]="formSubmitted"
                          [editDetail]="selectedDetail"
                          [users]="users"
                          [parentForm]="parentForm" [single]="true"></app-select-challenge>
    <app-select-challenge *ngIf="selected === actionsEnum.OPEN_ALL_TOGETHER_CHALLENGE" [allTogether]="true"
                          [editDetail]="selectedDetail"
                          [users]="users"
                          [formSubmitted]="formSubmitted" [parentForm]="parentForm" [single]="true"></app-select-challenge>
    <app-select-prize *ngIf="selected === actionsEnum.OPEN_PRIZE" [formSubmitted]="formSubmitted"
                      [editDetail]="selectedDetail"
                      [users]="users"
                      [parentForm]="parentForm" [single]="true"></app-select-prize>
    <app-link-validator *ngIf="selected === actionsEnum.OPEN_LINK"
                        [editDetail]="selectedDetail"
                        [formSubmitted]="formSubmitted"
                        [parentForm]="parentForm"></app-link-validator>
  </div>
</div>

import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UserBannedListService} from "@service/users/user-banned-list.service";
import {Observable, Subscription} from "rxjs";
import {UserElementInterface} from "@interface/user/user-element.interface";
import {TableColumnEnum} from "@enum/table-column/table-column.enum";
import {BanTypeEnum} from "@enum/ban-type/ban-type.enum";
import {BanStatusEnum} from "@enum/ban-status/ban-status.enum";
import {SortEventInterface} from "@interface/common/sort-event.interface";

@Component({
  selector: 'app-ban-history',
  templateUrl: './ban-history.component.html',
  styleUrls: ['./ban-history.component.scss']
})
export class BanHistoryComponent implements OnInit, OnDestroy {
  @Input() userId: number;
  public bans$: Observable<UserElementInterface[]>;
  public total$: Observable<number>;
  public loading$: Observable<boolean>;
  public column = TableColumnEnum;
  public banType = BanTypeEnum;
  public banStatus = BanStatusEnum;
  public selectedSearchTerm: any = undefined;
  public bans: UserElementInterface[] | undefined;
  @ViewChild('banEditModal') private banEditModal: any
  private obsSubscription: Subscription;

  constructor(public userBannedListService: UserBannedListService) {
  }

  ngOnInit(): void {
    if (this.userId) {
      this.bans$ = this.userBannedListService.userList$;
      this.loading$ = this.userBannedListService.loading$;
      this.total$ = this.userBannedListService.totalRecords$;
      this.userBannedListService.initSearchListener();
      this.userBannedListService.userId = this.userId;
      this.obsSubscription = this.userBannedListService.userList$.subscribe((list: any) => {
        if (list?.length === 1) {
          this.bans = list[0]?.bans;
        } else {
          this.bans = [];
        }
      });
    }
  }

  public sort({column, direction}: SortEventInterface) {
    this.userBannedListService.sortColumn = column;
    this.userBannedListService.sortDirection = direction;
  }

  public logTerm(searchTerm: any) {
    this.selectedSearchTerm = searchTerm;
  }

  public checkIfColumnIsVisible(column: TableColumnEnum): boolean {
    return true;
  }

  public extractEditTooltip(ban: any): string {
    if (ban?.status === BanStatusEnum.EXPIRED) {
      return 'You can\'t edit this ban because it\'s expired';
    } else {
      return 'Edit'
    }
  }

  async editBan(ban: any, banDetail: any) {
    if (banDetail?.status === BanStatusEnum.EXPIRED) {
      return;
    } else {
      const obj = {user: ban, ...banDetail};
      return await this.banEditModal.open(obj);
    }

  }

  ngOnDestroy() {
    this.userBannedListService.clearFilters();
    this.obsSubscription?.unsubscribe();
    this.userBannedListService.removeSearchSubscribe();
  }

}

<div class="input-group input-group-sm me-2">
  <label class="input-group-text">Visible columns</label>
  <ng-select
    (change)="updateColumns()"
    [(ngModel)]="visibleColumns"
    [class.picker-container]="true"
    [clearable]="false"
    [closeOnSelect]="false"
    [items]="availableColumns"
    [multiple]="true"
    [searchable]="false"
    [selectableGroupAsModel]="false"
    [selectableGroup]="true"
    bindLabel="label">
    <ng-template let-clear="clear" let-items="items" ng-multi-label-tmp>
      <div *ngFor="let item of items | slice:0:1" class="ng-value">
        <span class="ng-value-label">{{item.label}}</span>
        <span (click)="clear(item)" aria-hidden="true" class="ng-value-icon right">×</span>
      </div>
      <div *ngIf="items.length > 1" class="ng-value">
        <span class="ng-value-label">{{items.length - 1}} more...</span>
      </div>
    </ng-template>

    <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
      <input [checked]="item$.selected" id="item-{{index}}" type="checkbox"/> <span
      class="font-weight-semibold ms-2">{{item.label}}</span>
    </ng-template>
    <!--  <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>-->
    <!--    <span class="ng-placeholder p-0 picker-label">Choose columns</span>-->
    <!--    <div *ngIf="item$ | async">-->
    <!--      <input [ngModelOptions]="{ standalone : true }" [ngModel]="item$.selected" id="item-{{index}}"-->
    <!--             type="checkbox"/> {{item.label}}-->
    <!--    </div>-->
    <!--  </ng-template>-->
  </ng-select>
</div>

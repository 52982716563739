import {Injectable} from '@angular/core';
import {HttpService} from "@service/http/http.service";
import {Observable} from "rxjs";
import {endpoints} from "@endpoint/endpoint-list";
import {EndpointParamEnum} from "@enum/endpoint-param.enum";
import {UserNotificationAddInterface} from "@interface/user/user-notification-add.interface";
import {UserCreditsAddRemoveInterface} from "@interface/user/user-credits-add-remove.interface";
import {FitnessMetricEnum} from "@enum/fitness-metric/fitness-metric.enum";

@Injectable({
  providedIn: 'root'
})
export class UsersApiService {

  constructor(private http: HttpService) {
  }

  public getUsersList(filters?: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.user.list, params);
  }

  public getUserDetail(userId: number): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: String(userId)}];
    const url = this.http.findAndReplacePlaceholders(endpoints.user.detail, placeholders);
    return this.http.get<any>(url);
  }

  public assignCreditsToUser(data: any, userId: number): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: String(userId)}];
    const url = this.http.findAndReplacePlaceholders(endpoints.user.assignCredits, placeholders);
    return this.http.post(url, data);
  }

  public getUserMemberships(userId: number, page: number, size: number): Observable<any> {
    const params = this.http.generateQueryParams({userId, page, size});
    return this.http.get<any>(endpoints.user.memberships, params ? params : null);
  }

  public getUserBans(filters?: any): Observable<any> {
    const url = filters?.userId ? endpoints.user.bans : endpoints.user.bans;
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(url, params ? params : null);
  }

  public getUserDiary(filters?: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.user.diary, params ? params : null);
  }

  public getUserFitnessSummary(userId: string, filters?: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: String(userId)}];
    const url = this.http.findAndReplacePlaceholders(endpoints.user.fitnessSummary, placeholders);
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(url, params ? params : null);
  }

  public getUserFitness(filters?: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.user.fitness, params ? params : null);
  }

  public getUserChallenges(userId: number, page: number, size: number): Observable<any> {
    const params = this.http.generateQueryParams({userId, page, size});
    return this.http.get<any>(endpoints.user.challenges, params ? params : null);
  }

  public sendUserNotification(userId: string, notification: UserNotificationAddInterface): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: userId}];
    const url = this.http.findAndReplacePlaceholders(endpoints.user.notification, placeholders);
    return this.http.post(url, notification);
  }

  public addRemoveUserCredits(userId: string, credits: UserCreditsAddRemoveInterface): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: userId}];
    const url = this.http.findAndReplacePlaceholders(endpoints.user.credits, placeholders);
    return this.http.post(url, credits);
  }

  public banUsers(data: any): Observable<any> {
    // const placeholders = [{placeholder: EndpointParamEnum.ID, value: userId}];
    // const url = this.http.findAndReplacePlaceholders(endpoints.user.ban, placeholders);
    return this.http.post(endpoints.user.ban, data);
  }

  public getBanMotivations(filters?: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.user.banMotivations, params ? params : null);
  }

  public unban(userId: string): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: userId}];
    const url = this.http.findAndReplacePlaceholders(endpoints.user.ban, placeholders);
    return this.http.post(url, {});
  }

  public addToWhiteList(users: any[]): Observable<any> {
    const userIds = users?.map((user) => {
      return user.id
    });
    return this.http.post(endpoints.user.addRemoveWhiteList, {userIds});
  }

  public removeFromWhiteList(users: any[]): Observable<any> {
    const userIds = users?.map((user) => {
      return user.id
    });
    return this.http.delete(endpoints.user.addRemoveWhiteList, {body: {userIds}});
  }

  public getBannedList(page: number, size: number): Observable<any> {
    const params = this.http.generateQueryParams({page, size});
    return this.http.get<any>(endpoints.user.bans, params ? params : null);
  }

  public createBanMotivation(motivation: string): Observable<any> {
    return this.http.post(endpoints.user.createBanMotivation, {reason: motivation});
  }

  public getUserFitnessActivities(periodStartInclusive: string, periodEndExclusive: string, userId: string): Observable<any> {
    const params = this.http.generateQueryParams({periodStartInclusive, periodEndExclusive, userId});
    return this.http.get<any>(endpoints.user.fitnessActivities, params ? params : null);
  }

  public getUserFitnessCharts(periodStartInclusive: string, periodEndExclusive: string, userId: string, fitnessMetric: FitnessMetricEnum): Observable<any> {
    const params = this.http.generateQueryParams({periodStartInclusive, periodEndExclusive, fitnessMetric});
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: userId}];
    const url = this.http.findAndReplacePlaceholders(endpoints.user.fitnessCharts, placeholders);
    return this.http.get<any>(url, params ? params : null);
  }

  public editBanMotivation(motivation: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: motivation?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.user.editBanMotivation, placeholders);
    return this.http.put(url, motivation);
  }

  public deleteBanMotivation(motivation: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: motivation?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.user.deleteBanMotivation, placeholders);
    return this.http.delete(url, {});
  }

  public deleteUsers(users: any): Observable<any> {
    let usersToDelete: any[] = [];
    if (Array.isArray(users)) {
      usersToDelete = users?.map((user) => {
        if (user?.userId) {
          return user.userId;
        } else if (!user?.userId && user?.id) {
          return user.id;
        }
      });
    } else {
      if (users?.userId) {
        usersToDelete.push(users?.userId);
      } else if (!users?.userId && users?.id) {
        usersToDelete.push(users?.id);
      }
    }
    return this.http.delete(endpoints.user.deleteUsers, {body: {usersToDelete}});
  }

  public removeBan(bans: any[]): Observable<any> {
    return this.http.post(endpoints.user.unban, {banIds: bans});
  }

}

<app-table-topbar (emitSearchTerm)="invitesService.searchTerm = $event"></app-table-topbar>
<div *ngIf="inviteUses?.length > 0" class="wrapper-uses"  [hidden]="(loading$ | async)">
  <table class="table table-bordered align-middle table-nowrap table-hover table-striped datatables margin-bottom-50"
         id="invites-uses-datatable">
    <thead>
    <tr>
      <th></th>
      <th (sort)="sort($event)" class="column-sortable"
          sortable="id">
        <span class="me-3">{{'INVITE_USE_ID' | translate}}</span>
      </th>
      <th (sort)="sort($event)" class="column-sortable"
          sortable="createdAt">
        <span class="me-3">{{'INVITE_USE_CODE' | translate}}</span>
      </th>
      <th (sort)="sort($event)" class="column-sortable"
          sortable="createdAt">
        <span class="me-3">{{'INVITE_USE_CODE_TYPE' | translate}}</span>
      </th>
      <th (sort)="sort($event)" class="column-sortable"
          sortable="code">
        <span class="me-3">{{'INVITE_USE_CODE_ID' | translate}}</span>
      </th>
      <th (sort)="sort($event)">
        <span class="me-3">{{'INVITER_EMAIL' | translate}}</span>
      </th>
      <th (sort)="sort($event)">
        <span class="me-3">{{'INVITER_FULLNAME' | translate}}</span>
      </th>
      <th (sort)="sort($event)">
        <span class="me-3">{{'USER_EMAIL' | translate}}</span>
      </th>
      <th (sort)="sort($event)">
        <span class="me-3">{{'USER_FULLNAME' | translate}}</span>
      </th>
      <th (sort)="sort($event)" class="column-sortable"
          sortable="usedAt">
        <span class="me-3">{{'USED_AT' | translate}}</span>
      </th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let invite of inviteUses; let i=index;">
      <td align="center" class="pointer fit-width">
        <span class="badge badge-pill badge-soft-secondary">#{{extractRowNumber(i)}}</span>
      </td>
      <td align="center" class="fit-width">
        <span *ngIf="invite?.id" class="badge badge-pill badge-soft-primary">{{invite?.id}}</span>
        <app-missing-label *ngIf="!invite?.id"></app-missing-label>
      </td>
      <td align="center" class="fit-width">
        <span *ngIf="invite?.code">{{invite?.code}}</span>
        <app-missing-label *ngIf="!invite?.code"></app-missing-label>
      </td>
      <td align="center" class="fit-width">
        <span *ngIf="invite?.codeType">{{invite?.codeType}}</span>
        <app-missing-label *ngIf="!invite?.codeType"></app-missing-label>
      </td>
      <td align="center" class="fit-width">
        <span *ngIf="invite?.codeId">{{invite?.codeId}}</span>
        <app-missing-label *ngIf="!invite?.codeId"></app-missing-label>
      </td>
      <td align="center" class="fit-width">
        <span *ngIf="invite?.inviter?.email">{{invite?.inviter?.email}}</span>
        <app-missing-label *ngIf="!invite?.inviter?.email"></app-missing-label>
      </td>
      <td align="center" class="fit-width">
        <span *ngIf="invite?.inviter?.fullName">{{invite?.inviter?.fullName}}</span>
        <app-missing-label *ngIf="!invite?.inviter?.fullName"></app-missing-label>
      </td>
      <td align="center" class="fit-width">
        <span *ngIf="invite?.usedBy?.email">{{invite?.usedBy?.email}}</span>
        <app-missing-label *ngIf="!invite?.usedBy?.email"></app-missing-label>
      </td>
      <td align="center" class="fit-width">
        <span *ngIf="invite?.usedBy?.fullName">{{invite?.usedBy?.fullName}}</span>
        <app-missing-label *ngIf="!invite?.usedBy?.fullName"></app-missing-label>
      </td>
      <td align="center" class="fit-width">
        <span *ngIf="invite?.usedAt">{{invite?.usedAt | date:'dd/MM/yyyy'}}</span>
        <app-missing-label *ngIf="!invite?.usedAt"></app-missing-label>
      </td>
      <td class="fit-width">
        <ul class="list-inline font-size-20 contact-links mb-0">
          <li (click)="userDetail(invite)" class="list-inline-item px-2">
                  <span [ngClass]="{'disabled-opacity': !invite?.inviter}" [ngbTooltip]="extractInviteTooltip(invite)"
                        class="pointer"
                        placement="left"><i
                    class="bx bxs-user-detail"></i></span>
          </li>
        </ul>
      </td>
    </tr>
    </tbody>
  </table>
</div>
<div *ngIf="inviteUses?.length === 0" class="col-lg-12"  [hidden]="(loading$ | async)">
  <div class="">
    <div class="text-center mt-5">
      <h4 class="text-uppercase">{{'SORRY_NO_RESULTS' | translate}}</h4>
    </div>
  </div>
</div>
<div *ngIf="loading$ | async" class="col-12 p-0">
  <div class="wrapper-uses">
    <app-loader [table]="true"></app-loader>
  </div>
</div>
<app-pagination-footer [service]="invitesService" [total$]="total$"></app-pagination-footer>

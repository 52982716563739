import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {EventService} from "@service/common/event.service";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})

export class LayoutComponent implements OnInit {
  public isCondensed = false;


  constructor(private router: Router, private eventService: EventService) {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        const element = document.getElementById("page-content");
        document.body.classList.remove('sidebar-enable');
        if (event.url.includes('dashboard') || event.url.includes('send') || event.url.includes('detail')) {
          element.classList.add('dark-bg');
        } else {
          element.classList.remove('dark-bg');
        }
        if (event.url.includes('users/detail') || event.url.includes('companies/detail')) {
          element.classList.add('px-0');
        } else {
          element.classList.remove('px-0');
        }
      }
    });
  }

  ngOnInit() {
    document.body.setAttribute('data-sidebar', 'dark');
    document.body.removeAttribute('data-topbar');
    document.body.removeAttribute('data-layout-size');
    document.body.removeAttribute('data-keep-enlarged');
    document.body.removeAttribute('data-sidebar-size');
    document.body.classList.remove('sidebar-enable');
    document.body.classList.remove('vertical-collapsed');
    document.body.removeAttribute('data-layout-scrollable');
    document.body.setAttribute('data-layout', 'vertical');
  }

  isMobile() {
    const ua = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua);
  }

  public onToggleMobileMenu() {
    this.isCondensed = !this.isCondensed;
    document.body.classList.toggle('sidebar-enable');
    document.body.classList.toggle('vertical-collapsed');
    if (window.screen.width <= 768) {
      document.body.classList.remove('vertical-collapsed');
    }
  }

}

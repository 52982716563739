import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {PrizesService} from "@service/prizes/prizes.service";
import {Observable} from "rxjs";
import {SortEventInterface} from "@interface/common/sort-event.interface";

@Component({
  selector: 'app-user-redeem-prizes',
  templateUrl: './user-redeem-prizes.component.html',
  styleUrls: ['./user-redeem-prizes.component.scss']
})
export class UserRedeemPrizesComponent implements OnInit, OnDestroy {
  @Input() userId: number;
  public total$: Observable<number>;
  public loading$: Observable<boolean>;
  public prizes$: Observable<any[]>;

  constructor(public prizesService: PrizesService) {
  }

  ngOnInit(): void {
    this.prizesService.setUserFilter(this.userId)
    this.total$ = this.prizesService.totalRecords$;
    this.loading$ = this.prizesService.loading$;
    if (this.userId) {
      this.prizesService.clearFilters();
      this.sort({column: "purchasedAt", direction: "desc"});
      this.prizesService.initForUserSearchPrizesListener();
      this.prizes$ = this.prizesService.prizeList$;
      this.prizesService._search$.next();
    }
  }

  public extractRowNumber(index: any): string {
    return (this.prizesService.page - 1) * this.prizesService.pageSize + index + 1;
  }

  public sort({column, direction}: SortEventInterface) {
    this.prizesService.sortColumn = column;
    this.prizesService.sortDirection = direction;
  }

  public prizeDetail(prize: any): void {

  }

  public prizeContextDetail(prize: any): void {

  }

  ngOnDestroy() {
    this.prizesService.clearFilters();
    this.prizesService.removeSearchPrizesSubscribe();
  }

}

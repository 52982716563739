import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Subject} from "rxjs";
import {TableColumnEnum} from "@enum/table-column/table-column.enum";
import {TableFilterEnum} from "@enum/table-filter/table-filter.enum";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {UsersService} from "@service/users/users.service";
import {TopbarTypeEnum} from "@enum/topbar/topbar-type.enum";
import {DeleteModalTypeEnum} from "@enum/delete-modal-type/delete-modal-type.enum";
import {BanMotivationsService} from "@service/users/ban-motivations.service";
import {UserListService} from "@service/users/user-list.service";
import {NotificationsService} from "@service/notifications/notifications.service";
import {PriceTypeEnum} from "@enum/price-type/price-type.enum";
import {PrizeContextTypeEnum} from "@enum/prize-context-type/prize-context-type.enum";

@Component({
  selector: 'app-page-topbar',
  templateUrl: './page-topbar.component.html',
  styleUrls: ['./page-topbar.component.scss']
})

export class PageTopBarComponent implements OnInit {
  @Input() type?: TopbarTypeEnum;
  @Input() service: any;
  @Input() selectedItems: any[] = [];
  @Output() searchEvent: EventEmitter<string> = new EventEmitter();
  @Output() updateColumnsEvent: EventEmitter<any> = new EventEmitter();
  @Output() openPushUsersModal: EventEmitter<any> = new EventEmitter();
  @Output() openBanUsersModal: EventEmitter<any> = new EventEmitter();
  @Output() openUnbanUsersModal: EventEmitter<any> = new EventEmitter();
  @Output() addToWhiteListModal: EventEmitter<any> = new EventEmitter();
  @Output() removeFromWhiteListModal: EventEmitter<any> = new EventEmitter();
  @Output() createMotivationModal: EventEmitter<any> = new EventEmitter();
  @Output() createBlacklistSourceModal: EventEmitter<any> = new EventEmitter();
  @Output() openStreakCreateTemplate: EventEmitter<any> = new EventEmitter();
  @Output() openFtoCreateModal: EventEmitter<any> = new EventEmitter();
  @Output() openStreakCreate: EventEmitter<any> = new EventEmitter();
  @Output() openPrizeCreate: EventEmitter<any> = new EventEmitter();
  @Output() openFaqCreate: EventEmitter<any> = new EventEmitter();
  @Output() openCompanyCreate: EventEmitter<any> = new EventEmitter();
  @Output() openCompetitionCreate: EventEmitter<any> = new EventEmitter();
  @Output() createNewsModal: EventEmitter<any> = new EventEmitter();
  @Output() createQrCodeModal: EventEmitter<any> = new EventEmitter();
  public clearFilters: Subject<boolean> = new Subject<boolean>();
  public selectedSearchTerm: string = null;
  public searchFilters: any = null;
  public actionsOpened: boolean = false;
  public column = TableColumnEnum;
  public filter = TableFilterEnum;
  public visibleColumns: any[] = [];
  public availableColumns: any[] = [];
  public visibleFilters: any[] = [];
  public availableFilters: any[] = [];
  public topbarTypeEnum = TopbarTypeEnum;
  public deleteModalType = DeleteModalTypeEnum;
  public prizeContextTypeEnum = PrizeContextTypeEnum;
  @Input() selected: any[] = [];
  @ViewChild('deleteModal') private deleteModal: any
  @ViewChild('genericDeleteModal') private genericDeleteModal: any
  @ViewChild('deleteNotificationModal') private deleteNotificationModal: any
  @ViewChild('deleteUserModal') private deleteUserModal: any

  constructor(private modalService: NgbModal, private usersService: UsersService,
              public notificationsService: NotificationsService,
              public banMotivationsService: BanMotivationsService, public userListService: UserListService) {
  }

  ngOnInit() {
    switch (this.type) {
      case TopbarTypeEnum.BAN_MOTIVATIONS:
        this.setVisibleFilters();
        break;
      default:
        this.setSearchFilters();
        this.setVisibleColumns();
        this.setVisibleFilters();
        break;
    }
  }

  async deleteMotivations() {
    const title = 'DELETE_MOTIVATIONS';
    const description = 'LOREM';
    return await this.deleteModal.open(this.selectedItems, title, description)
  }

  async deleteNotifications() {
    const title = 'DELETE_NOTIFICATIONS';
    const description = 'LOREM';
    return await this.deleteNotificationModal.open(this.selectedItems, title, description)
  }

  async deleteUsers() {
    const title = 'DELETE_USERS';
    const description = 'LOREM';
    const titleParams = this.selectedItems?.length;
    return await this.deleteUserModal.open(this.selectedItems, title, description, titleParams)
  }


  public centerModal(centerDataModal: any) {
    this.modalService.open(centerDataModal, {size: 'xl', centered: true});
  }

  public updateColumns(event: any): void {
    this.updateColumnsEvent.emit(event);
  }

  public setVisibleColumns(): void {
    if (this.type === TopbarTypeEnum.INVITES) {
      this.visibleColumns = JSON.parse(JSON.stringify(this.service.listColumns));
      this.availableColumns = JSON.parse(JSON.stringify(this.service.listColumns));
    } else if (this.type === TopbarTypeEnum.INVITES_USES) {
      this.visibleColumns = JSON.parse(JSON.stringify(this.service.usesColumns));
      this.availableColumns = JSON.parse(JSON.stringify(this.service.usesColumns));
    } else {
      this.visibleColumns = JSON.parse(JSON.stringify(this.service.columns));
      this.availableColumns = JSON.parse(JSON.stringify(this.service.columns));
    }
    this.visibleColumns = this.visibleColumns.filter((column) => {
      return column.visible;
    });
    this.selected = this.visibleColumns.filter((column) => {
      return column.visible;
    });
  }

  public setVisibleFilters(): void {
    if (this.type === TopbarTypeEnum.INVITES) {
      this.visibleFilters = JSON.parse(JSON.stringify(this.service.listFilters));
      this.availableFilters = JSON.parse(JSON.stringify(this.service.listFilters));
    } else if (this.type === TopbarTypeEnum.INVITES_USES) {
      this.visibleFilters = JSON.parse(JSON.stringify(this.service.usesFilters));
      this.availableFilters = JSON.parse(JSON.stringify(this.service.usesFilters));
    } else {
      this.visibleFilters = JSON.parse(JSON.stringify(this.service.filters));
      this.availableFilters = JSON.parse(JSON.stringify(this.service.filters));
    }
    this.visibleFilters = this.visibleFilters.filter((column) => {
      return column.visible;
    });
  }

  public checkIfFilterIsVisible(column: TableFilterEnum) {
    return this.visibleFilters.filter(availableColumns => availableColumns.key === column).length > 0
  }

  public changed(list: any[]): void {
    if (list?.length === 0 && this.service.isFilterApplied()) {
      this.clearSearchFilters(false);
    } else {

    }
  }

  // public deleteUsers(): void {
  //   this.usersService.deleteUser(this.selectedItems);
  // }

  public search(event: any): void {
    this.searchEvent.emit(event);
  }

  public clearSearchFilters(clearSearchTerm?: boolean): void {
    if (!clearSearchTerm) {
      this.service.searchTerm = undefined;
    }
    this.clearFilters.next(true);
    this.service.clearFilters();
    this.selectedSearchTerm = undefined;
    this.setSearchFilters();
  }

  public getValue(event: Event, boolean?: boolean): any {
    const value = (event.target as HTMLInputElement).value;
    if (!boolean) return value;
    if (boolean) {
      switch (value) {
        case "true":
          return true;
        case "false":
          return false;
        default:
          return undefined;
      }
    }
  }

  public createStreak(): void {
    this.openStreakCreate.emit();
  }

  public createStreakTemplate(): void {
    this.openStreakCreateTemplate.emit();
  }

  public createFto(): void {
    this.openFtoCreateModal.emit();
  }

  public sendUserPush(): void {
    this.openPushUsersModal.emit();
  }

  public banUsers(): void {
    this.openBanUsersModal.emit();
  }

  public unbanUsers(): void {
    this.openUnbanUsersModal.emit();
  }

  public addToWhitelist(): void {
    this.addToWhiteListModal.emit();
  }

  public removeFromWhitelist(): void {
    this.removeFromWhiteListModal.emit();
  }

  public createMotivation(): void {
    this.createMotivationModal.emit();
  }

  public createBlacklistSource(): void {
    this.createBlacklistSourceModal.emit();
  }

  async deleteNews() {
    const title = 'DELETE_SELECTED_NEWS';
    const description = 'LOREM';
    return await this.genericDeleteModal.open(this.selectedItems, title, description)
  }

  public createNews(): void {
    this.createNewsModal.emit();
  }

  public createQrCode(): void {
    this.createQrCodeModal.emit();
  }

  public createNewPrize(): void {
    this.openPrizeCreate.emit();
  }

  public createNewFaq(): void {
    this.openFaqCreate.emit();
  }

  public createNewCompany(): void {
    this.openCompanyCreate.emit();
  }

  public createNewCompetition(): void {
    this.openCompetitionCreate.emit();
  }

  private setSearchFilters(): void {
    this.searchFilters = {
      companies: [{label: "All", id: null, selected: false}, {
        label: "Healthy Virtuoso",
        id: 3,
        selected: false
      }, {label: "American Express", id: 5, selected: false}],
      status: [{label: "USER.ALL", value: undefined, selected: false}, {
        label: "USER.ACTIVE",
        value: false,
        selected: false
      }, {label: "USER.BANNED", value: true, selected: false}],
      platform: [{label: "USER.ALL", value: undefined, selected: false},
        {
          label: "IOS",
          value: 'IOS',
          selected: false
        },
        {label: "ANDROID", value: 'ANDROID', selected: false}, {
          label: "OTHER",
          value: 'OTHER',
          selected: false
        }],
      boolean: [{label: "USER.ALL", value: undefined, selected: false}, {
        label: "YES",
        value: true,
        selected: false
      }, {label: "NO", value: false, selected: false}],
      challengeType: [
        {label: "ALL", value: undefined, selected: false},
        {label: "DUEL", value: "DUEL", selected: false},
        {label: "LEADERBOARD", value: "LEADERBOARD", selected: false},
        {label: "ALL_TOGETHER", value: "ALL_TOGETHER", selected: false},
      ],
      fitnessMetric: [
        {label: "ALL", value: undefined, selected: false},
        {label: "CREDITS", value: "CREDITS", selected: false},
        {label: "VIRTUOSITY", value: "VIRTUOSITY", selected: false},
        {label: "STEPS", value: "STEPS", selected: false},
        {label: "SPORT_MINUTES", value: "SPORT_MINUTES", selected: false},
        {label: "CYCLING_MINUTES", value: "CYCLING_MINUTES", selected: false},
        {label: "CYCLING_METERS", value: "CYCLING_METERS", selected: false},
        {label: "RUNNING_MINUTES", value: "RUNNING_MINUTES", selected: false},
        {label: "RUNNING_METERS", value: "RUNNING_METERS", selected: false}
      ],
      challengeStatus: [
        {label: "ALL", value: undefined, selected: false},
        {label: "AWAITING_START", value: "AWAITING_START", selected: false},
        {label: "ACTIVE", value: "ACTIVE", selected: false},
        {label: "ACTIVE_LAST_HALF", value: "ACTIVE_LAST_HALF", selected: false},
        {label: "AWAITING_RESULTS", value: "AWAITING_RESULTS", selected: false},
        {label: "COMPLETED", value: "COMPLETED", selected: false},
        {label: "ABORTED", value: "ABORTED", selected: false}
      ],
      competitionType: [
        {label: "ALL", value: undefined, selected: false},
        {label: "INDIVIDUAL", value: "INDIVIDUAL", selected: false},
        {label: "INDIVIDUAL_AND_TEAMS", value: "INDIVIDUAL_AND_TEAMS", selected: false},
        {label: "TEAMS", value: "TEAMS", selected: false},
      ],
      competitionStatus: [
        {label: "ALL", value: undefined, selected: false},
        {label: "AWAITING_START", value: "AWAITING_START", selected: false},
        {label: "COMPLETED", value: "COMPLETED", selected: false},
      ],
      prizeType: [
        {label: "ALL", value: undefined, selected: false},
        {label: PriceTypeEnum.CODE_REDEMPTION, value: PriceTypeEnum.CODE_REDEMPTION, selected: false},
        {label: PriceTypeEnum.CREDITS, value: PriceTypeEnum.CREDITS, selected: false},
        {label: PriceTypeEnum.SIMPLE, value: PriceTypeEnum.SIMPLE, selected: false},
      ],
      prizeContextType: [
        {label: "ALL", value: undefined, selected: false},
        {label: PrizeContextTypeEnum.COMPANY, value: PrizeContextTypeEnum.COMPANY, selected: false},
        {label: PrizeContextTypeEnum.COMPETITION, value: PrizeContextTypeEnum.COMPETITION, selected: false},
        {label: PrizeContextTypeEnum.GLOBAL, value: PrizeContextTypeEnum.GLOBAL, selected: false},
      ]
    }
    this.checkStreakFilters();
  }

  private checkStreakFilters(): void {
    if (this.type === TopbarTypeEnum.STREAKS || this.type === TopbarTypeEnum.STREAK_TEMPLATES) {
      this.service.getFitnessScoring().subscribe((list: any) => {
        this.searchFilters.fitnessScoring = [];
        if (list?.length > 0) {
          this.searchFilters.fitnessScoring = list;
        }
        this.searchFilters.fitnessScoring.unshift('ALL');
      });
    }
  }

  public selectContextDetailId(event: any): void {
    if (!!event && event !== 'null') {
      const selected = JSON.parse(event);
      this.service.contextDetailsId = selected && selected?.id ? selected?.id : null;
    } else {
      this.service.contextDetailsId = null;
    }
  }

}

<ng-template #modal>
  <div class="modal-header border-bottom-0">
    <h5 *ngIf="!company" class="modal-title" id="modal-title-1">{{'CREATE_COMPETITION' | translate}}</h5>
    <h5 *ngIf="company" class="modal-title" id="modal-title-2">{{'EDIT_COMPETITION' | translate}}</h5>
    <button [disabled]="(creatingCompany$ | async)" class="btn close-modal-button" closeModal="CLOSE_CREATE_COMPANY">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
  <div class="modal-body">
    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam atque blanditiis delectus enim eveniet ipsam iure necessitatibus reiciendis sed vitae? Aliquid aspernatur beatae blanditiis consequatur ducimus eos error ex excepturi facere fuga hic minima molestias nulla officiis optio perspiciatis, quae rerum soluta tempore vero! Consectetur distinctio dolor eos hic veniam.</p>
    <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="companyForm" autocomplete="off"
          class="needs-validation">
      <div class="row">
        <div class="col-6">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-8">
                  <div class="mb-3">
                    <label class="font-weight-bold" for="name">{{'NAME' | translate}}</label>
                    <input [ngClass]="{'is-invalid': formSubmitted && form?.name?.errors}" class="form-control"
                           formControlName="name" id="name" type="text">
                    <div *ngIf="formSubmitted && form?.name?.errors" class="invalid-feedback">
                      <span *ngIf="formSubmitted && form?.name?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                      <span *ngIf="form?.name?.errors?.maxlength">This value is too long. It should have 100 characters or fewer.</span>
                      <span *ngIf="form?.name?.errors?.minlength">This value is too short. It should have 5 characters or more.</span>

                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="mb-3">
                    <label class="font-weight-bold">Translations</label>
                    <div class="d-block mt-2">
                      <img (click)="utils.goCrowdin(lang)" *ngFor="let lang of languages" alt="Header Language"
                           class="me-2 pointer d-inline-block" height="16" ngbTooltip="{{lang?.text}} translation" placement="bottom"
                           src="{{lang.flag}}">
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="font-weight-bold" for="name">{{'DESCRIPTION' | translate}}</label>
                    <input [ngClass]="{'is-invalid': formSubmitted && form?.name?.errors}" class="form-control"
                           formControlName="name" id="name" type="text">
                    <div *ngIf="formSubmitted && form?.name?.errors" class="invalid-feedback">
                      <span *ngIf="formSubmitted && form?.name?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                      <span *ngIf="form?.name?.errors?.maxlength">This value is too long. It should have 100 characters or fewer.</span>
                      <span *ngIf="form?.name?.errors?.minlength">This value is too short. It should have 5 characters or more.</span>

                    </div>
                  </div>
                  <div class="mb-3" [ngClass]="{'precondition-editor-invalid': formSubmitted && form.rules.errors}">
                    <label class="font-weight-bold" for="title">{{'RULES' | translate}}</label>
                    <ckeditor [editor]="Editor" formControlName="rules" [config]="{ removePlugins: ['EasyImage','ImageUpload','MediaEmbed'], mediaEmbed: {} }"
                              data="<p>Description of the company.</p>"></ckeditor>
                    <div *ngIf="formSubmitted && form.rules?.errors" class="invalid-feedback d-block">
                      <span *ngIf="formSubmitted && form.rules?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                      <span *ngIf="form.rules?.errors?.maxlength">This value is too long. It should have 100 characters or fewer.</span>
                      <span *ngIf="form.rules?.errors?.minlength">This value is too short. It should have 5 characters or more.</span>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="row mb-3">
                <div class="col-12"  [ngClass]="{'col-12': !cardImage}">
                  <app-image-uploader (imageUploaded)="setCardImage($event)"
                                      [showImagePreview]="true"
                                      [name]="'IMAGE'"
                                      [imageErrors]="checkCardImageError()"
                                      [limit]="logoImageLimit" [previousSelectedImage]="checkSelectedCardImage()"></app-image-uploader>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="" [ngClass]="{'col-12': selectedPrizeRestriction === 'none', 'col-6': selectedPrizeRestriction !== 'none'}">
              <div class="mb-3">
                <label class="font-weight-bold" for="restriction">Restrict competition to</label>
                <select class="form-select" id="restriction" [(ngModel)]="selectedPrizeRestriction" [ngModelOptions]="{standalone: true}">
                  <option *ngFor="let restriction of prizeRestriction" [value]="restriction">{{restriction | translate}}</option>
                </select>
              </div>
            </div>
            <div class="col-6" *ngIf="selectedPrizeRestriction === 'company'">
              <app-select-company [formSubmitted]="formSubmitted"
                                  [basicSelect]="true"
                                  [selectedItems]="checkSelectedCompanies()"
                                  (selectedCompany)="selectCompany($event)"
                                  [parentForm]="companyForm" [single]="true"></app-select-company>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="mb-3">
                <label class="font-weight-bold" for="competitionType">Type</label>
                <select class="form-select" id="competitionType" [(ngModel)]="selectedCompetitionType" [ngModelOptions]="{standalone: true}">
                  <option *ngFor="let type of competitionType">{{type | translate}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="mb-3" [ngClass]="{'is-invalid-select': formSubmitted && form?.teamScoreStrategy?.errors}">
                <label class="font-weight-bold" for="teamScoreStrategy">Team score strategy</label>
                <select   class="form-select" formControlName="teamScoreStrategy" id="teamScoreStrategy">
                  <option *ngFor="let teamScoreStrategy of teamScoreStrategies" [value]="teamScoreStrategy">{{teamScoreStrategy | translate}}</option>
                </select>
                <div *ngIf="formSubmitted && form?.teamScoreStrategy?.errors" class="invalid-feedback d-block">
                  <span *ngIf="formSubmitted && form?.teamScoreStrategy?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3" [ngClass]="{'is-invalid-select': formSubmitted && form?.teamScoreStrategy?.errors}">
                <label class="font-weight-bold" for="fitnessMetric">Metric selection</label>
                <select   class="form-select" id="fitnessMetric">
                  <option *ngFor="let metric of fitnessMetric" [value]="metric">{{metric | translate}}</option>
                </select>
                <div *ngIf="formSubmitted && form?.fitnessMetric?.errors" class="invalid-feedback d-block">
                  <span *ngIf="formSubmitted && form?.fitnessMetric?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3">
                <span class="font-weight-bold">Visible from</span>
                <div class='input-group date' id='datetimepicker2'>
                  <input type='text' class="form-control" />
                  <span class="input-group-addon">
                  <span class="glyphicon glyphicon-calendar"></span>
                </span>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3">
                <span>Visible until</span>
                <div class='input-group date' id='datetimepicker2'>
                  <input type='text' class="form-control" />
                  <span class="input-group-addon">
                  <span class="glyphicon glyphicon-calendar"></span>
                </span>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="mb-3">
                <span>Starts at</span>
                <app-simple-datepicker></app-simple-datepicker>
              </div>
            </div>
            <div class="col-2">
              <div class="mb-3">
                <span>Hour</span>
                <div class='input-group date' id='datetimepicker2'>
                  <input type='time' class="form-control" />
                  <span class="input-group-addon">
                  <span class="glyphicon glyphicon-calendar"></span>
                </span>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3">
                <label class="font-weight-bold">{{'DAY_OF_REFUND' | translate}}</label>
                <app-simple-datepicker></app-simple-datepicker>
              </div>
            </div>
            <div class="col-12">
              <div class="mb-3">
                <span>Accept results until</span>
                <div class='input-group date' id='datetimepicker2'>
                  <input type='text' class="form-control" />
                  <span class="input-group-addon">
                  <span class="glyphicon glyphicon-calendar"></span>
                </span>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3">
                <label class="font-weight-bold font-size-11" for="active">Active</label>
                <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                  <input [checked]="form?.active?.value" autocomplete="off" class="form-check-input m-0 pointer"
                         formControlName="active"
                         id="active" type="checkbox">
                  <label class="form-check-label font-size-11 ms-3" for="active">
                              <span *ngIf="form?.active?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                    <span *ngIf="!form?.active?.value"
                          class="badge badge-pill badge-soft-danger font-size-11">No</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3">
                <label class="font-weight-bold font-size-11" for="enabled">Split leaderboards by power level</label>
                <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                  <input [checked]="form?.enabled?.value" autocomplete="off" class="form-check-input m-0 pointer"
                         formControlName="enabled"
                         id="enabled" type="checkbox">
                  <label class="form-check-label font-size-11 ms-3" for="enabled">
                              <span *ngIf="form?.enabled?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                    <span *ngIf="!form?.enabled?.value"
                          class="badge badge-pill badge-soft-danger font-size-11">No</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul class="list-inline wizard mb-0">
        <li class="next list-inline-item float-end">
          <button (click)="valid ? showAreYouSure = true : formSubmitted = true;" *ngIf="!showAreYouSure && !company"
                  class="btn btn-warning">Create
          </button>
          <button (click)="valid ? showAreYouSure = true : formSubmitted = true;" *ngIf="!showAreYouSure && company"
                  class="btn btn-warning">Edit
          </button>
          <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
          <button *ngIf="showAreYouSure" [disabled]="(creatingCompany$ | async)" class="btn btn-warning me-2" type="submit">
            <i *ngIf="creatingCompany$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            <span *ngIf="(creatingCompany$ | async) && !company">{{'CREATING' | translate}}</span>
            <span *ngIf="(creatingCompany$ | async) && company">{{'EDITING' | translate}}</span>
            <span [hidden]="(creatingCompany$ | async)">
            {{'YES' | translate}}
          </span>
          </button>
          <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(creatingCompany$ | async)"
                  class="btn btn-outline-light ">{{'NO' | translate}}
          </button>
        </li>
      </ul>
    </form>
  </div>
</ng-template>

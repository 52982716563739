export enum TableColumnEnum {
  USER_ID = 'USER_ID',
  KEYCLOAK_ID = 'KEYCLOAK_ID',
  EMAIL = 'EMAIL',
  NICKNAME = 'NICKNAME',
  COMPLETE_NAME = 'COMPLETE_NAME',
  REGISTRATION_DATE = 'REGISTRATION_DATE',
  PLATFORM = 'PLATFORM',
  LAST_APP_OPEN = 'LAST_APP_OPEN',
  LAST_SYNC = 'LAST_SYNC',
  AVAILABLE_CREDITS = 'AVAILABLE_CREDITS',
  PROFILE_COMPLETED = 'PROFILE_COMPLETED',
  JOINED_COMPANIES = 'JOINED_COMPANIES',
  BAN_ID = 'BAN_ID',
  BAN_START_DATE = 'BAN_START_DATE',
  BAN_END_DATE = 'BAN_END_DATE',
  BAN_MOTIVATION = 'BAN_MOTIVATION',
  BAN_START_HOUR = 'BAN_START_HOUR',
  BAN_END_HOUR = 'BAN_END_HOUR',
  BAN_SEND_EMAIL = 'BAN_SEND_EMAIL',
  BAN_SEND_PUSH = 'BAN_SEND_PUSH',
  BAN_NOTES = 'BAN_NOTES',
  BAN_TYPE = 'BAN_TYPE',
  BAN_STATUS = 'BAN_STATUS',
  BY_SYSTEM = 'BY_SYSTEM',
  TOTAL_BAN_RECEIVED = 'TOTAL_BAN_RECEIVED',
  NOTIFICATION_ID = 'NOTIFICATION_ID',
  TITLE = 'TITLE',
  MESSAGE = 'MESSAGE',
  NOTIFICATION_START_DATE = 'NOTIFICATION_START_DATE',
  NOTIFICATION_START_HOUR = 'NOTIFICATION_START_HOUR',
  LINK = 'LINK',
  IMAGE = 'IMAGE',
  NOTIFICATION_NOTES = 'NOTIFICATION_NOTES',
  BANNED = 'BANNED',
  QR_ID = 'QR_ID',
  CREATION_DATE = 'CREATION_DATE',
  CODE = 'CODE',
  DEEPLINK = 'DEEPLINK',
  ASSIGNED_CREDITS = 'ASSIGNED_CREDITS',
  SINGLE_USE_FOR_USER = 'SINGLE_USE_FOR_USER',
  CREDITS_VALID_FOR_COMPETITION = 'CREDITS_VALID_FOR_COMPETITION',
  ACTIVE = 'ACTIVE',
  NUMBER_OF_USES = 'NUMBER_OF_USES',
  QR_DETAIL = 'QR_DETAIL',
  NOTES = 'NOTES',
  LANGUAGES = 'LANGUAGES',
  // AUTOMATIC BAN COLUMNS
  RULE_ID = 'RULE_ID',
  BAN_THRESHOLD = 'BAN_THRESHOLD',
  BAN_THRESHOLD_FOR_WHITELIST = 'BAN_THRESHOLD_FOR_WHITELIST',
  FITNESS_METRIC = 'FITNESS_METRIC',
  // USER_DELETED = 'USER_DELETED'
  // BLACKLIST COLUMNS
  SOURCE_ID = 'SOURCE_ID',
  FITNESS_KIT = 'FITNESS_KIT',
  SOURCE_NAME = 'SOURCE_NAME',
  SOURCE_DESCRIPTION = 'SOURCE_DESCRIPTION',
  SOURCE_PATH = 'SOURCE_PATH',
  // NEWS
  NEWS_ID = 'NEWS_ID',
  VALID_FOR_ALL_COMPANIES = 'VALID_FOR_ALL_COMPANIES',
  UPDATED_AT = 'UPDATED_AT',
  PRIORITY = 'PRIORITY',
  // INVITES
  INVITE_ID = 'INVITE_ID',
  INVITE_CODE_TYPE = 'INVITE_CODE_TYPE',
  INVITE_CODE = 'INVITE_CODE',
  INVITER_EMAIL = 'INVITER_EMAIL',
  INVITER_FULLNAME = 'INVITER_FULLNAME',
  INVITE_ACTIVE = 'ACTIVE',
  INVITE_DEEPLINK = 'DEEPLINK',
  CREATED_AT = 'CREATED_AT',
  USES_COUNT = 'USES_COUNT',
  // INVITES USES
  INVITE_USE_ID = 'INVITE_USE_ID',
  INVITE_USE_CODE_TYPE = 'INVITE_USE_CODE_TYPE',
  INVITE_USE_CODE = 'INVITE_USE_CODE',
  INVITE_USE_CODE_ID = 'INVITE_USE_CODE_ID',
  USER_EMAIL = 'USER_EMAIL',
  USER_FULLNAME = 'USER_FULLNAME',
  USED_AT = 'USED_AT',
  // PRIZES
  PRIZE_ID = 'PRIZE_ID',
  PRIZE_IMAGE = 'PRIZE_IMAGE',
  PRIZE_ACTIVE = 'PRIZE_ACTIVE',
  PRIZE_CONTEXT_TYPE = 'PRIZE_CONTEXT_TYPE',
  PRIZE_CONTEXT_DESCRIPTION = 'PRIZE_CONTEXT_DESCRIPTION',
  PRIZE_CREATION_DATE = 'PRIZE_CREATION_DATE',
  PRIZE_TITLE = 'PRIZE_TITLE',
  PRIZE_DESCRIPTION = 'PRIZE_DESCRIPTION',
  PRIZE_EXPIRATION_DATE = 'PRIZE_EXPIRATION_DATE',
  PRIZE_TRANSLATIONS = 'PRIZE_TRANSLATIONS',
  PRIZE_PRICE = 'PRIZE_PRICE',
  PRIZE_PRIORITY = 'PRIZE_PRIORITY',
  PRIZE_TYPE = 'PRIZE_TYPE',
  PRIZE_REDEEMED_UNITS = 'PRIZE_REDEEMED_UNITS',
  PRIZE_TOTAL_UNITS = 'PRIZE_TOTAL_UNITS',
  PRIZE_AVAILABLE_UNITS = 'PRIZE_AVAILABLE_UNITS',
  PRIZE_REDEMPTION_URL = 'PRIZE_REDEMPTION_URL',
  PRIZE_WEBSITE = 'PRIZE_WEBSITE',
  // CHALLENGE
  CHALLENGE_ID = 'CHALLENGE_ID',
  CHALLENGE_TYPE = 'CHALLENGE_TYPE',
  CHALLENGE_NAME = 'CHALLENGE_NAME',
  CHALLENGE_INVITER = 'CHALLENGE_INVITER',
  CHALLENGE_DESCRIPTION = 'CHALLENGE_DESCRIPTION',
  CHALLENGE_PUBLIC = 'CHALLENGE_PUBLIC',
  CHALLENGE_METRIC = 'CHALLENGE_METRIC',
  CHALLENGE_ANTE = 'CHALLENGE_ANTE',
  CHALLENGE_POOL = 'CHALLENGE_POOL',
  CHALLENGE_PARTICIPANTS = 'CHALLENGE_PARTICIPANTS',
  CHALLENGE_MAX_PARTICIPANTS = 'CHALLENGE_MAX_PARTICIPANTS',
  CHALLENGE_STATUS = 'CHALLENGE_STATUS',
  CHALLENGE_CREATED_AT = 'CHALLENGE_CREATED_AT',
  CHALLENGE_STARTS_AT = 'CHALLENGE_STARTS_AT',
  CHALLENGE_ENDS_AT = 'CHALLENGE_ENDS_AT',
  CHALLENGE_ACCEPT_RESULTS_UNTIL = 'CHALLENGE_ACCEPT_RESULTS_UNTIL',
  // COMPANIES
  COMPANY_ID = 'COMPANY_ID',
  COMPANY_PRIORITY = 'COMPANY_PRIORITY',
  COMPANY_NAME = 'COMPANY_NAME',
  COMPANY_DESCRIPTION = 'COMPANY_DESCRIPTION',
  COMPANY_LOGO = 'COMPANY_LOGO',
  COMPANY_IMAGE = 'COMPANY_IMAGE',
  COMPANY_ENABLED = 'COMPANY_ENABLED',
  COMPANY_DISCOVERABLE = 'COMPANY_DISCOVERABLE',
  COMPANY_ACTIVE = 'COMPANY_ACTIVE',
  COMPANY_SHOW_USERS_COUNT = 'COMPANY_SHOW_USERS_COUNT',
  COMPANY_SHOW_LEADERBOARDS = 'COMPANY_SHOW_LEADERBOARDS',
  COMPANY_MUST_INSERT_CODE = 'COMPANY_MUST_INSERT_CODE',
  COMPANY_CREATED_AT = 'COMPANY_CREATED_AT',
  COMPANY_LAST_UPDATE = 'COMPANY_LAST_UPDATE',
  // COMPETITIONS
  COMPETITION_ID = 'COMPETITION_ID',
  COMPETITION_NAME = 'COMPETITION_NAME',
  COMPETITION_DESCRIPTION = 'COMPETITION_DESCRIPTION',
  COMPETITION_TYPE = 'COMPETITION_TYPE',
  COMPETITION_METRIC = 'COMPETITION_METRIC',
  COMPETITION_STATUS = 'COMPETITION_STATUS',
  COMPETITION_IMAGE = 'COMPETITION_IMAGE',
  COMPETITION_ACTIVE = 'COMPETITION_ACTIVE',
  COMPETITION_BY_POWER_LEVEL = 'COMPETITION_BY_POWER_LEVEL',
  COMPETITION_CREATED_AT = 'COMPETITION_CREATED_AT',
  // FAQS
  FAQ_ID = 'FAQ_ID',
  FAQ_PRIORITY = 'FAQ_PRIORITY',
  FAQ_TITLE = 'FAQ_TITLE',
  FAQ_DESCRIPTION = 'FAQ_DESCRIPTION',
  FAQ_ACTIVE = 'FAQ_ACTIVE',
  FAQ_LINK_URL = 'FAQ_LINK_URL',
  FAQ_LINK_TEXT = 'FAQ_LINK_TEXT',
  FAQ_CREATED_AT = 'FAQ_CREATED_AT',
  FAQ_UPDATED_AT = 'FAQ_UPDATED_AT',
  FAQ_LANGUAGES = 'FAQ_LANGUAGES',
  // STREAKS TEMPLATES
  STREAK_TEMPLATE_ID = 'STREAK_TEMPLATE_ID',
  STREAK_TEMPLATE_FITNESS_SCORING = 'STREAK_TEMPLATE_FITNESS_SCORING',
  STREAK_TEMPLATE_LOCKED_ICON = 'STREAK_TEMPLATE_LOCKED_ICON',
  STREAK_TEMPLATE_UNLOCKED_ICON = 'STREAK_TEMPLATE_UNLOCKED_ICON',
  STREAK_TEMPLATE_DESCRIPTION = 'STREAK_TEMPLATE_DESCRIPTION',
  STREAK_TEMPLATE_NAME = 'STREAK_TEMPLATE_NAME',
  STREAK_TEMPLATE_LANGUAGES = 'STREAK_TEMPLATE_LANGUAGES',
  // STREAKS
  STREAK_ID = 'STREAK_ID',
  STREAK_TEMPLATE = 'STREAK_TEMPLATE',
  STREAK_SINCE_DATE = 'STREAK_SINCE_DATE',
  STREAK_UP_TO_DATE = 'STREAK_UP_TO_DATE',
  STREAK_LOCKED_IMAGE = 'STREAK_LOCKED_IMAGE',
  STREAK_UNLOCKED_IMAGE = 'STREAK_UNLOCKED_IMAGE',
  STREAK_VALIDITY_DATE = 'STREAK_VALIDITY_DATE',
  STREAK_THRESHOLD = 'STREAK_THRESHOLD',
  STREAK_CREDITS = 'STREAK_CREDITS',
  STREAK_VALID_FOR_COMPETITIONS = 'STREAK_VALID_FOR_COMPETITIONS',
  STREAK_AUTOREDEEM = 'STREAK_AUTOREDEEM',
  STREAK_ACTION = 'STREAK_ACTION',
  STREAK_DETAIL = 'STREAK_DETAIL',
  // REWARD ID
  REWARD_NAME = 'REWARD_NAME',
  REWARD_BASED_ON = 'REWARD_BASED_ON',
  REWARD_ATTRIUTED_WHEN = 'REWARD_ATTRIUTED_WHEN',
  REWARD_CREDITS = 'REWARD_CREDITS',
  REWARD_LIMITS = 'REWARD_LIMITS',
  REWARD_ID = 'REWARD_ID',
  // FITNESS TO
  FTO_ID = 'FTO_ID',
  FTO_VALID_SINCE = 'FTO_VALID_SINCE',
  FTO_VALID_UNTIL = 'FTO_VALID_UNTIL',
  FTO_PRIORITY = 'FTO_PRIORITY',
  FTO_FITNESS_METRIC = 'FTO_FITNESS_METRIC',
  FTO_RECOMMENDED_UNITS = 'FTO_RECOMMENDED_UNITS',
  FTO_MAX_UNITS = 'FTO_MAX_UNITS',
  FTO_CREDITABLE_QUANTUM = 'FTO_CREDITABLE_QUANTUM',
  FTO_CREDITS_QUANTUM = 'FTO_CREDITS_QUANTUM',
  FTO_IMAGE = 'FTO_IMAGE',
  // TAGS
  TAG_ID = 'TAG_ID',
  TAG_ALIAS = 'TAG_ALIAS',
  TAG_TRANSLATIONS = 'TAG_TRANSLATIONS',
  // CHALLENGE_DURATIONS
  CHALLENGE_DURATION_ID = 'CHALLENGE_DURATION_ID',
  CHALLENGE_DURATION_TYPE = 'CHALLENGE_DURATION_TYPE',
  CHALLENGE_DURATION_HOUR = 'CHALLENGE_DURATION_HOUR',
  CHALLENGE_DURATION_PRIORITY = 'CHALLENGE_DURATION_PRIORITY',
  CHALLENGE_DURATION_ANTE = 'CHALLENGE_DURATION_ANTE',
  CHALLENGE_DURATION_DESCRIPTION = 'CHALLENGE_DURATION_DESCRIPTION',
  CHALLENGE_DURATION_TRANSLATIONS = 'CHALLENGE_DURATION_TRANSLATIONS',
  // CONTEST_METRICS
  CONTEST_ACTUAL_METRIC = 'CONTEST_ACTUAL_METRIC',
  CONTEST_PRIORITY = 'CONTEST_PRIORITY',
  CONTEST_DESCRIPTION = 'CONTEST_DESCRIPTION',
  CONTEST_HANDLE = 'CONTEST_HANDLE',
  CONTEST_CAN_BE_USED_FOR_CHALLENGES = 'CONTEST_CAN_BE_USED_FOR_CHALLENGES',
  CONTEST_TRANSLATIONS = 'CONTEST_TRANSLATIONS',
  CONTEST_MESSAGE = 'CONTEST_MESSAGE',
  CONTEST_IMAGE = 'CONTEST_IMAGE',
}

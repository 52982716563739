<ng-template #modal>
  <div class="modal-header border-bottom-0">
    <h5 *ngIf="!streak" class="modal-title" id="modal-title-1">{{'CREATE_STREAK' | translate}}</h5>
    <h5 *ngIf="streak" class="modal-title" id="modal-title-2">{{'EDIT_STREAK' | translate}}</h5>
    <button [disabled]="(creatingStreak$ | async)" class="btn close-modal-button" closeModal="CLOSE_CREATE_STREAK">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
  <div class="modal-body">
    <!--    <p class="modal-description">{{'MEDIUM_LOREM' | translate}}</p>-->
    <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="streakForm" autocomplete="off"
          class="needs-validation">
      <div class="row mt-3">
        <div class="col-12">
          <div class="row">
            <div class="col-md-12">
              <div class="mb-3 single-ng-select">
                <label class="font-weight-bold mb-1">{{'SELECT_TEMPLATE' | translate}}</label>
                <p class="card-title-desc mb-2">{{'SELECT_TEMPLATE_DESC' | translate}}</p>
                <ng-select [ngClass]="{'is-invalid-select': formSubmitted && form?.goalTemplateId?.errors?.required}"
                           (change)="selectTemplate($event)"
                           [clearable]="false"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="selected"
                           [items]="templates">
                  <ng-template let-item="item" ng-label-tmp>
                    <span class="font-weight-semibold ms-2"><span class="badge badge-pill badge-soft-primary font-size-11">{{item?.id}}</span> - {{ item?.title | translate }}</span>
                  </ng-template>
                  <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                    <span class="font-weight-semibold ms-2"><span class="badge badge-pill badge-soft-primary font-size-11">{{item?.id}}</span> - {{ item?.title | translate }}</span>
                  </ng-template>
                </ng-select>
                <div class="invalid-feedback d-block"  *ngIf="formSubmitted && form?.goalTemplateId?.errors?.required">
                  <span>{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <label class="font-weight-bold mb-1" for="sinceDate">{{'STREAK_START_DATE' | translate}}</label>
                <p class="card-title-desc mb-2">{{'STREAK_START_DATE_DESC' | translate}}</p>
                <app-simple-datepicker [name]="'Monday'" [specificWeekday]="'Monday'"
                                       [selectedStartDate]="form?.sinceDate?.value"
                                       [isInvalid]="formSubmitted && form?.sinceDate?.errors?.required"
                                       [selectedEndDate]="form?.upToDate?.value"
                                       [minDateLock]="true" (dateSelected)="setSinceDate($event)"></app-simple-datepicker>
                <div *ngIf="formSubmitted && form?.sinceDate?.errors" class="invalid-feedback d-block">
                  <span *ngIf="formSubmitted && form?.sinceDate?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label class="font-weight-bold mb-1" for="upToDate">{{'STREAK_END_DATE' | translate}}</label>
                <p class="card-title-desc mb-2">{{'STREAK_END_DATE_DESC' | translate}}</p>
                <app-simple-datepicker [name]="'Sunday'"  [specificWeekday]="'Sunday'"
                                       [selectedStartDate]="form?.sinceDate?.value"
                                       #upToDateComponent
                                       [isInvalid]="formSubmitted && form?.upToDate?.errors?.required"
                                       [selectedEndDate]="form?.upToDate?.value"
                                       [minDateLock]="true" (dateSelected)="setUpToDate($event)"></app-simple-datepicker>
                <div *ngIf="formSubmitted && form?.upToDate?.errors" class="invalid-feedback d-block">
                  <span *ngIf="formSubmitted && form?.upToDate?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <label class="font-weight-bold mb-1" for="name">{{'SELECT_THRESHOLD' | translate}}</label>
                <p class="card-title-desc mb-2">{{'SELECT_THRESHOLD_DESC' | translate}}</p>
                <input [ngClass]="{'is-invalid': formSubmitted && form?.target?.errors}" class="form-control"
                       formControlName="target"
                       id="target" min="1" type="number">
                <div *ngIf="formSubmitted && form?.target?.errors" class="invalid-feedback">
                  <span *ngIf="formSubmitted && form?.target?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label class="font-weight-bold mb-1" for="name">{{'CREDITS_AMOUNT' | translate}}</label>
                <p class="card-title-desc mb-2">{{'CREDITS_AMOUNT_DESC' | translate}}</p>
                <input [ngClass]="{'is-invalid': formSubmitted && form?.creditsReward?.errors}" class="form-control"
                       formControlName="creditsReward"
                       id="creditsReward" min="1" type="number">
                <div *ngIf="formSubmitted && form?.creditsReward?.errors" class="invalid-feedback">
                  <span *ngIf="formSubmitted && form?.creditsReward?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6" [ngClass]="{'col-md-12': action === 'NO_ACTION' && !!action}">
              <div class="mb-3 single-ng-select">
                <label class="font-weight-bold mb-1">{{'SELECT_ACTION' | translate}}</label>
                <p class="card-title-desc mb-2">{{'SELECT_ACTION_DESC' | translate}}</p>
                <ng-select [ngClass]="{'is-invalid-select': formSubmitted && form?.action?.errors?.required}"
                           [clearable]="false"
                           (change)="selectAction($event)"
                           [items]="actions"
                           formControlName="action">
                  <ng-template let-item="item" ng-label-tmp>
                    <span>{{ item | translate }}</span>
                  </ng-template>
                  <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                    <span class="font-weight-semibold ms-2">{{item | translate}}</span>
                  </ng-template>
                </ng-select>
                <div class="invalid-feedback d-block" *ngIf="formSubmitted && form?.action?.errors?.required">
                  <span >{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
            <div class="col-md-6" *ngIf="action !== 'NO_ACTION' && !!action">
              <div class="mb-3 single-ng-select">
                <label class="font-weight-bold mb-1" for="actionDetail" *ngIf="action === 'openFaq'">{{'SELECT_FAQ' | translate}}</label>
                <label class="font-weight-bold mb-1" for="actionDetail" *ngIf="action === 'openLink'">{{'SELECT_LINK' | translate}}</label>
                <p class="card-title-desc mb-2" *ngIf="action === 'openFaq'">{{'SELECT_FAQ_DESC' | translate}}</p>
                <p class="card-title-desc mb-2" *ngIf="action === 'openLink'">{{'SELECT_LINK_DESC' | translate}}</p>
                <input *ngIf="action === 'openLink'" [ngClass]="{'is-invalid': formSubmitted && form?.actionDetail?.errors}" class="form-control"
                       formControlName="actionDetail" id="actionDetail" type="text">
                <app-select-faq *ngIf="action === 'openFaq'"  (selectedFaq)="setFaq($event)" [form]="form" [formSubmitted]="formSubmitted"></app-select-faq>
                <div *ngIf="formSubmitted && form.actionDetail.errors" class="invalid-feedback d-block">
                  <span *ngIf="formSubmitted && form.actionDetail.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <label class="font-weight-bold mb-1">{{'STREAK_VALID_FOR_COMPETITIONS' | translate}}</label>
                <p class="card-title-desc mb-2">{{'STREAK_VALID_FOR_COMPETITIONS_DESC' | translate}}</p>
                <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                  <input [checked]="form?.validForCreditsBasedContests?.value" autocomplete="off" class="form-check-input m-0 pointer" formControlName="validForCreditsBasedContests"
                         id="validForCreditsBasedContests" type="checkbox">
                  <label class="form-check-label font-size-11 ms-3" for="validForCreditsBasedContests">
                    <span *ngIf="form?.validForCreditsBasedContests?.value"
                          class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                    <span *ngIf="!form?.validForCreditsBasedContests?.value"
                          class="badge badge-pill badge-soft-danger font-size-11">No</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label class="font-weight-bold mb-1">{{'AUTOREDEEM' | translate}}</label>
                <p class="card-title-desc mb-2">{{'AUTOREDEEM_DESC' | translate}}</p>
                <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                  <input [checked]="form?.autoRedeem?.value" autocomplete="off" class="form-check-input m-0 pointer" formControlName="autoRedeem"
                         id="autoRedeem" type="checkbox">
                  <label class="form-check-label font-size-11 ms-3" for="autoRedeem">
                    <span *ngIf="form?.autoRedeem?.value"
                          class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                    <span *ngIf="!form?.autoRedeem?.value"
                          class="badge badge-pill badge-soft-danger font-size-11">No</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul class="list-inline wizard mb-0">
        <li class="next list-inline-item float-end">
          <button (click)="createQrValidation()" *ngIf="!streak" [disabled]="(creatingStreak$ | async)"
                  class="btn btn-warning">
            <i *ngIf="creatingStreak$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            <span *ngIf="(creatingStreak$ | async) && !streak">
              {{'CREATING' | translate}}
            </span>
            <span *ngIf="!(creatingStreak$ | async) && !streak">{{'CREATE' | translate}}</span>
          </button>
          <button (click)="createQrValidation()" *ngIf="streak"
                  class="btn btn-warning">
            <i *ngIf="creatingStreak$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            <span *ngIf="(creatingStreak$ | async) && streak">{{'EDITING' | translate}}</span>
            <span *ngIf="!(creatingStreak$ | async) && streak">{{'EDIT' | translate}}</span>
          </button>
        </li>
      </ul>
    </form>
  </div>
</ng-template>

import { Injectable } from '@angular/core';
import {HttpService} from "@service/http/http.service";
import {Observable} from "rxjs";
import {endpoints} from "@endpoint/endpoint-list";
import {EndpointParamEnum} from "@enum/endpoint-param.enum";

@Injectable({
  providedIn: 'root'
})
export class StreakTemplateApiService {

  constructor(private http: HttpService) { }

  public getTemplateList(filters: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.streak.template.list, params);
  }

  public getTemplateFitnessScoringFunctions(filters: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.streak.template.fitnessScoring, params);
  }

  public createEditTemplate(params: any): Observable<any> {
    if (params?.id) {
      const placeholders = [{placeholder: EndpointParamEnum.ID, value: params?.id}];
      const url = this.http.findAndReplacePlaceholders(endpoints.streak.template.edit, placeholders);
      return this.http.post(url, params);
    } else {
      return this.http.put(endpoints.streak.template.add, params);
    }
  }

}

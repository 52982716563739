import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {EventService} from "@service/common/event.service";
import {StreakTemplateService} from "@service/streak/streak-template.service";
import {EventEnum} from "@enum/event/event.enum";
import {FitnessToCreditsService} from "@service/rewards/fitness-to-credits.service";
import {DatePipe} from "@angular/common";
import {FitnessMetricEnum} from "@enum/fitness-metric/fitness-metric.enum";
import {UtilsService} from "@service/utils/utils.service";

@Component({
  selector: 'app-add-fto-modal',
  templateUrl: './add-fto-modal.component.html',
  styleUrls: ['./add-fto-modal.component.scss']
})
export class AddFtoModalComponent implements OnInit {
  @Input() ftoSlot: any;
  @ViewChild('modal') private modalContent: TemplateRef<any>
  public creatingFto$: Observable<boolean>;
  public formSubmitted: boolean;
  public ftoSlotForm: FormGroup;
  private modalRef: NgbModalRef;
  private closeModalSubscription: Subscription;
  public limit: any = {
    fixedSize: true,
    ratio: '1:1',
    width: 120,
    height: 120
  };
  public fitnessMetricList = [
    FitnessMetricEnum.STEPS,
    FitnessMetricEnum.SLEEP,
    FitnessMetricEnum.MEDITATION,
    FitnessMetricEnum.SPORT,
    FitnessMetricEnum.YOGA_MINUTES
  ];
  public selectedFitnessMetric = undefined;

  constructor(private modalService: NgbModal, private eventService: EventService, public datepipe: DatePipe,
              private utils: UtilsService,
              public formBuilder: FormBuilder, private fitnessToCreditsService: FitnessToCreditsService) {
  }

  get form() {
    return this.ftoSlotForm.controls;
  }

  ngOnInit(): void {
    this._closeModal();
    this.creatingFto$ = this.fitnessToCreditsService?.creating$;
    this.initForm();
  }

  public setTemplate(streakTemplate: any): void {
    this.ftoSlot = streakTemplate;
    this.patchFormWithEditingData();
  }

  public validSubmit() {
    if (this.ftoSlotForm.valid) {
      let formData = this.ftoSlotForm.getRawValue();
      this.fitnessToCreditsService?.initCreateListener();
      // if (this.ftoSlot) {
      //   formData.id = this.ftoSlot?.id;
      // }
      formData.update = this.ftoSlot?.update;
      formData.id = this.ftoSlot?.id;
      formData.validSinceInclusive = this.utils.sanitizeDate(formData?.validSinceInclusive);
      formData.validUntilExclusive = this.utils.sanitizeDate(formData?.validUntilExclusive);
      this.fitnessToCreditsService.createFtoSlot(formData);
    }
  }

  public createStreakTemplate() {
    this.formSubmitted = true;
  }

  public open(data?: any): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, {size: 'lg', centered: true, backdrop: false})
      this.modalRef.result.then(resolve, resolve);
      if (data?.fitnessMetric) {
        this.selectedFitnessMetric = data?.fitnessMetric;
        this.ftoSlotForm.controls['fitnessMetric'].disable();
        this.setTemplate(data);
      }
    })
  }

  private initForm() {
    this.ftoSlotForm = this.formBuilder.group({
      fitnessMetric: [null, [Validators.required]],
      validSinceInclusive: [null, [Validators.required]],
      validUntilExclusive: [null, [Validators.required]],
      creditableQuantumSize: [null, [Validators.required]],
      creditsPerQuantum: [null, [Validators.required]],
      metricImageUrl: [null]
    });
  }


  public setSinceDate(date: any): void {
    let convertedDate = this.datepipe.transform(date, 'dd/MM/yyyy');
    this.ftoSlotForm.patchValue({['validSinceInclusive']: convertedDate});
    const validUntilExclusive = this.ftoSlotForm?.getRawValue()?.validUntilExclusive;
    const sinceToDate = this.ftoSlotForm?.getRawValue()?.validSinceInclusive;
    if (validUntilExclusive) {
      const validUntilExclusiveString = validUntilExclusive.split("/");
      const validSinceInclusive = sinceToDate.split("/");
      const validUntilExclusiveObject = new Date(+validUntilExclusiveString[2], validUntilExclusiveString[1] - 1, +validUntilExclusiveString[0]);
      const validSinceInclusiveObject = new Date(+validSinceInclusive[2], validSinceInclusive[1] - 1, +validSinceInclusive[0]);
      if (validUntilExclusiveObject < validSinceInclusiveObject) {
        this.ftoSlotForm.patchValue({['validUntilExclusive']: null});
      }
    }
  }

  public setUpToDate(date: any): void {
    let convertedDate = this.datepipe.transform(date, 'dd/MM/yyyy');
    this.ftoSlotForm.patchValue({['validUntilExclusive']: convertedDate});
  }

  public setCreditable(event: any): void {
    this.ftoSlotForm.patchValue({['creditableQuantumSize']: event});
  }

  public setCedits(event: any): void {
    this.ftoSlotForm.patchValue({['creditsPerQuantum']: event});
  }

  public setImage(image: any): void {
    this.ftoSlotForm.patchValue({
      metricImageUrl: image ? image?.originalUrl : undefined,
      lockedImageName: image ? image?.name : undefined,
      lockedImageType: image ? image?.mimeType : undefined,
    });
  }

  public checkLockedImageError(): string | undefined {
    if (this.formSubmitted && this.form.metricImageUrl?.errors?.required) {
      return 'This value is required';
    } else {
      return undefined;
    }
  }

  public checkSelectedLockedImage(): object | undefined {
    if (this.form.metricImageUrl?.value) {
      return {originalUrl: this.form.metricImageUrl?.value, name: [this.form.title?.value?.replace(/\s/g, "_"), 'locked_image'].join('_')}
    } else {
      return undefined;
    }
  }

  private patchFormWithEditingData() {
    this.ftoSlotForm.patchValue({
      fitnessMetric: this.ftoSlot?.fitnessMetric ? this.ftoSlot?.fitnessMetric : null,
      validSinceInclusive: this.ftoSlot?.validSinceInclusive ? new Date(this.ftoSlot?.validSinceInclusive)?.toISOString() : null,
      validUntilExclusive: this.ftoSlot?.validUntilExclusive ? new Date(this.ftoSlot?.validUntilExclusive)?.toISOString() : null,
      creditableQuantumSize: this.ftoSlot?.creditableQuantumSize ? this.ftoSlot?.creditableQuantumSize : null,
      creditsPerQuantum: this.ftoSlot?.creditsPerQuantum ? this.ftoSlot?.creditsPerQuantum : null,
      metricImageUrl: this.ftoSlot?.metricImage ? this.ftoSlot?.metricImage : null
    });
    if (this.ftoSlot.duplicate) {
      this.ftoSlot = undefined;
    }
    if (!this.ftoSlot?.validSinceInclusive) {
      this.ftoSlot = undefined;
    }
  }

  private _closeModal(): void {
    this.closeModalSubscription = this.eventService.subscribe(EventEnum.CLOSE_CREATE_FTO, (reason: string | undefined) => {
      this.fitnessToCreditsService?.removeCreateFtoSlotSubscription();
      this.initForm();
      this.selectedFitnessMetric = undefined;
      this.formSubmitted = false;
      this.ftoSlot = undefined;
      this.modalRef?.dismiss(reason);
    });
  }
}

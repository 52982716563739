<ng-template #modal>
  <div class="modal-header border-bottom-0">
    <h5 *ngIf="!company" class="modal-title" id="modal-title-1">{{'CREATE_COMPANY' | translate}}</h5>
    <h5 *ngIf="company" class="modal-title" id="modal-title-2">{{'EDIT_COMPANY' | translate}}</h5>
    <button [disabled]="(creatingCompany$ | async)" class="btn close-modal-button" closeModal="CLOSE_CREATE_COMPANY">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
  <div class="modal-body">
    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam atque blanditiis delectus enim eveniet ipsam iure necessitatibus reiciendis sed vitae? Aliquid aspernatur beatae blanditiis consequatur ducimus eos error ex excepturi facere fuga hic minima molestias nulla officiis optio perspiciatis, quae rerum soluta tempore vero! Consectetur distinctio dolor eos hic veniam.</p>
    <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="companyForm" autocomplete="off"
          class="needs-validation">
<!--      <div class="row mb-3">-->
<!--        <div class="col-md-3">-->
<!--          <ul ngbNav #verticalNav="ngbNav" [activeId]="1" class="nav-pills flex-column">-->
<!--            <li [ngbNavItem]="1">-->
<!--              <a ngbNavLink class="mb-2">-->
<!--                <span class="d-inline-block danger me-2" *ngIf="checkAlert('BASE')">-->
<!--                  <i class="mdi mdi-alert-circle-outline"></i>-->
<!--                </span>-->
<!--                <span class="d-inline-block">Base and presentational</span>-->
<!--              </a>-->
<!--              <ng-template ngbNavContent>-->
<!--                <div class="row">-->
<!--                  <div class="col-12">-->
<!--                    <div class="row">-->
<!--                      <div class="col-md-12">-->
<!--                        <div class="mb-3">-->
<!--                          <label class="font-weight-bold" for="name">{{'NAME' | translate}}</label>-->
<!--                          <input [ngClass]="{'is-invalid': formSubmitted && form?.name?.errors}" class="form-control"-->
<!--                                 formControlName="name" id="name" type="text">-->
<!--                          <div *ngIf="formSubmitted && form?.name?.errors" class="invalid-feedback">-->
<!--                            <span *ngIf="formSubmitted && form?.name?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>-->
<!--                            <span *ngIf="form?.name?.errors?.maxlength">This value is too long. It should have 100 characters or fewer.</span>-->
<!--                            <span *ngIf="form?.name?.errors?.minlength">This value is too short. It should have 5 characters or more.</span>-->

<!--                          </div>-->
<!--                        </div>-->
<!--                        <div class="mb-3" [ngClass]="{'precondition-editor-invalid': formSubmitted && form.description.errors}">-->
<!--                          <label class="font-weight-bold" for="title">{{'DESCRIPTION' | translate}}</label>-->
<!--                          <ckeditor [editor]="Editor" formControlName="description" [config]="{ removePlugins: ['EasyImage','ImageUpload','MediaEmbed'], mediaEmbed: {} }"-->
<!--                                    data="<p>Description of the company.</p>"></ckeditor>-->
<!--                          <div *ngIf="formSubmitted && form.description?.errors" class="invalid-feedback d-block">-->
<!--                            <span *ngIf="formSubmitted && form.description?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>-->
<!--                            <span *ngIf="form.description?.errors?.maxlength">This value is too long. It should have 100 characters or fewer.</span>-->
<!--                            <span *ngIf="form.description?.errors?.minlength">This value is too short. It should have 5 characters or more.</span>-->

<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-12">-->
<!--                    <div class="row">-->
<!--                      <div class="col-6">-->
<!--                        <div class="row mb-3">-->
<!--                          <div class="col-12"  [ngClass]="{'col-12': !cardImage}">-->
<!--                            <app-image-uploader (imageUploaded)="setCardImage($event)"-->
<!--                                                [showImagePreview]="true"-->
<!--                                                [name]="'LOGO'"-->
<!--                                                [imageErrors]="checkCardImageError()"-->
<!--                                                [limit]="logoImageLimit" [previousSelectedImage]="checkSelectedCardImage()"></app-image-uploader>-->

<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="col-6">-->
<!--                        <div class="row mb-3">-->
<!--                          <div class="col-12"  [ngClass]="{'col-12': !detailImage}">-->
<!--                            <app-image-uploader (imageUploaded)="setDetailImage($event)"-->
<!--                                                [name]="'DETAIL_IMAGE'"-->
<!--                                                [showImagePreview]="true"-->
<!--                                                [imageErrors]="checkDetailImageError()"-->
<!--                                                [limit]="detailImageLimit" [previousSelectedImage]="checkSelectedDetailImage()"></app-image-uploader>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="col-6">-->
<!--                        <div class="mb-3" [ngClass]="{'is-invalid-select': formSubmitted && form?.teamScoreStrategy?.errors}">-->
<!--                          <label class="font-weight-bold" for="teamScoreStrategy">Team score strategy</label>-->
<!--                          <select   class="form-select" formControlName="teamScoreStrategy" id="teamScoreStrategy">-->
<!--                            <option *ngFor="let teamScoreStrategy of teamScoreStrategies" [value]="teamScoreStrategy">{{teamScoreStrategy | translate}}</option>-->
<!--                          </select>-->
<!--                          <div *ngIf="formSubmitted && form?.teamScoreStrategy?.errors" class="invalid-feedback d-block">-->
<!--                            <span *ngIf="formSubmitted && form?.teamScoreStrategy?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="col-6">-->
<!--                        <div class="mb-3">-->
<!--                          <label class="font-weight-bold" for="priority">Priority</label>-->
<!--                          <input [ngClass]="{'is-invalid': formSubmitted && form?.priority?.errors}" class="form-control"-->
<!--                                 formControlName="priority" id="priority"-->
<!--                                 min="0"-->
<!--                                 placeholder="Priority" type="number">-->
<!--                          <div class="invalid-feedback"  *ngIf="formSubmitted && form?.priority?.errors?.required">-->
<!--                            <span>{{'VALUE_REQUIRED' | translate}}</span>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-12">-->
<!--                    <div class="row">-->
<!--                      <div class="col-2">-->
<!--                        <div class="mb-3">-->
<!--                          <label class="font-weight-bold font-size-11" for="active">Active</label>-->
<!--                          <div class="form-check form-check-custom form-check-custom-big align-middle p-0">-->
<!--                            <input [checked]="form?.active?.value" autocomplete="off" class="form-check-input m-0 pointer"-->
<!--                                   formControlName="active"-->
<!--                                   id="active" type="checkbox">-->
<!--                            <label class="form-check-label font-size-11 ms-3" for="active">-->
<!--                              <span *ngIf="form?.active?.value"-->
<!--                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>-->
<!--                              <span *ngIf="!form?.active?.value"-->
<!--                                    class="badge badge-pill badge-soft-danger font-size-11">No</span>-->
<!--                            </label>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="col-2">-->
<!--                        <div class="mb-3">-->
<!--                          <label class="font-weight-bold font-size-11" for="enabled">Enabled</label>-->
<!--                          <div class="form-check form-check-custom form-check-custom-big align-middle p-0">-->
<!--                            <input [checked]="form?.enabled?.value" autocomplete="off" class="form-check-input m-0 pointer"-->
<!--                                   formControlName="enabled"-->
<!--                                   id="enabled" type="checkbox">-->
<!--                            <label class="form-check-label font-size-11 ms-3" for="enabled">-->
<!--                              <span *ngIf="form?.enabled?.value"-->
<!--                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>-->
<!--                              <span *ngIf="!form?.enabled?.value"-->
<!--                                    class="badge badge-pill badge-soft-danger font-size-11">No</span>-->
<!--                            </label>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="col-2">-->
<!--                        <div class="mb-3">-->
<!--                          <label class="font-weight-bold font-size-11" for="discoverable">Discoverable</label>-->
<!--                          <div class="form-check form-check-custom form-check-custom-big align-middle p-0">-->
<!--                            <input [checked]="form?.discoverable?.value" autocomplete="off" class="form-check-input m-0 pointer"-->
<!--                                   formControlName="discoverable"-->
<!--                                   id="discoverable" type="checkbox">-->
<!--                            <label class="form-check-label font-size-11 ms-3" for="discoverable">-->
<!--                              <span *ngIf="form?.discoverable?.value"-->
<!--                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>-->
<!--                              <span *ngIf="!form?.discoverable?.value"-->
<!--                                    class="badge badge-pill badge-soft-danger font-size-11">No</span>-->
<!--                            </label>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="col-2">-->
<!--                        <div class="mb-3">-->
<!--                          <label class="font-weight-bold font-size-11" for="showMembersCount">Show members count</label>-->
<!--                          <div class="form-check form-check-custom form-check-custom-big align-middle p-0">-->
<!--                            <input [checked]="form?.showMembersCount?.value" autocomplete="off" class="form-check-input m-0 pointer"-->
<!--                                   formControlName="showMembersCount"-->
<!--                                   id="showMembersCount" type="checkbox">-->
<!--                            <label class="form-check-label font-size-11 ms-3" for="showMembersCount">-->
<!--                              <span *ngIf="form?.showMembersCount?.value"-->
<!--                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>-->
<!--                              <span *ngIf="!form?.showMembersCount?.value"-->
<!--                                    class="badge badge-pill badge-soft-danger font-size-11">No</span>-->
<!--                            </label>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="col-2">-->
<!--                        <div class="mb-3">-->
<!--                          <label class="font-weight-bold font-size-11" for="showCompanyLeaderboards">Show leaderboard</label>-->
<!--                          <div class="form-check form-check-custom form-check-custom-big align-middle p-0">-->
<!--                            <input [checked]="form?.showCompanyLeaderboards?.value" autocomplete="off" class="form-check-input m-0 pointer"-->
<!--                                   formControlName="showCompanyLeaderboards"-->
<!--                                   id="showCompanyLeaderboards" type="checkbox">-->
<!--                            <label class="form-check-label font-size-11 ms-3" for="showCompanyLeaderboards">-->
<!--                              <span *ngIf="form?.showCompanyLeaderboards?.value"-->
<!--                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>-->
<!--                              <span *ngIf="!form?.showCompanyLeaderboards?.value"-->
<!--                                    class="badge badge-pill badge-soft-danger font-size-11">No</span>-->
<!--                            </label>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="col-2">-->
<!--                        <div class="mb-3">-->
<!--                          <label class="font-weight-bold font-size-11" for="mustInsertCode">Access code mandatory</label>-->
<!--                          <div class="form-check form-check-custom form-check-custom-big align-middle p-0">-->
<!--                            <input [checked]="form?.mustInsertCode?.value" autocomplete="off" class="form-check-input m-0 pointer"-->
<!--                                   formControlName="mustInsertCode"-->
<!--                                   id="mustInsertCode" type="checkbox">-->
<!--                            <label class="form-check-label font-size-11 ms-3" for="mustInsertCode">-->
<!--                              <span *ngIf="form?.mustInsertCode?.value"-->
<!--                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>-->
<!--                              <span *ngIf="!form?.mustInsertCode?.value"-->
<!--                                    class="badge badge-pill badge-soft-danger font-size-11">No</span>-->
<!--                            </label>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </ng-template>-->
<!--            </li>-->
<!--            <li [ngbNavItem]="2">-->
<!--              <a ngbNavLink class="mb-2">-->
<!--                <span class="d-inline-block danger me-2" *ngIf="checkAlert('CONFIGURATION')">-->
<!--                  <i class="mdi mdi-alert-circle-outline"></i>-->
<!--                </span>-->
<!--                <span class="d-inline-block">Stats configuration</span>-->
<!--              </a>-->
<!--              <ng-template ngbNavContent>-->
<!--                <div class="row">-->
<!--                  <div class="col-12">-->
<!--                    <p class="font-weight-bold mb-1">Stats configuration</p>-->
<!--                    <p class="text-muted">This fields is used for company stats in app</p>-->
<!--                  </div>-->
<!--                  <div class="col-12">-->
<!--                    <div class="mb-3" [ngClass]="{'is-invalid-select': formSubmitted && form?.countChallengeWhen?.errors}">-->
<!--                      <label class="font-weight-bold" for="countChallengeWhen">Count challenge when</label>-->
<!--                      <select class="form-select" formControlName="countChallengeWhen" id="countChallengeWhen">-->
<!--                        <option *ngFor="let challengeWhen of countChallengeWhen" [value]="challengeWhen">{{challengeWhen | translate}}</option>-->
<!--                      </select>-->
<!--                      <div *ngIf="formSubmitted && form?.countChallengeWhen?.errors" class="invalid-feedback d-block">-->
<!--                        <span *ngIf="formSubmitted && form?.countChallengeWhen?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="mb-3"  [ngClass]="{'is-invalid-select': formSubmitted && form?.countChallengeStrategy?.errors}">-->
<!--                      <label class="font-weight-bold" for="countChallengeStrategy">Count challenge strategy</label>-->
<!--                      <select class="form-select" formControlName="countChallengeStrategy" id="countChallengeStrategy">-->
<!--                        <option *ngFor="let challengeStrategy of countChallengeStrategy" [value]="challengeStrategy">{{challengeStrategy | translate}}</option>-->
<!--                      </select>-->
<!--                      <div *ngIf="formSubmitted && form?.countChallengeStrategy?.errors" class="invalid-feedback d-block">-->
<!--                        <span *ngIf="formSubmitted && form?.countChallengeStrategy?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="mb-3">-->
<!--                      <label class="font-weight-bold" for="co2Improvement">CO2 Improvement</label>-->
<!--                      <input [ngClass]="{'is-invalid': formSubmitted && form?.co2Improvement?.errors}" class="form-control"-->
<!--                             formControlName="co2Improvement" id="co2Improvement"-->
<!--                             min="0" type="number">-->
<!--                      <div class="invalid-feedback d-block"  *ngIf="formSubmitted && form?.co2Improvement?.errors?.required">-->
<!--                        <span>{{'VALUE_REQUIRED' | translate}}</span>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </ng-template>-->
<!--            </li>-->
<!--            <li [ngbNavItem]="3">-->
<!--              <a ngbNavLink class="mb-2">-->
<!--                <span class="d-inline-block danger me-2" *ngIf="checkAlert('PRECONDITIONS')">-->
<!--                  <i class="mdi mdi-alert-circle-outline"></i>-->
<!--                </span>-->
<!--                <span class="d-inline-block line-height-1-2">Prize preconditions</span>-->
<!--              </a>-->
<!--              <ng-template ngbNavContent>-->
<!--                <div class="row">-->
<!--                  <div class="col-12">-->
<!--                    <p class="font-weight-bold mb-1">Prize preconditions</p>-->
<!--                    <p class="text-muted">All prizes for this competition will display the following prerequisite</p>-->
<!--                  </div>-->
<!--                  <div class="col-12">-->
<!--                    <div class="mb-3">-->
<!--                      <label class="font-weight-bold" for="prizeContextTitle">{{'TITLE' | translate}}</label>-->
<!--                      <input [ngClass]="{'is-invalid': formSubmitted && form?.prizeContextTitle?.errors}" class="form-control"-->
<!--                             formControlName="prizeContextTitle" id="prizeContextTitle" type="text">-->
<!--                      <div *ngIf="formSubmitted && form?.prizeContextTitle?.errors" class="invalid-feedback">-->
<!--                        <span *ngIf="formSubmitted && form?.prizeContextTitle?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>-->
<!--                        <span *ngIf="form?.prizeContextTitle?.errors?.maxlength">This value is too long. It should have 100 characters or fewer.</span>-->
<!--                        <span *ngIf="form?.prizeContextTitle?.errors?.minlength">This value is too short. It should have 5 characters or more.</span>-->

<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="mb-3" [ngClass]="{'precondition-editor-invalid': formSubmitted && form.prizeContextDescription.errors}">-->
<!--                      <label class="font-weight-bold" for="prizeContextDescription">{{'DESCRIPTION' | translate}}</label>-->
<!--                      <ckeditor [editor]="Editor" formControlName="prizeContextDescription" [config]="{ removePlugins: ['EasyImage','ImageUpload','MediaEmbed'], mediaEmbed: {} }"-->
<!--                                data="<p>Description of the company.</p>"></ckeditor>-->
<!--                      <div *ngIf="formSubmitted && form.prizeContextDescription?.errors" class="invalid-feedback d-block">-->
<!--                        <span *ngIf="formSubmitted && form.prizeContextDescription?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>-->
<!--                        <span *ngIf="form.prizeContextDescription?.errors?.maxlength">This value is too long. It should have 100 characters or fewer.</span>-->
<!--                        <span *ngIf="form.prizeContextDescription?.errors?.minlength">This value is too short. It should have 5 characters or more.</span>-->

<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </ng-template>-->
<!--            </li>-->
<!--&lt;!&ndash;            <li [ngbNavItem]="4">&ndash;&gt;-->
<!--&lt;!&ndash;              <a ngbNavLink class="mb-2">&ndash;&gt;-->
<!--&lt;!&ndash;                <span class="d-inline-block danger me-2" *ngIf="checkAlert('CODES')">&ndash;&gt;-->
<!--&lt;!&ndash;                  <i class="mdi mdi-alert-circle-outline"></i>&ndash;&gt;-->
<!--&lt;!&ndash;                </span>&ndash;&gt;-->
<!--&lt;!&ndash;                <span class="d-inline-block line-height-1-2">Invite codes</span>&ndash;&gt;-->
<!--&lt;!&ndash;              </a>&ndash;&gt;-->
<!--&lt;!&ndash;              <ng-template ngbNavContent>&ndash;&gt;-->

<!--&lt;!&ndash;              </ng-template>&ndash;&gt;-->
<!--&lt;!&ndash;            </li>&ndash;&gt;-->
<!--          </ul>-->
<!--        </div>-->
<!--        <div class="col-md-9">-->
<!--          <div [ngbNavOutlet]="verticalNav"></div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="row">
        <div class="col-7">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="font-weight-bold" for="name">{{'NAME' | translate}}</label>
                    <input [ngClass]="{'is-invalid': formSubmitted && form?.name?.errors}" class="form-control"
                           formControlName="name" id="name" type="text">
                    <div *ngIf="formSubmitted && form?.name?.errors" class="invalid-feedback">
                      <span *ngIf="formSubmitted && form?.name?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                      <span *ngIf="form?.name?.errors?.maxlength">This value is too long. It should have 100 characters or fewer.</span>
                      <span *ngIf="form?.name?.errors?.minlength">This value is too short. It should have 5 characters or more.</span>

                    </div>
                  </div>
                  <div class="mb-3" [ngClass]="{'precondition-editor-invalid': formSubmitted && form.description.errors}">
                    <label class="font-weight-bold" for="title">{{'DESCRIPTION' | translate}}</label>
                    <ckeditor [editor]="Editor" formControlName="description" [config]="{ removePlugins: ['EasyImage','ImageUpload','MediaEmbed'], mediaEmbed: {} }"
                              data="<p>Description of the company.</p>"></ckeditor>
                    <div *ngIf="formSubmitted && form.description?.errors" class="invalid-feedback d-block">
                      <span *ngIf="formSubmitted && form.description?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                      <span *ngIf="form.description?.errors?.maxlength">This value is too long. It should have 100 characters or fewer.</span>
                      <span *ngIf="form.description?.errors?.minlength">This value is too short. It should have 5 characters or more.</span>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-5">
          <div class="row">
            <div class="col-6">
              <div class="mb-3" [ngClass]="{'is-invalid-select': formSubmitted && form?.teamScoreStrategy?.errors}">
                <label class="font-weight-bold" for="teamScoreStrategy">Team score strategy</label>
                <select   class="form-select" formControlName="teamScoreStrategy" id="teamScoreStrategy">
                  <option *ngFor="let teamScoreStrategy of teamScoreStrategies" [value]="teamScoreStrategy">{{teamScoreStrategy | translate}}</option>
                </select>
                <div *ngIf="formSubmitted && form?.teamScoreStrategy?.errors" class="invalid-feedback d-block">
                  <span *ngIf="formSubmitted && form?.teamScoreStrategy?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3">
                <label class="font-weight-bold" for="priority">Priority</label>
                <input [ngClass]="{'is-invalid': formSubmitted && form?.priority?.errors}" class="form-control"
                       formControlName="priority" id="priority"
                       min="0"
                       placeholder="Priority" type="number">
                <div class="invalid-feedback"  *ngIf="formSubmitted && form?.priority?.errors?.required">
                  <span>{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
<!--            <div class="col-12">-->
<!--              <p class="font-weight-bold mb-1">Stats configuration</p>-->
<!--              <p class="text-muted">This fields is used for company stats in app</p>-->
<!--            </div>-->
            <div class="col-6">
              <div class="mb-3" [ngClass]="{'is-invalid-select': formSubmitted && form?.countChallengeWhen?.errors}">
                <label class="font-weight-bold" for="countChallengeWhen">Count challenge when</label>
                <select class="form-select" formControlName="countChallengeWhen" id="countChallengeWhen">
                  <option *ngFor="let challengeWhen of countChallengeWhen" [value]="challengeWhen">{{challengeWhen | translate}}</option>
                </select>
                <div *ngIf="formSubmitted && form?.countChallengeWhen?.errors" class="invalid-feedback d-block">
                  <span *ngIf="formSubmitted && form?.countChallengeWhen?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3"  [ngClass]="{'is-invalid-select': formSubmitted && form?.countChallengeStrategy?.errors}">
                <label class="font-weight-bold" for="countChallengeStrategy">Count challenge strategy</label>
                <select class="form-select" formControlName="countChallengeStrategy" id="countChallengeStrategy">
                  <option *ngFor="let challengeStrategy of countChallengeStrategy" [value]="challengeStrategy">{{challengeStrategy | translate}}</option>
                </select>
                <div *ngIf="formSubmitted && form?.countChallengeStrategy?.errors" class="invalid-feedback d-block">
                  <span *ngIf="formSubmitted && form?.countChallengeStrategy?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="mb-3">
                <label class="font-weight-bold" for="co2Improvement">CO2 Improvement</label>
                <input [ngClass]="{'is-invalid': formSubmitted && form?.co2Improvement?.errors}" class="form-control"
                       formControlName="co2Improvement" id="co2Improvement"
                       min="0" type="number">
                <div class="invalid-feedback d-block"  *ngIf="formSubmitted && form?.co2Improvement?.errors?.required">
                  <span>{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-4">
                  <div class="mb-3">
                    <label class="font-weight-bold font-size-11" for="active">Active</label>
                    <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                      <input [checked]="form?.active?.value" autocomplete="off" class="form-check-input m-0 pointer"
                             formControlName="active"
                             id="active" type="checkbox">
                      <label class="form-check-label font-size-11 ms-3" for="active">
                              <span *ngIf="form?.active?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                        <span *ngIf="!form?.active?.value"
                              class="badge badge-pill badge-soft-danger font-size-11">No</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="mb-3">
                    <label class="font-weight-bold font-size-11" for="enabled">Enabled</label>
                    <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                      <input [checked]="form?.enabled?.value" autocomplete="off" class="form-check-input m-0 pointer"
                             formControlName="enabled"
                             id="enabled" type="checkbox">
                      <label class="form-check-label font-size-11 ms-3" for="enabled">
                              <span *ngIf="form?.enabled?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                        <span *ngIf="!form?.enabled?.value"
                              class="badge badge-pill badge-soft-danger font-size-11">No</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="mb-3">
                    <label class="font-weight-bold font-size-11" for="discoverable">Discoverable</label>
                    <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                      <input [checked]="form?.discoverable?.value" autocomplete="off" class="form-check-input m-0 pointer"
                             formControlName="discoverable"
                             id="discoverable" type="checkbox">
                      <label class="form-check-label font-size-11 ms-3" for="discoverable">
                              <span *ngIf="form?.discoverable?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                        <span *ngIf="!form?.discoverable?.value"
                              class="badge badge-pill badge-soft-danger font-size-11">No</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-4">
                  <div class="mb-3">
                    <label class="font-weight-bold font-size-11" for="showMembersCount">Show members count</label>
                    <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                      <input [checked]="form?.showMembersCount?.value" autocomplete="off" class="form-check-input m-0 pointer"
                             formControlName="showMembersCount"
                             id="showMembersCount" type="checkbox">
                      <label class="form-check-label font-size-11 ms-3" for="showMembersCount">
                              <span *ngIf="form?.showMembersCount?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                        <span *ngIf="!form?.showMembersCount?.value"
                              class="badge badge-pill badge-soft-danger font-size-11">No</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="mb-3">
                    <label class="font-weight-bold font-size-11" for="showCompanyLeaderboards">Show leaderboard</label>
                    <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                      <input [checked]="form?.showCompanyLeaderboards?.value" autocomplete="off" class="form-check-input m-0 pointer"
                             formControlName="showCompanyLeaderboards"
                             id="showCompanyLeaderboards" type="checkbox">
                      <label class="form-check-label font-size-11 ms-3" for="showCompanyLeaderboards">
                              <span *ngIf="form?.showCompanyLeaderboards?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                        <span *ngIf="!form?.showCompanyLeaderboards?.value"
                              class="badge badge-pill badge-soft-danger font-size-11">No</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="mb-3">
                    <label class="font-weight-bold font-size-11" for="mustInsertCode">Access code mandatory</label>
                    <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                      <input [checked]="form?.mustInsertCode?.value" autocomplete="off" class="form-check-input m-0 pointer"
                             formControlName="mustInsertCode"
                             id="mustInsertCode" type="checkbox">
                      <label class="form-check-label font-size-11 ms-3" for="mustInsertCode">
                              <span *ngIf="form?.mustInsertCode?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                        <span *ngIf="!form?.mustInsertCode?.value"
                              class="badge badge-pill badge-soft-danger font-size-11">No</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-6">
              <div class="row mb-3">
                <div class="col-12"  [ngClass]="{'col-12': !cardImage}">
                  <app-image-uploader (imageUploaded)="setCardImage($event)"
                                      [showImagePreview]="true"
                                      [name]="'LOGO'"
                                      [imageErrors]="checkCardImageError()"
                                      [limit]="logoImageLimit" [previousSelectedImage]="checkSelectedCardImage()"></app-image-uploader>

                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="row mb-3">
                <div class="col-12"  [ngClass]="{'col-12': !detailImage}">
                  <app-image-uploader (imageUploaded)="setDetailImage($event)"
                                      [name]="'DETAIL_IMAGE'"
                                      [showImagePreview]="true"
                                      [imageErrors]="checkDetailImageError()"
                                      [limit]="detailImageLimit" [previousSelectedImage]="checkSelectedDetailImage()"></app-image-uploader>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul class="list-inline wizard mb-0">
        <li class="next list-inline-item float-end">
          <button (click)="valid ? showAreYouSure = true : formSubmitted = true;" *ngIf="!showAreYouSure && !company"
                  class="btn btn-warning">Create
          </button>
          <button (click)="valid ? showAreYouSure = true : formSubmitted = true;" *ngIf="!showAreYouSure && company"
                  class="btn btn-warning">Edit
          </button>
          <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
          <button *ngIf="showAreYouSure" [disabled]="(creatingCompany$ | async)" class="btn btn-warning me-2" type="submit">
            <i *ngIf="creatingCompany$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            <span *ngIf="(creatingCompany$ | async) && !company">{{'CREATING' | translate}}</span>
            <span *ngIf="(creatingCompany$ | async) && company">{{'EDITING' | translate}}</span>
            <span [hidden]="(creatingCompany$ | async)">
            {{'YES' | translate}}
          </span>
          </button>
          <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(creatingCompany$ | async)"
                  class="btn btn-outline-light ">{{'NO' | translate}}
          </button>
        </li>
      </ul>
    </form>
  </div>
</ng-template>

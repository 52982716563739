export enum TopbarTypeEnum {
  BAN_MOTIVATIONS = 'BAN_MOTIVATIONS',
  BANNED_LIST = 'BANNED_LIST',
  USER_LIST = 'USER_LIST',
  WHITE_LIST = 'WHITE_LIST',
  SCHEDULED_NOTIFICATIONS = 'SCHEDULED_NOTIFICATIONS',
  QR_CODES = 'QR_CODES',
  AUTOMATIC_BAN = 'AUTOMATIC_BAN',
  BLACKLIST_SOURCES = 'BLACKLIST_SOURCES',
  NEWS_LIST = 'NEWS_LIST',
  DIAGNOSTIC_COMPLETE = 'DIAGNOSTIC_COMPLETE',
  DIAGNOSTIC_FITNESS_CHARTS = 'DIAGNOSTIC_FITNESS_CHARTS',
  DIAGNOSTIC_RECORDED_ACTIVITIES = 'DIAGNOSTIC_RECORDED_ACTIVITIES',
  INVITES = 'INVITES',
  INVITES_USES = 'INVITES_USES',
  PRIZE_LIST = 'PRIZE_LIST',
  CHALLENGE_LIST = 'CHALLENGE_LIST',
  COMPANY_LIST = 'COMPANY_LIST',
  COMPETITION_LIST = 'COMPETITION_LIST',
  FAQS_LIST = 'FAQS_LIST',
  STREAK_TEMPLATES = 'STREAK_TEMPLATES',
  STREAKS = 'STREAKS',
  REWARDS = 'REWARDS',
  FTO = 'FTO',
}

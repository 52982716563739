import {Injectable} from '@angular/core';
import {BehaviorSubject, forkJoin, Observable, Subject, Subscription} from "rxjs";
import {TableStateInterface} from "@interface/common/table-state.interface";
import {UtilsService} from "@service/utils/utils.service";
import {UserElementInterface} from "@interface/user/user-element.interface";
import {UsersApiService} from "@service/users/users-api.service";
import {SortDirection} from "@type/common/sort-direction.type";
import {catchError, debounceTime, map, switchMap, tap} from "rxjs/operators";
import {TableColumnEnum} from "@enum/table-column/table-column.enum";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from '@angular/common';
import {TableFilterEnum} from "@enum/table-filter/table-filter.enum";
import {EventEnum} from "@enum/event/event.enum";
import {EventService} from "@service/common/event.service";
import {ToastService} from "@service/toast.service";

@Injectable({
  providedIn: 'root'
})
export class UserListService {
  public _search$ = new Subject<void>();
  public _ban$ = new Subject<void>();
  public _delete$ = new Subject<void>();
  public _work$ = new Subject<any[] | any>();
  private searchUsersSubscription: Subscription;
  private workingSubscription: Subscription;
  public visibleColumns: { visible: boolean, key: TableColumnEnum, label: any }[] = [
    {visible: true, key: TableColumnEnum.USER_ID, label: this.translate.instant(TableColumnEnum.USER_ID)},
    {visible: true, key: TableColumnEnum.KEYCLOAK_ID, label: this.translate.instant(TableColumnEnum.KEYCLOAK_ID)},
    {visible: true, key: TableColumnEnum.EMAIL, label: this.translate.instant(TableColumnEnum.EMAIL)},
    {visible: true, key: TableColumnEnum.NICKNAME, label: this.translate.instant(TableColumnEnum.NICKNAME)},
    {visible: true, key: TableColumnEnum.COMPLETE_NAME, label: this.translate.instant(TableColumnEnum.COMPLETE_NAME)},
    {visible: true, key: TableColumnEnum.REGISTRATION_DATE, label: this.translate.instant(TableColumnEnum.REGISTRATION_DATE)},
    {visible: true, key: TableColumnEnum.PLATFORM, label: this.translate.instant(TableColumnEnum.PLATFORM)},
    {visible: true, key: TableColumnEnum.LAST_APP_OPEN, label: this.translate.instant(TableColumnEnum.LAST_APP_OPEN)},
    {visible: true, key: TableColumnEnum.LAST_SYNC, label: this.translate.instant(TableColumnEnum.LAST_SYNC)},
    {visible: false, key: TableColumnEnum.AVAILABLE_CREDITS, label: this.translate.instant(TableColumnEnum.AVAILABLE_CREDITS)},
    {visible: false, key: TableColumnEnum.PROFILE_COMPLETED, label: this.translate.instant(TableColumnEnum.PROFILE_COMPLETED)},
    {visible: false, key: TableColumnEnum.JOINED_COMPANIES, label: this.translate.instant(TableColumnEnum.JOINED_COMPANIES)},
    {visible: false, key: TableColumnEnum.BANNED, label: this.translate.instant(TableColumnEnum.BANNED)}
  ]
  public visibleFilters: { visible: boolean, key: TableFilterEnum, label: any }[] = [
    {visible: false, key: TableFilterEnum.REGISTRATION_DATE, label: this.translate.instant(TableFilterEnum.REGISTRATION_DATE)},
    {visible: false, key: TableFilterEnum.LAST_APP_OPEN, label: this.translate.instant(TableFilterEnum.LAST_APP_OPEN)},
    {visible: false, key: TableFilterEnum.LAST_SYNC, label: this.translate.instant(TableFilterEnum.LAST_SYNC)},
    {visible: false, key: TableFilterEnum.PROFILE_COMPLETED, label: this.translate.instant(TableFilterEnum.PROFILE_COMPLETED)},
    {visible: false, key: TableFilterEnum.USER_DELETED, label: this.translate.instant(TableFilterEnum.USER_DELETED)},
    {visible: false, key: TableFilterEnum.AVAILABLE_CREDITS, label: this.translate.instant(TableFilterEnum.AVAILABLE_CREDITS)},
    {visible: false, key: TableFilterEnum.PLATFORM, label: this.translate.instant(TableFilterEnum.PLATFORM)},
  ]
  private _currentTableState: TableStateInterface = this.utils.tableDefaultState;

  constructor(private utils: UtilsService, private usersApiService: UsersApiService, private translate: TranslateService,
              private router: Router, private activatedRoute: ActivatedRoute, private location: Location,
              private eventService: EventService, private toastService: ToastService) {
    this.initBanListener();
    this.initDeleteListener();
  }

  private _loading$ = new BehaviorSubject<boolean>(true);

  get loading$() {
    return this._loading$.asObservable();
  }

  private _banning$ = new BehaviorSubject<boolean>(false);

  get banning$() {
    return this._banning$.asObservable();
  }

  private _deleting$ = new BehaviorSubject<boolean>(false);

  get deleting$() {
    return this._deleting$.asObservable();
  }

  private _working$ = new BehaviorSubject<boolean>(false);

  get working$() {
    return this._working$.asObservable();
  }

  private _totalRecords$ = new BehaviorSubject<number>(0);

  get totalRecords$() {
    return this._totalRecords$.asObservable();
  }

  private _userList$ = new BehaviorSubject<UserElementInterface[]>([]);

  get userList$() {
    return this._userList$.asObservable();
  }

  get columns() {
    return this.visibleColumns;
  }

  get filters() {
    return this.visibleFilters;
  }

  get page() {
    return this._currentTableState.page;
  }

  set page(page: number) {
    this._setValue({page});
  }

  get sortColumn() {
    return this._currentTableState.sortColumn;
  }

  set sortColumn(sortColumn: string) {
    this._setValue({sortColumn});
  }

  get sortDirection() {
    return this._currentTableState.sortDirection;
  }

  set sortDirection(sortDirection: SortDirection) {
    this._setValue({sortDirection});
  }

  get pageSize() {
    return this._currentTableState.pageSize;
  }

  set pageSize(pageSize: number) {
    const page = 1;
    this._setValue({page})
    this._setValue({pageSize});
  }

  get searchTerm() {
    return this._currentTableState.searchTerm;
  }

  set searchTerm(searchTerm: string) {
    this._setValue({searchTerm});
  }

  get registrationDate() {
    return this._currentTableState.registrationDate;
  }

  set registrationDate(registrationDate: string[]) {
    this._setValue({registrationDate});
  }

  get lastSync() {
    return this._currentTableState.lastSync;
  }

  set lastSync(lastSync: string[]) {
    this._setValue({lastSync});
  }

  get lastAppAccessTs() {
    return this._currentTableState.lastAppAccessTs;
  }

  set lastAppAccessTs(lastAppAccessTs: string[]) {
    this._setValue({lastAppAccessTs});
  }


  get company() {
    return this._currentTableState.company;
  }

  set company(company: string) {
    this._setValue({company});
  }

  get whiteList() {
    return this._currentTableState.whiteList;
  }

  set whiteList(whiteList: boolean | undefined | null) {
    this._setValue({whiteList});
  }

  get banned() {
    return this._currentTableState.banned;
  }

  set banned(banned: boolean | undefined | null) {
    this._setValue({banned});
  }

  get platform() {
    return this._currentTableState.platform;
  }

  set platform(platform: string | undefined) {
    this._setValue({platform});
  }

  get availableCredits() {
    return this._currentTableState.availableCredits;
  }

  set availableCredits(availableCredits: string[] | undefined) {
    this._setValue({availableCredits});
  }


  get profileComplete() {
    return this._currentTableState.profileComplete;
  }

  set profileComplete(profileComplete: boolean | undefined) {
    this._setValue({profileComplete});
  }

  get startIndex() {
    return this._currentTableState.startIndex;
  }

  set startIndex(startIndex: number) {
    this._setValue({startIndex});
  }

  get endIndex() {
    return this._currentTableState.endIndex;
  }

  set endIndex(endIndex: number) {
    this._setValue({endIndex});
  }

  get totalRecords() {
    return this._currentTableState.totalRecords;
  }

  set totalRecords(totalRecords: number) {
    this._setValue({totalRecords});
  }

  public createBan(data: any): void {
    this._ban$.next(data);
  }

  public delete(data: any): void {
    this._delete$.next(data);
  }

  public removeWorkingSubscription(): void {
    this._working$.next(false);
    this.workingSubscription?.unsubscribe();
  }

  public addRemoveToWhiteList(user: any): void {
    this._work$.next(user);
  }

  public initAddToWhiteListListener(): void {
    this.workingSubscription = this._work$.pipe(
      tap(() => this._working$.next(true)),
      tap(() => this._loading$.next(true)),
      switchMap((user: any[]) => this.usersApiService.addToWhiteList(user).pipe(
        map((result) => {
          return this.modalSuccess(result, EventEnum.CLOSE_ADD_REMOVE_WHITELIST_MODAL, 'User added to whitelist successfully');
        }),
        catchError((err, caught) => {
          return this.modalError(err, EventEnum.CLOSE_ADD_REMOVE_WHITELIST_MODAL);
        })
      )),
      tap(() => this._working$.next(false))
    ).subscribe(() => {
    });
  }

  public initRemoveFromWhiteListListener(): void {
    this.workingSubscription = this._work$.pipe(
      tap(() => this._working$.next(true)),
      tap(() => this._loading$.next(true)),
      switchMap((user: any[]) => this.usersApiService.removeFromWhiteList(user).pipe(
        map((result) => {
          return this.modalSuccess(result, EventEnum.CLOSE_ADD_REMOVE_WHITELIST_MODAL, 'User removed from whitelist successfully');
        }),
        catchError((err, caught) => {
          return this.modalError(err, EventEnum.CLOSE_ADD_REMOVE_WHITELIST_MODAL);
        })
      )),
      tap(() => this._working$.next(false))
    ).subscribe(() => {
    });
  }

  public initDeleteListener(): void {
    this._delete$.pipe(
      tap(() => this._deleting$.next(true)),
      tap(() => this._loading$.next(true)),
      switchMap((banData: any) => this.usersApiService.deleteUsers(banData).pipe(
        map((result) => {
          return this.modalSuccess(result, EventEnum.CLOSE_DELETE_MODAL, 'User deleted successfully');
        }),
        catchError((err, caught) => {
          return this.modalError(err, EventEnum.CLOSE_DELETE_MODAL);
        })
      )),
      tap(() => this._deleting$.next(false))
    ).subscribe(() => {
    });
  }

  public initBanListener(): void {
    this._ban$.pipe(
      tap(() => this._banning$.next(true)),
      tap(() => this._loading$.next(true)),
      switchMap((banData) => this.usersApiService.banUsers(banData).pipe(
        map((result) => {
          if (result?.length > 0) {
            result.map((error) => {
              this.toastService.show(error?.cause, {classname: 'bg-danger text-light'});
              return error;
            });
            this.eventService.broadcast(EventEnum.CLOSE_BAN_MODAL, null)
            this._search$.next();
            return result;
          } else {
            return this.modalSuccess(result, EventEnum.CLOSE_BAN_MODAL, 'User banned successfully');
          }
        }),
        catchError((err, caught) => {
          return this.modalError(err, EventEnum.CLOSE_BAN_MODAL);
        })
      )),
      tap(() => this._banning$.next(false))
    ).subscribe(() => {
    });
  }

  public removeSearchUsersSubscribe(): void {
    this.searchUsersSubscription?.unsubscribe();
    this._loading$.next(false);
  }

  public initSearchListener(): void {
    this.searchUsersSubscription = this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(50),
      switchMap(() => this.usersApiService.getUsersList(this._extractSearchParams())),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      this._userList$.next(result.data);
      this._totalRecords$.next(result.size);
    });
  }

  public _setValue(patch: Partial<TableStateInterface>) {
    Object.assign(this._currentTableState, patch);
    this._search$.next();
  }

  public isFilterApplied(): boolean {
    const params: any = this._extractSearchParams();
    if (Object.keys(params?.filters)?.length > 0) {
      const obj = this.utils.clearObject(params?.filters);
      if (Object.keys(obj)?.length === 1 && Object.keys(obj)[0] === 'whiteList') {
        return false;
      } else {
        return Object.keys(obj)?.length > 0;
      }
    } else {
      return false;
    }
  }

  public clearFilters(): void {
    this.platform = undefined;
    this.company = undefined;
    this.banned = undefined;
    this.profileComplete = undefined;
    this.lastSync = undefined;
    this.lastAppAccessTs = undefined;
    this.registrationDate = undefined;
    this.searchTerm = undefined;
    this.whiteList = undefined;
  }

  public userDetailArray(ids: number[]): any {
    const observables: Observable<any[]>[] = ids.map(id => this.usersApiService.getUserDetail(id));
    return forkJoin(
      observables
    ).pipe(
      map(users => this.utils.flat(users))
    );
  }


  private modalError(err, modalEvent: EventEnum) {
    this.eventService.broadcast(modalEvent, null)
    this.toastService.show(err, {classname: 'bg-danger text-light'});
    this._search$.next();
    return err;
  }

  private modalSuccess(result, modalEvent: EventEnum, message: string) {
    this.toastService.show(message, {classname: 'bg-success text-light'});
    this.eventService.broadcast(modalEvent, null)
    this._search$.next();
    return result;
  }

  private _extractSearchParams(): any {
    return {
      filters: {
        query: this.searchTerm ? [this.searchTerm] : undefined,
        registeredAt: this.registrationDate && this.registrationDate?.length > 0 ? this.registrationDate : undefined,
        lastSync: this.lastSync && this.lastSync?.length > 0 ? this.lastSync : undefined,
        lastAppAccessTs: this.lastAppAccessTs && this.lastAppAccessTs?.length > 0 ? this.lastAppAccessTs : undefined,
        availableCredits: this.availableCredits && this.availableCredits?.length > 0 ? this.availableCredits : undefined,
        company: this.company ? [this.company] : undefined,
        lastPlatform: this.platform && (this.platform !== 'undefined') ? [this.platform] : undefined,
        profileComplete: typeof this.profileComplete === 'boolean' ? [this.profileComplete] : undefined,
        banned: (this.banned !== undefined && this.banned !== null) ? [this.banned] : undefined,
        whiteList: (this.whiteList !== undefined && this.whiteList !== null) ? [this.whiteList] : undefined,

      },
      sort: this.extractSorting(),
      page: this.page,
      size: this.pageSize
    }
  }

  private extractSorting(): string {
    return this.utils.extractSorting(this.sortColumn, this.sortDirection);
  }
}


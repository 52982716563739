import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Subscription} from "rxjs";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {EventService} from "@service/common/event.service";
import {BlacklistSourcesService} from "@service/utility/blacklist-sources.service";
import {EventEnum} from "@enum/event/event.enum";
import {DeleteModalTypeEnum} from "@enum/delete-modal-type/delete-modal-type.enum";

@Component({
  selector: 'app-user-uses-modal',
  templateUrl: './user-uses-modal.component.html',
  styleUrls: ['./user-uses-modal.component.scss']
})
export class UserUsesModalComponent implements OnInit {
  @ViewChild('modal') private modalContent: TemplateRef<any>
  @Input() source: any;
  public banned: boolean = false;
  private modalRef: NgbModalRef;
  public deleteModalTypeEnum = DeleteModalTypeEnum;
  private closeModalSubscription: Subscription;
  public detail: any = {
    loading: false,
    noResults: false,
    users: []
  }

  constructor(private modalService: NgbModal, private eventService: EventService,
              private blacklistSourceService: BlacklistSourcesService) { }

  ngOnInit(): void {
    this._closeModal();
  }

  public open(source: any, banned?: boolean): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, {size: 'lg', centered: true, backdrop: false})
      this.modalRef.result.then(resolve, resolve)
      this.source = source;
      this.banned = banned;
      if (this.banned) {
        this.loadBannedUsers();
      } else {
        this.loadUsers();
      }
    })
  }

  private loadUsers(): void {
    this.resetDetail();
    this.detail.loading = true;
    this.blacklistSourceService.sourceDetail(this.source).subscribe((result) => {
      this.setUsers(result);
    });
  }

  private setUsers(result) {
    this.detail.users = Array.isArray(result) ? result : undefined;
    this.detail.loading = false;
    this.detail.noResults = this.detail?.users?.length === 0 || !this.detail?.users;
  }

  private loadBannedUsers(): void {
    this.resetDetail();
    this.detail.loading = true;
    this.blacklistSourceService.automaticBannedUsers(this.source).subscribe((result) => {
      this.setUsers(result);
    });
  }

  private resetDetail(): void {
   this.detail = {
      loading: false,
      noResults: false,
      users: []
    }
  }

  private _closeModal(): void {
    this.closeModalSubscription = this.eventService.subscribe(EventEnum.CLOSE_DETAIL_BLACKLIST_SOURCE_MODAL, (reason: string | undefined) => {
      this.resetDetail();
      this.source = undefined;
      this.modalRef?.dismiss(reason);
    });
  }
}

import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {endpoints} from "@endpoint/endpoint-list";
import {HttpService} from "@service/http/http.service";
import {EndpointParamEnum} from "@enum/endpoint-param.enum";

@Injectable({
  providedIn: 'root'
})
export class CompaniesApiService {

  constructor(private http: HttpService) {
  }

  public getCompanyList(filters: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.companies.list, params);
  }

  public getUserCompanies(filters: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.companies.memberships, params);
  }

  public getCompanyData(filters: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: filters?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.companies.detail, placeholders);
    return this.http.get(url, null);
  }

  public getCompanyInviteCodes(filters: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.companies.codes, params);
  }

  public addCompanyCode(params: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: params?.companyId}];
    const url = this.http.findAndReplacePlaceholders(endpoints.companies.addCodes, placeholders);
    return this.http.post(url, params);
  }

  public createEditCompany(params: any): Observable<any> {
    if (params?.id) {
      const placeholders = [{placeholder: EndpointParamEnum.ID, value: params?.id}];
      const url = this.http.findAndReplacePlaceholders(endpoints.companies.edit, placeholders);
      params.id = null;
      return this.http.put(url, params);
    } else {
      return this.http.post(endpoints.companies.create, params);
    }
  }

  public deleteCompany(faqs: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: faqs[0]?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.companies.delete, placeholders);
    return this.http.delete(url, faqs);
  }
}

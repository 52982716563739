import {Injectable} from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor() {
  }


  public deleteUser(users: any[]): void {
    const label = users?.length > 1 ? [users?.length, 'users'].join(' ') : users[0]?.fullName;
    Swal.fire({
      title: 'Delete ' + label + '?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor: '#54BD4C',
      cancelButtonColor: '#D51E58',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value) {
        Swal.fire({
          icon: 'success',
          title: 'Deleted',
          text: label + ' has been deleted',
          confirmButtonColor: '#54BD4C',
          showCancelButton: false,
        });
      }
    });
  }
}

<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between pb-2">
          <h4 class="card-title card-header card-header-section"><i
            class="bx bx-building-house me-2"></i> {{'UTILS.COMMON.FITNESS_METRICS' | translate}}</h4>
        </div>
      </div>
    </div>
    <div class="row mb-4 mt-2">
      <div class="col-lg-6">
        <div class="toolbar button-items text-start">
          <button (click)="updateOptions( periodOfInterestEnum.DAY )"
                  [class.active]="activeOptionButton == periodOfInterestEnum.DAY" class="btn btn-light btn-sm active"
                  ngbTooltip="Day of selected date"
                  placement="bottom"
                  type="button">
            DAY
          </button>
          <button (click)="updateOptions( periodOfInterestEnum.WEEK )"
                  [class.active]="activeOptionButton == periodOfInterestEnum.WEEK" class="btn btn-light btn-sm"
                  ngbTooltip="Week of selected date"
                  placement="bottom"
                  type="button">
            WEEK
          </button>
          <button (click)="updateOptions(  periodOfInterestEnum.MONTH )"
                  [class.active]="activeOptionButton == periodOfInterestEnum.MONTH" class="btn btn-light btn-sm"
                  ngbTooltip="Month of selected date"
                  placement="bottom"
                  type="button">
            MONTH
          </button>
        </div>
      </div>
      <div class="col-lg-3 ms-auto">
        <app-simple-datepicker (dateSelected)="userFitnessMetrics.aroundInstant = $event"></app-simple-datepicker>
      </div>
    </div>
    <div *ngIf="metrics$ | async as metrics" class="row">
      <div *ngFor="let metric of metrics;" class="col-lg-4">
        <app-data-card [id]="userId" [image]="metric?.metricImage" [number]="metric?.confirmedAmount"
                       [stats]="true" [title]="metric?.confirmedDescription"
                       [unitOfMeasure]="metric?.unitOfMeasure"></app-data-card>
      </div>
    </div>
    <!--    <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav-tabs nav-tabs-custom">-->
    <!--      <li [ngbNavItem]="1">-->
    <!--        <a ngbNavLink>Steps</a>-->
    <!--        <ng-template ngbNavContent>-->
    <!--          <div class="ms-auto">-->
    <!--            <div class="toolbar button-items text-end">-->
    <!--              <button type="button" class="btn btn-light btn-sm" (click)="updateOptions( 'all' )"-->
    <!--                      [class.active]="activeOptionButton=='all'">-->
    <!--                ALL-->
    <!--              </button>-->
    <!--              <button type="button" class="btn btn-light btn-sm" (click)="updateOptions( '1m' )"-->
    <!--                      [class.active]="activeOptionButton=='1m'">-->
    <!--                1M-->
    <!--              </button>-->
    <!--              <button type="button" class="btn btn-light btn-sm" (click)="updateOptions( '6m' )"-->
    <!--                      [class.active]="activeOptionButton=='6m'">-->
    <!--                6M-->
    <!--              </button>-->
    <!--              <button type="button" class="btn btn-light btn-sm active"-->
    <!--                      (click)="updateOptions( '1y' )" [class.active]="activeOptionButton=='1y'">-->
    <!--                1Y-->
    <!--              </button>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="row text-center">-->
    <!--            <div class="col-lg-4">-->
    <!--              <div class="mt-4">-->
    <!--                <p class="text-muted mb-1">Today</p>-->
    <!--                <h5>1024</h5>-->
    <!--              </div>-->
    <!--            </div>-->

    <!--            <div class="col-lg-4">-->
    <!--              <div class="mt-4">-->
    <!--                <p class="text-muted mb-1">This week</p>-->
    <!--                <h5>12356</h5>-->
    <!--              </div>-->
    <!--            </div>-->

    <!--            <div class="col-lg-4">-->
    <!--              <div class="mt-4">-->
    <!--                <p class="text-muted mb-1">This month</p>-->
    <!--                <h5>102354</h5>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <hr class="mb-4">-->
    <!--          <apx-chart dir="ltr" #chart class="apex-charts" [chart]="stepsChart.chart"-->
    <!--                     [series]="stepsChart.series" [legend]="stepsChart.legend"-->
    <!--                     [colors]="stepsChart.colors" [fill]="stepsChart.fill"-->
    <!--                     [dataLabels]="stepsChart.dataLabels" [xaxis]="stepsChart.xaxis"-->
    <!--                     [yaxis]="stepsChart.yaxis"-->
    <!--                     [plotOptions]="stepsChart.plotOptions"-->
    <!--                     [stroke]="stepsChart.stroke">-->
    <!--          </apx-chart>-->
    <!--        </ng-template>-->
    <!--      </li>-->
    <!--      <li [ngbNavItem]="2">-->
    <!--        <a ngbNavLink>Sport</a>-->
    <!--        <ng-template ngbNavContent>-->
    <!--          <div class="ms-auto">-->
    <!--            <div class="toolbar button-items text-end">-->
    <!--              <button type="button" class="btn btn-light btn-sm" (click)="updateOptions( 'all' )"-->
    <!--                      [class.active]="activeOptionButton=='all'">-->
    <!--                ALL-->
    <!--              </button>-->
    <!--              <button type="button" class="btn btn-light btn-sm" (click)="updateOptions( '1m' )"-->
    <!--                      [class.active]="activeOptionButton=='1m'">-->
    <!--                1M-->
    <!--              </button>-->
    <!--              <button type="button" class="btn btn-light btn-sm" (click)="updateOptions( '6m' )"-->
    <!--                      [class.active]="activeOptionButton=='6m'">-->
    <!--                6M-->
    <!--              </button>-->
    <!--              <button type="button" class="btn btn-light btn-sm active"-->
    <!--                      (click)="updateOptions( '1y' )" [class.active]="activeOptionButton=='1y'">-->
    <!--                1Y-->
    <!--              </button>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="row text-center">-->
    <!--            <div class="col-lg-4">-->
    <!--              <div class="mt-4">-->
    <!--                <p class="text-muted mb-1">Today</p>-->
    <!--                <h5>1024</h5>-->
    <!--              </div>-->
    <!--            </div>-->

    <!--            <div class="col-lg-4">-->
    <!--              <div class="mt-4">-->
    <!--                <p class="text-muted mb-1">This week</p>-->
    <!--                <h5>12356</h5>-->
    <!--              </div>-->
    <!--            </div>-->

    <!--            <div class="col-lg-4">-->
    <!--              <div class="mt-4">-->
    <!--                <p class="text-muted mb-1">This month</p>-->
    <!--                <h5>102354</h5>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <hr class="mb-4">-->
    <!--          <apx-chart dir="ltr" #chart class="apex-charts" [chart]="sportsChart.chart"-->
    <!--                     [series]="sportsChart.series" [legend]="sportsChart.legend"-->
    <!--                     [colors]="sportsChart.colors" [fill]="sportsChart.fill"-->
    <!--                     [dataLabels]="sportsChart.dataLabels" [xaxis]="sportsChart.xaxis"-->
    <!--                     [yaxis]="sportsChart.yaxis"-->
    <!--                     [plotOptions]="sportsChart.plotOptions"-->
    <!--                     [stroke]="sportsChart.stroke">-->
    <!--          </apx-chart>-->
    <!--        </ng-template>-->
    <!--      </li>-->
    <!--      <li [ngbNavItem]="3">-->
    <!--        <a ngbNavLink>Meditation</a>-->
    <!--        <ng-template ngbNavContent>-->
    <!--          <div class="ms-auto">-->
    <!--            <div class="toolbar button-items text-end">-->
    <!--              <button type="button" class="btn btn-light btn-sm" (click)="updateOptions( 'all' )"-->
    <!--                      [class.active]="activeOptionButton=='all'">-->
    <!--                ALL-->
    <!--              </button>-->
    <!--              <button type="button" class="btn btn-light btn-sm" (click)="updateOptions( '1m' )"-->
    <!--                      [class.active]="activeOptionButton=='1m'">-->
    <!--                1M-->
    <!--              </button>-->
    <!--              <button type="button" class="btn btn-light btn-sm" (click)="updateOptions( '6m' )"-->
    <!--                      [class.active]="activeOptionButton=='6m'">-->
    <!--                6M-->
    <!--              </button>-->
    <!--              <button type="button" class="btn btn-light btn-sm active"-->
    <!--                      (click)="updateOptions( '1y' )" [class.active]="activeOptionButton=='1y'">-->
    <!--                1Y-->
    <!--              </button>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="row text-center">-->
    <!--            <div class="col-lg-4">-->
    <!--              <div class="mt-4">-->
    <!--                <p class="text-muted mb-1">Today</p>-->
    <!--                <h5>1024</h5>-->
    <!--              </div>-->
    <!--            </div>-->

    <!--            <div class="col-lg-4">-->
    <!--              <div class="mt-4">-->
    <!--                <p class="text-muted mb-1">This week</p>-->
    <!--                <h5>12356</h5>-->
    <!--              </div>-->
    <!--            </div>-->

    <!--            <div class="col-lg-4">-->
    <!--              <div class="mt-4">-->
    <!--                <p class="text-muted mb-1">This month</p>-->
    <!--                <h5>102354</h5>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <hr class="mb-4">-->
    <!--          <apx-chart dir="ltr" #chart class="apex-charts" [chart]="meditationChart.chart"-->
    <!--                     [series]="meditationChart.series" [legend]="meditationChart.legend"-->
    <!--                     [colors]="meditationChart.colors" [fill]="meditationChart.fill"-->
    <!--                     [dataLabels]="meditationChart.dataLabels" [xaxis]="meditationChart.xaxis"-->
    <!--                     [yaxis]="meditationChart.yaxis"-->
    <!--                     [plotOptions]="meditationChart.plotOptions"-->
    <!--                     [stroke]="meditationChart.stroke">-->
    <!--          </apx-chart>-->
    <!--        </ng-template>-->
    <!--      </li>-->
    <!--      <li [ngbNavItem]="4">-->
    <!--        <a ngbNavLink>Sleep</a>-->
    <!--        <ng-template ngbNavContent>-->
    <!--          <div class="ms-auto">-->
    <!--            <div class="toolbar button-items text-end">-->
    <!--              <button type="button" class="btn btn-light btn-sm" (click)="updateOptions( 'all' )"-->
    <!--                      [class.active]="activeOptionButton=='all'">-->
    <!--                ALL-->
    <!--              </button>-->
    <!--              <button type="button" class="btn btn-light btn-sm" (click)="updateOptions( '1m' )"-->
    <!--                      [class.active]="activeOptionButton=='1m'">-->
    <!--                1M-->
    <!--              </button>-->
    <!--              <button type="button" class="btn btn-light btn-sm" (click)="updateOptions( '6m' )"-->
    <!--                      [class.active]="activeOptionButton=='6m'">-->
    <!--                6M-->
    <!--              </button>-->
    <!--              <button type="button" class="btn btn-light btn-sm active"-->
    <!--                      (click)="updateOptions( '1y' )" [class.active]="activeOptionButton=='1y'">-->
    <!--                1Y-->
    <!--              </button>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="row text-center">-->
    <!--            <div class="col-lg-4">-->
    <!--              <div class="mt-4">-->
    <!--                <p class="text-muted mb-1">Today</p>-->
    <!--                <h5>1024</h5>-->
    <!--              </div>-->
    <!--            </div>-->

    <!--            <div class="col-lg-4">-->
    <!--              <div class="mt-4">-->
    <!--                <p class="text-muted mb-1">This week</p>-->
    <!--                <h5>12356</h5>-->
    <!--              </div>-->
    <!--            </div>-->

    <!--            <div class="col-lg-4">-->
    <!--              <div class="mt-4">-->
    <!--                <p class="text-muted mb-1">This month</p>-->
    <!--                <h5>102354</h5>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <hr class="mb-4">-->
    <!--          <apx-chart dir="ltr" #chart class="apex-charts" [chart]="sleepChart.chart"-->
    <!--                     [series]="sleepChart.series" [legend]="sleepChart.legend"-->
    <!--                     [colors]="sleepChart.colors" [fill]="sleepChart.fill"-->
    <!--                     [dataLabels]="sleepChart.dataLabels" [xaxis]="sleepChart.xaxis"-->
    <!--                     [yaxis]="sleepChart.yaxis"-->
    <!--                     [plotOptions]="sleepChart.plotOptions"-->
    <!--                     [stroke]="sleepChart.stroke">-->
    <!--          </apx-chart>-->
    <!--        </ng-template>-->
    <!--      </li>-->
    <!--      <li [ngbNavItem]="5">-->
    <!--        <a ngbNavLink>Yoga</a>-->
    <!--        <ng-template ngbNavContent>-->
    <!--          <div class="ms-auto">-->
    <!--            <div class="toolbar button-items text-end">-->
    <!--              <button type="button" class="btn btn-light btn-sm" (click)="updateOptions( 'all' )"-->
    <!--                      [class.active]="activeOptionButton=='all'">-->
    <!--                ALL-->
    <!--              </button>-->
    <!--              <button type="button" class="btn btn-light btn-sm" (click)="updateOptions( '1m' )"-->
    <!--                      [class.active]="activeOptionButton=='1m'">-->
    <!--                1M-->
    <!--              </button>-->
    <!--              <button type="button" class="btn btn-light btn-sm" (click)="updateOptions( '6m' )"-->
    <!--                      [class.active]="activeOptionButton=='6m'">-->
    <!--                6M-->
    <!--              </button>-->
    <!--              <button type="button" class="btn btn-light btn-sm active"-->
    <!--                      (click)="updateOptions( '1y' )" [class.active]="activeOptionButton=='1y'">-->
    <!--                1Y-->
    <!--              </button>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="row text-center">-->
    <!--            <div class="col-lg-4">-->
    <!--              <div class="mt-4">-->
    <!--                <p class="text-muted mb-1">Today</p>-->
    <!--                <h5>1024</h5>-->
    <!--              </div>-->
    <!--            </div>-->

    <!--            <div class="col-lg-4">-->
    <!--              <div class="mt-4">-->
    <!--                <p class="text-muted mb-1">This week</p>-->
    <!--                <h5>12356</h5>-->
    <!--              </div>-->
    <!--            </div>-->

    <!--            <div class="col-lg-4">-->
    <!--              <div class="mt-4">-->
    <!--                <p class="text-muted mb-1">This month</p>-->
    <!--                <h5>102354</h5>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <hr class="mb-4">-->
    <!--          <apx-chart dir="ltr" #chart class="apex-charts" [chart]="yogaChart.chart"-->
    <!--                     [series]="yogaChart.series" [legend]="yogaChart.legend"-->
    <!--                     [colors]="yogaChart.colors" [fill]="yogaChart.fill"-->
    <!--                     [dataLabels]="yogaChart.dataLabels" [xaxis]="yogaChart.xaxis"-->
    <!--                     [yaxis]="yogaChart.yaxis"-->
    <!--                     [plotOptions]="yogaChart.plotOptions"-->
    <!--                     [stroke]="yogaChart.stroke">-->
    <!--          </apx-chart>-->
    <!--        </ng-template>-->
    <!--      </li>-->
    <!--    </ul>-->
    <!--    <div [ngbNavOutlet]="nav" class="mt-4"></div>-->
  </div>
</div>

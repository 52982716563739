import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import MetisMenu from 'metismenujs/dist/metismenujs';
import {EventService} from '@service/common/event.service';
import {NavigationEnd, Router} from '@angular/router';
import {MenuItem} from '@interface/menu.interface';
import {TranslateService} from '@ngx-translate/core';
import {MENU_SMALL} from "./menu_small";
import {AuthenticationService} from "@service/common/auth.service";
import {Observable} from "rxjs";
import {KeycloakService} from "keycloak-angular";
import {USR_ROLES} from "../../../configs/roles/roles";
import {MENU} from "./menu";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('componentRef') scrollRef;
  @ViewChild('sideMenu') sideMenu: ElementRef;
  @Input() isCondensed = false;
  public menuItems = [];
  public menu: any;
  public data: any;
  private usrRoles = USR_ROLES;
  private userObservable: Observable<any>;

  constructor(private eventService: EventService, private router: Router, public translate: TranslateService,
              private auth: AuthenticationService, protected readonly keycloak: KeycloakService) {
    this.userObservable = this.auth.currentUser$;
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.activateMenuDropdown();
        this.scrollElement();
      }
    });
  }

  ngOnInit() {
    this.initialize();
    this.scrollElement();
  }

  ngAfterViewInit() {
    this.menu = new MetisMenu(this.sideMenu.nativeElement);
    this.activateMenuDropdown();
  }

  ngOnChanges() {
    if (!this.isCondensed && this.sideMenu || this.isCondensed) {
      setTimeout(() => {
        this.menu = new MetisMenu(this.sideMenu.nativeElement);
      });
    } else if (this.menu) {
      this.menu.dispose();
    }
  }

  public initialize(): void {
    const allowed = this.keycloak.getUserRoles().includes(this.usrRoles[1]);
    if (allowed) {
      this.menuItems = MENU;
    } else {
      this.menuItems = MENU_SMALL;
    }
  }

  public hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }

  public navigateParent(item: MenuItem) {
  }

  private subscribeLoggedUser(): void {
    // this.userObservable.subscribe((user) => {
    //   if (!this.utils.authorizedFullAccess(user)) {
    //     this.menuItems = MENU_SMALL;
    //   } else {
    //     this.menuItems = MENU;
    //   }
    // });
  }

  private scrollElement() {
    setTimeout(() => {
      if (document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition = document.getElementsByClassName("mm-active")[0]['offsetTop'];
        if (currentPosition > 500)
          if (this.scrollRef.SimpleBar !== null)
            this.scrollRef.SimpleBar.getScrollElement().scrollTop =
              currentPosition + 300;
      }
    }, 300);
  }

  private removeAllClass(className) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  private activateMenuDropdown() {
    this.removeAllClass('mm-active');
    this.removeAllClass('mm-show');
    const links = document.getElementsByClassName('side-nav-link-ref');
    let menuItemEl = null;
    const paths = [];
    for (let i = 0; i < links.length; i++) {
      paths.push(links[i]['pathname']);
    }
    var itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf('/');
      const item = window.location.pathname.substr(0, strIndex).toString();
      menuItemEl = links[paths.indexOf(item)];
    } else {
      menuItemEl = links[itemIndex];
    }
    if (menuItemEl) {
      menuItemEl.classList.add('active');
      const parentEl = menuItemEl.parentElement;
      if (parentEl) {
        parentEl.classList.add('mm-active');
        const parent2El = parentEl.parentElement.closest('ul');
        if (parent2El && parent2El.id !== 'side-menu') {
          parent2El.classList.add('mm-show');
          const parent3El = parent2El.parentElement;
          if (parent3El && parent3El.id !== 'side-menu') {
            parent3El.classList.add('mm-active');
            const childAnchor = parent3El.querySelector('.has-arrow');
            const childDropdown = parent3El.querySelector('.has-dropdown');
            if (childAnchor) {
              childAnchor.classList.add('mm-active');
            }
            if (childDropdown) {
              childDropdown.classList.add('mm-active');
            }
            const parent4El = parent3El.parentElement;
            if (parent4El && parent4El.id !== 'side-menu') {
              parent4El.classList.add('mm-show');
              const parent5El = parent4El.parentElement;
              if (parent5El && parent5El.id !== 'side-menu') {
                parent5El.classList.add('mm-active');
                const childanchor = parent5El.querySelector('.is-parent');
                if (childanchor && parent5El.id !== 'side-menu') {
                  childanchor.classList.add('mm-active');
                }
              }
            }
          }
        }
      }
    }

  }
}

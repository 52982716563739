import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {EventEnum} from "@enum/event/event.enum";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {EventService} from "@service/common/event.service";
import {Observable, Subscription} from "rxjs";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NewsService} from "@service/utility/news.service";
import {UtilsService} from "@service/utils/utils.service";
import {Options} from "ng5-slider";
import {NewsAudienceEnum} from "@enum/news/news-audience/news-audience.enum";

@Component({
  selector: 'app-add-news-modal',
  templateUrl: './add-news-modal.component.html',
  styleUrls: ['./add-news-modal.component.scss']
})
export class AddNewsModalComponent implements OnInit, OnDestroy {
  public addingNews$: Observable<boolean>;
  public newsForm: FormGroup;
  public formSubmitted: boolean;
  public showAreYouSure: boolean = false;
  public disableCompany: boolean = false;
  public edit: boolean = false;
  public news: any = undefined;
  public newsAudienceEnum = NewsAudienceEnum;
  public selectedAudience: any = NewsAudienceEnum.ALL_USERS;
  public audienceList: NewsAudienceEnum[] = [NewsAudienceEnum.ALL_USERS, NewsAudienceEnum.ALL_COMPANIES, NewsAudienceEnum.ALL_USERS_IN_SPECIFIC_COMPANIES];
  public limit: any = {
    fixedSize: true,
    ratio: '21:9',
    width: 1440,
    height: 616
  };
  public tickValueoptions: Options = {
    floor: 0,
    ceil: 100,
    step: 3
  };
  @ViewChild('modal') private modalContent: TemplateRef<any>
  private modalRef: NgbModalRef;
  private closeModalSubscription: Subscription;

  constructor(private modalService: NgbModal, private eventService: EventService,
              private newsService: NewsService, public formBuilder: FormBuilder,
              private utils: UtilsService) {
  }

  get valid() {
    this.selectAudience(this.selectedAudience);
    return this.newsForm.valid;
  }

  get form() {
    return this.newsForm.controls;
  }

  ngOnInit(): void {
    this.addingNews$ = this.newsService?.creating$;
    this._closeModal();
  }

  ngOnDestroy() {
    if (this.closeModalSubscription) {
      this.closeModalSubscription.unsubscribe();
    }
  }

  public validSubmit() {
    if (this.newsForm.valid) {
      let formData = this.newsForm.getRawValue();
      formData = this.sanitizeAudienceData(formData);
      this.newsService?.initCreateListener();
      this.newsService.createNews(formData);
    } else {
      this.formSubmitted = true;
    }
  }

  public open(news?: any, duplicate?: boolean): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, {size: 'lg', centered: true, backdrop: false})
      this.modalRef.result.then(resolve, resolve);
      this.news = news;
      this.edit = !!news && !duplicate;
      this.initForm();
    })
  }

  public setImage(image: any): void {
    this.newsForm.patchValue({
      imageUrl: image ? image?.originalUrl : undefined,
      imageName: image ? image?.name : undefined,
      imageType: image ? image?.mimeType : undefined,
    });
  }

  public checkImageError(): string | undefined {
    if (this.formSubmitted && this.form.imageUrl.errors?.required) {
      return 'This value is required';
    } else {
      return undefined;
    }
  }

  public checkSelectedImage(): object | undefined {
    if (this.news?.imageUrl) {
      return {originalUrl: this.news?.imageUrl, name: [this.news?.title.replace(/\s/g, "_"), 'image'].join('_')}
    } else {
      return undefined;
    }
  }

  public checkSelectedCompanies(): any {
    if (this.news?.company) {
      return [this.news?.company]
    } else {
      return undefined;
    }
  }

  public updateValidFlag(): void {
    this.newsForm.patchValue({
      validForAllCompanies: false,
    });
  }

  public updateCompany(): void {
    if (this.form.validForAllCompanies.value) {
      this.newsForm.patchValue({
        company: undefined,
      });
    }
  }

  public selectAudience(audience: NewsAudienceEnum): void {
    switch (audience) {
      case NewsAudienceEnum.ALL_USERS:
        this.newsForm.patchValue({
          validForAllCompanies: false,
        });
        this.newsForm.patchValue({
          company: undefined,
        });
        this.disableCompany = true;
        this.form.company.disable();
        this.form['company']?.clearValidators();
        break;
      case NewsAudienceEnum.ALL_USERS_IN_SPECIFIC_COMPANIES:
        this.disableCompany = false;
        this.form.company.enable();
        this.form['company']?.clearValidators();
        this.form['company']?.setValidators([Validators.required])
        break;
      case NewsAudienceEnum.ALL_COMPANIES:
        this.newsForm.patchValue({
          validForAllCompanies: true,
        });
        this.newsForm.patchValue({
          company: undefined,
        });
        this.disableCompany = true;
        this.form.company.disable();
        this.form['company']?.clearValidators();
        break;
    }
  }

  private initializeAudienceData(news?: any): any {
    if (this.news?.validForAllCompanies && !this.news?.company) {
      this.selectedAudience = NewsAudienceEnum.ALL_COMPANIES;
    } else if (this.news?.company && !this.news?.validForAllCompanies) {
      this.selectedAudience = NewsAudienceEnum.ALL_USERS_IN_SPECIFIC_COMPANIES;
    } else {
      this.selectedAudience = NewsAudienceEnum.ALL_USERS;
    }
    this.selectAudience(this.selectedAudience);
  }

  private sanitizeAudienceData(formData: any): any {
    switch (this.selectedAudience) {
      case NewsAudienceEnum.ALL_USERS:
        formData.company = undefined;
        formData.validForAllCompanies = false;
        break;
      case NewsAudienceEnum.ALL_COMPANIES:
        formData.company = undefined;
        formData.validForAllCompanies = true;
        break
      case NewsAudienceEnum.ALL_USERS_IN_SPECIFIC_COMPANIES:
        formData.company = formData?.company?.id;
        formData.validForAllCompanies = false;
        break;
    }
    return formData;
  }

  private initForm() {
    this.newsForm = this.formBuilder.group({
      id: [this.edit && this.news?.id ? this.news?.id : undefined],
      company: [this.news?.company],
      title: [this.news?.title, [Validators.required]],
      audience: [this.news?.audience],
      active: [this.news?.active ? this.news?.active : false],
      validForAllCompanies: [this.news?.validForAllCompanies ? this.news?.validForAllCompanies : false],
      priority: [this?.news?.priority ? this?.news?.priority : 1],
      linkUrl: [this.news?.linkUrl ? this.news?.linkUrl : null, [Validators.required, Validators.pattern(this.utils.validateUrlRegex)]],
      imageUrl: [this.news?.imageUrl ? this.news?.imageUrl : null, [Validators.required]],
      imageName: [this.news?.imageName ? this.news?.imageName : null],
      imageType: [this.news?.imageType ? this.news?.imageType : null],
    });
    this.initializeAudienceData(this.news);
  }

  private _closeModal(): void {
    this.closeModalSubscription = this.eventService.subscribe(EventEnum.CLOSE_ADD_NEWS_MODAL, (reason: string | undefined) => {
      this.formSubmitted = false;
      this.showAreYouSure = false;
      this.edit = false;
      this.newsService.removeCreateSubscribe();
      this.modalRef?.dismiss(reason);
    });
  }

}

import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-prizes-table',
  templateUrl: './prizes-table.component.html',
  styleUrls: ['./prizes-table.component.scss']
})
export class PrizesTableComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}

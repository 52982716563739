import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {CompaniesService} from "@service/companies/companies.service";
import {Observable, Subscription} from "rxjs";
import {NgSelectComponent} from "@ng-select/ng-select";
import {AudienceEnum} from "@enum/audience/audience.enum";
import {FormGroup} from "@angular/forms";
import {UtilsService} from "@service/utils/utils.service";

@Component({
  selector: 'app-select-company',
  templateUrl: './select-company.component.html',
  styleUrls: ['./select-company.component.scss']
})
export class SelectCompanyComponent implements OnInit, OnDestroy {
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  @Output() selectedCompany = new EventEmitter();
  @Input() newsOptions: boolean;
  @Input() selectedAudience: any;
  @Input() pageFilter: boolean;
  @Input() single: boolean;
  @Input() disabled: boolean;
  @Input() basicSelect: boolean;
  @Input() selectedItems: any;
  @Input() users: any;
  @Input() editDetail: any;
  @Input() error: boolean;
  @Input() hideDescription: boolean;
  @Input() formSubmitted: boolean;
  @Input() parentForm: FormGroup;
  public selected: any;
  public filtered: any;
  public list$: Observable<any[]>;
  public subscription: Subscription;
  public JSON = JSON;
  constructor(private companiesService: CompaniesService, private utils: UtilsService) {
  }

  get form() {
    return this.parentForm.controls;
  }

  ngOnInit(): void {
    if (this.parentForm && this.parentForm?.controls['actionDetail']?.disabled) {
      this.parentForm.controls['actionDetail'].enable();
    }
    this.companiesService.initSearchCompaniesListener();
    this.companiesService.pageSize = 1000;
    this.list$ = this.companiesService.companyList$;
    this.subscription = this.list$.subscribe((data: any) => {
      if (data?.length > 0 && this.selectedItems?.length > 0) {
        const result = this.utils.extractSelectedItems(data, this.selectedItems);
        if (result && result?.length > 0) {
          this.selectedItems = Array.from(new Set(result.map(a => a.id))).map(id => {
            return result.find(a => a.id === id)
          });
          if (this.selectedItems && this.selectedItems?.length > 0) {
            this.selected = this.selectedItems[0];
            this.selectCompanies(this.selectedItems[0]);
          }
        }
      }
    });
    if (this.single && this.users && this.users?.audience === AudienceEnum.ALL_USERS_IN_COMPANY && this.users?.data?.companies?.length > 0) {
      this.parentForm.patchValue({['actionDetail']: this.users?.data?.companies[0]});
      this.parentForm.controls['actionDetail'].disable();
      this.filtered = this.users?.data?.companies[0]?.name;
    }
    if (this.selectedItems && this.selectedItems?.length > 0) {
      this.selected = this.selectedItems[0];
    }
    this.preselectActionForEdit();
  }

  private preselectActionForEdit() {
    if (!!this.editDetail && this.editDetail?.id && !this.selected) {
      this.selected = this.editDetail;
      this.parentForm.patchValue({['actionDetail']: this.selected});
    } else if (!!this.editDetail && !this.selected) {
      this.subscription = this.list$.subscribe((data: any) => {
        const selected = data?.find((el) => el?.id === Number(this.editDetail));
        if (selected) {
          this.selected = selected;
          this.parentForm.patchValue({['actionDetail']: this.selected});
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const audience: AudienceEnum = changes?.selectedAudience?.currentValue;
    if (changes?.selectedItems?.currentValue && changes?.selectedItems?.currentValue[0]) {
      this.selected = changes?.selectedItems?.currentValue[0];
    } else {
      this.selected = undefined;
    }
  }

  public selectCompanies(event: any): void {
    if (this.pageFilter) {
      if (this.selected) {
        this.selectedCompany.emit(this.selected);
      } else if (!this.selected) {
        this.selectedCompany.emit(null);
      }
    } else {
      if (event) {
        this.selectedCompany.emit([event]);
      } else {
        this.selectedCompany.emit(null);
      }
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.selectedItems = null;
    this.selected = null;
    this.companiesService.removeSearchCompaniesSubscribe();
  }

}

import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DeleteModalTypeEnum} from "@enum/delete-modal-type/delete-modal-type.enum";
import {Observable, Subscription} from "rxjs";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {EventService} from "@service/common/event.service";
import {EventEnum} from "@enum/event/event.enum";
import {UserElementInterface} from "@interface/user/user-element.interface";

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit, OnDestroy {
  @Input() service: any;
  @Input() type: DeleteModalTypeEnum;
  public showAreYouSure: boolean = false;
  public deleting$: Observable<boolean>;
  public title: string | undefined = undefined;
  public titleParams: string | undefined = undefined;
  public description: string | undefined = undefined;
  public deleteModalType = DeleteModalTypeEnum;
  public data: any = undefined;
  @ViewChild('modal') private modalContent: TemplateRef<any>
  private closeModalSubscription: Subscription;
  private removeUserSubscription: Subscription;
  private modalRef: NgbModalRef;

  constructor(private modalService: NgbModal, private eventService: EventService) {
  }

  ngOnInit(): void {
    this._closeModal();
    if (this.type === DeleteModalTypeEnum.USER) {
      this._removeUserFromDeleteList();
    }
  }

  public open(data?: any, title?: string, description?: string, titleParams?: string): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      if (this.type === DeleteModalTypeEnum.USER) {
        this.modalRef = this.modalService.open(this.modalContent, {size: 'lg', centered: true, backdrop: false})
      } else {
        this.modalRef = this.modalService.open(this.modalContent, {size: 'md', centered: true, backdrop: false})
      }
      if (this.returnCheckComplexServices()) {
        this.service.initDeleteListener();
      }
      this.modalRef.result.then(resolve, resolve)
      this.setModalData(data, title, description, titleParams);
    })
  }

  ngOnDestroy() {
    if (this.removeUserSubscription) {
      this.removeUserSubscription.unsubscribe();
    }
    if (this.closeModalSubscription) {
      this.closeModalSubscription.unsubscribe();
    }
  }

  public delete(): void {
    this.service.delete(this.data);
  }

  private setModalData(data: any, title: string, description: string, titleParams: string) {
    this.data = data;
    this.title = title;
    this.description = description;
    this.titleParams = titleParams;
    this.deleting$ = this.service.deleting$;
  }

  private resetModalData(): void {
    this.data = undefined;
    this.title = undefined;
    this.description = undefined;
    this.titleParams = undefined;
    this.showAreYouSure = false;
  }

  private returnCheckComplexServices(): boolean {
    return this.type === DeleteModalTypeEnum.BLACKLIST_SOURCE || this.type === DeleteModalTypeEnum.FAQ || this.type === DeleteModalTypeEnum.STREAK || this.type === DeleteModalTypeEnum.FTO;
  }

  private _closeModal(): void {
    this.closeModalSubscription = this.eventService.subscribe(EventEnum.CLOSE_DELETE_MODAL, (reason: string | undefined) => {
      this.resetModalData();
      if (this.returnCheckComplexServices()) {
        this.service?.removeDeleteSubscribe();
      }
      this.modalRef?.dismiss(reason);
    });
  }

  private _removeUserFromDeleteList(): void {
    this.removeUserSubscription = this.eventService.subscribe(EventEnum.REMOVE_USER_FROM_DELETE_LIST, (selectedUser: UserElementInterface) => {
      if (this.data?.length > 0) {
        this.data = this.data.filter((user) => user !== selectedUser);
      }
    });
  }
}

<ng-template #modal>
  <div class="modal-header border-bottom-0">
    <h5 *ngIf="!prize" class="modal-title" id="modal-title-1">{{'CREATE_PRIZE' | translate}}</h5>
    <h5 *ngIf="prize" class="modal-title" id="modal-title-2">{{'EDIT_PRIZE' | translate}}: {{prize?.title}}</h5>
    <button [disabled]="(creatingPrize$ | async)" class="btn close-modal-button" closeModal="CLOSE_CREATE_PRIZE">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
  <div class="modal-body">
    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam atque blanditiis delectus enim eveniet ipsam iure necessitatibus reiciendis sed vitae? Aliquid aspernatur beatae blanditiis consequatur ducimus eos error ex excepturi facere fuga hic minima molestias nulla officiis optio perspiciatis, quae rerum soluta tempore vero! Consectetur distinctio dolor eos hic veniam.</p>
    <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="prizeForm" autocomplete="off"
          class="needs-validation">
      <div class="row mb-3">
        <div class="col-md-3">
          <ul ngbNav #verticalNav="ngbNav" [activeId]="activeId" class="nav-pills flex-column">
            <li [ngbNavItem]="1">
              <a ngbNavLink class="mb-2">
                <span class="d-inline-block danger me-2" *ngIf="checkAlert('BASE') || checkAlert('RESTRICTIONS')">
                  <i class="mdi mdi-alert-circle-outline"></i>
                </span>
                <span class="d-inline-block">Base and presentational</span>
              </a>
              <ng-template ngbNavContent>
                <div class="row">
                  <div class="col-6">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="mb-3">
                          <label class="font-weight-bold" for="title">{{'TITLE' | translate}}</label>
                          <input [ngClass]="{'is-invalid': formSubmitted && form?.title?.errors}" class="form-control"
                                 formControlName="title" id="title" type="text">
                          <div *ngIf="formSubmitted && form?.title?.errors" class="invalid-feedback">
                            <span *ngIf="formSubmitted && form?.title?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                            <span *ngIf="form?.title?.errors?.maxlength">This value is too long. It should have 100 characters or fewer.</span>
                            <span *ngIf="form?.title?.errors?.minlength">This value is too short. It should have 5 characters or more.</span>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="row">
                      <div class="col-9">
                        <div class="mb-3">
                          <label class="font-weight-bold" for="expiresOn">{{'EXPIRATION_DATE' | translate}}</label>
                          <app-simple-datepicker [isInvalid]="formSubmitted && form?.expiresOn?.errors?.required"
                                                 [selectedDate]="form?.expiresOn?.value"
                                                 (dateSelected)="setExpirationDate($event)"></app-simple-datepicker>
                          <div *ngIf="formSubmitted && form?.expiresOn?.errors" class="invalid-feedback d-block">
                            <span *ngIf="formSubmitted && form?.expiresOn?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="mb-3">
                          <label class="font-weight-bold" for="active">Active</label>
                          <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                            <input [checked]="form?.active?.value" autocomplete="off" class="form-check-input m-0 pointer"
                                   formControlName="active"
                                   id="active" type="checkbox">
                            <label class="form-check-label font-size-11 ms-3" for="active">
                              <span *ngIf="form?.active?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                              <span *ngIf="!form?.active?.value"
                                    class="badge badge-pill badge-soft-danger font-size-11">No</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-12">
                        <div class="mb-3" [ngClass]="{'precondition-editor-invalid': formSubmitted && form.description.errors}">
                          <label class="font-weight-bold" for="title">{{'DESCRIPTION' | translate}}</label>
                          <ckeditor [editor]="Editor" formControlName="description" [config]="{ removePlugins: ['EasyImage','ImageUpload','MediaEmbed'], mediaEmbed: {} }"
                                    data="<p>Description of the prize.</p>"></ckeditor>
                          <pre>{{form.description?.errors | json}}</pre>
                          <div *ngIf="formSubmitted && form.description?.errors" class="invalid-feedback d-block">
                            <span *ngIf="formSubmitted && form.description?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                            <span *ngIf="form.description?.errors?.maxlength">This value is too long. It should have 100 characters or fewer.</span>
                            <span *ngIf="form.description?.errors?.minlength">This value is too short. It should have 5 characters or more.</span>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="row mb-3">
                          <div class="col-12"  [ngClass]="{'col-12': !cardImage}">
                            <app-image-uploader (imageUploaded)="setCardImage($event)"
                                                [showImagePreview]="true"
                                                [name]="'CARD_IMAGE'"
                                                [imageErrors]="checkCardImageError()"
                                                [limit]="cardImageLimit" [previousSelectedImage]="checkSelectedCardImage()"></app-image-uploader>

                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="row mb-3">
                          <div class="col-12"  [ngClass]="{'col-12': !detailImage}">
                            <app-image-uploader (imageUploaded)="setDetailImage($event)"
                                                [name]="'DETAIL_IMAGE'"
                                                [showImagePreview]="true"
                                                [imageErrors]="checkDetailImageError()"
                                                [limit]="detailImageLimit" [previousSelectedImage]="checkSelectedDetailImage()"></app-image-uploader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink class="mb-2">
                <span class="d-inline-block danger me-2" *ngIf="checkAlert('TYPE')">
                  <i class="mdi mdi-alert-circle-outline"></i>
                </span>
                <span class="d-inline-block">Type <span *ngIf="form?.prizeType?.value === 'code_redemption'">& Codes</span></span>
              </a>
              <ng-template ngbNavContent>
                <div class="row">
                  <div class="col-12" [ngClass]="{'col-12': form?.prizeType?.value !== 'code_redemption'}">
                    <div class="mb-3">
                      <label class="font-weight-bold" for="type">{{'PRIZE_TYPE' | translate}}</label>
                      <select (change)="checkTypeValidators($event)" class="form-select" formControlName="prizeType" id="type">
                        <option *ngFor="let prizeType of prizeTypes"  [value]="prizeType">{{prizeType | translate}}</option>
                      </select>
                      <div *ngIf="formSubmitted && form?.prizeType?.errors" class="invalid-feedback">
                        <span *ngIf="formSubmitted && form?.prizeType?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="form?.prizeType?.value === 'code_redemption'" class="col-6">
                    <div class="mb-3">
                      <label class="font-weight-bold" for="codeType">Code type</label>
                      <select class="form-select" id="codeType" (change)="checkPrizeCodes()" [(ngModel)]="selectedPrizeCodeType" [ngModelOptions]="{standalone: true}">
                        <option *ngFor="let codeType of prizeCodeType" [value]="codeType">{{codeType | translate}}</option>
                      </select>
                    </div>
                  </div>
                  <div *ngIf="form?.prizeType?.value === 'code_redemption'" class="col-6 text-right button-code">
                    <div class="mb-3">
                      <button *ngIf="!showAddCodes && selectedPrizeCodeType === 'individual'" (click)="showAddCodes = !showAddCodes" class="btn btn-primary">Add multiple individual code</button>
                      <button *ngIf="!showAddCodes && selectedPrizeCodeType === 'shared'" (click)="showAddCodes = !showAddCodes" [disabled]="prizeCodes?.length > 0"
                              class="btn btn-primary">Add single shared code</button>
                      <button *ngIf="showAddCodes" (click)="addCodes()" class="btn btn-primary">Add</button>
                      <button *ngIf="selectedItems?.length > 0" (click)="removeSelectedCodes()" class="btn btn-danger ms-2">Remove selected</button>
                    </div>
                  </div>
                  <div *ngIf="form?.prizeType?.value === 'code_redemption'" class="col-12" [hidden]="showAddCodes || prizeCodes?.length === 0">
                    <div class="codes-table mt-3 mb-3">
                      <table class="table table-bordered align-middle table-hover table-striped table-nowrap mt-0 mb-0">
                        <thead>
                        <tr>
                          <th>
                            <div (click)="selectAllResults()" class="form-check form-check-custom font-size-16 align-middle p-0">
                              <input [checked]="allCodesSelected" class="form-check-input m-0" id="userSelection01" type="checkbox">
                            </div>
                          </th>
                          <th>
                            <span class="me-3">{{'CODE' | translate}}</span>
                          </th>
                          <th>
                            <span class="me-3">{{'PRIZE_AVAILABLE_UNITS' | translate}}</span>
                          </th>
                          <th>
                            <span class="me-3">{{'CURRENT_USES' | translate}}</span>
                          </th>
                          <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let code of prizeCodes; let id=index;">
                          <td align="center" class="fit-width">
                            <div class="form-check form-check-custom font-size-16 p-0 m-0">
                              <input (click)="code.selected = !code.selected; extractSelectedItems()"
                                     [checked]="code.selected"
                                     [disabled]="code?.currentUses > 0"
                                     [value]="code.selected"
                                     class="form-check-input p-0 m-0" id="userSelection{{id}}"
                                     type="checkbox">
                            </div>
                          </td>
                          <td contenteditable="true"  (keyup)="changeValue(id, 'code', $event)" (blur)="updateList(id, 'code', $event)" align="center">
                            <span>{{code?.code}}</span>
                          </td>
                          <td contenteditable="true" inputmode="decimal"
                              (keypress)="checkNumberMaxUses($event)"
                              *ngIf="selectedPrizeCodeType === 'shared'" (keyup)="changeValue(id, 'totalUnits', $event)" (blur)="updateList(id, 'totalUnits', $event)" align="center" class="fit-width">
                            <span>{{code?.totalUnits}}</span>
                          </td>
                          <td *ngIf="selectedPrizeCodeType === 'individual'" align="center" class="fit-width">
                            <span>{{code?.totalUnits}}</span>
                          </td>
                          <td align="center" class="fit-width">
                            <span>{{code?.currentUses}}</span>
                          </td>
                          <td  class="pointer fit-width">
                            <span class="pointer danger font-size-16" [ngClass]="{'disabled-opacity': selectedItems?.length > 0}"  (click)="removeCode(code)" ngbTooltip="Delete" placement="left"><i
                              class="bx bx bx-trash"></i></span>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div *ngIf="form?.prizeType?.value === 'code_redemption'" class="col-12" [hidden]="!showAddCodes">
                    <div class="mt-3 mb-3" *ngIf="selectedPrizeCodeType === 'individual'">
                      <textarea [(ngModel)]="newPrizeCodes" [ngModelOptions]="{standalone: true}"
                                class="form-control codes-textarea"
                                placeholder="Insert all the codes separated with a space"
                                id="description"
                                rows="4"></textarea>
                    </div>
                    <div class="mt-3 mb-3" *ngIf="selectedPrizeCodeType === 'shared'">
                      <input class="form-control" placeholder="Insert one single code" [(ngModel)]="newPrizeCodes" [ngModelOptions]="{standalone: true}" id="description" type="text">
                    </div>
                  </div>

                  <div class="col-6" [ngClass]="{'col-md-4': form?.prizeType?.value === 'simple'}">
                    <div class="mb-3">
                      <label class="font-weight-bold" for="priority">Priority</label>
                      <input [ngClass]="{'is-invalid': formSubmitted && form?.priority?.errors}" class="form-control"
                             formControlName="priority" id="priority"
                             min="0"
                             placeholder="Priority" type="number">
                      <div class="invalid-feedback"  *ngIf="formSubmitted && form?.priority?.errors?.required">
                        <span>{{'VALUE_REQUIRED' | translate}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6" [ngClass]="{'col-md-4': form?.prizeType?.value === 'simple'}">
                    <div class="mb-3">
                      <label class="font-weight-bold" for="price">Price</label>
                      <input [ngClass]="{'is-invalid': formSubmitted && form?.price?.errors}" class="form-control"
                             formControlName="price" id="price"
                             min="0"
                             placeholder="Price" type="number">
                      <div class="invalid-feedback"  *ngIf="formSubmitted && form?.price?.errors?.required">
                        <span>{{'VALUE_REQUIRED' | translate}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6" [ngClass]="{'col-md-4': form?.prizeType?.value === 'simple'}" *ngIf="form?.prizeType?.value !== 'code_redemption'">
                    <div class="mb-3">
                      <label class="font-weight-bold" for="totalUnits">Total uses</label>
                      <input [ngClass]="{'is-invalid': formSubmitted && form?.totalUnits?.errors}" class="form-control"
                             formControlName="totalUnits" id="totalUnits"
                             min="0"
                             placeholder="Total uses" type="number">
                      <div class="invalid-feedback"  *ngIf="formSubmitted && form?.totalUnits?.errors?.required">
                        <span>{{'VALUE_REQUIRED' | translate}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6" *ngIf="form?.prizeType?.value === 'credits'">
                    <div class="mb-3">
                      <label class="font-weight-bold" for="creditsAmount">Credits amount</label>
                      <input [ngClass]="{'is-invalid': formSubmitted && form?.creditsAmount?.errors}" class="form-control"
                             formControlName="creditsAmount" id="creditsAmount"
                             min="0"
                             placeholder="Credits amount" type="number">
                      <div class="invalid-feedback"  *ngIf="formSubmitted && form?.creditsAmount?.errors?.required">
                        <span>{{'VALUE_REQUIRED' | translate}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4" *ngIf="form?.prizeType?.value === 'code_redemption'">
                    <div class="mb-3">
                      <label class="font-weight-bold" for="websiteActionText">Website title</label>
                      <input [ngClass]="{'is-invalid': formSubmitted && form?.websiteActionText?.errors}" class="form-control"
                             formControlName="websiteActionText" id="websiteActionText"
                             min="0" type="text">
                      <div class="invalid-feedback"  *ngIf="formSubmitted && form?.websiteActionText?.errors?.required">
                        <span>{{'VALUE_REQUIRED' | translate}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4" *ngIf="form?.prizeType?.value === 'code_redemption'">
                    <div class="mb-3">
                      <label class="font-weight-bold" for="websiteActionUrl">Website url</label>
                      <input [ngClass]="{'is-invalid': formSubmitted && form?.websiteActionUrl?.errors}" class="form-control"
                             formControlName="websiteActionUrl" id="websiteActionUrl" type="text">
                      <div *ngIf="formSubmitted && form?.websiteActionUrl?.errors" class="invalid-feedback">
                        <span *ngIf="formSubmitted && form?.websiteActionUrl?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                        <span *ngIf="formSubmitted && form?.websiteActionUrl?.errors?.pattern">Please insert a valid url.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4" *ngIf="form?.prizeType?.value === 'code_redemption'">
                    <div class="mb-3">
                      <label class="font-weight-bold" for="redemptionUrl">Redemption url</label>
                      <input [ngClass]="{'is-invalid': formSubmitted && form?.redemptionUrl?.errors}" class="form-control"
                             formControlName="redemptionUrl" id="redemptionUrl" type="text">
                      <div *ngIf="formSubmitted && form?.redemptionUrl?.errors" class="invalid-feedback">
                        <span *ngIf="formSubmitted && form?.redemptionUrl?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                        <span *ngIf="formSubmitted && form?.redemptionUrl?.errors?.pattern">Please insert a valid url.</span>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="3">
              <a ngbNavLink class="mb-2">
                <span class="d-inline-block danger me-2" *ngIf="checkAlert('RESTRICTIONS')">
                  <i class="mdi mdi-alert-circle-outline"></i>
                </span>
                <span class="d-inline-block line-height-1-2">Restrictions & Preconditions</span>
              </a>
              <ng-template ngbNavContent>
                <div class="row">
                  <div class="" [ngClass]="{'col-12': selectedPrizeRestriction === 'none', 'col-6': selectedPrizeRestriction !== 'none'}">
                    <div class="mb-3">
                      <label class="font-weight-bold" for="restriction">Restrict prize to</label>
                      <select class="form-select" (change)="checkRestrictionValidators($event)" id="restriction" [(ngModel)]="selectedPrizeRestriction" [ngModelOptions]="{standalone: true}">
                        <option *ngFor="let restriction of prizeRestriction" [value]="restriction">{{restriction | translate}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-6" *ngIf="selectedPrizeRestriction !== 'none'">
                    <app-select-company *ngIf="selectedPrizeRestriction === 'company'" [formSubmitted]="formSubmitted"
                                        [basicSelect]="true"
                                        [selectedItems]="checkSelectedCompanies()"
                                        (selectedCompany)="selectCompany($event)"
                                        [parentForm]="prizeForm" [single]="true"></app-select-company>
                    <app-select-competition *ngIf="selectedPrizeRestriction === 'competition'" [formSubmitted]="formSubmitted"
                                            [basicSelect]="true" [single]="true"
                                            [selectedItems]="checkSelectedCompetition()"
                                            (selectedCompetition)="selectCompetition($event)"
                                            [parentForm]="prizeForm"></app-select-competition>
                  </div>
                </div>
                <div class="row">
                  <label class="font-weight-bold">Preconditions</label>
                  <div [ngClass]="{'is-invalid-select': formSubmitted && form?.precondition?.errors?.required}" class="single-ng-select">
                    <ng-select
                      (change)="selectPreconditions($event)"
                      formControlName="precondition"
                      [items]="prizePreconditions"
                      [multiple]="true"
                      bindLabel="title"></ng-select>
                    <div *ngIf="formSubmitted && form?.precondition?.errors?.required" class="invalid-feedback d-block mb-0">
                      <span>{{'VALUE_REQUIRED' | translate}}</span>
                    </div>
                  </div>
                </div>
                <div class="mt-3 competition-preconditions" *ngIf="selectedPrizeRestriction === 'competition'">
                  <div class="">
                    <div class="row">
                      <span class="font-weight-bold mb-3">Competition preconditions</span>
                      <div class="col-7">
                        <div class="row">
                          <div class="col-12">
                            <div class="mb-3">
                              <label class="font-weight-bold" for="prizeContextTitle">Precondition title</label>
                              <input [ngClass]="{'is-invalid': formSubmitted && form?.prizeContextTitle?.errors}" class="form-control"
                                     formControlName="prizeContextTitle" id="prizeContextTitle" type="text">
                              <div *ngIf="formSubmitted && form?.prizeContextTitle?.errors" class="invalid-feedback">
                                <span *ngIf="formSubmitted && form?.prizeContextTitle?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="mb-3 precondition-editor">
                              <label class="font-weight-bold" for="prizeContextDescription">Precondition description</label>
                              <ckeditor [editor]="Editor" formControlName="prizeContextDescription" [config]="{ removePlugins: ['EasyImage','ImageUpload','MediaEmbed'], mediaEmbed: {} }"
                                        data="<p>Description of the prize.</p>"></ckeditor>
                              <div *ngIf="formSubmitted && form.prizeContextDescription?.errors" class="invalid-feedback">
                                <span *ngIf="formSubmitted && form.prizeContextDescription?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                                <span *ngIf="form.prizeContextDescription?.errors?.maxlength">This value is too long. It should have 100 characters or fewer.</span>
                                <span *ngIf="form.prizeContextDescription?.errors?.minlength">This value is too short. It should have 5 characters or more.</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-5">
                        <div class="row">
                          <div class="col-8">
                            <div class="mb-3">
                              <label class="font-weight-bold" for="bracket">Valid for bracket</label>
                              <select (change)="checkTypeValidators($event)" class="form-select" formControlName="bracket" id="bracket">
                                <option *ngFor="let bracket of brackets"  [value]="bracket">{{bracket | translate}}</option>
                              </select>
                              <div *ngIf="formSubmitted && form?.bracket?.errors" class="invalid-feedback">
                                <span *ngIf="formSubmitted && form?.bracket?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="mb-3">
                              <label class="font-weight-bold">Team prize</label>
                              <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                                <input [checked]="form.teamPrize.value" autocomplete="off" class="form-check-input m-0 pointer" formControlName="teamPrize"
                                       id="teamPrize" type="checkbox">
                                <label class="form-check-label font-size-11 ms-3" for="teamPrize">
                              <span *ngIf="form?.teamPrize?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                                  <span *ngIf="!form?.teamPrize?.value"
                                        class="badge badge-pill badge-soft-danger font-size-11">No</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <div class="mb-3" [ngClass]="{'d-inline-block': byLeaderboardPosition}">
                              <label class="font-weight-bold">By leaderboard</label>
                              <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                                <input [checked]="byLeaderboardPosition" [(ngModel)]="byLeaderboardPosition" autocomplete="off"
                                       [ngModelOptions]="{standalone: true}"
                                       class="form-check-input m-0 pointer"
                                       id="byLeaderboardPosition" type="checkbox">
                                <label class="form-check-label font-size-11 ms-3" for="teamPrize">
                              <span *ngIf="byLeaderboardPosition"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                                  <span *ngIf="!byLeaderboardPosition"
                                        class="badge badge-pill badge-soft-danger font-size-11">No</span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="mb-3 d-inline-block" *ngIf="byLeaderboardPosition">
                              <label class="font-weight-bold" for="fromPositionInclusive">From position</label>
                              <input [ngClass]="{'is-invalid': formSubmitted && form.fromPositionInclusive.errors}" class="form-control"
                                     formControlName="fromPositionInclusive" id="fromPositionInclusive" type="number" min="1">
                              <div *ngIf="formSubmitted && form.fromPositionInclusive.errors" class="invalid-feedback">
                                <span *ngIf="formSubmitted && form.fromPositionInclusive.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="mb-3 d-inline-block" *ngIf="byLeaderboardPosition">
                              <label class="font-weight-bold" for="toPositionInclusive">To position</label>
                              <input [ngClass]="{'is-invalid': formSubmitted && form.toPositionInclusive.errors}" class="form-control"
                                     formControlName="toPositionInclusive" id="toPositionInclusive" type="number" min="1">
                              <div *ngIf="formSubmitted && form.toPositionInclusive.errors" class="invalid-feedback">
                                <span *ngIf="formSubmitted && form.toPositionInclusive.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <div class="mb-3">
                              <label class="font-weight-bold">By metric value</label>
                              <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                                <input [checked]="byMetricValue" autocomplete="off" class="form-check-input m-0 pointer"
                                       [(ngModel)]="byMetricValue"
                                       [ngModelOptions]="{standalone: true}"
                                       id="teamPrize" type="checkbox">
                                <label class="form-check-label font-size-11 ms-3" for="teamPrize">
                              <span *ngIf="byMetricValue"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                                  <span *ngIf="!byMetricValue"
                                        class="badge badge-pill badge-soft-danger font-size-11">No</span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="mb-3 d-inline-block" *ngIf="byMetricValue">
                              <label class="font-weight-bold" for="minValue">Min value</label>
                              <input [ngClass]="{'is-invalid': formSubmitted && form.minValue.errors}" class="form-control"
                                     formControlName="minValue" id="minValue" type="number" min="1">
                              <div *ngIf="formSubmitted && form.minValue.errors" class="invalid-feedback">
                                <span *ngIf="formSubmitted && form.minValue.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
          </ul>
        </div>
        <div class="col-md-9">
          <div [ngbNavOutlet]="verticalNav"></div>
        </div>
      </div>
      <ul class="list-inline wizard mb-0">
        <li class="next list-inline-item float-end">
          <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && !prize" class="btn btn-warning">Create
          </button>
          <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && prize"  class="btn btn-warning">Edit
          </button>
          <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
          <button *ngIf="showAreYouSure" [disabled]="(creatingPrize$ | async)" class="btn btn-warning me-2" type="submit">
            <i *ngIf="creatingPrize$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            <span *ngIf="(creatingPrize$ | async) && !prize">{{'CREATING' | translate}}</span>
            <span *ngIf="(creatingPrize$ | async) && prize">{{'EDITING' | translate}}</span>
            <span [hidden]="(creatingPrize$ | async)">
            {{'YES' | translate}}
          </span>
          </button>
          <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(creatingPrize$ | async)"
                  class="btn btn-outline-light ">{{'NO' | translate}}
          </button>
        </li>
      </ul>
    </form>
  </div>
</ng-template>

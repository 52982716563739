import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {
  NgbAlertModule,
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbNavModule,
  NgbPaginationModule,
  NgbTimepickerModule,
  NgbToastModule,
  NgbTooltipModule,
  NgbTypeaheadModule
} from '@ng-bootstrap/ng-bootstrap';

import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';
import {DateRangePickerComponent} from './utils/date-range-picker/date-range-picker.component';
import {TranslateModule} from "@ngx-translate/core";
import {MissingLabelComponent} from './utils/missing-label/missing-label.component';
import {DiaryComponent} from './users/diary/diary.component';
import {LoaderComponent} from './utils/loader/loader.component';
import {TableSizeComponent} from './utils/table-size/table-size.component';
import {DirectiveModule} from "@app/directives/directive.module";
import {FitnessMetricsComponent} from './users/fitness-metrics/fitness-metrics.component';
import {NgApexchartsModule} from "ng-apexcharts";
import {DataCardComponent} from './utils/data-card/data-card.component';
import {SimpleDatepickerComponent} from './utils/simple-datepicker/simple-datepicker.component';
import {FitnessMetricsChartsComponent} from './users/fitness-metrics-charts/fitness-metrics-charts.component';
import {UserDetailCardComponent} from './users/user-detail-card/user-detail-card.component';
import {ColumnPickerComponent} from './utils/column-picker/column-picker.component';
import {NgSelectModule} from "@ng-select/ng-select";
import {ToastsComponent} from './utils/toasts/toasts.component';
import {NumberPickerComponent} from './utils/number-picker/number-picker.component';
import {BanModalComponent} from './users/ban-modal/ban-modal.component';
import {ArchwizardModule} from "angular-archwizard";
import {BanInformationComponent} from './users/ban-modal/steps/ban-information/ban-information.component';
import {BanSummaryComponent} from './users/ban-modal/steps/ban-summary/ban-summary.component';
import {UsersTableComponent} from './users/users-table/users-table.component';
import {BanMotivationModalComponent} from './users/ban-motivation-modal/ban-motivation-modal.component';
import {DeleteModalComponent} from './utils/delete-modal/delete-modal.component';
import {CoreModule} from "@app/core.module";
import {BanRemoveModalComponent} from './users/ban-remove-modal/ban-remove-modal.component';
import {SendNotificationModalComponent} from './notifications/send-notification-modal/send-notification-modal.component';
import {UsersRecapComponent} from "@component/utils/steps/users-recap/users-recap.component";
import {NotificationOptionsComponent} from './notifications/send-notification-modal/steps/notification-options/notification-options.component';
import {DropzoneModule} from "ngx-dropzone-wrapper";
import {NotificationSummaryComponent} from './notifications/send-notification-modal/steps/notification-summary/notification-summary.component';
import {RecordedActivitiesComponent} from './users/recorded-activities/recorded-activities.component';
import {TablePaginationComponent} from './utils/table-pagination/table-pagination.component';
import {TableTopbarComponent} from './utils/table-topbar/table-topbar.component';
import {ImagekitioAngularModule} from "imagekitio-angular";
import {SelectAudienceComponent} from './notifications/select-audience/select-audience.component';
import {SelectActionsComponent} from './notifications/select-actions/select-actions.component';
import {QrDetailModalComponent} from './utils/qr-detail-modal/qr-detail-modal.component';
import {QRCodeModule} from "angularx-qrcode";
import {QrCreateModalComponent} from './utils/qr-create-modal/qr-create-modal.component';
import {SelectCompanyComponent} from './companies/select-company/select-company.component';
import {SelectCompetitionComponent} from './competitions/select-competition/select-competition.component';
import {SelectChallengeComponent} from './challenges/select-challenge/select-challenge.component';
import {SelectPrizeComponent} from './prizes/select-prize/select-prize.component';
import {LinkValidatorComponent} from './utils/link-validator/link-validator.component';
import {NotificationDeliveryComponent} from './notifications/send-notification-modal/steps/notification-delivery/notification-delivery.component';
import {PrizesTableComponent} from './prizes/prizes-table/prizes-table.component';
import {ChallengesTableComponent} from './challenges/challenges-table/challenges-table.component';
import {CompetitionsTableComponent} from './competitions/competitions-table/competitions-table.component';
import {CompaniesTableComponent} from './companies/companies-table/companies-table.component';
import {NotificationAudienceComponent} from './notifications/send-notification-modal/steps/notification-audience/notification-audience.component';
import {ClipboardModule} from "ngx-clipboard";
import {AutomaticBanRuleModalComponent} from './utils/automatic-ban-rule-modal/automatic-ban-rule-modal.component';
import {AddRemoveWhitelistComponent} from './users/add-remove-whitelist/add-remove-whitelist.component';
import {AddBlacklistSourceModalComponent} from './utils/add-blacklist-source-modal/add-blacklist-source-modal.component';
import {UserUsesModalComponent} from './utils/user-uses-modal/user-uses-modal.component';
import {ImageUploaderComponent} from './utils/image-uploader/image-uploader.component';
import {UcWidgetModule} from "ngx-uploadcare-widget";
import {AddNewsModalComponent} from './news/add-news-modal/add-news-modal.component';
import {Ng5SliderModule} from "ng5-slider";
import {BanHistoryComponent} from './users/ban-history/ban-history.component';
import {DeactivateModalComponent} from './utils/deactivate-modal/deactivate-modal.component';
import {UserDiagnosticComponent} from './users/user-diagnostic/user-diagnostic.component';
import {DiagnosticTopbarComponent} from './utils/diagnostic-topbar/diagnostic-topbar.component';
import {UserFitnessChartsComponent} from './users/user-fitness-charts/user-fitness-charts.component';
import {FitnessMetricPickerComponent} from './utils/fitness-metric-picker/fitness-metric-picker.component';
import {UserChallengesComponent} from './users/user-challenges/user-challenges.component';
import {PaginationFooterComponent} from "@component/utils/pagination-footer/pagination-footer.component";
import {UserRedeemPrizesComponent} from './users/user-redeem-prizes/user-redeem-prizes.component';
import {UserUsedCodesComponent} from './users/user-used-codes/user-used-codes.component';
import {UserInvitationsSentComponent} from './users/user-invitations-sent/user-invitations-sent.component';
import {UserProfileComponent} from './users/user-profile/user-profile.component';
import {SimplebarAngularModule} from "simplebar-angular";
import {AddRemoveCreditsModalComponent} from './users/add-remove-credits-modal/add-remove-credits-modal.component';
import { AddStreakTemplateModalComponent } from './streaks/add-streak-template-modal/add-streak-template-modal.component';
import { AddStreakModalComponent } from './streaks/add-streak-modal/add-streak-modal.component';
import { AddPrizeModalComponent } from './prizes/add-prize-modal/add-prize-modal.component';
import { SelectFaqComponent } from './faqs/select-faq/select-faq.component';
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";
import {ActivateModalComponent} from "@component/utils/activate-modal/activate-modal.component";
import { AddFaqModalComponent } from './faqs/add-faq-modal/add-faq-modal.component';
import { AddCompanyModalComponent } from './companies/add-company-modal/add-company-modal.component';
import { AddCompetitionModalComponent } from './competitions/add-competition-modal/add-competition-modal.component';
import { PrizeCodesModalComponent } from './prizes/prize-codes-modal/prize-codes-modal.component';
import { CompanyCodesModalComponent } from './companies/company-codes-modal/company-codes-modal.component';
import { PrizeListComponent } from './prizes/prize-list/prize-list.component';
import { AddFtoModalComponent } from './rewards/fitness-to-credits/add-fto-modal/add-fto-modal.component';

@NgModule({
  declarations: [BreadcrumbComponent, DateRangePickerComponent,
    MissingLabelComponent, DiaryComponent, LoaderComponent,
    TableSizeComponent, FitnessMetricsComponent, DataCardComponent,
    SimpleDatepickerComponent, FitnessMetricsChartsComponent, UserDetailCardComponent,
    ColumnPickerComponent, ToastsComponent, NumberPickerComponent, BanModalComponent,
    UsersRecapComponent, BanInformationComponent, BanSummaryComponent, UsersTableComponent,
    BanMotivationModalComponent, DeleteModalComponent, BanRemoveModalComponent,
    SendNotificationModalComponent, NotificationOptionsComponent, NotificationSummaryComponent,
    RecordedActivitiesComponent, TablePaginationComponent, TableTopbarComponent,
    SelectAudienceComponent, SelectActionsComponent, QrDetailModalComponent, QrCreateModalComponent,
    SelectCompanyComponent, SelectCompetitionComponent, SelectChallengeComponent, SelectPrizeComponent,
    LinkValidatorComponent, NotificationDeliveryComponent, PrizesTableComponent, ChallengesTableComponent,
    CompetitionsTableComponent, CompaniesTableComponent, NotificationAudienceComponent, AutomaticBanRuleModalComponent,
    AddRemoveWhitelistComponent, AddBlacklistSourceModalComponent, UserUsesModalComponent, ImageUploaderComponent,
    AddNewsModalComponent, DeactivateModalComponent, ActivateModalComponent, BanHistoryComponent, UserDiagnosticComponent, DiagnosticTopbarComponent,
    UserFitnessChartsComponent, FitnessMetricPickerComponent, UserChallengesComponent, PaginationFooterComponent, UserRedeemPrizesComponent, UserUsedCodesComponent, UserInvitationsSentComponent, UserProfileComponent, AddRemoveCreditsModalComponent, AddStreakTemplateModalComponent, AddStreakModalComponent, AddPrizeModalComponent, SelectFaqComponent, AddFaqModalComponent, AddCompanyModalComponent, AddCompetitionModalComponent, PrizeCodesModalComponent, CompanyCodesModalComponent, PrizeListComponent, AddFtoModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    TranslateModule,
    NgbDropdownModule,
    NgbNavModule,
    NgbPaginationModule,
    DirectiveModule,
    NgbTooltipModule,
    NgApexchartsModule,
    ArchwizardModule,
    NgbToastModule,
    NgSelectModule,
    CoreModule,
    ReactiveFormsModule,
    DropzoneModule,
    NgbTypeaheadModule,
    ClipboardModule,
    NgbAlertModule,
    UcWidgetModule,
    CKEditorModule,
    ImagekitioAngularModule.forRoot({
      publicKey: 'public_iruF18DVvfPmTmasXn1V3VqJ7ZI=',
      urlEndpoint: 'https://ik.imagekit.io/b6tdghouzuj',
      authenticationEndpoint: 'http://localhost:3000/imageKitAuth'
    }),
    QRCodeModule,
    Ng5SliderModule,
    SimplebarAngularModule
  ],
  providers: [
    DatePipe
  ],
  exports: [BreadcrumbComponent, DateRangePickerComponent, MissingLabelComponent, DiaryComponent, LoaderComponent, TableSizeComponent,
    FitnessMetricsComponent, DataCardComponent, FitnessMetricsChartsComponent, UserDetailCardComponent, ColumnPickerComponent,
    ToastsComponent, NumberPickerComponent, BanModalComponent, BanMotivationModalComponent, DeleteModalComponent, BanRemoveModalComponent,
    SendNotificationModalComponent, UsersRecapComponent, NotificationOptionsComponent, NotificationSummaryComponent, RecordedActivitiesComponent,
    QrDetailModalComponent, QrCreateModalComponent, SelectActionsComponent, SelectAudienceComponent, SelectCompanyComponent,
    SelectCompetitionComponent, SelectChallengeComponent, NotificationDeliveryComponent, NotificationAudienceComponent,
    AutomaticBanRuleModalComponent, AddRemoveWhitelistComponent, AddBlacklistSourceModalComponent, UsersTableComponent,
    UserUsesModalComponent, AddNewsModalComponent, DeactivateModalComponent, ActivateModalComponent, BanHistoryComponent, UserDiagnosticComponent,
    UserFitnessChartsComponent, TableTopbarComponent, UserChallengesComponent, PaginationFooterComponent, UserRedeemPrizesComponent, UserUsedCodesComponent, UserInvitationsSentComponent, UserProfileComponent, AddRemoveCreditsModalComponent, AddStreakTemplateModalComponent, AddStreakModalComponent, AddPrizeModalComponent, AddFaqModalComponent, AddCompanyModalComponent, AddCompetitionModalComponent, PrizeCodesModalComponent, CompanyCodesModalComponent, AddFtoModalComponent]
})
export class ComponentsModule {
}

//TODO CREARE N MODULI PER SEZIONE COMPONENTS

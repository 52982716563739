import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {SortEventInterface} from "@interface/common/sort-event.interface";
import {ChallengesService} from "@service/challenges/challenges.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-user-challenges',
  templateUrl: './user-challenges.component.html',
  styleUrls: ['./user-challenges.component.scss']
})
export class UserChallengesComponent implements OnInit, OnDestroy {
  @Input() userId: number;
  public total$: Observable<number>;
  public loading$: Observable<boolean>;
  public challenges$: Observable<any[]>;

  constructor(public challengesService: ChallengesService, private router: Router) {
  }

  ngOnInit(): void {
    this.total$ = this.challengesService.totalRecords$;
    this.loading$ = this.challengesService.loading$;
    if (this.userId) {
      this.challengesService.clearFilters();
      this.sort({column: "createdAt", direction: "desc"});
      this.challengesService.initForUserSearchChallengesListener();
      this.challengesService.userId = this.userId;
      this.challenges$ = this.challengesService.challengeList$;
    }
  }

  public userDetail(user: any): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['users/detail', String(user?.id)])
    );
    window.open(url, '_blank');
  }

  public extractUserFullName(user: any): string {
    let name: string[] = [];
    name.push(user?.profile?.firstName);
    name.push(user?.profile?.lastName);
    name = name.filter(function (string) {
      return !!string;
    });
    return name.join(" ");
  }

  public expandChallenge(challenge: any): any {
    if (!challenge.leaderboard) {
      this.challengesService.challengeDetail(challenge.id).subscribe((result) => {
        challenge.leaderboard = result?.data ? result?.data : [];
      });
    }
    challenge.expand = !challenge.expand
  }

  public extractRowNumber(index: any): string {
    return (this.challengesService.page - 1) * this.challengesService.pageSize + index + 1;
  }

  public sort({column, direction}: SortEventInterface) {
    this.challengesService.sortColumn = column;
    this.challengesService.sortDirection = direction;
  }

  ngOnDestroy() {
    this.challengesService.clearFilters();
    this.challengesService.removeSearchChallengesSubscribe();
  }

}

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {UtilsService} from "@service/utils/utils.service";

@Component({
  selector: 'app-link-validator',
  templateUrl: './link-validator.component.html',
  styleUrls: ['./link-validator.component.scss']
})
export class LinkValidatorComponent implements OnInit, OnDestroy {
  @Input() parentForm: FormGroup;
  @Input() editDetail: any;
  @Input() formSubmitted: boolean;
  @Input() hideDescription: boolean;

  constructor(private utils: UtilsService) {
  }

  get form() {
    return this.parentForm.controls;
  }

  ngOnInit(): void {
    if (this.parentForm && this.parentForm?.controls['actionDetail']?.disabled) {
      this.parentForm.controls['actionDetail'].enable();
    }
    if (!!this.editDetail) {
      this.parentForm.patchValue({['actionDetail']: this.editDetail});
    }
  }

  ngOnDestroy() {
  }

}

<div *ngIf="parentForm" [formGroup]="parentForm" class="mb-3">
  <label class="font-weight-bold" for="actionDetail">Link</label>
  <p class="card-title-desc mb-1">
    Insert the link that the notifcation will open
  </p>
  <input [ngClass]="{'is-invalid': formSubmitted && form.actionDetail.errors}" class="form-control"
         formControlName="actionDetail" id="actionDetail" type="text">
  <div *ngIf="formSubmitted && form?.actionDetail?.errors" class="invalid-feedback">
    <span *ngIf="formSubmitted && form.actionDetail.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
    <span *ngIf="formSubmitted && form.actionDetail.errors.pattern">Please insert a valid url.</span>
  </div>
</div>

import {ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {EventService} from "@service/common/event.service";
import {EventEnum} from "@enum/event/event.enum";
import {PrizesService} from "@service/prizes/prizes.service";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {DatePipe} from "@angular/common";
import {UtilsService} from "@service/utils/utils.service";
import {ToastService} from "@service/toast.service";

@Component({
  selector: 'app-add-prize-modal',
  templateUrl: './add-prize-modal.component.html',
  styleUrls: ['./add-prize-modal.component.scss']
})
export class AddPrizeModalComponent implements OnInit, OnDestroy {
  @Input() prize: any;
  public Editor = ClassicEditor
  public showAreYouSure: boolean = false;
  public creatingPrize$: Observable<boolean>;
  public formSubmitted: boolean;
  public prizeForm: FormGroup;
  public activeId: number = 1;
  public cardImageLimit: any = {
    fixedSize: true,
    ratio: '3:4',
    width: 1440,
    height: 1920
  };
  public detailImageLimit: any = {
    fixedSize: true,
    ratio: '21:9',
    width: 1440,
    height: 616
  };
  public prizePreconditions = [];
  public prizeTypes = ['code_redemption', 'credits', 'simple'];
  public brackets = ['bronze', 'silver', 'gold', 'diamond'];
  public prizeRestriction = ['none', 'company', 'competition'];
  public prizeCodeType = ['shared', 'individual'];
  public selectedPrizeCodeType: string | undefined = 'individual';
  public selectedPrizeRestriction = 'none';
  public fitnessScoringFunction = undefined;
  public byLeaderboardPosition: boolean = false;
  public byMetricValue: boolean = false;
  public selectedPrecondition: any;
  public newPrizeCodes: string | undefined;
  public prizeCodes: any[] = [];
  public editField: string | number;
  public showAddCodes = false;
  public codesPresent = false;
  public allCodesSelected = false;
  public selectedItems: any[] = [];
  @ViewChild('modal') private modalContent: TemplateRef<any>
  private modalRef: NgbModalRef;
  private closeModalSubscription: Subscription;
  private prizeCodesSubscription: Subscription;
  private prizePreconditionsSubscription: Subscription;
  private duplicate = false;
  constructor(private modalService: NgbModal, private eventService: EventService, private ngZone: NgZone,
              public datepipe: DatePipe, private utils: UtilsService, private cdr: ChangeDetectorRef,
              private toastService: ToastService,
              public formBuilder: FormBuilder, private prizeService: PrizesService) {
  }

  get form() {
    return this.prizeForm.controls;
  }

  public get cardImage(): string | undefined {
    if (this.prize?.cardImageUrl) {
      return this.prize?.cardImageUrl;
    } else if (this.form?.cardImageUrl?.value) {
      return this.form?.cardImageUrl?.value;
    } else {
      return undefined;
    }
  }

  public get detailImage(): string | undefined {
    if (this.prize?.detailsImageUrl) {
      return this.prize?.detailsImageUrl;
    } else if (this.form?.detailsImageUrl?.value) {
      return this.form?.detailsImageUrl?.value;
    } else {
      return undefined;
    }
  }

  get valid() {
    return this.prizeForm.valid;
  }

  public removeCode(selectedCode: any): void {
    if (selectedCode?.currentUses > 0) {
      return;
    } else {
      if (this.prize?.id) {
        this.prizeService.deletePrizeCodes([selectedCode]).subscribe((result: any) => {
          this.toastService.show('Code removed successfully', {classname: 'bg-success text-light'});
          this.deleteSingleLocalCode(selectedCode);
        });
      } else {
        this.deleteSingleLocalCode(selectedCode);
      }
    }
  }

  private deleteSingleLocalCode(selectedCode: any) {
    this.prizeCodes = this.prizeCodes?.filter((code: any) => {
      return code?.id !== selectedCode?.id;
    });
    if (this.prizeCodes?.length === 0) {
      this.allCodesSelected = false;
      this.showAddCodes = true;
    }
    this.cdr.detectChanges();
    this.extractSelectedItems();
  }

  updateList(id: number, property: string, event: any) {
    this.ngZone.run(() => {
      const originalValue = JSON.parse(JSON.stringify(this.prizeCodes[id][property]))
      if (property === 'totalUnits' && !isNaN(Number(event?.target?.textContent))) {
        this.prizeCodes[id][property] = !isNaN(Number(event?.target?.textContent)) ? Number(event?.target?.textContent) : this.prizeCodes[id][property];
      } else if (property !== 'totalUnits') {
        this.prizeCodes[id][property] = event?.target?.textContent;
      }
      if (this.prize?.id && (originalValue !== event?.target?.textContent)) {
        this.prizeService.editPrizeCode(this.prizeCodes[id], this.prize?.id);
      }
      this.cdr.detectChanges();
    });
  }

  public checkNumberMaxUses(e: any) {
    if (isNaN(Number(String.fromCharCode(e.which)))) e.preventDefault();
  }

  changeValue(id: number, property: string, event: any) {
    this.ngZone.run(() => {
      if (property === 'totalUnits' && !isNaN(Number(event?.target?.textContent))) {
        this.editField = !isNaN(Number(event?.target?.textContent)) ? Number(event?.target?.textContent) : 1;
      } else if (property !== 'totalUnits') {
        this.editField = event?.target?.textContent;
      }
      this.cdr.detectChanges();
    });
  }

  ngOnInit(): void {
    this._closeModal();
    this.creatingPrize$ = this.prizeService?.creating$;
    this.initForm();
  }

  public validSubmit() {
    this.checkTypeValidators(null);
    if (this.prizeForm.valid) {
      let formData = this.prizeForm.getRawValue();
      this.prizeService?.initCreateListener();
      if (this.prize) {
        formData.id = this.prize?.id;
      } else {
        formData.prizeCodes = this.prizeCodes
      }
      this.prizeService.createPrize(JSON.parse(JSON.stringify(formData)));
    }
  }

  public createPrize() {
    this.formSubmitted = true;
  }

  public open(data?: any): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, {size: 'xl', centered: true, backdrop: false})
      this.modalRef.result.then(resolve, resolve);
      if (data?.id) {
        this.checkPrizeContext(data);
        this.duplicate = data?.duplicate;
      }
    })
  }

  public selectAllResults(): void {
    this.allCodesSelected = !this.allCodesSelected;
    this.prizeCodes?.map((code: any) => {
      if (code?.currentUses === 0) {
        code.selected = this.allCodesSelected;
      } else {
        code.selected = false;
      }
      return code;
    })
    this.extractSelectedItems();
  }

  public extractSelectedItems(): any {
    this.selectedItems = this.prizeCodes?.filter((code) => {
      return code.selected;
    });
  }

  public addCodes(): void {
    if (this.selectedPrizeCodeType === 'individual') {
      const newCodes = this.newPrizeCodes.split('\n');
      const finalArray = newCodes?.map((code: string) => {
        return {id: Math.random().toString(36).substr(2, 9), code: code, totalUnits: 1, currentUses: 0};
      })?.filter((code) => !!code?.code);
      if (this.prize?.id) {
        this.prizeService.addPrizeCodes(finalArray, this.prize?.id).subscribe((result: any) => {
          if (result?.length > 0) {
            this.toastService.show('Codes added successfully', {classname: 'bg-success text-light'});
            this.addCodesToList(result);
          } else {
            this.toastService.show('There was an error adding the codes', {classname: 'bg-danger text-light'});
          }
        });
      } else {
        this.addCodesToList(finalArray);
      }
    } else if (this.selectedPrizeCodeType === 'shared') {
      this.prizeCodes = [{
        id: Math.random().toString(36).substr(2, 9),
        code: this.newPrizeCodes,
        totalUnits: 1,
        currentUses: 0
      }];
      if (this.prize?.id) {
        this.prizeService.addPrizeCodes(this.prizeCodes, this.prize?.id).subscribe((result: any) => {
          if (result?.id) {
            this.toastService.show('Code added successfully', {classname: 'bg-success text-light'});
            this.prizeCodes = [result];
          }
        });
      }
      this.newPrizeCodes = undefined;
      this.showAddCodes = !this.showAddCodes;
    }
  }

  private addCodesToList(finalArray: { code: string; currentUses: number; id: string; totalUnits: number }[]) {
    this.prizeCodes = [...finalArray, ...this.prizeCodes];
    this.newPrizeCodes = undefined;
    this.showAddCodes = !this.showAddCodes;
  }

  public removeSelectedCodes(): void {
    if (this.prize?.id) {
      this.prizeService.deletePrizeCodes(this.selectedItems).subscribe((result: any) => {
        this.toastService.show('Codes removed successfully', {classname: 'bg-success text-light'});
        this.deleteLocalCodes();
      });
    } else {
      this.deleteLocalCodes();
    }
  }

  private deleteLocalCodes() {
    this.selectedItems?.map((selectedCode: any) => {
      this.prizeCodes = this.prizeCodes?.filter((code: any) => {
        return code?.id !== selectedCode?.id;
      });
      return selectedCode;
    });
    if (this.prizeCodes?.length === 0) {
      this.showAddCodes = true;
    }
    this.allCodesSelected = false;
    this.cdr.detectChanges();
    this.extractSelectedItems();
  }

  public checkPrizeCodes(): void {
    this.prizeCodes = [];
    this.newPrizeCodes = undefined;
    this.showAddCodes = true;
  }

  public checkTypeValidators(event: any): void {
    if (!this.form?.prizeType?.value) {
      return;
    } else {
      switch (this.form?.prizeType?.value) {
        case 'simple':
          this.clearAllPrizeTypeValidators();
          this.form["totalUnits"].addValidators([Validators.required]);
          break;
        case 'code_redemption':
          this.clearAllPrizeTypeValidators();
          this.form["websiteActionText"].addValidators([Validators.required]);
          this.form["redemptionUrl"].addValidators([Validators.required, Validators.pattern(this.utils.validateUrlRegex)]);
          this.form["websiteActionUrl"].addValidators([Validators.required, Validators.pattern(this.utils.validateUrlRegex)]);
          break;
        case 'credits':
          this.clearAllPrizeTypeValidators();
          this.form["totalUnits"].addValidators([Validators.required]);
          this.form["creditsAmount"].addValidators([Validators.required]);
          break;
      }
    }
  }

  public setExpirationDate(date: any): void {
    let convertedDate = this.datepipe.transform(date, 'dd/MM/yyyy');
    this.prizeForm.patchValue({['expiresOn']: convertedDate});
  }

  public setCardImage(image: any): void {
    this.prizeForm.patchValue({
      cardImageUrl: image ? image?.originalUrl : undefined,
      cardImageName: image ? image?.name : undefined,
    });
  }

  public setDetailImage(image: any): void {
    this.prizeForm.patchValue({
      detailsImageUrl: image ? image?.originalUrl : undefined,
      detailsImageName: image ? image?.name : undefined,
    });
  }

  public checkCardImageError(): string | undefined {
    if (this.formSubmitted && this.form.cardImageUrl.errors?.required) {
      return 'This value is required';
    } else {
      return undefined;
    }
  }

  public checkDetailImageError(): string | undefined {
    if (this.formSubmitted && this.form.detailsImageUrl.errors?.required) {
      return 'This value is required';
    } else {
      return undefined;
    }
  }

  public checkSelectedCardImage(): object | undefined {
    if (this.prize?.cardImageUrl && this.form?.cardImageUrl?.value) {
      return {
        originalUrl: this.prize?.cardImageUrl,
        name: [this.prize?.title.replace(/\s/g, "_"), 'card_image'].join('_')
      }
    } else if (this.form?.cardImageUrl?.value) {
      return {
        originalUrl: this.form?.cardImageUrl?.value,
        name: [this.prize?.title.replace(/\s/g, "_"), 'card_image'].join('_')
      }
    } else {
      return undefined;
    }
  }

  public checkSelectedDetailImage(): object | undefined {
    if (this.prize?.detailsImageUrl && this.form.detailsImageUrl?.value) {
      return {
        originalUrl: this.prize?.detailsImageUrl,
        name: [this.prize?.title.replace(/\s/g, "_"), 'detail_image'].join('_')
      }
    } else if (this.form.detailsImageUrl?.value) {
      return {
        originalUrl: this.form.detailsImageUrl?.value,
        name: [this.prize?.title.replace(/\s/g, "_"), 'card_image'].join('_')
      }
    } else {
      return undefined;
    }
  }

  public selectPreconditions(event: any): void {
    if (event) {
      this.prizeForm.patchValue({
        precondition: event?.length > 0 ? event : null,
      });
    } else {
      this.prizeForm.patchValue({
        precondition: null,
      });
    }
  }

  public selectCompany(event: any): void {
    if (event) {
      this.prizeForm.patchValue({
        company: event?.length > 0 ? event[0] : null,
      });
    } else {
      this.prizeForm.patchValue({
        company: null,
      });
    }
  }

  public selectCompetition(event: any): void {
    if (event) {
      this.prizeForm.patchValue({
        competition: event?.length > 0 ? event[0] : null,
      });
    } else {
      this.prizeForm.patchValue({
        competition: null,
      });
    }
  }

  public checkSelectedCompanies(): any {
    if (this.form?.company?.value) {
      return [this.form?.company?.value]
    } else {
      return undefined;
    }
  }

  public checkSelectedCompetition(): any {
    if (this.form?.competition?.value) {
      return [this.form?.competition?.value]
    } else {
      return undefined;
    }
  }

  public extractCodesTooltip(): string {
    if (this.form?.prizeType?.value !== 'code_redemption') {
      return 'To insert codes the prize type must be CODE REDEMPTION'
    } else {
      return '';
    }
  }

  public checkAlert(type: string): boolean {
    if (!this.formSubmitted) {
      return false;
    }
    switch (type) {
      case 'BASE':
        return !!(this.form?.title?.errors || this.form?.expiresOn?.errors || this.form?.description?.errors || this.checkCardImageError() || this.checkDetailImageError());
        break;
      case 'TYPE':
        return !!(this.form?.prizeType?.errors || this.form?.priority?.errors || this.form?.price?.errors ||
          this.form?.totalUnits?.errors || this.form?.creditsAmount?.errors || this.form?.websiteActionText?.errors || this.form?.websiteActionUrl?.errors
          || this.form?.redemptionUrl?.errors || this.form?.precondition?.errors);
        break;
      case 'RESTRICTIONS':
        if (this.selectedPrizeRestriction === 'none') {
          return false;
        } else if (this.selectedPrizeRestriction === 'company' && this.form?.company?.errors) {
          return true;
        } else if (this.selectedPrizeRestriction === 'competition' && this.form?.competition?.errors) {
          return true;
        }
        return false;
        break;
      case 'CODES':
        break;
      default:
        return false;
    }
  }

  public checkNextActionBtn(): void {
    if (this.valid) {
      this.showAreYouSure = true;
    } else {
      this.formSubmitted = true
      if (this.checkAlert('BASE') || this.checkAlert('RESTRICTIONS')) {
        this.activeId = 1;
        this.cdr.detectChanges();
      } else if (this.checkAlert('TYPE')) {
        this.activeId = 2;
        this.cdr.detectChanges();
      } else if (this.checkAlert('RESTRICTIONS')) {
        this.activeId = 3;
        this.cdr.detectChanges();
      }
    }
  }

  public checkRestrictionValidators(event: any): void {
    if (this.selectedPrizeRestriction === 'none') {
      this.form["company"].clearValidators();
      this.form["competition"].clearValidators();
    } else if (this.selectedPrizeRestriction === 'company') {
      this.form["company"].addValidators([Validators.required]);
    } else if (this.selectedPrizeRestriction === 'competition') {
      this.form["competition"].addValidators([Validators.required]);
    }
  }

  private initForm() {
    this.getPrizePreconditions();
    this.prizeForm = this.formBuilder.group({
      title: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(100)]],
      prizeType: ['code_redemption', [Validators.required]],
      precondition: [null],
      active: [false],
      priority: [null, [Validators.required]],
      expiresOn: [null, [Validators.required]],
      price: [null, [Validators.required]],
      description: [null, [Validators.required]],
      cardImageUrl: [null, [Validators.required]],
      cardImageName: [null, [Validators.required]],
      detailsImageUrl: [null, [Validators.required]],
      detailsImageName: [null, [Validators.required]],
      websiteActionText: [null],
      websiteActionUrl: [null, [Validators.pattern(this.utils.validateUrlRegex)]],
      redemptionUrl: [null, [Validators.pattern(this.utils.validateUrlRegex)]],
      totalUnits: [null],
      creditsAmount: [null],
      company: [null],
      competition: [null],
      prizeContextTitle: [null],
      bracket: [null],
      fromPositionInclusive: [null],
      toPositionInclusive: [null],
      minValue: [null],
      teamPrize: [false],
      prizeContextDescription: [null]
    });
    this.checkTypeValidators(null);
    if (this.prizeCodes?.length === 0) {
      this.showAddCodes = true;
    }
  }

  private clearAllPrizeTypeValidators() {
    this.form["websiteActionText"].clearValidators();
    this.form["websiteActionText"].updateValueAndValidity();
    this.form["websiteActionUrl"].clearValidators();
    this.form["websiteActionUrl"].updateValueAndValidity();
    this.form["redemptionUrl"].clearValidators();
    this.form["redemptionUrl"].updateValueAndValidity();
    this.form["totalUnits"].clearValidators();
    this.form["totalUnits"].updateValueAndValidity();
    this.form["creditsAmount"].clearValidators();
    this.form["creditsAmount"].updateValueAndValidity();
  }

  private getPrizePreconditions(): void {
    this.prizePreconditionsSubscription = this.prizeService.getPrizePreconditions().subscribe((result: any) => {
      this.prizePreconditions = result?.length > 0 ? result : [];
      if (this?.prize?.preconditions?.length > 0) {
        this.prizeForm.patchValue({
          precondition: this.extractCorrectPrecondition()
        });
      }
      this.prizePreconditionsSubscription?.unsubscribe();
    });
  }

  private extractPrizeCodes(): void {
    if (this.prize?.prizeType === 'code_redemption') {
      this.prizeCodesSubscription = this.prizeService.getPrizeCodes(this.prize?.id).subscribe((list: any) => {
        this.prizeCodes = list;
        if (list?.length > 0) {
          this.codesPresent = false;
          this.showAddCodes = false;
        }
        if (list?.length > 1) {
          this.selectedPrizeCodeType = 'individual';
        } else {
          this.selectedPrizeCodeType = 'shared';
        }
        this.prizeCodesSubscription?.unsubscribe();
      });
    }
  }

  private checkPrizeContext(prize: any): void {
    switch (prize?.contextType) {
      case 'COMPANY':
        this.selectedPrizeRestriction = 'company';
        this.prizeForm.patchValue({
          company: prize?.contextDetailsId
        });
        this.prizeService.getPrizeForCompany(prize?.id, prize?.contextDetailsId).subscribe((result: any) => {
          this.patchPrizeData(result);
          this.extractPrizeCodes();
        });
        break;
      case 'COMPETITION':
        this.selectedPrizeRestriction = 'competition';
        this.prizeForm.patchValue({
          competition: prize?.contextDetailsId
        });
        this.prizeService.getPrizeForCompetition(prize?.id, prize?.contextDetailsId).subscribe((result: any) => {
          this.patchPrizeData(result);
          this.extractPrizeCodes();
        });
        break;
      default:
        this.prizeService.getPrizeData(prize?.id).subscribe((result: any) => {
          this.patchPrizeData(result);
          this.extractPrizeCodes();
        });
        break;
    }
  }

  private patchPrizeData(prize: any) {
    this.prize = JSON.parse(JSON.stringify(prize));
    this.patchFormWithEditingData();
  }

  private extractCorrectPrecondition(): any {
    if (this.prizePreconditions?.length > 0 && this.prize?.preconditions?.length > 0) {
      return this.prizePreconditions.filter((precondition: any) => {
        return this.prize?.preconditions?.find(id => id === precondition?.id);
      });
    } else {
      return [];
    }
  }

  private extractTitle(): string | null {
    if (this.duplicate && this.prize?.title) {
      return [this.prize?.title, ' Duplicate'].join(' -');
    } else if (!this.duplicate && this.prize?.title) {
      return this.prize?.title;
    } else {
      return null;
    }
  }

  private patchFormWithEditingData() {
    this.prizeForm.patchValue({
      title: this.extractTitle(),
      prizeType: this.prize?.prizeType,
      precondition: this.extractCorrectPrecondition(),
      active: this.duplicate ? false : this.prize?.active,
      priority: this.prize?.priority,
      expiresOn: new Date(this.prize?.expiresAt),
      price: this.prize?.price ? this.prize?.price : 0,
      description: this.prize?.description,
      cardImageUrl: this.prize?.cardImageUrl,
      cardImageName: this.prize?.cardImageName ? this.prize?.cardImageName : [this.prize?.title.replace(/\s/g, "_"), 'card_image'].join('_'),
      detailsImageUrl: this.prize?.detailsImageUrl,
      detailsImageName: this.prize?.detailsImageName ? this.prize?.detailsImageName : [this.prize?.title.replace(/\s/g, "_"), 'detail_image'].join('_'),
      websiteActionText: this.prize?.websiteActionText,
      websiteActionUrl: this.prize?.websiteActionUrl,
      redemptionUrl: this.prize?.redemptionUrl,
      totalUnits: this.prize?.totalUnits,
      creditsAmount: this.prize?.creditsAmount,
      prizeContextTitle: this.prize?.prizeContext?.title,
      bracket: this.prize?.bracket,
      fromPositionInclusive: this.prize?.leaderboardPosition?.fromPositionInclusive,
      toPositionInclusive: this.prize?.leaderboardPosition?.toPositionInclusive,
      minValue: this.prize?.minValue,
      teamPrize: this.prize?.teamPrize,
      prizeContextDescription: this.prize?.prizeContext?.description
    });
    this.byLeaderboardPosition = !!(this.prize?.leaderboardPosition?.fromPositionInclusive && this.prize?.leaderboardPosition?.toPositionInclusive);
    this.byMetricValue = !!this.prize?.minValue;
    this.checkTypeValidators(null);
    if (this.duplicate) {
      this.prize = undefined;
    }
    this.cdr.detectChanges();
  }

  private _closeModal(): void {
    this.closeModalSubscription = this.eventService.subscribe(EventEnum.CLOSE_CREATE_PRIZE, (reason: string | undefined) => {
      this.prizeService?.removeCreatePrizesSubscribe();
      this.initForm();
      this.resetAllVariables();
      this.modalRef?.dismiss(reason);
    });
  }

  private resetAllVariables() {
    this.fitnessScoringFunction = undefined;
    this.formSubmitted = false;
    this.showAreYouSure = false;
    this.byLeaderboardPosition = false;
    this.byMetricValue = false;
    this.prize = undefined;
    this.selectedItems = undefined;
    this.selectedPrizeCodeType = 'individual';
    this.selectedPrizeRestriction = 'none';
    this.showAddCodes = false;
    this.prizePreconditions = [];
    this.prizeCodes = [];
    this.allCodesSelected = false;
    this.codesPresent = false;
    this.duplicate = false;
  }

  ngOnDestroy() {
    if (this.closeModalSubscription) {
      this.closeModalSubscription.unsubscribe();
    }
  }
}

import {Injectable} from '@angular/core';
import {TableStateInterface} from "@interface/common/table-state.interface";
import {UtilsService} from "@service/utils/utils.service";
import {BehaviorSubject, Subject} from "rxjs";
import {SortDirection} from "@type/common/sort-direction.type";
import {catchError, debounceTime, map, switchMap, tap} from "rxjs/operators";
import {UsersApiService} from "@service/users/users-api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {EventService} from "@service/common/event.service";
import {EventEnum} from "@enum/event/event.enum";
import {ToastService} from "@service/toast.service";
import {NumberPickerEnum} from "@enum/number-picker/number-picker.enum";
import {TableFilterEnum} from "@enum/table-filter/table-filter.enum";

@Injectable({
  providedIn: 'root'
})
export class BanMotivationsService {
  public _search$ = new Subject<void>();
  public _create$ = new Subject<string>();
  public _delete$ = new Subject<any>();
  public visibleFilters: { visible: boolean, key: TableFilterEnum, label: any }[] = this.utils.banMotivationFilters;
  private _currentTableState: TableStateInterface = this.utils.tableDefaultState;

  constructor(private utils: UtilsService, private usersApiService: UsersApiService, private router: Router,
              private activatedRoute: ActivatedRoute, private location: Location, private eventService: EventService,
              private toastService: ToastService) {
    this.initSearchListener();
    this.initCreateListener();
    this.initDeleteListener();
  }

  get filters() {
    return this.visibleFilters;
  }

  private _loading$ = new BehaviorSubject<boolean>(true);

  get loading$() {
    return this._loading$.asObservable();
  }

  private _creating$ = new BehaviorSubject<boolean>(false);

  get creating$() {
    return this._creating$.asObservable();
  }

  private _deleting$ = new BehaviorSubject<boolean>(false);

  get deleting$() {
    return this._deleting$.asObservable();
  }

  private _totalRecords$ = new BehaviorSubject<number>(0);

  get totalRecords$() {
    return this._totalRecords$.asObservable();
  }

  private _motivationList$ = new BehaviorSubject<any[]>([]);

  get motivationList$() {
    return this._motivationList$.asObservable();
  }

  get searchTerm() {
    return this._currentTableState.searchTerm;
  }

  set searchTerm(searchTerm: string) {
    this._setValue({searchTerm});
  }

  get sortColumn() {
    return this._currentTableState.sortColumn;
  }

  set sortColumn(sortColumn: string) {
    this._setValue({sortColumn});
  }

  get page() {
    return this._currentTableState.page;
  }

  set page(page: number) {
    this._setValue({page});
  }

  get numberOfUsers() {
    return this._currentTableState.numberOfUsers;
  }

  set numberOfUsers(numberOfUsers: string[]) {
    this._setValue({numberOfUsers});
  }

  get sortDirection() {
    return this._currentTableState.sortDirection;
  }

  set sortDirection(sortDirection: SortDirection) {
    this._setValue({sortDirection});
  }

  get pageSize() {
    return this._currentTableState.pageSize;
  }

  set pageSize(pageSize: number) {
    const page = 1;
    this._setValue({page})
    this._setValue({pageSize});
  }

  public createBanMotivation(motivation: string): void {
    this._create$.next(motivation);
  }

  public editBanMotivation(editingMotivation?: any): void {
    this._create$.next(editingMotivation);
  }

  public delete(data?: any): void {
    this._delete$.next(data);
  }

  public initCreateListener(): void {
    this._create$.pipe(
      tap(() => this._creating$.next(true)),
      tap(() => this._loading$.next(true)),
      switchMap((motivation) => this.switchEditCreateBanMotivation(motivation).pipe(
        map((result) => {
          this._creating$.next(false);
          return this.modalSuccess(result, EventEnum.CLOSE_CREATE_BAN_MOTIVATION_MODAL, 'Motivation created successfully');
        }),
        catchError((err, caught) => {
          this._creating$.next(false);
          return this.modalError(err, EventEnum.CLOSE_CREATE_BAN_MOTIVATION_MODAL);
        })
      )),
      tap(() => this._creating$.next(false))
    ).subscribe((result) => {
    });
  }

  public initDeleteListener(): void {
    this._delete$.pipe(
      tap(() => this._deleting$.next(true)),
      tap(() => this._loading$.next(true)),
      switchMap((motivation) => this.usersApiService.deleteBanMotivation(motivation).pipe(
        map((result) => {
          this._deleting$.next(false);
          return this.modalSuccess(result, EventEnum.CLOSE_DELETE_MODAL, 'Motivation deleted successfully');
        }),
        catchError((err, caught) => {
          this._deleting$.next(false);
          return this.modalError(err, EventEnum.CLOSE_DELETE_MODAL);
        })
      )),
      tap(() => this._deleting$.next(false))
    ).subscribe((result) => {
    });
  }

  public switchEditCreateBanMotivation(motivation: any): any {
    if (typeof motivation === 'string') {
      return this.usersApiService.createBanMotivation(motivation);
    } else {
      return this.usersApiService.editBanMotivation(motivation);
    }
  }

  public initSearchListener(): void {
    this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(50),
      switchMap(() => this.usersApiService.getBanMotivations(this._extractSearchParams())),
      tap(() => this._loading$.next(false))
    ).subscribe((result) => {
      let motivations = result?.map((motivation) => {
        motivation.languages = this.utils.getAvailableLang(motivation?.localizableKey);
        return motivation;
      });
      if (this.numberOfUsers && this.numberOfUsers?.length > 0) {
        motivations = motivations.filter((motivation) => {
          return this.utils.checkNumber(motivation.numberOfUses, Number(this.numberOfUsers[1]), this.numberOfUsers[0] as NumberPickerEnum);
        });
      }
      this._motivationList$.next(motivations);
      this._totalRecords$.next(motivations.length);
      var encoded = btoa(JSON.stringify(this.utils.clearObject(this._currentTableState)))
      // const url = this.router.createUrlTree([], {
      //   relativeTo: this.activatedRoute,
      //   queryParams: {find: encoded}
      // }).toString()
      // this.location.go(url);
    });
  }

  public isFilterApplied(): boolean {
    const params: any = this._extractSearchParams();
    if (Object.keys(params?.filters)?.length > 0) {
      const obj = this.utils.clearObject(params?.filters);
      return Object.keys(obj)?.length > 0;
    } else {
      return false;
    }
  }

  private modalError(err, modalEvent: EventEnum) {
    this.eventService.broadcast(modalEvent, null)
    this.toastService.show(err, {classname: 'bg-danger text-light'});
    this._search$.next();
    return err;
  }

  private modalSuccess(result, modalEvent: EventEnum, message: string) {
    this.toastService.show(message, {classname: 'bg-success text-light'});
    this.eventService.broadcast(modalEvent, null)
    this._search$.next();
    return result;
  }

  private _setValue(patch: Partial<TableStateInterface>) {
    Object.assign(this._currentTableState, patch);
    this._search$.next();
  }

  private _extractSearchParams(): any {
    return {
      filters: {
        query: this.searchTerm ? [this.searchTerm] : undefined,
        numberOfUsers: this.numberOfUsers && this.numberOfUsers?.length > 0 ? this.numberOfUsers : undefined,
      },
      sort: this.extractSorting(),
      page: this.page,
      size: this.pageSize
    }
  }

  private extractSorting(): string {
    return this.utils.extractSorting(this.sortColumn, this.sortDirection);
  }

  private deleteBanMotivation(result) {
    if (result.success) {
      this.toastService.show('Motivation deleted successfully', {classname: 'bg-secondary text-light'});
    } else {
      this.toastService.show(result, {classname: 'bg-danger text-light'});
    }
    this._search$.next();
    this.eventService.broadcast(EventEnum.CLOSE_DELETE_MODAL, null)
  }

  private banMotivationUpsert(result) {
    if (result.success) {
      this.toastService.show('Motivation created successfully', {classname: 'bg-secondary text-light'});
    } else {
      this.toastService.show(result, {classname: 'bg-danger text-light'});
    }
    this._search$.next();
    this.eventService.broadcast(EventEnum.CLOSE_CREATE_BAN_MOTIVATION_MODAL, null)
  }
}

import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {UcWidgetComponent} from "ngx-uploadcare-widget";
import {AudienceEnum} from "@enum/audience/audience.enum";

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent implements OnInit {
  @ViewChild('uploader') private uploader: UcWidgetComponent;
  @Output() imageUploaded = new EventEmitter();
  @Input() previousSelectedImage: any = undefined;
  @Input() name: any = undefined;
  @Input() showImagePreview: boolean;
  @Input() limit: any = undefined;
  public selectedImage: any = undefined;
  @Input() imageErrors: any = undefined;

  constructor() { }

  ngOnInit(): void {
    if (!!this.previousSelectedImage?.name && !!this.previousSelectedImage?.originalUrl) {
      this.selectedImage = this.previousSelectedImage;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes?.previousSelectedImage?.currentValue?.name && !!changes?.previousSelectedImage?.currentValue?.name && this.previousSelectedImage) {
      this.selectedImage = changes?.previousSelectedImage?.currentValue;
    }
  }

  public openUploader(show?: boolean): void {
    if (show && !!this.previousSelectedImage?.name && !!this.previousSelectedImage?.originalUrl) {
      window.open(this.previousSelectedImage?.originalUrl, '_blank');
    } else {
      this.imageErrors = undefined;
      this.uploader.openDialog();
    }
  }

  public clearImage(): void {
    this.selectedImage = undefined;
    this.uploader.clearUploads();
    this.imageUploaded.emit(null)
  }

  public yourOnUploadHandler(event: any) {
    this.selectedImage = event;
    this.checkImageType();
    if (this.selectedImage) {
      this.previousSelectedImage = undefined;
      this.imageUploaded.emit(this.selectedImage)
    }
  }

  private checkImageType() {
    const allowedFormats = ["PNG", "GIF", "JPG", "JPEG"];
    if (!allowedFormats?.includes(this.selectedImage?.originalImageInfo?.format)) {
      this.imageErrors = 'This format is not allowed. You can only upload JPG-JPEG-PNG-GIF files';
      this.clearImage();
    } else if (this.limit && this.limit?.fixedSize) {
      this.checkLimitFixedSize();
    } else {
      this.checkNotificationCabledSize();
    }
  }

  private checkNotificationCabledSize() {
    if (this.selectedImage?.originalImageInfo?.height < 200 || this.selectedImage?.originalImageInfo?.width < 300) {
      this.imageErrors = 'The image don\'t respect the minimum size';
      this.clearImage();
    } else if (this.selectedImage?.originalImageInfo?.height > 1000 || this.selectedImage?.originalImageInfo?.width > 2000) {
      this.imageErrors = 'The image don\'t respect the maximum size';
      this.clearImage();
    }
  }

  private checkLimitFixedSize() {
    if (this.selectedImage?.originalImageInfo?.height !== this.limit?.height && this.selectedImage?.originalImageInfo?.width === this.limit?.width) {
      this.imageErrors = 'The image don\'t respect the required height size';
      this.clearImage();
    } else if (this.selectedImage?.originalImageInfo?.width !== this.limit?.width && this.selectedImage?.originalImageInfo?.height === this.limit?.height) {
      this.imageErrors = 'The image don\'t respect the required width size';
      this.clearImage();
    } else if (this.selectedImage?.originalImageInfo?.width !== this.limit?.width && this.selectedImage?.originalImageInfo?.height !== this.limit?.height) {
      this.imageErrors = 'The image don\'t respect the required width and height size';
      this.clearImage();
    }
  }
}

<ng-template #modal>
  <div class="modal-header border-bottom-0">
    <h5 class="modal-title" id="modal-title">
      <span>Edit codes for company: {{company?.name}}</span>
    </h5>
    <button class="btn btn-outline-light close-modal-button"
            closeModal="CLOSE_COMPANY_CODES_MODAL">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12" [hidden]="showAddCodes || codes?.length === 0 || loading">
        <div class="codes-table mt-3 mb-3">
          <table class="table table-bordered align-middle table-hover table-striped table-nowrap mt-0 mb-0">
            <thead>
            <tr>
              <th>
                <span class="me-3">{{'ID' | translate}}</span>
              </th>
              <th>
                <span class="me-3">{{'CODE' | translate}}</span>
              </th>
<!--              <th>-->
<!--                <span class="me-3">{{'CODE_TYPE' | translate}}</span>-->
<!--              </th>-->
              <th>
                <span class="me-3">{{'CURRENT_USES' | translate}}</span>
              </th>
              <th>
                <span class="me-3">{{'ACTIVE' | translate}}</span>
              </th>
              <th>
                <span class="me-3">{{'INVITER' | translate}}</span>
              </th>
              <th>
                <span class="me-3">{{'CREATED_AT' | translate}}</span>
              </th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let code of codes; let id=index;">
              <td align="center" class="fit-width">
                <span class="badge badge-pill badge-soft-primary">{{code?.id}}</span>
              </td>
              <td align="center">
                <span>{{code?.code}}</span>
              </td>
<!--              <td align="center">-->
<!--                <span>{{code?.codeType | translate}}</span>-->
<!--              </td>-->
              <td align="center" class="fit-width">
                <span>{{code?.usesCount}}</span>
              </td>
              <td align="center" class="fit-width">
                <span *ngIf="code?.active" class="badge badge-pill badge-soft-success">{{'YES' | translate}}</span>
                <span *ngIf="!code?.active" class="badge badge-pill badge-soft-danger">{{'NO' | translate}}</span>
              </td>
              <td align="center" class="fit-width">
                <span *ngIf="code?.inviter?.email" ngbTooltip="User detail" class="pointer"
                      placement="left" (click)="userDetail(code?.inviter)">{{code?.inviter?.email}}</span>
              </td>
              <td align="center" class="fit-width">
                <span>{{code?.createdAt | date:'dd/MM/yyyy HH:mm'}}</span>
              </td>
              <td class="fit-width">
                <ul class="list-inline font-size-20 contact-links mb-0">
                  <li (cbOnSuccess)="copied($event)" (mouseenter)="code.copy = true;" (mouseleave)="code.copy = false;"
                      [cbContent]="code?.deepLink" align="center" class="list-inline-item px-2" ngxClipboard>
                    <span class="pointer" ngbTooltip="Copy deeplink"
                          placement="left"><i
                      class="bx bx-link"></i></span>
                  </li>
                  <li (click)="utils.downloadQrCode(code)" class="list-inline-item px-2">
                    <qrcode *ngIf="code?.deepLink" [allowEmptyString]="false" [attr.id]="'qrCode' + code?.id"
                            [elementType]="'img'"
                            [errorCorrectionLevel]="'M'"
                            [qrdata]="code?.deepLink" [width]="1024"
                            class="d-none"></qrcode>
                    <span class="pointer"
                          ngbTooltip="Download"
                          placement="left"><i
                      class="mdi mdi-download"></i></span>
                  </li>
                </ul>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-12" *ngIf="!loading && !showAddCodes && codes?.length === 0">
        <div class="text-center mt-5 mb-5">
          <h4 class="text-uppercase">{{'SORRY_NO_RESULTS' | translate}}</h4>
        </div>
      </div>
      <div *ngIf="loading" class="col-12 p-0">
        <div class="wrapper">
          <app-loader [table]="false"></app-loader>
        </div>
      </div>
      <div class="col-12" *ngIf="showAddCodes && !loading">
        <div class="mt-3 mb-3">
                      <textarea [(ngModel)]="newPrizeCodes"
                                class="form-control codes-textarea"
                                placeholder="Insert all the codes separated with a space"
                                id="description"
                                rows="4"></textarea>
        </div>
      </div>
    </div>
    <ul class="list-inline wizard mb-0">
      <li class="previous list-inline-item" *ngIf="showAddCodes">
        <button [disabled]="loading" class="btn btn-outline-light" (click)="showAddCodes = false; newPrizeCodes = undefined;">{{'BACK' | translate}}</button>
      </li>
      <li class="next list-inline-item float-end">
        <button [disabled]="loading" *ngIf="showAddCodes" (click)="addCodes()" class="btn btn-primary" type="submit">Save new codes</button>
        <button [disabled]="loading" *ngIf="!showAddCodes" (click)="showAddCodes = true;" class="btn btn-primary" type="submit">Add codes</button>
      </li>
    </ul>
  </div>
</ng-template>

import {Directive, Input} from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appPlaceholderValidator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: PlaceholderValidatorDirective,
    multi: true
  }]
})
export class PlaceholderValidatorDirective implements Validator {
  @Input() appPlaceholderValidator: string;
  validate(control: AbstractControl) : {[key: string]: any} | null {
    const validators = this.appPlaceholderValidator.split(',');
    if (validators?.length > 0) {
      validators?.map((placeholder) => {
        if (control?.value && !control?.value?.includes(placeholder)) {
          return { placeholder: true }
        } else {
          return null;
        }
      })
    } else {
      return null;
    }
  }
}

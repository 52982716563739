import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NgbDate, NgbDatepickerConfig} from "@ng-bootstrap/ng-bootstrap";
import {Router} from "@angular/router";
import {UtilsService} from "@service/utils/utils.service";

@Component({
  selector: 'app-ban-information',
  templateUrl: './ban-information.component.html',
  styleUrls: ['./ban-information.component.scss']
})
export class BanInformationComponent implements OnInit, OnDestroy {
  @ViewChild('nextStep', {static: true, read: ElementRef}) public nextStep: ElementRef;
  @Input() banData: any;
  @Input() motivations$: any;
  @Input() editData: any;
  @Output() selectedBanInformation = new EventEmitter();
  @Output() reloadMotivations = new EventEmitter();
  public banForm: FormGroup;
  public formSubmitted: boolean;
  public motivationList: any[] = [{label: 'ciao', value: 2}];


  constructor(public formBuilder: FormBuilder, private config: NgbDatepickerConfig, private router: Router,
              public utils: UtilsService) {
    const current = new Date();
    config.minDate = {year: current.getFullYear(), month: current.getMonth() + 1, day: current.getDate()};
    config.outsideDays = 'hidden';
  }

  get form() {
    return this.banForm.controls;
  }

  ngOnInit(): void {
    const today = new Date()
    this.banForm = this.formBuilder.group({
      startsAt: [new NgbDate(today.getFullYear(), today.getMonth(), today.getDate()), [Validators.required]],
      startsAtHour: [1, [Validators.required]],
      endsAt: [null],
      endsAtHour: [null],
      sendMail: [true],
      sendPushNotification: [true],
      motivation: [null, Validators.required],
    });
    this.banForm.valueChanges.subscribe(() => {
      this.formSubmitted = false;
    });
    if (this.editData) {
      this.patchFormWithEditingData();
    }
  }

  ngOnDestroy() {
    this.config.minDate = null;
    this.config.outsideDays = 'visible';
  }

  async validSubmit() {
    if (this.banForm.valid && !this.formSubmitted) {
      let banForm = this.extractBanData();
      this.selectedBanInformation.emit(banForm)
      const delay = ms => new Promise(res => setTimeout(res, ms));
      await delay(150);
      this.formSubmitted = true;
      this.nextStep.nativeElement.click();
    } else if (this.banForm.valid && this.formSubmitted) {
      let banForm = this.extractBanData();
      this.selectedBanInformation.emit(banForm)
    } else {
      this.formSubmitted = true;
    }
  }

  public addNewMotivation(): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['users/ban-motivations'])
    );
    window.open(url, '_blank');
  }

  public reloadMotivationList(): void {
    this.reloadMotivations.emit()
  }

  private patchFormWithEditingData() {
    const startDate = new Date(this.editData?.startsAt);
    const endDate = new Date(this.editData?.endsAt);
    this.banForm.patchValue({
      startsAt: new NgbDate(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()),
      startsAtHour: this.editData?.startsAtHour,
      endsAt: new NgbDate(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()),
      endsAtHour: this.editData?.endsAtHour,
      motivation: this.editData?.banMotivation,
      sendMail: this.editData?.sendMail,
      sendPushNotification: this.editData?.sendPushNotification,
    });
  }

  private extractBanData() {
    let banForm = this.banForm.getRawValue();
    if (banForm.endsAt) {
      banForm.endsAt = this.utils.formatDateFromNgb(banForm.endsAt);
    }
    banForm.startsAt = this.utils.formatDateFromNgb(banForm.startsAt);
    return banForm;
  }


}

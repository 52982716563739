<header id="page-topbar-filters">
  <div class="navbar-header">
    <div class="d-flex">
      <div class="navbar-brand-box">
      </div>
      <div class="row ms-2">
        <div class="col-sm-12 col-lg-12">
          <form class="app-search d-none d-xl-inline-block">
            <div class="position-relative">
              <input (ngModelChange)="search($event)" [(ngModel)]="selectedSearchTerm"
                     class="form-control form-control-dark"
                     name="searchTerm" placeholder="{{'SEARCH' | translate}}" type="text">
              <span class="bx bx-search-alt"></span>
            </div>
          </form>
          <div *ngIf="selectedItems.length > 0" class="d-inline-block">
            <button (click)="deleteNotifications()" *ngIf="type === topbarTypeEnum.SCHEDULED_NOTIFICATIONS"
                    [hidden]="selectedItems.length < 1" class="btn btn-danger mb-0 ms-2"
                    type="button">
              <i class="bx bx bx-trash mr-2"></i>
              Delete notifications
            </button>
            <button (click)="deleteUsers()"
                    *ngIf="selectedItems.length > 0 && (type === topbarTypeEnum.USER_LIST || type === topbarTypeEnum.WHITE_LIST)"
                    class="btn btn-danger mb-0 ms-2" type="button">
              <i class="bx bx bx-trash mr-2"></i>
              Delete users
            </button>
            <button (click)="banUsers()"
                    *ngIf="selectedItems.length > 0 && (type === topbarTypeEnum.USER_LIST || type === topbarTypeEnum.WHITE_LIST)"
                    class="btn btn-warning mb-0 ms-2" type="button">
              <i class="bx bx-minus-circle mr-2"></i>
              Ban users
            </button>
            <button (click)="unbanUsers()" *ngIf="selectedItems.length > 0 && type === topbarTypeEnum.BANNED_LIST"
                    class="btn btn-warning mb-0 ms-2" type="button">
              <i class="bx bx-minus-circle mr-2"></i>
              Unban users
            </button>
            <button (click)="addToWhitelist()" *ngIf="selectedItems.length > 0 && type === topbarTypeEnum.USER_LIST"
                    class="btn btn-dark mb-0 ms-2"
                    type="button">
              <i class="bx bx-check-shield mr-2"></i>
              Add to automatic ban whitelist
            </button>
            <button (click)="removeFromWhitelist()"
                    *ngIf="selectedItems.length > 0 && type === topbarTypeEnum.WHITE_LIST"
                    class="btn btn-dark mb-0 ms-2"
                    type="button">
              <i class="bx bx-shield mr-2"></i>
              Remove from automatic ban whitelist
            </button>
            <button (click)="sendUserPush()"
                    *ngIf="selectedItems.length > 0 && (type === topbarTypeEnum.USER_LIST || type === topbarTypeEnum.WHITE_LIST)"
                    class="btn btn-secondary mb-0 ms-2"
                    type="button">
              <i class="bx bx-send mr-2"></i>
              Send push notification
            </button>
            <button (click)="deleteNews()" *ngIf="type === topbarTypeEnum.NEWS_LIST" [hidden]="selectedItems?.length < 1"
                    class="btn btn-danger mb-0 ms-2">
              <i class="bx bx bx-trash mr-2"></i>
              Delete selected news
            </button>
            <button (click)="deleteMotivations()"
                    *ngIf="selectedItems.length > 0 && type === topbarTypeEnum.BAN_MOTIVATIONS"
                    class="btn btn-danger mb-0 ms-2" type="button">
              <i class="bx bx bx-trash mr-2"></i>
              Delete motivations
            </button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="type !== topbarTypeEnum.BAN_MOTIVATIONS && type !== topbarTypeEnum.BLACKLIST_SOURCES" class="d-flex">
      <button (click)="createNews()" *ngIf="type === topbarTypeEnum.NEWS_LIST" class="btn btn-primary mb-0 me-2">
        <i class="bx bx-plus-circle mr-2"></i>
        Create news
      </button>
      <button (click)="createQrCode()" *ngIf="type === topbarTypeEnum.QR_CODES" class="btn btn-primary mb-0 me-2">
        <i class="mdi mdi-qrcode-plus mr-2"></i>
        Create new QR
      </button>
      <button (click)="createNewPrize()" *ngIf="type === topbarTypeEnum.PRIZE_LIST" class="btn btn-primary mb-0 me-2">
        <i class="bx bx-plus-circle mr-2"></i>
        Create new prize
      </button>
      <button (click)="createNewFaq()" *ngIf="type === topbarTypeEnum.FAQS_LIST" class="btn btn-primary mb-0 me-2">
        <i class="bx bx-plus-circle mr-2"></i>
        Create new faq
      </button>
      <button (click)="createNewCompany()" *ngIf="type === topbarTypeEnum.COMPANY_LIST" class="btn btn-primary mb-0 me-2">
        <i class="bx bx-plus-circle mr-2"></i>
        Create new company
      </button>
<!--      <button (click)="createNewCompetition()" *ngIf="type === topbarTypeEnum.COMPETITION_LIST" class="btn btn-primary mb-0 me-2">-->
<!--        <i class="bx bx-plus-circle mr-2"></i>-->
<!--        Create new competition-->
<!--      </button>-->
      <button (click)="createStreakTemplate()" *ngIf="type === topbarTypeEnum.STREAK_TEMPLATES" class="btn btn-primary mb-0 me-2">
        <i class="bx bx-plus-circle mr-2"></i>
        Create new template
      </button>
      <button (click)="createFto()" *ngIf="type === topbarTypeEnum.FTO" class="btn btn-primary mb-0 me-2">
        <i class="bx bx-plus-circle mr-2"></i>
        Create new slot
      </button>
      <button (click)="createStreak()" *ngIf="type === topbarTypeEnum.STREAKS" class="btn btn-primary mb-0 me-2">
        <i class="bx bx-plus-circle mr-2"></i>
        Create new streak
      </button>
      <app-column-picker (updateVisibleColumns)="updateColumns($event)" [availableColumns]="availableColumns"
                         [visibleColumns]="selected"></app-column-picker>
    </div>
    <div *ngIf="type === topbarTypeEnum.BAN_MOTIVATIONS" class="d-flex">
      <button (click)="createMotivation()" class="btn btn-primary mb-0">
        <i class="bx bx-plus-circle mr-2"></i>
        Add ban motivation
      </button>
    </div>
    <div *ngIf="type === topbarTypeEnum.BLACKLIST_SOURCES" class="d-flex">
      <button (click)="createBlacklistSource()" class="btn btn-primary mb-0">
        <i class="bx bx-plus-circle mr-2"></i>
        Add source to blacklist
      </button>
    </div>
  </div>
  <div
    *ngIf=" type !== topbarTypeEnum.AUTOMATIC_BAN &&
     type !== topbarTypeEnum.BLACKLIST_SOURCES &&
     type !== topbarTypeEnum.STREAK_TEMPLATES &&
     type !== topbarTypeEnum.STREAKS &&
     type !== topbarTypeEnum.REWARDS &&
     type !== topbarTypeEnum.FTO &&
      type !== topbarTypeEnum.SCHEDULED_NOTIFICATIONS"
    class="navbar-header navbar-header-bb">
    <div class="d-flex">
      <div class="navbar-brand-box">
      </div>
      <div class="d-flex ms-3">
        <div class="input-group input-group-sm me-2 selectable-filters">
          <label class="input-group-text">
            <i class="bx bx-filter-alt"></i>
          </label>
          <ng-select
            (ngModelChange)="changed($event)"
            [(ngModel)]="visibleFilters"
            [class.picker-container]="true"
            [clearable]="false"
            [closeOnSelect]="false"
            [items]="availableFilters"
            [multiple]="true"
            [placeholder]="'AVAILABLE_FILTERS' | translate"
            [searchable]="false"
            [selectableGroupAsModel]="false"
            [selectableGroup]="true"
            bindLabel="label">
            <ng-template ng-multi-label-tmp>
              <span class="d-block">Available filters</span>
            </ng-template>
            <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
              <input [checked]="item$.selected" id="item-{{index}}" type="checkbox"/> <span
              class="font-weight-semibold ms-2">{{item.label}}</span>
            </ng-template>
          </ng-select>
        </div>
      </div>
<!--      <div *ngIf="type === topbarTypeEnum.STREAK_TEMPLATES" class="d-flex">-->
<!--        <div *ngIf="checkIfFilterIsVisible(filter.STREAK_TEMPLATE_FITNESS_SCORING)" class="input-group me-2 group-flex-fix">-->
<!--          <label class="input-group-text">{{'STREAK_TEMPLATE_FITNESS_SCORING' | translate}}</label>-->
<!--          <select (change)="service.enabled = getValue($event, true)" class="form-select"-->
<!--                  name="status">-->
<!--            <option *ngFor="let scoring of searchFilters.fitnessScoring"-->
<!--                    [value]="scoring">{{scoring | translate}}</option>-->
<!--          </select>-->
<!--        </div>-->
<!--      </div>-->
      <div *ngIf="type === topbarTypeEnum.COMPANY_LIST" class="d-flex">
        <div *ngIf="checkIfFilterIsVisible(filter.COMPANY_PRIORITY)" class="input-group me-2">
          <app-number-picker (numberSelected)="service.priority = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [title]="'COMPANY_PRIORITY'"></app-number-picker>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.COMPANY_ENABLED)" class="input-group me-2 group-flex-fix">
          <label class="input-group-text">{{'COMPANY_ENABLED' | translate}}</label>
          <select (change)="service.enabled = getValue($event, true)" class="form-select"
                  name="status">
            <option *ngFor="let status of searchFilters.status" [selected]="status.selected"
                    [value]="status.value">{{status.label | translate}}</option>
          </select>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.COMPANY_DISCOVERABLE)" class="input-group me-2 group-flex-fix">
          <label class="input-group-text">{{'COMPANY_DISCOVERABLE' | translate}}</label>
          <select (change)="service.discoverable = getValue($event, true)" class="form-select"
                  name="status">
            <option *ngFor="let status of searchFilters.status" [selected]="status.selected"
                    [value]="status.value">{{status.label | translate}}</option>
          </select>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.COMPANY_ACTIVE)" class="input-group me-2 group-flex-fix">
          <label class="input-group-text">{{'COMPANY_ACTIVE' | translate}}</label>
          <select (change)="service.active = getValue($event, true)" class="form-select"
                  name="status">
            <option *ngFor="let status of searchFilters.status" [selected]="status.selected"
                    [value]="status.value">{{status.label | translate}}</option>
          </select>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.COMPANY_SHOW_USERS_COUNT)" class="input-group me-2 group-flex-fix">
          <label class="input-group-text">{{'COMPANY_SHOW_USERS_COUNT' | translate}}</label>
          <select (change)="service.showUsersCount = getValue($event, true)" class="form-select"
                  name="status">
            <option *ngFor="let status of searchFilters.status" [selected]="status.selected"
                    [value]="status.value">{{status.label | translate}}</option>
          </select>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.COMPANY_SHOW_LEADERBOARDS)" class="input-group me-2 group-flex-fix">
          <label class="input-group-text">{{'COMPANY_SHOW_LEADERBOARDS' | translate}}</label>
          <select (change)="service.showCompanyLeaderboards = getValue($event, true)" class="form-select"
                  name="status">
            <option *ngFor="let status of searchFilters.status" [selected]="status.selected"
                    [value]="status.value">{{status.label | translate}}</option>
          </select>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.COMPANY_MUST_INSERT_CODE)" class="input-group me-2 group-flex-fix">
          <label class="input-group-text">{{'COMPANY_MUST_INSERT_CODE' | translate}}</label>
          <select (change)="service.mustInsertCode = getValue($event, true)" class="form-select"
                  name="status">
            <option *ngFor="let status of searchFilters.status" [selected]="status.selected"
                    [value]="status.value">{{status.label | translate}}</option>
          </select>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.COMPANY_CREATED_AT)" class="input-group me-2">
          <app-date-range-picker (dateRangeSelected)="service.createdAt = $event"
                                 [clearAll]="clearFilters.asObservable()"
                                 [noLabel]="true"
                                 [title]="'COMPANY_CREATED_AT'"></app-date-range-picker>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.COMPANY_LAST_UPDATE)" class="input-group me-2">
          <app-date-range-picker (dateRangeSelected)="service.updatedAt = $event"
                                 [clearAll]="clearFilters.asObservable()"
                                 [noLabel]="true"
                                 [title]="'COMPANY_LAST_UPDATE'"></app-date-range-picker>
        </div>
      </div>
      <div *ngIf="type === topbarTypeEnum.COMPETITION_LIST" class="d-flex">
        <div *ngIf="checkIfFilterIsVisible(filter.COMPETITION_ACTIVE)" class="input-group me-2 group-flex-fix">
          <label class="input-group-text">{{'COMPETITION_ACTIVE' | translate}}</label>
          <select (change)="service.active = getValue($event, true)" class="form-select"
                  name="status">
            <option *ngFor="let status of searchFilters.status" [selected]="status.selected"
                    [value]="status.value">{{status.label | translate}}</option>
          </select>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.COMPETITION_BY_POWER_LEVEL)" class="input-group me-2 group-flex-fix">
          <label class="input-group-text">{{'COMPETITION_BY_POWER_LEVEL' | translate}}</label>
          <select (change)="service.byPowerLevel = getValue($event, true)" class="form-select"
                  name="status">
            <option *ngFor="let status of searchFilters.status" [selected]="status.selected"
                    [value]="status.value">{{status.label | translate}}</option>
          </select>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.COMPETITION_TYPE)" class="input-group me-2 group-flex-fix">
          <label class="input-group-text">{{'COMPETITION_TYPE' | translate}}</label>
          <select (change)="service.type = getValue($event, false)" class="form-select"
                  name="status">
            <option *ngFor="let status of searchFilters.competitionType" [selected]="status.selected"
                    [value]="status.value">{{status.label | translate}}</option>
          </select>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.COMPETITION_STATUS)" class="input-group me-2 group-flex-fix">
          <label class="input-group-text">{{'COMPETITION_STATUS' | translate}}</label>
          <select (change)="service.status = getValue($event, false)" class="form-select"
                  name="status">
            <option *ngFor="let status of searchFilters.competitionStatus" [selected]="status.selected"
                    [value]="status.value">{{status.label | translate}}</option>
          </select>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.COMPETITION_CREATED_AT)" class="input-group me-2">
          <app-date-range-picker (dateRangeSelected)="service.createdAt = $event"
                                 [clearAll]="clearFilters.asObservable()"
                                 [noLabel]="true"
                                 [title]="'COMPETITION_CREATED_AT'"></app-date-range-picker>
        </div>
      </div>
      <div *ngIf="type === topbarTypeEnum.FAQS_LIST" class="d-flex">
        <div *ngIf="checkIfFilterIsVisible(filter.FAQ_PRIORITY)" class="input-group me-2">
          <app-number-picker (numberSelected)="service.priority = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [title]="'FAQ_PRIORITY'"></app-number-picker>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.FAQ_ACTIVE)" class="input-group me-2 group-flex-fix">
          <label class="input-group-text">{{'FAQ_ACTIVE' | translate}}</label>
          <select (change)="service.active = getValue($event, true)" class="form-select"
                  name="status">
            <option *ngFor="let status of searchFilters.status" [selected]="status.selected"
                    [value]="status.value">{{status.label | translate}}</option>
          </select>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.FAQ_CREATED_AT)" class="input-group me-2">
          <app-date-range-picker (dateRangeSelected)="service.createdAt = $event"
                                 [clearAll]="clearFilters.asObservable()"
                                 [noLabel]="true"
                                 [title]="'FAQ_CREATED_AT'"></app-date-range-picker>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.FAQ_UPDATED_AT)" class="input-group me-2">
          <app-date-range-picker (dateRangeSelected)="service.updatedAt = $event"
                                 [clearAll]="clearFilters.asObservable()"
                                 [noLabel]="true"
                                 [title]="'FAQ_UPDATED_AT'"></app-date-range-picker>
        </div>
      </div>
      <div *ngIf="type === topbarTypeEnum.CHALLENGE_LIST" class="d-flex">
        <div *ngIf="checkIfFilterIsVisible(filter.CHALLENGE_TYPE)" class="input-group me-2 group-flex-fix">
          <label class="input-group-text">{{'CHALLENGE_TYPE' | translate}}</label>
          <select (change)="service.active = getValue($event, true)" class="form-select"
                  name="status">
            <option *ngFor="let status of searchFilters.challengeType" [selected]="status.selected"
                    [value]="status.value">{{status.label | translate}}</option>
          </select>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.CHALLENGE_PUBLIC)" class="input-group me-2 group-flex-fix">
          <label class="input-group-text">{{'CHALLENGE_PUBLIC' | translate}}</label>
          <select (change)="service.isPublic = getValue($event, true)" class="form-select"
                  name="status">
            <option *ngFor="let status of searchFilters.status" [selected]="status.selected"
                    [value]="status.value">{{status.label | translate}}</option>
          </select>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.CHALLENGE_ANTE)" class="input-group me-2">
          <app-number-picker (numberSelected)="service.ante = $event" [clearAll]="clearFilters.asObservable()"
                             [title]="'CHALLENGE_ANTE'"></app-number-picker>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.CHALLENGE_POOL)" class="input-group me-2">
          <app-number-picker (numberSelected)="service.currentPool = $event" [clearAll]="clearFilters.asObservable()"
                             [title]="'CHALLENGE_POOL'"></app-number-picker>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.CHALLENGE_PARTICIPANTS)" class="input-group me-2">
          <app-number-picker (numberSelected)="service.participantsCount = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [title]="'CHALLENGE_PARTICIPANTS'"></app-number-picker>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.CHALLENGE_MAX_PARTICIPANTS)" class="input-group me-2">
          <app-number-picker (numberSelected)="service.maxParticipants = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [title]="'CHALLENGE_MAX_PARTICIPANTS'"></app-number-picker>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.CHALLENGE_METRIC)" class="input-group me-2 group-flex-fix">
          <label class="input-group-text">{{'CHALLENGE_METRIC' | translate}}</label>
          <select (change)="service.metricLongDescription = getValue($event, false)" class="form-select"
                  name="status">
            <option *ngFor="let status of searchFilters.fitnessMetric" [selected]="status.selected"
                    [value]="status.value">{{status.label | translate}}</option>
          </select>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.CHALLENGE_STATUS)" class="input-group me-2 group-flex-fix">
          <label class="input-group-text">{{'CHALLENGE_STATUS' | translate}}</label>
          <select (change)="service.challengeStatus = getValue($event, false)" class="form-select"
                  name="status">
            <option *ngFor="let status of searchFilters.challengeStatus" [selected]="status.selected"
                    [value]="status.value">{{status.label | translate}}</option>
          </select>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.CHALLENGE_CREATED_AT)" class="input-group me-2">
          <app-date-range-picker (dateRangeSelected)="service.createdAt = $event"
                                 [clearAll]="clearFilters.asObservable()"
                                 [noLabel]="true"
                                 [title]="'CHALLENGE_CREATED_AT'"></app-date-range-picker>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.CHALLENGE_STARTS_AT)" class="input-group me-2">
          <app-date-range-picker (dateRangeSelected)="service.startsAt = $event"
                                 [clearAll]="clearFilters.asObservable()"
                                 [noLabel]="true"
                                 [title]="'CHALLENGE_STARTS_AT'"></app-date-range-picker>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.CHALLENGE_ENDS_AT)" class="input-group me-2">
          <app-date-range-picker (dateRangeSelected)="service.endsAt = $event"
                                 [clearAll]="clearFilters.asObservable()"
                                 [noLabel]="true"
                                 [title]="'CHALLENGE_ENDS_AT'"></app-date-range-picker>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.CHALLENGE_ACCEPT_RESULTS_UNTIL)" class="input-group me-2">
          <app-date-range-picker (dateRangeSelected)="service.acceptsResultsUntil = $event"
                                 [clearAll]="clearFilters.asObservable()"
                                 [noLabel]="true"
                                 [title]="'CHALLENGE_ACCEPT_RESULTS_UNTIL'"></app-date-range-picker>
        </div>
      </div>
      <div *ngIf="type === topbarTypeEnum.PRIZE_LIST" class="d-flex">
        <div *ngIf="checkIfFilterIsVisible(filter.PRIZE_PRICE)" class="input-group me-2">
          <app-number-picker (numberSelected)="service.price = $event" [clearAll]="clearFilters.asObservable()"
                             [title]="'PRIZE_PRICE'"></app-number-picker>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.PRIZE_TOTAL_UNITS)" class="input-group me-2">
          <app-number-picker (numberSelected)="service.totalUnits = $event" [clearAll]="clearFilters.asObservable()"
                             [title]="'PRIZE_TOTAL_UNITS'"></app-number-picker>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.PRIZE_REDEEMED_UNITS)" class="input-group me-2">
          <app-number-picker (numberSelected)="service.redeemedUnits = $event" [clearAll]="clearFilters.asObservable()"
                             [title]="'PRIZE_REDEEMED_UNITS'"></app-number-picker>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.PRIZE_AVAILABLE_UNITS)" class="input-group me-2">
          <app-number-picker (numberSelected)="service.availableUnits = $event" [clearAll]="clearFilters.asObservable()"
                             [title]="'PRIZE_AVAILABLE_UNITS'"></app-number-picker>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.PRIZE_TYPE)" class="input-group me-2">
          <label class="input-group-text">{{'PRIZE_TYPE' | translate}}</label>
          <select (change)="service.prizeType = getValue($event)" class="form-select form-select-sm2"
                  name="fitnessKit">
            <option *ngFor="let status of searchFilters.prizeType" [selected]="status.selected"
                    [value]="status.value">{{status.label | translate}}</option>
          </select>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.PRIZE_CONTEXT_TYPE)" class="input-group me-2">
          <label class="input-group-text">{{'PRIZE_CONTEXT_TYPE' | translate}}</label>
          <select (change)="service.contextType = getValue($event)" class="form-select form-select-sm2"
                  name="fitnessKit">
            <option *ngFor="let status of searchFilters.prizeContextType" [selected]="status.selected"
                    [value]="status.value">{{status.label | translate}}</option>
          </select>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.PRIZE_CONTEXT_TYPE) && !!service.contextType && service.contextType === prizeContextTypeEnum.COMPANY">
          <app-select-company (selectedCompany)="selectContextDetailId($event)" [pageFilter]="true" [single]="true"></app-select-company>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.PRIZE_CONTEXT_TYPE) && !!service.contextType && service.contextType === prizeContextTypeEnum.COMPETITION">
          <app-select-competition (selectedCompetition)="selectContextDetailId($event)" [pageFilter]="true" [single]="true"></app-select-competition>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.PRIZE_ACTIVE)" class="input-group me-2 group-flex-fix">
          <label class="input-group-text">{{'PRIZE_ACTIVE' | translate}}</label>
          <select (change)="service.active = getValue($event, true)" class="form-select"
                  name="status">
            <option *ngFor="let status of searchFilters.status" [selected]="status.selected"
                    [value]="status.value">{{status.label | translate}}</option>
          </select>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.PRIZE_CREATION_DATE)" class="input-group me-2">
          <app-date-range-picker (dateRangeSelected)="service.createdAt = $event"
                                 [clearAll]="clearFilters.asObservable()"
                                 [noLabel]="true"
                                 [title]="'PRIZE_CREATION_DATE'"></app-date-range-picker>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.PRIZE_EXPIRATION_DATE)" class="input-group me-2">
          <app-date-range-picker (dateRangeSelected)="service.expiresAt = $event"
                                 [clearAll]="clearFilters.asObservable()"
                                 [noLabel]="true"
                                 [title]="'PRIZE_EXPIRATION_DATE'"></app-date-range-picker>
        </div>
      </div>
      <div *ngIf="type === topbarTypeEnum.BAN_MOTIVATIONS" class="d-flex">
        <div *ngIf="checkIfFilterIsVisible(filter.NUMBER_OF_USERS)" class="input-group me-2">
          <app-number-picker (numberSelected)="service.numberOfUsers = $event" [clearAll]="clearFilters.asObservable()"
                             [title]="'BANNED_USERS'"></app-number-picker>
        </div>
      </div>
      <div *ngIf="type === topbarTypeEnum.BANNED_LIST" class="d-flex">
        <div *ngIf="checkIfFilterIsVisible(filter.BAN_STATUS)" class="input-group me-2">
          <label class="input-group-text">Ban active</label>
          <select (change)="service.banned = getValue($event, true)" class="form-select form-select-sm2"
                  name="status">
            <option *ngFor="let status of searchFilters.status" [selected]="status.selected"
                    [value]="status.value">{{status.label | translate}}</option>
          </select>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.BY_SYSTEM)" class="input-group me-2">
          <label class="input-group-text">Banned by system</label>
          <select (change)="service.bannedBySystem = getValue($event, true)" class="form-select form-select-sm2"
                  name="status">
            <option *ngFor="let status of searchFilters.status" [selected]="status.selected"
                    [value]="status.value">{{status.label | translate}}</option>
          </select>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.BAN_START_DATE)" class="input-group me-2">
          <app-date-range-picker (dateRangeSelected)="service.banStartDate = $event"
                                 [clearAll]="clearFilters.asObservable()"
                                 [noLabel]="true"
                                 [title]="'BAN_START_DATE'"></app-date-range-picker>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.BAN_END_DATE)" class="input-group me-2">
          <app-date-range-picker (dateRangeSelected)="service.banEndDate = $event"
                                 [clearAll]="clearFilters.asObservable()"
                                 [noLabel]="true"
                                 [title]="'BAN_END_DATE'"></app-date-range-picker>
        </div>
      </div>
      <div *ngIf="type === topbarTypeEnum.USER_LIST || type === topbarTypeEnum.WHITE_LIST" class="d-flex">
        <div *ngIf="checkIfFilterIsVisible(filter.REGISTRATION_DATE)" class="input-group me-2">
          <app-date-range-picker (dateRangeSelected)="service.registrationDate = $event"
                                 [clearAll]="clearFilters.asObservable()"
                                 [noLabel]="true"
                                 [title]="'UTILS.REGISTRATION.DATE.RANGE'"></app-date-range-picker>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.LAST_APP_OPEN)" class="input-group me-2">
          <app-date-range-picker (dateRangeSelected)="service.lastAppAccessTs = $event"
                                 [clearAll]="clearFilters.asObservable()"
                                 [noLabel]="true"
                                 [title]="'LAST_APP_OPEN'"></app-date-range-picker>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.LAST_SYNC)" class="input-group me-2">
          <app-date-range-picker (dateRangeSelected)="service.lastSync = $event"
                                 [clearAll]="clearFilters.asObservable()"
                                 [noLabel]="true"
                                 [title]="'LAST_SYNC'"></app-date-range-picker>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.PROFILE_COMPLETED)" class="input-group me-2">
          <label class="input-group-text">Profile completed</label>
          <select (change)="service.profileComplete = getValue($event, true)" class="form-select form-select-sm2"
                  name="status">
            <option *ngFor="let status of searchFilters.boolean" [selected]="status.selected"
                    [value]="status.value">{{status.label | translate}}</option>
          </select>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.USER_DELETED)" class="input-group me-2">
          <label class="input-group-text">User Deleted</label>
          <select (change)="service.banned = getValue($event, true)" class="form-select form-select-sm2"
                  name="status">
            <option *ngFor="let status of searchFilters.status" [selected]="status.selected"
                    [value]="status.value">{{status.label | translate}}</option>
          </select>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.AVAILABLE_CREDITS)" class="input-group me-2">
          <app-number-picker (numberSelected)="service.availableCredits = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [title]="'AVAILABLE_CREDITS'"></app-number-picker>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.PLATFORM)" class="input-group me-2">
          <label class="input-group-text">Platform</label>
          <select (change)="service.platform = getValue($event)" class="form-select form-select-sm2"
                  name="fitnessKit">
            <option *ngFor="let status of searchFilters.platform" [selected]="status.selected"
                    [value]="status.value">{{status.label | translate}}</option>
          </select>
        </div>
      </div>
      <div *ngIf="type === topbarTypeEnum.QR_CODES" class="d-flex">
        <div *ngIf="checkIfFilterIsVisible(filter.ACTIVE)" class="input-group me-2 group-flex-fix">
          <label class="input-group-text">Link active</label>
          <select (change)="service.active = getValue($event, true)" class="form-select"
                  name="status">
            <option *ngFor="let status of searchFilters.status" [selected]="status.selected"
                    [value]="status.value">{{status.label | translate}}</option>
          </select>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.SINGLE_USE_FOR_USER)" class="input-group me-2">
          <label class="input-group-text">Single use for user</label>
          <select (change)="service.singleUseForUser = getValue($event, true)" class="form-select"
                  name="status">
            <option *ngFor="let status of searchFilters.status" [selected]="status.selected"
                    [value]="status.value">{{status.label | translate}}</option>
          </select>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.ASSIGNED_CREDITS)" class="input-group me-2">
          <app-number-picker (numberSelected)="service.assignedCredits = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [title]="'ASSIGNED_CREDITS'"></app-number-picker>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.NUMBER_OF_USES)" class="input-group me-2">
          <app-number-picker (numberSelected)="service.numberOfUses = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [title]="'NUMBER_OF_USES'"></app-number-picker>
        </div>
      </div>
      <div *ngIf="type === topbarTypeEnum.NEWS_LIST" class="d-flex">
        <div *ngIf="checkIfFilterIsVisible(filter.ACTIVE)" class="input-group me-2 group-flex-fix">
          <label class="input-group-text">Active</label>
          <select (change)="service.active = getValue($event, true)" class="form-select form-select-sm2"
                  name="status">
            <option *ngFor="let status of searchFilters.status" [selected]="status.selected"
                    [value]="status.value">{{status.label | translate}}</option>
          </select>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.VALID_FOR_ALL_COMPANIES)" class="input-group me-2">
          <label class="input-group-text">{{'VALID_FOR_ALL_COMPANIES' | translate}}</label>
          <select (change)="service.validForAllCompanies = getValue($event, true)" class="form-select form-select-sm2"
                  name="status">
            <option *ngFor="let status of searchFilters.status" [selected]="status.selected"
                    [value]="status.value">{{status.label | translate}}</option>
          </select>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.PRIORITY)" class="input-group me-2">
          <app-number-picker (numberSelected)="service.priority = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [title]="'PRIORITY'"></app-number-picker>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.CREATION_DATE)" class="input-group me-2">
          <app-date-range-picker (dateRangeSelected)="service.createdAt = $event"
                                 [clearAll]="clearFilters.asObservable()"
                                 [noLabel]="true"
                                 [title]="'CREATION_DATE'"></app-date-range-picker>
        </div>
        <div *ngIf="checkIfFilterIsVisible(filter.UPDATED_AT)" class="input-group me-2">
          <app-date-range-picker (dateRangeSelected)="service.updatedAt = $event"
                                 [clearAll]="clearFilters.asObservable()"
                                 [noLabel]="true"
                                 [title]="'UPDATED_AT'"></app-date-range-picker>
        </div>
      </div>
    </div>
    <div class="d-flex clear-btn">
      <button (click)="clearSearchFilters()" *ngIf="service.isFilterApplied()" class="btn btn-secondary me-2"
              type="button">
        <i class="mdi mdi-progress-close"></i>
        Clear filters
      </button>
    </div>
  </div>
</header>
<app-delete-modal #deleteNotificationModal [service]="notificationsService"
                  [type]="deleteModalType.SCHEDULED_NOTIFICATION"></app-delete-modal>
<app-delete-modal #deleteModal [service]="banMotivationsService"
                  [type]="deleteModalType.BAN_MOTIVATION"></app-delete-modal>
<app-delete-modal #deleteUserModal [service]="service" [type]="deleteModalType.USER"></app-delete-modal>
<app-delete-modal #genericDeleteModal [service]="service" [type]="deleteModalType.BLACKLIST_SOURCE"></app-delete-modal>

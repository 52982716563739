<div *ngIf="!basicSelect && !single && (list$ | async) as companies" class="row mb-3">
  <label class="col-md-3 col-form-label font-weight-bold">Select companies</label>
  <div [ngClass]="{'is-invalid-select': error}" class="col-md-5 single-ng-select">
    <ng-select
      (change)="selectCompanies($event)"
      [(ngModel)]="selected"
      [items]="companies"
      [multiple]="false"
      bindLabel="name"></ng-select>
    <div *ngIf="error" class="invalid-feedback d-block mb-0">
      <span>{{'VALUE_REQUIRED' | translate}}</span>
    </div>
  </div>
</div>
<div *ngIf="!basicSelect && parentForm && single && (list$ | async) as companies" [formGroup]="parentForm" class="col-md-12">
  <div [ngClass]="{'is-invalid-select': formSubmitted && form.actionDetail.errors}" class="mb-3 single-ng-select">
    <label class="font-weight-bold">Select companies</label>
    <p class="card-title-desc mb-1" *ngIf="!filtered">
      Select the company that the notification will open.
    </p>
    <p class="card-title-desc mb-1" *ngIf="filtered">
      The field is disabled because the audience is {{filtered}} users.
    </p>
    <ng-select (change)="selectCompanies($event)" [items]="companies" bindLabel="name"
               formControlName="actionDetail"></ng-select>
    <div *ngIf="formSubmitted && form.actionDetail.errors" class="invalid-feedback d-block">
      <span *ngIf="formSubmitted && form.actionDetail.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
    </div>
  </div>
</div>
<div *ngIf="basicSelect && (list$ | async) as companies" [formGroup]="parentForm" class="col-md-12">
  <div [ngClass]="{'is-invalid-select': formSubmitted && form.company.errors}" class="mb-3 single-ng-select">
    <label class="font-weight-bold">Select company</label>
    <ng-select (change)="selectCompanies($event)" [(ngModel)]="selected"
               [placeholder]="disabled ? 'Select specific companies audience' : 'Select company'"
               [items]="companies" bindLabel="name"
               formControlName="company"></ng-select>
    <div *ngIf="formSubmitted && form.company.errors" class="invalid-feedback d-block">
      <span *ngIf="formSubmitted && form.company.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
    </div>
  </div>
</div>
<div *ngIf="pageFilter && (list$ | async) as companies" class="input-group me-2 page-filter-group">
  <label class="input-group-text">{{'SELECT_COMPANY' | translate}}</label>
  <select (change)="selectCompanies($event)"  [(ngModel)]="selected" class="form-select form-select-sm2"
          name="company">
    <option [value]="null">{{'ALL' | translate}}</option>
    <option *ngFor="let company of companies" [selected]="company.selected"
            [value]="JSON.stringify(company)">{{company.name}}</option>
  </select>
</div>

<ng-template #modal>
  <div class="modal-header border-bottom-0">
    <h5 *ngIf="!ftoSlot" class="modal-title" id="modal-title-1">Create new slot</h5>
    <h5 *ngIf="ftoSlot" class="modal-title" id="modal-title-2">Edit {{this.ftoSlot?.fitnessMetric | translate}} slot</h5>
    <button [disabled]="(creatingFto$ | async)" class="btn close-modal-button" closeModal="CLOSE_CREATE_FTO">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
  <div class="modal-body">
    <!--    <p class="modal-description">{{'MEDIUM_LOREM' | translate}}</p>-->
    <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="ftoSlotForm" autocomplete="off"
          class="needs-validation">
      <div class="row mt-3">
        <div class="col-12">
          <div class="row">
            <div class="col-md-12">
              <div class="mb-3 single-ng-select">
                <label class="font-weight-bold mb-2">{{'FITNESS_METRIC' | translate}}</label>
                <ng-select [ngClass]="{'is-invalid-select': formSubmitted && form?.fitnessMetric?.errors}" [(ngModel)]="selectedFitnessMetric"
                           [clearable]="false"
                           [disabled]="true"
                           disabled
                           [items]="fitnessMetricList"
                           formControlName="fitnessMetric">
                  <ng-template let-item="item" ng-label-tmp>
                    <span>{{ item | translate }}</span>
                  </ng-template>
                  <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                    <span class="font-weight-semibold ms-2">{{item | translate}}</span>
                  </ng-template>
                </ng-select>
                <div class="invalid-feedback d-block"  *ngIf="formSubmitted && form?.fitnessMetric?.errors">
                  <span *ngIf="formSubmitted && form?.fitnessMetric?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <label class="font-weight-bold mb-1" for="validSinceInclusive">{{'STREAK_START_DATE' | translate}}</label>
                <app-simple-datepicker [selectedStartDate]="form?.validSinceInclusive?.value"
                                       [isInvalid]="formSubmitted && form?.validSinceInclusive?.errors?.required"
                                       [selectedEndDate]="form?.validUntilExclusive?.value"
                                       [name]="'Monday'"
                                       [minDateLock]="false" (dateSelected)="setSinceDate($event)"></app-simple-datepicker>
                <div *ngIf="formSubmitted && form?.validSinceInclusive?.errors" class="invalid-feedback d-block">
                  <span *ngIf="formSubmitted && form?.validSinceInclusive?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label class="font-weight-bold mb-1" for="validUntilExclusive">{{'STREAK_END_DATE' | translate}}</label>
                <app-simple-datepicker [selectedStartDate]="form?.validSinceInclusive?.value"
                                       [isInvalid]="formSubmitted && form?.validUntilExclusive?.errors?.required"
                                       [name]="'Sunday'"
                                       [selectedEndDate]="form?.validUntilExclusive?.value"
                                       [minDateLock]="false" (dateSelected)="setUpToDate($event)"></app-simple-datepicker>
                <div *ngIf="formSubmitted && form?.validUntilExclusive?.errors" class="invalid-feedback d-block">
                  <span *ngIf="formSubmitted && form?.validUntilExclusive?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <label class="font-weight-bold mb-2" for="creditableQuantumSize">Creditable quantum size</label>
                <input [ngClass]="{'is-invalid': formSubmitted && form.creditableQuantumSize.errors}" class="form-control"
                       formControlName="creditableQuantumSize" id="creditableQuantumSize" type="number" min="1">
                <div *ngIf="formSubmitted && form.creditableQuantumSize.errors" class="invalid-feedback">
                  <span *ngIf="formSubmitted && form.creditableQuantumSize.errors.required">{{'VALUE_REQUIRED' | translate}}</span>

                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label class="font-weight-bold mb-2" for="creditsPerQuantum">Credits per quantum</label>
                <input [ngClass]="{'is-invalid': formSubmitted && form.creditsPerQuantum.errors}" class="form-control"
                       formControlName="creditsPerQuantum" id="creditsPerQuantum" type="number" min="1">
                <div *ngIf="formSubmitted && form.creditsPerQuantum.errors" class="invalid-feedback">
                  <span *ngIf="formSubmitted && form.creditsPerQuantum.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="mb-3">
                <app-image-uploader [name]="'IMAGE'"
                                    (imageUploaded)="setImage($event)"
                                    [showImagePreview]="true"
                                    [imageErrors]="checkLockedImageError()"
                                    [limit]="limit" [previousSelectedImage]="checkSelectedLockedImage()"></app-image-uploader>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul class="list-inline wizard mb-0">
        <li class="next list-inline-item float-end">
          <button (click)="createStreakTemplate()" *ngIf="!ftoSlot" [disabled]="(creatingFto$ | async)"
                  class="btn btn-warning">
            <i *ngIf="creatingFto$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            <span *ngIf="(creatingFto$ | async) && !ftoSlot">
              {{'CREATING' | translate}}
            </span>
            <span *ngIf="!(creatingFto$ | async) && !ftoSlot">{{'CREATE' | translate}}</span>
          </button>
          <button (click)="createStreakTemplate()" *ngIf="ftoSlot"
                  class="btn btn-warning">
            <i *ngIf="creatingFto$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            <span *ngIf="(creatingFto$ | async) && ftoSlot">{{'EDITING' | translate}}</span>
            <span *ngIf="!(creatingFto$ | async) && ftoSlot">{{'EDIT' | translate}}</span>
          </button>
        </li>
      </ul>
    </form>
  </div>
</ng-template>

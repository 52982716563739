<div id="layout-wrapper">
  <app-topbar (mobileMenuButtonClicked)="onToggleMobileMenu()"></app-topbar>
  <app-sidebar [isCondensed]="isCondensed"></app-sidebar>
  <div class="main-content">
    <div class="page-content" id="page-content">
      <router-outlet></router-outlet>
    </div>
    <app-toasts aria-atomic="true" aria-live="polite"></app-toasts>
  </div>
</div>

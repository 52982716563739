<app-table-topbar (emitSearchTerm)="prizesService.searchTermLocal = $event"></app-table-topbar>
<div [hidden]="(loading$ | async)" class="col-12 p-0 full-height-table">
  <div *ngIf="prizes$ | async as prizes" class="wrapper">
    <table
      [hidden]="prizes?.length === 0"
      class="table table-bordered align-middle table-nowrap table-hover table-striped mb-0 datatables recorded-activities" id="basic-datatable">
      <thead>
      <tr>
        <th></th>
        <th (sort)="sort($event)" class="column-sortable" sortable="prizeId">
          <span class="me-3">{{'ID' | translate}}</span>
        </th>
        <th (sort)="sort($event)" class="column-sortable" sortable="active">
          <span class="me-3">{{'ACTIVE' | translate}}</span>
        </th>
        <th (sort)="sort($event)" class="column-sortable" sortable="title">
          <span class="me-3">{{'TITLE' | translate}}</span>
        </th>
        <th (sort)="sort($event)" class="column-sortable" sortable="description">
          <span class="me-3">{{'DESCRIPTION' | translate}}</span>
        </th>
        <th (sort)="sort($event)" class="column-sortable" sortable="code">
          <span class="me-3">{{'CODE' | translate}}</span>
        </th>
        <th (sort)="sort($event)" class="column-sortable" sortable="price">
          <span class="me-3">{{'PRICE' | translate}}</span>
        </th>
        <th (sort)="sort($event)" class="column-sortable" sortable="prizeType">
          <span class="me-3">{{'TYPE' | translate}}</span>
        </th>
        <th (sort)="sort($event)" class="column-sortable" sortable="purchasedAt">
          <span class="me-3">{{'PURCHASED_AT' | translate}}</span>
        </th>
        <th (sort)="sort($event)" class="column-sortable" sortable="context.type">
          <span class="me-3">{{'PRIZE_CONTEXT' | translate}}</span>
        </th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let prize of prizes | search:'prizeId,title,description,code':prizesService.searchTerm; let i=index;"
          class="no-bg">
        <td align="center" class="pointer fit-width">
          <span class="badge badge-pill badge-soft-secondary">#{{extractRowNumber(i)}}</span>
        </td>
        <td align="center" class="fit-width">
          <span *ngIf="prize?.prizeId">{{prize?.prizeId}}</span>
          <app-missing-label *ngIf="!prize?.prizeId"></app-missing-label>
        </td>
        <td align="center" class="fit-width">
          <span *ngIf="!prize?.active"
                class="badge badge-pill font-size-11 badge-soft-danger">{{'INACTIVE' | translate | uppercase}}</span>
          <span *ngIf="prize?.active"
                class="badge badge-pill font-size-11 badge-soft-success">{{'ACTIVE' | translate | uppercase}}</span>
        </td>
        <td align="center" class="fit-width">
          <span *ngIf="prize?.title" ngbTooltip="{{prize?.title}}"
                placement="bottom">{{prize?.title | truncate:[25]}}</span>
          <app-missing-label *ngIf="!prize?.title"></app-missing-label>
        </td>
        <td align="center" class="fit-width">
          <span *ngIf="prize?.description" ngbTooltip="{{prize?.description}}"
                placement="bottom">{{prize?.description | truncate:[25]}}</span>
          <app-missing-label *ngIf="!prize?.description"></app-missing-label>
        </td>
        <td align="center" class="fit-width">
          <span *ngIf="prize?.code">{{prize?.code}}</span>
          <app-missing-label *ngIf="!prize?.code"></app-missing-label>
        </td>
        <td align="center" class="fit-width">
          <span *ngIf="prize?.price">{{prize?.price}}</span>
          <app-missing-label *ngIf="!prize?.price"></app-missing-label>
        </td>
        <td align="center" class="fit-width">
          <span *ngIf="prize?.prizeType">{{prize?.prizeType}}</span>
          <app-missing-label *ngIf="!prize?.prizeType"></app-missing-label>
        </td>
        <td align="center" class="fit-width">
          <span *ngIf="prize?.purchasedAt">{{prize?.purchasedAt | date:'dd/MM/yyyy HH:mm'}}</span>
          <app-missing-label *ngIf="!prize?.purchasedAt"></app-missing-label>
        </td>
        <td align="center" class="fit-width">
          <span *ngIf="prize?.context?.type">{{prize?.context?.type}}</span>
          <app-missing-label *ngIf="!prize?.context?.type"></app-missing-label>
        </td>
        <td class="fit-width">
          <ul class="list-inline font-size-20 contact-links mb-0">
            <li (click)="prizeContextDetail(prize)" class="list-inline-item px-2">
                  <span [ngClass]="{'disabled-opacity': true}" class="pointer"
                        ngbTooltip="See prize context detail"
                        placement="bottom"><i
                    class="bx bx-search-alt"></i></span>
            </li>
            <li (click)="prizeDetail(prize)" class="list-inline-item">
                  <span class="pointer"
                        ngbTooltip="See prize detail"
                        placement="bottom"><i
                    class="bx bx-trophy"></i></span>
            </li>
          </ul>
        </td>
      </tr>
      </tbody>
    </table>
    <div *ngIf="prizes?.length === 0" class="col-lg-12">
      <div class="">
        <div class="text-center mt-5">
          <h4 class="text-uppercase">{{'SORRY_NO_RESULTS' | translate}}</h4>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loading$ | async" class="col-12 p-0">
  <div class="wrapper">
    <app-loader [table]="true"></app-loader>
  </div>
</div>
<app-pagination-footer [service]="prizesService" [total$]="total$"></app-pagination-footer>

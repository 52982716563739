import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UtilsService} from "@service/utils/utils.service";

@Component({
  selector: 'app-fitness-metric-picker',
  templateUrl: './fitness-metric-picker.component.html',
  styleUrls: ['./fitness-metric-picker.component.scss']
})
export class FitnessMetricPickerComponent implements OnInit {
  @Output() selectedFitnessMetric = new EventEmitter();
  public list: any = this.utils.getFitnessMetrics();
  constructor(private utils: UtilsService) { }

  ngOnInit(): void {
  }

  public getValue(event: Event, boolean?: boolean): any {
    const value = (event.target as HTMLInputElement).value;
    if (!boolean) return value;
    if (boolean) {
      switch (value) {
        case "true":
          return true;
        case "false":
          return false;
        default:
          return undefined;
      }
    }
  }


}

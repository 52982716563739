<label class="form-label font-weight-semibold mb-1 image-label" *ngIf="!name" for="message">{{'IMAGE' | translate}}</label>
<label class="form-label font-weight-semibold mb-1 image-label" *ngIf="name" for="message">{{name | translate}}</label>
<div class="row">
  <div [ngClass]="{'col-7': showImagePreview && selectedImage, 'col-12': !showImagePreview}">
    <p class="card-title-desc mb-3" *ngIf="!limit">
      The correct aspect-ratio is <span class="badge badge-pill badge-soft-danger font-size-11">2:1</span>
      The min-width for the image is <span class="badge badge-pill badge-soft-danger font-size-11">300px</span> and the min-height is <span class="badge badge-pill badge-soft-danger font-size-11">200px</span>
      The max-width for the image is <span class="badge badge-pill badge-soft-danger font-size-11">2000px</span> and the max-height is <span class="badge badge-pill badge-soft-danger font-size-11">1000px</span>
    </p>
    <p *ngIf="limit?.fixedSize && limit?.ratio && limit?.height && limit?.width" class="specific-width">
      The correct aspect-ratio is <span class="badge badge-pill badge-soft-danger font-size-11">{{limit?.ratio}}</span>
      The width for the image must be <span class="badge badge-pill badge-soft-danger font-size-11">{{limit?.width}}px</span> and the height <span class="badge badge-pill badge-soft-danger font-size-11">{{limit?.height}}px</span>
    </p>
  </div>
  <div class="col-5 text-right" *ngIf="showImagePreview && selectedImage">
    <img class="image-preview" [src]="selectedImage?.originalUrl" alt="">
  </div>
  <div class="col-md-12">
    <div class="input-group mt-1">
      <input (click)="openUploader()"  [ngClass]="{'is-invalid': !!imageErrors}" disabled [placeholder]="selectedImage?.name ? selectedImage?.name : 'Please choose image'" class="form-control bg-white" id="title">
      <div class="input-group-append">
        <span *ngIf="!selectedImage" (click)="openUploader()" class="input-group-text btn btn-primary pointer remove-radius-left">Choose image</span>
        <span *ngIf="selectedImage" (click)="openUploader(true)" class="input-group-text btn-primary pointer remove-radius-left remove-radius-right">Show image</span>
      </div>
      <div class="input-group-append"  *ngIf="selectedImage">
        <span *ngIf="selectedImage" (click)="clearImage()" class="input-group-text btn-dark pointer remove-radius-left">Remove image</span>
      </div>
      <div *ngIf="imageErrors" class="invalid-feedback">
        <span>{{imageErrors}}</span>
      </div>
    </div>
  </div>
</div>
<ngx-uploadcare-widget
  #uploader
  [class.d-none]="true"
  images-only="true"
  data-file-types="jpg,png,gif"
  clearable="true"
  preview-step="true"
  tabs="file gdrive gphotos dropbox"
  input-accept-types="image/png, image/jpeg"
  (on-upload-complete)="yourOnUploadHandler($event)"
  public-key="demopublickey">
</ngx-uploadcare-widget>

import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-table-topbar',
  templateUrl: './table-topbar.component.html',
  styleUrls: ['./table-topbar.component.scss']
})
export class TableTopbarComponent implements OnInit {
  @Output() emitSearchTerm = new EventEmitter();
  public searchTerm: any;
  constructor() {
  }

  ngOnInit(): void {
  }

  fieldsChange(values:any):void {
    this.emitSearchTerm.emit(this.searchTerm);
  }

}

<app-table-topbar (emitSearchTerm)="challengesService.searchTerm = $event"></app-table-topbar>
<div [hidden]="(loading$ | async)" class="col-12 p-0 full-height-table">
  <div *ngIf="challenges$ | async as challenges" class="wrapper">
    <table [hidden]="challenges?.length === 0"
           class="table table-bordered align-middle table-nowrap table-hover table-striped-bg mb-0 datatables"
           id="basic-datatable">
      <thead>
      <tr>
        <th></th>
        <th></th>
        <th (sort)="sort($event)" class="column-sortable" sortable="id">
          <span class="me-3">{{'ID' | translate}}</span>
        </th>
        <th (sort)="sort($event)" class="column-sortable" sortable="type">
          <span class="me-3">{{'CHALLENGE_TYPE' | translate}}</span>
        </th>
        <th (sort)="sort($event)" class="column-sortable" sortable="metric.actualMetric">
          <span class="me-3">{{'METRIC' | translate}}</span>
        </th>
        <th (sort)="sort($event)" class="column-sortable" sortable="active">
          <span class="me-3">{{'ACTIVE' | translate}}</span>
        </th>
        <th (sort)="sort($event)" class="column-sortable" sortable="isPublic">
          <span class="me-3">{{'PUBLIC' | translate}}</span>
        </th>
        <th (sort)="sort($event)" class="column-sortable" sortable="challengeName">
          <span class="me-3">{{'CHALLENGE_NAME' | translate}}</span>
        </th>
        <th (sort)="sort($event)" class="column-sortable" sortable="description">
          <span class="me-3">{{'CHALLENGE_DESCRIPTION' | translate}}</span>
        </th>
        <th (sort)="sort($event)" class="column-sortable" sortable="ante">
          <span class="me-3">{{'CREDIT_FEE' | translate}}</span>
        </th>
        <th (sort)="sort($event)" class="column-sortable" sortable="currentPool">
          <span class="me-3">{{'CREDIT_POOL' | translate}}</span>
        </th>
        <th (sort)="sort($event)" class="column-sortable" sortable="participantsCount">
          <span class="me-3">{{'PARTICIPANTS' | translate}}</span>
        </th>
        <th (sort)="sort($event)" class="column-sortable" sortable="maxParticipants">
          <span class="me-3">{{'MAX_PARTICIPANTS' | translate}}</span>
        </th>
        <th (sort)="sort($event)" class="column-sortable" sortable="createdAt">
          <span class="me-3">{{'CREATION_DATE_HOUR' | translate}}</span>
        </th>
        <th (sort)="sort($event)" class="column-sortable" sortable="startsAt">
          <span class="me-3">{{'START_DATE_HOUR' | translate}}</span>
        </th>
        <th (sort)="sort($event)" class="column-sortable" sortable="endsAt">
          <span class="me-3">{{'END_DATE_HOUR' | translate}}</span>
        </th>
        <th (sort)="sort($event)" class="column-sortable" sortable="acceptsResultsUntil">
          <span class="me-3">{{'ACCEPT_RESULTS_UNTIL' | translate}}</span>
        </th>
        <th (sort)="sort($event)" class="column-sortable" sortable="status">
          <span class="me-3">{{'STATUS' | translate}}</span>
        </th>
      </tr>
      </thead>
      <tbody *ngFor="let challenge of challenges; let i=index;">
      <tr>
        <td align="center" class="pointer fit-width">
                <span (click)="expandChallenge(challenge)" class="btn btn-secondary font-size-11 show-hide-users">
                  <i *ngIf="challenge.expand" class="mdi mdi-close-circle-outline me-2"></i>
                  <i *ngIf="!challenge.expand" class="bx bx-plus-circle me-2"></i>
                  <span *ngIf="!challenge.expand">{{'SHOW_PARTICIPANTS' | translate}}</span>
                  <span *ngIf="challenge.expand">{{'HIDE_PARTICIPANTS' | translate}}</span>
                </span>
        </td>
        <td align="center" class="pointer fit-width">
          <span class="badge badge-pill badge-soft-secondary">#{{extractRowNumber(i)}}</span>
        </td>
        <td align="center" class="fit-width">
          <span *ngIf="challenge?.id" class="badge badge-pill badge-soft-primary">{{challenge?.id}}</span>
          <app-missing-label *ngIf="!challenge?.id"></app-missing-label>
        </td>
        <td align="center" class="fit-width">
          <span *ngIf="challenge?.type">{{challenge?.type}}</span>
          <app-missing-label *ngIf="!challenge?.type"></app-missing-label>
        </td>
        <td align="center" class="fit-width">
          <span *ngIf="challenge?.metric?.actualMetric">{{challenge?.metric?.actualMetric | translate}}</span>
          <app-missing-label *ngIf="!challenge?.metric?.actualMetric"></app-missing-label>
        </td>
        <td align="center" class="fit-width">
          <span *ngIf="!challenge?.active"
                class="badge badge-pill font-size-11 badge-soft-danger">{{'c' | translate | uppercase}}</span>
          <span *ngIf="challenge?.active"
                class="badge badge-pill font-size-11 badge-soft-success">{{'ACTIVE' | translate | uppercase}}</span>
        </td>
        <td align="center" class="fit-width">
          <span *ngIf="challenge?.isPublic" class="badge badge-pill badge-soft-success">{{'YES' | translate}}</span>
          <span *ngIf="!challenge?.isPublic" class="badge badge-pill badge-soft-danger">{{'NO' | translate}}</span>
        </td>
        <td align="center" class="fit-width">
          <span *ngIf="challenge?.challengeName">{{challenge?.challengeName}}</span>
          <app-missing-label *ngIf="!challenge?.challengeName"></app-missing-label>
        </td>
        <td align="center" class="fit-width">
          <span *ngIf="challenge?.description">{{challenge?.description}}</span>
          <app-missing-label *ngIf="!challenge?.description"></app-missing-label>
        </td>
        <td align="center" class="fit-width">
          <span *ngIf="challenge?.ante">{{challenge?.ante}}</span>
          <app-missing-label *ngIf="!challenge?.ante"></app-missing-label>
        </td>
        <td align="center" class="fit-width">
          <span *ngIf="challenge?.currentPool">{{challenge?.currentPool}}</span>
          <app-missing-label *ngIf="!challenge?.currentPool"></app-missing-label>
        </td>
        <td align="center" class="fit-width">
          <span *ngIf="challenge?.participantsCount">{{challenge?.participantsCount}}</span>
          <app-missing-label *ngIf="!challenge?.participantsCount"></app-missing-label>
        </td>
        <td align="center" class="fit-width">
          <span *ngIf="challenge?.maxParticipants">{{challenge?.maxParticipants}}</span>
          <app-missing-label *ngIf="!challenge?.maxParticipants"></app-missing-label>
        </td>
        <td align="center" class="fit-width">
          <span *ngIf="challenge?.createdAt">{{challenge?.createdAt | date:'dd/MM/yyyy HH:mm'}}</span>
          <app-missing-label *ngIf="!challenge?.currentPool"></app-missing-label>
        </td>
        <td align="center" class="fit-width">
          <span *ngIf="challenge?.startsAt">{{challenge?.startsAt | date:'dd/MM/yyyy HH:mm'}}</span>
          <app-missing-label *ngIf="!challenge?.startsAt"></app-missing-label>
        </td>
        <td align="center" class="fit-width">
          <span *ngIf="challenge?.endsAt">{{challenge?.endsAt | date:'dd/MM/yyyy HH:mm'}}</span>
          <app-missing-label *ngIf="!challenge?.endsAt"></app-missing-label>
        </td>
        <td align="center" class="fit-width">
          <span
            *ngIf="challenge?.acceptsResultsUntil">{{challenge?.acceptsResultsUntil | date:'dd/MM/yyyy HH:mm'}}</span>
          <app-missing-label *ngIf="!challenge?.acceptsResultsUntil"></app-missing-label>
        </td>
        <td align="center" class="fit-width">
          <span *ngIf="challenge?.status">{{challenge?.status}}</span>
          <app-missing-label *ngIf="!challenge?.status"></app-missing-label>
        </td>
      </tr>
      <tr *ngIf="challenge.expand && challenge?.leaderboard?.length > 0" class="sub-table no-bg">
        <td class="no-padding" colspan="18">
          <table class="table table-bordered align-middle table-nowrap mb-0 datatables no-bg">
            <thead class="bg-dark text-white">
            <tr>
              <th>
                <span class="me-3">{{'POSITION' | translate}}</span>
              </th>
              <th>
                <span class="me-3">{{'USER_ID' | translate}}</span>
              </th>
              <th>
                <span class="me-3">{{'USER_FULLNAME' | translate}}</span>
              </th>
              <th>
                <span class="me-3">{{'POWER_LEVEL' | translate}}</span>
              </th>
              <th>
                <span class="me-3">{{'STATUS' | translate}}</span>
              </th>
              <th>
                <span class="me-3">{{'PROVISIONAL_SCORE' | translate}}</span>
              </th>
              <th>
                <span class="me-3">{{'CONSOLIDATED_SCORE' | translate}}</span>
              </th>
              <th>
                <span class="me-3">{{'WON_CREDITS' | translate}}</span>
              </th>
              <th>
                <span class="me-3">{{'PARTICIPANT_CREATED_AT' | translate}}</span>
              </th>
              <th>
                <span class="me-3">{{'PARTICIPANT_LAST_UPDATE' | translate}}</span>
              </th>
              <th></th>
            </tr>
            </thead>
            <tbody class="no-bg">
            <tr *ngFor="let participant of challenge?.leaderboard" class="no-bg pointer">
              <td align="center" class="fit-width">
                <span *ngIf="participant?.position"
                      class="badge badge-pill badge-soft-secondary">{{participant?.position}}</span>
                <app-missing-label *ngIf="!participant?.position"></app-missing-label>
              </td>
              <td (click)="userDetail(participant?.user)" class="fit-width pointer">
                <span *ngIf="participant?.user?.id"
                      class="badge badge-pill badge-soft-primary">{{participant?.user?.id}}</span>
                <app-missing-label *ngIf="!participant?.user?.id"></app-missing-label>
              </td>
              <td (click)="userDetail(participant?.user)" class="fit-width pointer">
                <span *ngIf="participant?.user?.profile?.firstName">{{extractUserFullName(participant?.user)}}</span>
                <app-missing-label *ngIf="!participant?.user?.profile?.firstName"></app-missing-label>
              </td>
              <td class="fit-width">
                <span *ngIf="participant?.powerLevel">{{participant?.powerLevel}}</span>
                <app-missing-label *ngIf="!participant?.powerLevel"></app-missing-label>
              </td>
              <td class="fit-width">
                <span *ngIf="participant?.membershipStatus">{{participant?.membershipStatus}}</span>
                <app-missing-label *ngIf="!participant?.membershipStatus"></app-missing-label>
              </td>
              <td class="fit-width">
                <span *ngIf="participant?.provisional">{{participant?.provisional}}</span>
                <app-missing-label *ngIf="!participant?.provisional"></app-missing-label>
              </td>
              <td class="fit-width">
                <span *ngIf="participant?.consolidated">{{participant?.consolidated}}</span>
                <app-missing-label *ngIf="!participant?.consolidated"></app-missing-label>
              </td>
              <td class="fit-width">
                <span *ngIf="participant?.wonCredits">{{participant?.wonCredits}}</span>
                <app-missing-label *ngIf="!participant?.wonCredits"></app-missing-label>
              </td>
              <td align="center" class="fit-width">
                <span
                  *ngIf="participant?.participantCreatedAt">{{participant?.participantCreatedAt | date:'dd/MM/yyyy HH:mm'}}</span>
                <app-missing-label *ngIf="!participant?.participantCreatedAt"></app-missing-label>
              </td>
              <td align="center" class="fit-width">
                <span
                  *ngIf="participant?.participantLastUpdate">{{participant?.participantLastUpdate | date:'dd/MM/yyyy HH:mm'}}</span>
                <app-missing-label *ngIf="!participant?.participantLastUpdate"></app-missing-label>
              </td>
              <td class="fit-width min-width-25px">
                <ul class="list-inline font-size-20 contact-links mb-0 fit-width">
                  <li (click)="userDetail(participant?.user)" class="list-inline-item px-2">
                  <span class="pointer" ngbTooltip="User detail"
                        placement="left"><i
                    class="bx bxs-user-detail"></i></span>
                  </li>
                </ul>
              </td>
            </tr>
            </tbody>
          </table>
        </td>
      </tr>
      </tbody>
    </table>
    <div *ngIf="challenges?.length === 0" class="col-lg-12">
      <div class="">
        <div class="text-center mt-5">
          <h4 class="text-uppercase">{{'SORRY_NO_RESULTS' | translate}}</h4>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loading$ | async" class="col-12 p-0">
  <div class="wrapper">
    <app-loader [table]="true"></app-loader>
  </div>
</div>
<app-pagination-footer [service]="challengesService" [total$]="total$"></app-pagination-footer>

export enum DeleteModalTypeEnum {
  BAN_MOTIVATION = 'BAN_MOTIVATION',
  USER = 'USER',
  SCHEDULED_NOTIFICATION = 'SCHEDULED_NOTIFICATION',
  AUTOMATIC_BAN_RULE = 'AUTOMATIC_BAN_RULE',
  BLACKLIST_SOURCE = 'BLACKLIST_SOURCE',
  FAQ = 'FAQ',
  FTO = 'FTO',
  STREAK = 'STREAK'
}

import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {EventService} from "@service/common/event.service";
import {EventEnum} from "@enum/event/event.enum";
import {UserElementInterface} from "@interface/user/user-element.interface";
import {UserBannedListService} from "@service/users/user-banned-list.service";
import {NgbModalOptions} from "@ng-bootstrap/ng-bootstrap/modal/modal-config";
import {BanStatusEnum} from "@enum/ban-status/ban-status.enum";
import {UserListService} from "@service/users/user-list.service";

@Component({
  selector: 'app-ban-remove-modal',
  templateUrl: './ban-remove-modal.component.html',
  styleUrls: ['./ban-remove-modal.component.scss']
})
export class BanRemoveModalComponent implements OnInit, OnDestroy {
  public removingBan$: Observable<boolean>;
  public showAreYouSure: boolean = false;
  public selectedUsers: any[] = [];
  public whiteList: boolean = false;
  public showWhiteList: boolean = false;
  @ViewChild('modal') private modalContent: TemplateRef<any>
  private closeModalSubscription: Subscription;
  private removeUserSubscription: Subscription;
  private modalRef: NgbModalRef;
  private modalOptions: NgbModalOptions = {size: 'md', centered: true, backdrop: false};

  constructor(private modalService: NgbModal, private eventService: EventService,
              private userBannedListService: UserBannedListService, private userListService: UserListService) {
  }

  ngOnInit(): void {
    // this.userBannedListService?.initRemoveBanListener();
    this.removingBan$ = this.userBannedListService?.removingBan$;
    this._closeModal();
    this._removeUserFromBanList();
  }

  ngOnDestroy() {
    if (this.closeModalSubscription) {
      this.closeModalSubscription?.unsubscribe();
    }
    if (this.removeUserSubscription) {
      this.removeUserSubscription.unsubscribe();
    }
  }

  public open(users: UserElementInterface[]): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService?.open(this.modalContent, this.modalOptions)
      this.modalRef?.result?.then(resolve, resolve)
      this.selectedUsers = users;
      if (this.selectedUsers?.length === 1) {
        const user: any = users[0];
        this.showWhiteList = !!user?.bans?.find(ban => ban.bySystem);
      }
    })
  }

  public removeBan(): void {
    this.userBannedListService?.initRemoveBanListener();
    const banIds = this.selectedUsers.reduce((banIds, user) => {
      const activeBans = user?.bans?.filter((ban) => {
        return ban?.status === BanStatusEnum.ACTIVE;
      });
      const userActiveBans = activeBans?.map((ban) => {
        return ban?.id;
      });
      return banIds.concat(userActiveBans)
    }, []);

    this.handleAddWhiteList();
    this.userBannedListService.removeUsersBan(banIds);
  }

  private handleAddWhiteList(): void {
    if (this.selectedUsers?.length === 1 && this.showWhiteList && this.whiteList) {
      const user: any = {id: this.selectedUsers[0]?.userId};
      this.userListService.initAddToWhiteListListener();
      this.userListService.addRemoveToWhiteList(user);
    }
  }

  private _removeUserFromBanList(): void {
    this.removeUserSubscription = this.eventService.subscribe(EventEnum.REMOVE_USER_FROM_BAN_LIST, (selectedUser: UserElementInterface) => {
      if (this.selectedUsers?.length > 0) {
        this.selectedUsers = this.selectedUsers.filter((user) => user !== selectedUser);
      }
    });
  }

  private _closeModal(): void {
    this.closeModalSubscription = this.eventService.subscribe(EventEnum.CLOSE_REMOVE_BAN_MODAL, (reason: string | undefined) => {
      this.modalRef?.dismiss(reason);
      if (this.showWhiteList && this.whiteList) {
        this.userListService.removeWorkingSubscription();
      }
      this.userBannedListService.removeBanUnsubscribe();
      this.showAreYouSure = false;
    });
  }

}

<app-table-topbar (searchTerm)="logTerm($event)"></app-table-topbar>
<div [hidden]="(loading$ | async)" class="col-12 p-0 full-height-table">
  <div *ngIf="bans?.length > 0" class="wrapper">
    <table
      class="table table-bordered align-middle table-nowrap table-hover table-striped mb-0 datatables recorded-activities"
      id="basic-datatable">
      <thead>
      <tr>
        <th *ngIf="checkIfColumnIsVisible(column.BAN_ID)">
          <span class="me-3">{{'BAN_ID' | translate}}</span>
        </th>
        <th *ngIf="checkIfColumnIsVisible(column.BAN_START_DATE)">
          <span class="me-3">{{'BAN_START_DATE' | translate}}</span>
        </th>
        <th *ngIf="checkIfColumnIsVisible(column.BAN_START_HOUR)">
          <span class="me-3">{{'BAN_START_HOUR' | translate}}</span>
        </th>
        <th *ngIf="checkIfColumnIsVisible(column.BAN_END_DATE)">
          <span class="me-3">{{'BAN_END_DATE' | translate}}</span>
        </th>
        <th *ngIf="checkIfColumnIsVisible(column.BAN_END_HOUR)">
          <span class="me-3">{{'BAN_END_HOUR' | translate}}</span>
        </th>
        <th *ngIf="checkIfColumnIsVisible(column.BAN_MOTIVATION)">
          <span class="me-3">{{'BAN_MOTIVATION' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.BY_SYSTEM)">
          <span class="me-3">{{'BY_SYSTEM' | translate}}</span>
        </th>
        <th *ngIf="checkIfColumnIsVisible(column.BAN_STATUS)">
          <span class="me-3">{{'BAN_STATUS' | translate}}</span>
        </th>
        <th *ngIf="checkIfColumnIsVisible(column.BAN_NOTES)">
          <span class="me-3">{{'BAN_NOTES' | translate}}</span>
        </th>
        <th></th>
      </tr>
      </thead>
      <tbody class="no-bg">
      <tr *ngFor="let banDetail of bans | search:'id,banMotivation?.reason':selectedSearchTerm; let i=index;"
          class="no-bg">
        <td *ngIf="checkIfColumnIsVisible(column.BAN_ID)" align="center"
            class="pointer fit-width">
          <ngb-highlight [highlightClass]="'term-highlight'" [result]="banDetail?.id"
                         [term]="userBannedListService.searchTerm"
                         class="badge badge-pill badge-soft-primary"></ngb-highlight>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.BAN_START_DATE)" class="pointer">
          <span>{{banDetail?.startsAt}}</span>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.BAN_START_HOUR)" class="pointer">
          <span>{{banDetail?.startsAtHour}}</span>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.BAN_END_DATE)" class="pointer">
          <span>{{banDetail?.endsAt}}</span>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.BAN_END_HOUR)" class="pointer">
          <span>{{banDetail?.endsAtHour}}</span>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.BAN_MOTIVATION)"
            class="pointer wrap-single-cell">
          <span>{{banDetail?.banMotivation?.reason}}</span>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.BY_SYSTEM)" align="center"
            class="pointer fit-width">
          <span *ngIf="banDetail?.bySystem">{{'YES' | translate}}</span>
          <span *ngIf="!banDetail?.bySystem">{{'NO' | translate}}</span>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.BAN_STATUS)" align="center"
            class="pointer">
                    <span *ngIf="banDetail?.status === banStatus.EXPIRED"
                          class="badge badge-pill badge-soft-danger">{{banDetail?.status}}</span>
          <span *ngIf="banDetail?.status === banStatus.ACTIVE"
                class="badge badge-pill badge-soft-success">{{banDetail?.status}}</span>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.BAN_NOTES)"
            class="pointer wrap-single-cell">
          <span>{{banDetail?.note}}</span>
        </td>
        <td>
          <ul class="list-inline font-size-20 contact-links mb-0">
            <li (click)="editBan(bans[0], banDetail)" class="list-inline-item px-2">
                            <span [ngClass]="{'disabled-opacity': banDetail?.status === banStatus.EXPIRED}"
                                  [ngbTooltip]="extractEditTooltip(banDetail)" class="pointer"
                                  placement="left"><i
                              class="bx bx-pencil"></i></span>
            </li>
          </ul>
        </td>
      </tr>
      </tbody>
    </table>

  </div>
  <div *ngIf="bans?.length === 0" class="col-lg-12">
    <div class="">
      <div class="text-center mt-5">
        <h4 class="text-uppercase">{{'SORRY_NO_RESULTS' | translate}}</h4>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loading$ | async" class="col-12 p-0">
  <div class="wrapper">
    <app-loader [table]="true"></app-loader>
  </div>
</div>
<app-ban-modal #banEditModal [banType]="banType.EDIT_GLOBAL"></app-ban-modal>

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TruncatePipe} from "@app/pipes/truncate/truncate.pipe";
import {SearchPipe} from "@app/pipes/search/search.pipe";
import {RemoveCompaniesPipe} from "@app/pipes/remove-companies/remove-companies.pipe";
import {HourPipe} from "@app/pipes/hour/hour.pipe";

@NgModule({
  declarations: [
    TruncatePipe,
    SearchPipe,
    HourPipe,
    RemoveCompaniesPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TruncatePipe,
    SearchPipe,
    HourPipe,
    RemoveCompaniesPipe
  ]
})
export class CoreModule {
}

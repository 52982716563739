import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject, Subscription} from "rxjs";
import {UserElementInterface} from "@interface/user/user-element.interface";
import {TableStateInterface} from "@interface/common/table-state.interface";
import {EventService} from "@service/common/event.service";
import {ToastService} from "@service/toast.service";
import {UtilsService} from "@service/utils/utils.service";
import {SortDirection} from "@type/common/sort-direction.type";
import {debounceTime, switchMap, tap} from "rxjs/operators";
import {CompetitionsApiService} from "@service/competitions/competitions-api.service";
import {TableColumnEnum} from "@enum/table-column/table-column.enum";
import {TableFilterEnum} from "@enum/table-filter/table-filter.enum";

@Injectable({
  providedIn: 'root'
})
export class CompetitionsService {
  public _search$ = new Subject<void>();
  public visibleColumns: { visible: boolean, key: TableColumnEnum, label: any }[] = this.utils.competitionsTableColumn;
  public visibleFilters: { visible: boolean, key: TableFilterEnum, label: any }[] = this.utils.competitionsTableFilters;
  private _currentTableState: TableStateInterface = this.utils.tableDefaultState;
  private searchCompetitionsSubscription: Subscription;

  constructor(private competitionsApiService: CompetitionsApiService, private eventService: EventService,
              private toastService: ToastService, private utils: UtilsService) {
  }

  get columns() {
    return this.visibleColumns;
  }

  get filters() {
    return this.visibleFilters;
  }


  private _loading$ = new BehaviorSubject<boolean>(true);

  public get loading$() {
    return this._loading$.asObservable();
  }

  private _competitionList$ = new BehaviorSubject<UserElementInterface[]>([]);

  public get competitionList$() {
    return this._competitionList$.asObservable();
  }

  private _totalRecords$ = new BehaviorSubject<number>(0);

  public get totalRecords$() {
    return this._totalRecords$.asObservable();
  }

  public get searchTerm() {
    return this._currentTableState.searchTerm;
  }

  public set searchTerm(searchTerm: string) {
    this._setValue({searchTerm});
  }

  public get pageSize() {
    return this._currentTableState.pageSize;
  }

  public set pageSize(pageSize: number) {
    const page = 1;
    this._setValue({page})
    this._setValue({pageSize});
  }

  public get page() {
    return this._currentTableState.page;
  }

  public set page(page: number) {
    this._setValue({page});
  }

  public get sortColumn() {
    return this._currentTableState.sortColumn;
  }

  public set sortColumn(sortColumn: string) {
    this._setValue({sortColumn});
  }

  public get sortDirection() {
    return this._currentTableState.sortDirection;
  }

  public set sortDirection(sortDirection: SortDirection) {
    this._setValue({sortDirection});
  }

  get active() {
    return this._currentTableState.active;
  }

  set active(active: boolean | undefined | null) {
    this._setValue({active});
  }

  get byPowerLevel() {
    return this._currentTableState.byPowerLevel;
  }

  set byPowerLevel(byPowerLevel: boolean | undefined | null) {
    this._setValue({byPowerLevel});
  }

  public get competitionStatus() {
    return this._currentTableState.competitionStatus;
  }

  public set competitionStatus(competitionStatus: string) {
    this._setValue({competitionStatus});
  }

  public get competitionType() {
    return this._currentTableState.competitionType;
  }

  public set competitionType(competitionType: string) {
    this._setValue({competitionType});
  }

  get createdAt() {
    return this._currentTableState.createdAt;
  }

  set createdAt(createdAt: string[]) {
    this._setValue({createdAt});
  }

  public removeSearchCompetitionsSubscribe(): void {
    this.searchCompetitionsSubscription?.unsubscribe();
    this._loading$.next(false);
  }

  public initSearchCompetitionsListener(companyId?: any): void {
    this.searchCompetitionsSubscription = this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(50),
      switchMap(() => this.competitionsApiService.getCompetitionList(this._extractSearchParams())),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      if (companyId) {
        result = result.filter((competition) => competition?.company?.id === companyId);
      }
      this._competitionList$.next(result);
      this._totalRecords$.next(result?.length);
    });
  }

  public clearFilters(): void {
    this.searchTerm = undefined;
    this.byPowerLevel = undefined;
    this.competitionStatus = undefined;
    this.competitionType = undefined;
    this.active = undefined;
    this.createdAt = undefined;
  }

  public isFilterApplied(): boolean {
    const params: any = this._extractSearchParams();
    if (Object.keys(params?.filters)?.length > 0) {
      const obj = this.utils.clearObject(params?.filters);
      return Object.keys(obj)?.length > 0;
    } else {
      return false;
    }
  }

  private _extractSearchParams(): any {
    return {
      filters: {
        query: this.searchTerm ? [this.searchTerm] : undefined,
        active: (this.active !== undefined && this.active !== null) ? [this.active] : undefined,
        byPowerLevel: (this.byPowerLevel !== undefined && this.byPowerLevel !== null) ? [this.byPowerLevel] : undefined,
        status: (this.competitionStatus !== undefined && this.competitionStatus !== null) ? [this.competitionStatus] : undefined,
        type: (this.competitionType !== undefined && this.competitionType !== null) ? [this.competitionType] : undefined,
        createdAt: this.createdAt && this.createdAt?.length > 0 ? this.createdAt : undefined,
      },
      sort: this.extractSorting(),
      page: this.page,
      size: this.pageSize
    }
  }

  private _setValue(patch: Partial<TableStateInterface>) {
    Object.assign(this._currentTableState, patch);
    this._search$.next();
  }

  private extractSorting(): string {
    return this.utils.extractSorting(this.sortColumn, this.sortDirection);
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {TopbarTypeEnum} from "@enum/topbar/topbar-type.enum";
import {InvitesService} from "@service/utility/invites.service";
import {Router} from "@angular/router";
import {ToastService} from "@service/toast.service";
import {SortEventInterface} from "@interface/common/sort-event.interface";

@Component({
  selector: 'app-prize-list',
  templateUrl: './prize-list.component.html',
  styleUrls: ['./prize-list.component.scss']
})
export class PrizeListComponent implements OnInit {
  @Input() userId: number;
  public total$: Observable<number>;
  public loading$: Observable<boolean>;
  public topBarType = TopbarTypeEnum.INVITES_USES;
  public invites: any = [];

  constructor(public invitesService: InvitesService, private router: Router, private toastService: ToastService) {
  }

  ngOnInit(): void {
    this.invitesService.removeSearchUsesSubscribe();
    this.invitesService.clearFilters();
    this.total$ = this.invitesService.totalRecords$;
    this.loading$ = this.invitesService.loading$;
    this.invitesService.userId = this.userId;
    this.invitesService.initSearchListListener();
    this.invitesService._search$.next();
    this.invitesService.inviteList$.subscribe((list: any) => {
      this.invites = list;
    })
  }

  public extractRowNumber(index: any): string {
    return (this.invitesService.page - 1) * this.invitesService.pageSize + index + 1;
  }

  public userDetail(invite: any): void {
    if (invite?.inviter) {
      this.router.navigate(['users/detail', String(invite?.inviter?.userId)]);
    }
  }

  public sort({column, direction}: SortEventInterface) {
    this.invitesService.sortColumn = column;
    this.invitesService.sortDirection = direction;
  }

  public inviteDetail(invite: any, detailPage?: boolean): void {
    if (invite?.usesCount > 0) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`/utility/invites/uses/${invite?.id}`])
      );
      window.open(url, '_blank');
    }
  }

  public extractInviteUsesTooltip(invite: any): string {
    if (invite?.usesCount > 0) {
      return 'View who is using this code';
    } else {
      return 'No one is using this code'
    }
  }

  public expandInvite(invite: any): any {
    if (invite?.usesCount > 0) {
      if (!invite.users) {
        this.invitesService.getInviteUses(invite).subscribe((result: any) => {
          invite.users = result?.data ? result?.data : [];
        });
      }
      invite.expand = !invite.expand;
    }
  }

  public usedByDetail(user): void {
    if (user) {
      this.router.navigate(['users/detail', String(user?.userId)]);
    }
  }

  public extractInviteTooltip(invite: any): string | null {
    if (invite?.usesCount <= 0) {
      return 'No one is using this code'
    } else {
      return null;
    }
  }


  public copied(event: any): void {
    this.toastService.show('Copied - ' + event?.content, {classname: 'bg-secondary text-light'});
  }


  ngOnDestroy() {
    this.invitesService.removeSearchListSubscribe();
  }

}

<form (ngSubmit)="validateForm()" [autocomplete]="'off'" [formGroup]="notificationForm" autocomplete="off"
      class="needs-validation">
  <div class="row mt-3">
    <div class="col-12">
      <div class="row">
        <div class="col-md-6">
          <div class="mb-3">
            <h5 class="card-title">{{'SELECT_CONTENT_DESC' | translate}}</h5>
          </div>
        </div>
        <div class="col-md-12">
          <div class="mb-3">
            <label class="font-weight-bold" for="locale">Choose notification language</label>
            <p class="card-title-desc mb-3">
              This language is the one related to the end users, <span class="badge badge-pill badge-soft-danger font-size-11">please be sure</span>  to write the title and message below in the language selected here.
            </p>
            <select [(ngModel)]="selectedLanguage" class="form-select" formControlName="locale" id="locale"  [ngClass]="{'is-invalid': formSubmitted && form.locale.errors}">
              <option *ngFor="let lang of languages" [value]="lang.lang">
                {{lang.text}}
              </option>
            </select>
            <div *ngIf="formSubmitted && form.locale.errors" class="invalid-feedback">
              <span *ngIf="formSubmitted && form.locale.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="mb-3">
            <label class="font-weight-bold" for="title">{{'TITLE' | translate}}</label>
            <p class="card-title-desc mb-1">
              This field allows you to enter the title of the notification. The max length is 65 characters
            </p>
            <input [ngClass]="{'is-invalid': formSubmitted && form.title.errors}" class="form-control"
                   formControlName="title" id="title" type="text">
            <div *ngIf="formSubmitted && form.title.errors" class="invalid-feedback">
              <span *ngIf="formSubmitted && form.title.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
              <span *ngIf="form.title.errors.maxlength">This value is too long. It should have 65 characters or fewer.</span>
              <span *ngIf="form.title.errors.minlength">This value is too short. It should have 5 characters or more.</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="mb-3">
          <label class="form-label font-weight-semibold mb-1" for="message">{{'MESSAGE' | translate}}</label>
          <p class="card-title-desc mb-1">
            This field allows you to enter the message of the notification. The max length is 178 characters
          </p>
          <div>
            <textarea [ngClass]="{'is-invalid': formSubmitted && form.message.errors}" class="form-control description-textarea"
                      formControlName="message" id="message" rows="4"></textarea>
            <div *ngIf="formSubmitted && form.message.errors" class="invalid-feedback">
              <span *ngIf="formSubmitted && form.message.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
              <span *ngIf="form.message.errors.maxlength">This value is too long. It should have 178 characters or fewer.</span>
              <span *ngIf="form.message.errors.minlength">This value is too short. It should have 5 characters or more.</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="">
            <app-select-actions (selectedAction)="logForm($event)" [editData]="notificationData"
                                [limitToOpenLink]="limitToOpenLink"
                                [users]="notificationUsers"
                                [formSubmitted]="formSubmitted" [parentForm]="notificationForm"></app-select-actions>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="mb-5">
            <app-image-uploader [previousSelectedImage]="{name: this.notificationData?.imageName, originalUrl: this.notificationData?.imageUrl, mimeType: this.notificationData?.imageType}"
                                (imageUploaded)="setImage($event)"></app-image-uploader>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ul *ngIf="!massiveNotification" class="list-inline wizard mb-0">
    <li class="previous list-inline-item">
      <button awPreviousStep class="btn btn-outline-light">{{'BACK' | translate}}</button>
    </li>
    <li class="next list-inline-item float-end">
      <button [hidden]="!valid" awNextStep class="btn btn-primary" type="submit">{{'CONTINUE' | translate}}</button>
      <button [hidden]="valid" class="btn btn-primary" type="submit">{{'CONTINUE' | translate}}</button>
    </li>
  </ul>
  <div *ngIf="massiveNotification">
    <ul class="list-inline wizard mb-0">
      <li class="previous list-inline-item">
        <button (click)="goBackStep()" class="btn btn-outline-light">{{'BACK' | translate}}</button>
      </li>
      <li class="next list-inline-item float-end">
        <button class="btn btn-primary" type="submit">{{'CONTINUE' | translate}}</button>
      </li>
    </ul>
  </div>
</form>

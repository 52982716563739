import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {EventService} from "@service/common/event.service";
import {EventEnum} from "@enum/event/event.enum";
import {StreakTemplateService} from "@service/streak/streak-template.service";

@Component({
  selector: 'app-add-streak-template-modal',
  templateUrl: './add-streak-template-modal.component.html',
  styleUrls: ['./add-streak-template-modal.component.scss']
})
export class AddStreakTemplateModalComponent implements OnInit {
  @Input() streakTemplate: any;
  @ViewChild('modal') private modalContent: TemplateRef<any>
  public creatingTemplate$: Observable<boolean>;
  public formSubmitted: boolean;
  public streakTemplateForm: FormGroup;
  private modalRef: NgbModalRef;
  private closeModalSubscription: Subscription;
  public limit: any = {
    fixedSize: true,
    ratio: '1:1',
    width: 120,
    height: 120
  };
  public fitnessScoringFunctions = [];
  public fitnessScoringFunction = undefined;

  constructor(private modalService: NgbModal, private eventService: EventService,
              public formBuilder: FormBuilder, private streakTemplateService: StreakTemplateService) {
  }

  get form() {
    return this.streakTemplateForm.controls;
  }

  ngOnInit(): void {
    this._closeModal();
    this.creatingTemplate$ = this.streakTemplateService?.creating$;
    this.initForm();
    this.extractFitnessScoring();
  }

  private extractFitnessScoring(): void {
    this.streakTemplateService.getFitnessScoring().subscribe((result) => {
      this.fitnessScoringFunctions = result?.length > 0 ? result : [];
    });
  }

  public setTemplate(streakTemplate: any): void {
    this.streakTemplate = streakTemplate;
    this.patchFormWithEditingData();
  }

  public validSubmit() {
    if (this.streakTemplateForm.valid) {
      let formData = this.streakTemplateForm.getRawValue();
      this.streakTemplateService?.initCreateListener();
      if (this.streakTemplate) {
        formData.id = this.streakTemplate?.id;
      }
      this.streakTemplateService.createStreakTemplate(formData);
    }
  }

  public createStreakTemplate() {
    this.formSubmitted = true;
  }

  public open(data?: any): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, {size: 'lg', centered: true, backdrop: false})
      this.modalRef.result.then(resolve, resolve);
      if (data?.scoringFunction) {
        this.fitnessScoringFunction = data?.scoringFunction;
        this.setTemplate(data);
      }
    })
  }

  private initForm() {
    this.streakTemplateForm = this.formBuilder.group({
      scoringFunction: [null, [Validators.required]],
      title: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(100)]],
      descriptionTemplate: [null, [Validators.required, Validators.minLength(5),
        Validators.maxLength(100), this.ValidateFirstDescription, this.ValidateSecondDescription]],
      lockedImageUrl: [null, [Validators.required]],
      unlockedImageUrl: [null, [Validators.required]],
    });
  }

  public ValidateFirstDescription(control: AbstractControl): {[key: string]: any} | null  {
    if (control?.value && !control?.value?.includes('{0}')) {
      return { placeholderFirst: true }
    }
    return null;
  }

  public ValidateSecondDescription(control: AbstractControl): {[key: string]: any} | null  {
    if (control?.value && !control?.value?.includes('{1}')) {
      return { placeholderSecond: true }
    }
    return null;
  }

  public setImage(image: any): void {
    this.streakTemplateForm.patchValue({
      lockedImageUrl: image ? image?.originalUrl : undefined,
      lockedImageName: image ? image?.name : undefined,
      lockedImageType: image ? image?.mimeType : undefined,
    });
    console.log(this.streakTemplateForm.getRawValue());
  }

  public setUnlockedImage(image: any): void {
    this.streakTemplateForm.patchValue({
      unlockedImageUrl: image ? image?.originalUrl : undefined,
      unlockedImageName: image ? image?.name : undefined,
      unlockedImageType: image ? image?.mimeType : undefined,
    });
  }

  public checkLockedImageError(): string | undefined {
    if (this.formSubmitted && this.form.lockedImageUrl.errors?.required) {
      return 'This value is required';
    } else {
      return undefined;
    }
  }

  public checkUnlockedImageError(): string | undefined {
    if (this.formSubmitted && this.form.unlockedImageUrl.errors?.required) {
      return 'This value is required';
    } else {
      return undefined;
    }
  }

  public checkSelectedLockedImage(): object | undefined {
    if (this.form.lockedImageUrl?.value) {
      return {originalUrl: this.form.lockedImageUrl?.value, name: [this.form.title?.value?.replace(/\s/g, "_"), 'locked_image'].join('_')}
    } else {
      return undefined;
    }
  }

  public checkSelectedUnlockedImage(): object | undefined {
    if (this.form.unlockedImageUrl?.value) {
      return {originalUrl: this.form.unlockedImageUrl?.value, name: [this.form.title?.value?.replace(/\s/g, "_"), 'unlocked_image'].join('_')}
    } else {
      return undefined;
    }
  }


  private patchFormWithEditingData() {
    this.streakTemplateForm.patchValue({
      scoringFunction: this.streakTemplate?.scoringFunction,
      title: this.streakTemplate?.title,
      descriptionTemplate: this.streakTemplate?.descriptionTemplate,
      lockedImageUrl: this.streakTemplate?.lockedImageUrl,
      unlockedImageUrl: this.streakTemplate?.unlockedImageUrl,
    });
    if (this.streakTemplate.duplicate) {
      this.streakTemplate = undefined;
    }
  }

  private _closeModal(): void {
    this.closeModalSubscription = this.eventService.subscribe(EventEnum.CLOSE_CREATE_STREAK_TEMPLATE, (reason: string | undefined) => {
      this.streakTemplateService?.removeTemplateCreateSubscribe();
      this.initForm();
      this.fitnessScoringFunction = undefined;
      this.formSubmitted = false;
      this.streakTemplate = undefined;
      this.modalRef?.dismiss(reason);
    });
  }
}

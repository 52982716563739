import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgbDate, NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {BanTypeEnum} from "@enum/ban-type/ban-type.enum";
import {BanMotivationInterface} from "@interface/common/ban-motivation.interface";
import {UsersApiService} from "@service/users/users-api.service";
import {Observable, Subscription} from "rxjs";
import {UserElementInterface} from "@interface/user/user-element.interface";
import {EventService} from "@service/common/event.service";
import {EventEnum} from "@enum/event/event.enum";
import {UserListService} from "@service/users/user-list.service";

@Component({
  selector: 'app-ban-modal',
  templateUrl: './ban-modal.component.html',
  styleUrls: ['./ban-modal.component.scss']
})
export class BanModalComponent implements OnInit, OnDestroy {
  @Input() users: UserElementInterface[] = [];
  @Input() banType: BanTypeEnum;
  public ban = BanTypeEnum;
  public startDateHour: NgbDate;
  public endDateHour: NgbDate;
  public banData: any = undefined;
  public banning$: Observable<boolean>;
  public banMotivations$: Observable<BanMotivationInterface[]>;
  public editingData: any;
  public finalStep = false;
  @ViewChild('modal') private modalContent: TemplateRef<any>
  private modalRef: NgbModalRef;
  private removeUserSubscription: Subscription;
  private closeModalSubscription: Subscription;

  constructor(private modalService: NgbModal, private usersApiService: UsersApiService,
              private eventService: EventService, private userListService: UserListService,
              private changeDetection: ChangeDetectorRef) {
    this.banning$ = userListService.banning$;
  }

  ngOnInit(): void {
    this._extractBanMotivations();
    this._removeUserFromBanList();
    this._closeModal();
  }

  ngOnDestroy() {
    if (this.removeUserSubscription) {
      this.removeUserSubscription.unsubscribe();
    }
    if (this.closeModalSubscription) {
      this.closeModalSubscription.unsubscribe();
    }
  }

  public open(data?: any): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, {size: 'lg', centered: true, backdrop: false})
      this.modalRef.result.then(resolve, resolve)
      if (!!data) {
        this.setEditData(data);
      }
    })
  }

  public setUsers(usersId: UserElementInterface[]): void {
    this.users = usersId;
  }

  public extractBanTypeTitle(): string {
    switch (this.banType) {
      case BanTypeEnum.GLOBAL:
        return 'GLOBAL_BAN';
      case BanTypeEnum.COMPANY:
        return 'COMPANY_BAN';
      case BanTypeEnum.COMPETITION:
        return 'COMPETITION_BAN';
      case BanTypeEnum.EDIT_GLOBAL:
        return 'EDIT_BAN';
      default:
        return 'GLOBAL_BAN';
    }
  }

  public setBanData(data: any): void {
    this.banData = data;
    this.banData.usersToBan = this.users;
    this.changeDetection.detectChanges();
  }

  public banUsers(data: any): void {
    data.usersToBan = data?.usersToBan?.map((user) => {
      return user?.id;
    });
    data.banType = BanTypeEnum.GLOBAL;
    this.userListService.createBan(data);
  }

  public _extractBanMotivations(): void {
    if (!this.banType) {
      return;
    } else {
      this.banMotivations$ = this.usersApiService.getBanMotivations({banType: this.banType});
    }
  }

  private setEditData(data: any): void {
    this.users = [data?.user];
    this.editingData = data;
  }

  private _removeUserFromBanList(): void {
    this.removeUserSubscription = this.eventService.subscribe(EventEnum.REMOVE_USER_FROM_BAN_LIST, (selectedUser: UserElementInterface) => {
      if (this.users?.length > 0) {
        this.users = this.users.filter((user) => user !== selectedUser);
      }
    });
  }

  private _closeModal(): void {
    this.closeModalSubscription = this.eventService.subscribe(EventEnum.CLOSE_BAN_MODAL, (reason: string | undefined) => {
      this.banData = null;
      this.modalRef?.dismiss(reason);
    });
  }

}
